<template>
  <div>
    <Header2></Header2>
    <Banner></Banner>
    <!-- <Ranking></Ranking> -->
    <!-- <textarea name="" id="" cols="30" value="asdasdasdasdasd" rows="10"></textarea> -->
    <RemoveGameList></RemoveGameList>
    <Age></Age>
    <Report></Report>
    <ReturnTop></ReturnTop>
    <Footer></Footer>
    <Login class="login" ref="login"></Login>
  </div>
</template>

<script>

import Header2 from '../components/Header2.vue';
import Footer from '../components/Footer.vue';
import Banner from '../components/Banner.vue';
import Ranking from '../components/Ranking.vue';
import RemoveGameList from '../components/RemoveGameList.vue';
import Age from '../components/Age.vue'
import Report from '../components/Report.vue'
import ReturnTop from '../components/ReturnTop.vue'
import { mapState } from 'vuex';
    export default {
        name:'RemoveGame',
        components:{
            Header2,
            Footer,
            Banner,
            Ranking,
            RemoveGameList,
            Age,
            Report,
            ReturnTop
        },
        data() {
          return {
          }
        },
        computed:{
          ...mapState([
            'getClient',
            'material'
          ])
        },
        methods:{
          openLogin(){
            this.$refs.login.open()
          }
        },
       
        
    }
</script>

<style scoped>

</style>