<template>
  <div class="july_box">
    <img class="btn_hover ljcz_btn" src="/activity/july/img/btn.png" @click="toRecharge">
  </div>
</template>

<script>
export default {
    name: 'JulyEnd',
   
    data() {
        return {
        }
    },
    methods:{
      toRecharge(){
        this.$router.push({
          path:'/member/wallet'
        })
      }
    },
    created(){
     
    }
}
</script>

<style scoped>
.july_box{
    background: url('/public/activity/july/img/bg.png') no-repeat;
   background-size: 1920px 1080px;
   min-width: 1903px;
   min-height: 1080px;
   position: relative;
}
.ljcz_btn{
  position: absolute;
  bottom: 2%;
    right: 33%;
}
</style>