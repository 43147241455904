<template>
    <div class="phonebind">
        <div class="bag_title">
            <h4 class="font_color">完善信息</h4>
            <div class="tip">
                <div class="tip_icon flex_box font_color">?</div>
                <span class="font_color">如遇充值问题，请联系任意平台客服</span>
            </div>
        </div>
        <div class="phone_box">
            <div class="form_tel">
                <div class="phone flex_box">
                    <label class="lab_font">手机号：</label>
                    <input class="phone_input" type="tel" v-model="phone">
                </div>
                <div class="yzm flex_box">
                    <label class="lab_font">验证码：</label>
                    <div class="yzm_input flex_box">
                        <input class="code_input" type="text" v-model="code">
                        <a class="yzm_title flex_box lab_font" @click="getCode" v-if="isCode">获取验证码</a>
                        <a class="yzm_title flex_box lab_font" v-else>{{ count }}秒后重发</a>
                    </div>
                </div>
                <a class="flex_box tel_btn tel_btn_color tel_btn_font" @click="submit()">立即提交</a>
            </div>

        </div>
    </div>
</template>

<script>
import { bindPhone, messageVerification } from '@/api/http.js'
import { mapState } from 'vuex'
export default {
    name: 'PhoneBinding',
    data() {
        return {
            phone: '',
            code: '',
            sms_token: '',
            isCode: true, //判断验证码按钮
            count: '',
            timer: null,
            bool: true, //节流
        }
    },
    computed: {
        ...mapState([
            'userInfo'
        ])
    },
    methods: {
        getCode() {
            this.messageVerification()
            console.log('我触发了')
            const num = 60;
            if (!this.timer) {
                this.count = num;
                this.isCode = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= num) {
                        this.count--;
                    } else {
                        this.isCode = true;
                        clearInterval(this.timer);
                        this.bool = true
                        this.timer = null;
                    }
                }, 1000);
            }
        },
        //短信验证
        messageVerification() {
            messageVerification({
                mobile: this.phone,
                scene: 'binding_mobile'
            }).then((res) => {
                if (res.code === 200) {
                    this.isCode = false
                    this.sms_token = res.data.sms_token
                    this.$message({
                        message: '短信发送成功！',
                        type: 'success',
                        duration: 1500
                    })
                }
            }).catch((err) => {
                this.isCode = true
                clearInterval(this.timer);
                this.timer = null;
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        },
        bindPhone() {
            var tok = localStorage.getItem('token')
            if (!tok) {
                this.$message({
                    message: "请登录后再试！",
                    type: 'warning',
                    onClose: () => {
                        this.bool = true
                    },
                    duration: 1500
                });
            } else {
                if (this.userInfo.user_mobile) {
                    this.$message({
                        message: "已绑定手机号！",
                        type: 'warning',
                        onClose: () => {
                            this.bool = true
                        },
                        duration: 1500
                    });
                } else {
                    this.$confirm(`该手机号若在平台已经注册,并且未绑定微信系统将自动合并,合并后请重新登入!`, '注意', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true
                    }).then(() => {
                        bindPhone({
                            mobile: this.phone,
                            code: this.code,
                            sms_token: this.sms_token
                        }).then((res) => {
                            if (res.code === 200) {
                                this.$message({
                                    message: res.msg,
                                    type: 'success',
                                    onClose: () => {
                                        this.bool = true
                                        this.$router.push({
                                            path:'/member'
                                        })
                                        window.location.href=window.location.href
                                    },
                                    duration: 1500
                                });
                            }
                        }).catch((err) => {
                            console.log(err)
                            // this.$message({
                            //     message: err.msg,
                            //     type: 'warning',
                            //     onClose: () => {
                            //         this.bool = true
                            //     },
                            //     duration: 1500
                            // });
                            if(err.msg == 30002){
                                localStorage.setItem('token','')
                                this.$message({
                                    message: "账号已经合并，请重新登录！",
                                    type: 'success',
                                    onClose: () => {
                                        this.bool = true
                                        this.$router.push({
                                            path:'/home'
                                        })
                                    },
                                    duration: 1500
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消绑定'
                        });
                    });
                }

            }

        },
        submit() {
            if (this.sms_token) {
                if (this.bool) {
                    this.bool = false
                    this.bindPhone()
                } else {
                    this.$message({
                        message: '操作过于频繁，请稍后再试！',
                        type: 'warning',
                        duration: 1500
                    })
                }
            } else {
                this.$message({
                    message: '请获取验证码！',
                    type: 'warning',
                    duration: 1500
                })
            }

        }
    },
}
</script>

<style scoped>
a {
    text-decoration: none;
    cursor: pointer;
}

input {
    outline-style: none;
}

.phonebind {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 4px;
    height: 100%;
    padding: 30px 40px;
}

.bag_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(178, 147, 96, 0.2);
}

.bag_title h4 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin: 0;
}

.tip {
    display: flex;
    align-items: center;
    justify-content: end;
}

.tip_icon {
    width: 13px;
    height: 13px;
    border: 1px solid #806437;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-right: 5px;
}

.tip span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
}

.font_color {
    color: #886A35;
}

/*  */
.phone_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.form_tel {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
}

.lab_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4D4D4D;
}

.phone_input {
    width: 410px;
    height: 50px;
    border: 1px solid #B3B3B3;
    border-radius: 8px;
}

.yzm {
    margin-top: 10px;
}

.code_input {
    width: 307px;
    height: 50px;
    border: 1px solid #B2B2B2;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

/* .code_input:focus + .yzm_title{
        border-color: #AC8747;
    } */
.yzm_title {
    width: 102px;
    height: 50px;
    border: 1px solid #B2B2B2;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tel_btn {
    width: 410px;
    height: 50px;
    border-radius: 8px;
    margin-top: 20px;
}

.tel_btn_color {
    background: #B5945B;
    transition: all 0.5s;
}

.tel_btn_color:hover {
    filter: brightness(1.2);
    transition: all 0.5s;
}

.tel_btn_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

@media (max-width:1600px) {
    .phonebind {
        padding: 20px 30px;
    }

    .bag_title h4 {
        font-size: 20px;
    }
}

@media (max-width:1300px) {

    /* top */
    .bag_title h4 {
        font-size: 18px;
    }

    .tip span {
        font-size: 12px;
    }

}

@media (max-width:1100px) {

    /* top */
    .bag_title h4 {
        font-size: 16px;
    }

    .tip span {
        font-size: 12px;
    }

    .lab_font {
        font-size: 12px;
    }

    .tel_btn_font {
        font-size: 12px;
    }

    .phone_input {
        width: 310px;
        height: 35px;
    }

    .code_input {
        width: 207px;
        height: 35px;
    }

    .yzm_title {
        height: 35px;
    }

    .tel_btn {
        width: 310px;
        height: 35px;
    }
}
</style>