<template>
  <div>
    <div class="doubleElevenEgg_top ">
        <div class="top_info_box">
            <div class="jzjl">
                <img class="mr-1" src="/activity/doubleTwelveEgg/img/tip1.png" height="76px">
                <div class="reach_list">
                    <div class="list_item">
                        <span class="list_str">“双12会员狂欢节”</span>
                        <span class="jl_text">奖励：<span class="num_color">5888</span></span>
                        <div class="reach_btn flex_box btn_hover" @click="CollectiveWord('双,12,会,员,狂,欢,节')"><span>点击兑换</span></div>
                    </div>
                    <div class="list_item">
                        <span class="list_str">“会员狂欢节”</span>
                        <span class="jl_text">奖励：<span class="num_color">1888</span></span>
                        <div class="reach_btn flex_box btn_hover" @click="CollectiveWord('会,员,狂,欢,节')"><span>点击兑换</span></div>
                    </div>
                    <div class="list_item">
                        <span class="list_str">“狂欢节”</span>
                        <span class="jl_text">奖励：<span class="num_color">888</span></span>
                        <div class="reach_btn flex_box btn_hover" @click="CollectiveWord('狂,欢,节')"><span>点击兑换</span></div>
                    </div>
                    <div class="list_item">
                        <span class="list_str">“会员”</span>
                        <span class="jl_text">奖励：<span class="num_color">588</span></span>
                        <div class="reach_btn flex_box btn_hover" @click="CollectiveWord('会,员')"><span>点击兑换</span></div>
                    </div>
                    <div class="list_item">
                        <span class="list_str">“双12”</span>
                        <span class="jl_text">奖励：<span class="num_color">588</span></span>
                        <div class="reach_btn flex_box btn_hover" @click="CollectiveWord('双,12')"><span>点击兑换</span></div>
                    </div>
                </div>
            </div>
            <img class="xian" src="/activity/doubleTwelveEgg/img/xian.png" >
            <div class="jzjl">
                <img class="mr-1" src="/activity/doubleTwelveEgg/img/tip2.png" height="76px">
                <span class="rule_text">
                    1.单笔充值对应金额，可以获得砸金蛋次数；<br>
                    2.每个金蛋对应随机奖励和“字符”，每轮金蛋砸完自动重置； <br>
                    3.集齐对应“字符”,可以换取额外奖励;<br>
                    4.奖励储值点发放到会员账号，储值点有效期90天。
                </span>
            </div>
        </div>
        <div class="take_box">
            <div class="take_item">
                <div class="times_text">
                    单笔充值100<br>
                    砸金蛋1次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值200<br>
                    砸金蛋2次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值300<br>
                    砸金蛋3次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值500<br>
                    砸金蛋6次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值1000<br>
                    砸金蛋13次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值1500<br>
                    砸金蛋19次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值2000<br>
                    砸金蛋26次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值2500<br>
                    砸金蛋32次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
            <div class="take_item">
                <div class="times_text">
                    单笔充值3000<br>
                    砸金蛋40次
                </div>
                <!-- 背景 -->
                <img src="/activity/doubleTwelveEgg/img/card.png">
            </div>
        </div>
        <div class="num_bag" v-if="isactive">
            <div class="bag_item" v-for="item in word_arr" :key="item.id">
                <div class="bag_text">{{item.quantity}}张</div>
                <img :src="item.img">
            </div>
        </div>
        <div class="num_bag" v-else>
            <div class="bag_item" >
                <div class="bag_text">0张</div>
                <img src="/activity/doubleTwelveEgg/img/1.png">
            </div>
            <div class="bag_item" >
                <div class="bag_text">0张</div>
                <img src="/activity/doubleTwelveEgg/img/2.png">
            </div>
            <div class="bag_item" >
                <div class="bag_text">0张</div>
                <img src="/activity/doubleTwelveEgg/img/3.png">
            </div>
            <div class="bag_item" >
                <div class="bag_text">0张</div>
                <img src="/activity/doubleTwelveEgg/img/4.png">
            </div>
            <div class="bag_item" >
                <div class="bag_text">0张</div>
                <img src="/activity/doubleTwelveEgg/img/5.png">
            </div>
            <div class="bag_item" >
                <div class="bag_text">0张</div>
                <img src="/activity/doubleTwelveEgg/img/6.png">
            </div>
            <div class="bag_item" >
                <div class="bag_text">0张</div>
                <img src="/activity/doubleTwelveEgg/img/7.png">
            </div>
        </div>
        <div class="info_box">
            <span class="info_text">单个金蛋随机奖励:1111、888、588、388、288、188</span>
            <img src="/activity/doubleTwelveEgg/img/bg2.png">
        </div>
        <div class="active_one ">
            <div class="active_one_content">
                <div class="egg_box">
                    <div class="egg_item" >
                        <div class="egg_item_box">
                            <div class="click_zz"
                                v-if="egg_arr[0] ? !egg_arr[0].place : false"
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(1)"
                            >
                            </div>
                            <div v-if="isBroken_1 == true" class="animation_egg"></div>
                            <img class="egg" v-else-if="egg_arr[0] ? egg_arr[0].place : false" src="/activity/doubleTwelveEgg/img/long2.png">
                            <img class="egg" v-else src="/activity/doubleTwelveEgg/img/long.png">
                            <img class="dizuo" src="/activity/doubleTwelveEgg/img/dizuo.png">
                        </div>
                    </div>
                    <div class="egg_item" >
                        <div class="egg_item_box">
                            <div class="click_zz"
                                v-if="egg_arr[1] ? !egg_arr[1].place : false"
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(2)"
                            >
                            </div>
                            <div v-if="isBroken_2 == true" class="animation_egg"></div>
                            <img class="egg" v-else-if="egg_arr[1] ? egg_arr[1].place : false" src="/activity/doubleTwelveEgg/img/long2.png">
                            <img class="egg" v-else src="/activity/doubleTwelveEgg/img/long.png">
                            <img class="dizuo" src="/activity/doubleTwelveEgg/img/dizuo.png">
                        </div>
                    </div>
                    <div class="egg_item" >
                        <div class="egg_item_box" >
                            <div class="click_zz"
                                v-if="egg_arr[2] ? !egg_arr[2].place : false"
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(3)"
                            >
                            </div>
                            <div v-if="isBroken_3 == true" class="animation_egg"></div>
                            <img class="egg" v-else-if="egg_arr[2] ? egg_arr[2].place : false" src="/activity/doubleTwelveEgg/img/long2.png">
                            <img class="egg" v-else src="/activity/doubleTwelveEgg/img/long.png">
                            <img class="dizuo" src="/activity/doubleTwelveEgg/img/dizuo.png">
                        </div>
                    </div>
                </div>
                <div class="egg_box egg_lang2">
                    <div class="egg_item" >
                        <div class="egg_item_box" >
                            <div class="click_zz"
                                v-if="egg_arr[3] ? !egg_arr[3].place : false"
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(4)"
                            >
                            </div>
                            <div v-if="isBroken_4 == true" class="animation_egg"></div>
                            <img class="egg" v-else-if="egg_arr[3] ? egg_arr[3].place : false" src="/activity/doubleTwelveEgg/img/long2.png">
                            <img class="egg" v-else src="/activity/doubleTwelveEgg/img/long.png">
                            <img class="dizuo" src="/activity/doubleTwelveEgg/img/dizuo.png">
                        </div>
                    </div>
                    <div class="egg_item" >
                        <div class="egg_item_box" >
                            <div class="click_zz"
                                v-if="egg_arr[4] ? !egg_arr[4].place : false"
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(5)"
                            >
                            </div>
                            <div v-if="isBroken_5 == true" class="animation_egg"></div>
                            <img class="egg" v-else-if="egg_arr[4] ? egg_arr[4].place : false" src="/activity/doubleTwelveEgg/img/long2.png">
                            <img class="egg" v-else src="/activity/doubleTwelveEgg/img/long.png">
                            <img class="dizuo" src="/activity/doubleTwelveEgg/img/dizuo.png">
                        </div>
                    </div>
                    <div class="egg_item" >
                        <div class="egg_item_box" >
                            <div class="click_zz"
                                v-if="egg_arr[5] ? !egg_arr[5].place : false"
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(6)"
                            >
                            </div>
                            <div v-if="isBroken_6 == true" class="animation_egg"></div>
                            <img class="egg" v-else-if="egg_arr[5] ? egg_arr[5].place : false" src="/activity/doubleTwelveEgg/img/long2.png">
                            <img class="egg" v-else src="/activity/doubleTwelveEgg/img/long.png">
                            <img class="dizuo" src="/activity/doubleTwelveEgg/img/dizuo.png">
                        </div>
                    </div>
                    <div class="egg_item" >
                        <div class="egg_item_box" >
                            <div class="click_zz"
                                v-if="egg_arr[6] ? !egg_arr[6].place : false"
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(7)"
                            >
                            </div>
                            <div v-if="isBroken_7 == true" class="animation_egg"></div>
                            <img class="egg" v-else-if="egg_arr[6] ? egg_arr[6].place : false" src="/activity/doubleTwelveEgg/img/long2.png">
                            <img class="egg" v-else src="/activity/doubleTwelveEgg/img/long.png">
                            <img class="dizuo" src="/activity/doubleTwelveEgg/img/dizuo.png">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class=" reward">
            <div class="rule_title">
                <div class="reward_title">
                    <span v-if="isactive">会员ID：{{userID}}<br>剩余砸蛋次数：{{ onedata }}</span>
                    <span v-else>活动已结束</span>
                </div>
            </div>
        </div>
        <img class="ljcz_btn btn_hover" @click="toReach" src="/activity/doubleTwelveEgg/img/ljcz.png">
    </div>
  
    <!-- 弹窗 -->
    <div class="leader_tc">
        <div class="tc_title">
            <h5>恭喜您获得</h5>
            <img :src="word" width="40px">
            <span>{{money}}福利点</span>
        </div>
        <img class="leader_btn" src="/activity/doubleTwelveEgg/img/btn.png">
        <!-- 背景 -->
        <img class="leader_bg" src="/activity/doubleTwelveEgg/img/leader_bg.png">
    </div>
  </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {Double12,TwelveReceive,CollectiveWord} from '@/api/http.js'
export default {
    name: 'TwelveEgg',
    data() {
      return {
        isBroken_1:false,
        isBroken_2:false,
        isBroken_3:false,
        isBroken_4:false,
        isBroken_5:false,
        isBroken_6:false,
        isBroken_7:false,
        isClick: true, //防抖
        colloct:true,//防抖
        isactive:false, //活动是否开启
        money:'', //砸中的金额
        onedata:0, 
        eggdata:[],//所有蛋的信息
        reset:false,//当日是否可以重置
        money:[],
        word_arr:[],
        userID:'',
        img_url:'',
        word:'',
        egg_arr:[]
      }
    },
    methods: {
        changeCursor() {
            // console.log('鼠标进入');
            document.body.style.cursor = "url('/activity/doubleTwelveEgg/img/before.cur'), auto";
        },
        resetCursor() {
            // console.log('鼠标移出');
            document.body.style.cursor = "default";
        },
        liftHammer() {
            // console.log('鼠标按下');
            document.body.style.cursor = "url('/activity/doubleTwelveEgg/img/after.cur'), auto";
        },
        dropAndHitHammer(type) {
            // console.log('鼠标松开');
            document.body.style.cursor = "url('/activity/doubleTwelveEgg/img/before.cur'), auto";
            if(this.isClick){
                this.isClick = false
                this.TwelveReceive(type)  // 敲击后破裂金蛋
            }else{
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.isClick = true
                    }
                })
            }
        },
        toReach(){
            this.$router.push({
            path:'/member/wallet'
            })
        },
        // 字兑换接口
        CollectiveWord(str){
            if(this.colloct){
                this.colloct = false
                CollectiveWord({
                    word:str,
                    token:this.$route.query.token
                }).then((res) =>{
                    if(res.code == 200){
                        this.Double12()
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration: 3000,
                            onClose: () => {
                                this.colloct = true
                            }
                        })
                    }
                }).catch((err) =>{
                    this.$message({
                        message: err.msg,
                        type: 'warning',
                        duration: 3000,
                        onClose: () => {
                            this.colloct = true
                        }
                    })
                })
            }else{
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.colloct = true
                    }
                })
            }
            
        },
        // 获得一点击砸金蛋接口
        TwelveReceive(type){
            TwelveReceive({
                eggId:type,
                token:this.$route.query.token
            }).then((res) => {
                if(res.code == 200){
                    switch (type) {
                        case 1:
                            this.isBroken_1 = true;
                            break;
                        case 2:
                            this.isBroken_2 = true;
                            console.log('55555')
                            break;
                        case 3:
                            this.isBroken_3 = true;
                            break;
                        case 4:
                            this.isBroken_4 = true;
                            break;
                        case 5:
                            this.isBroken_5 = true;
                            break;
                        case 6:
                            this.isBroken_6 = true;
                            break;
                        case 7:
                            this.isBroken_7 = true;
                            break;
                    }
                    this.money = res.data.money
                    this.word = res.data.word
                    setTimeout(()=>{
                        this.leaderTc()
                        this.isClick = true
                        this.isBroken_1 = false
                        this.isBroken_2 = false
                        this.isBroken_3 = false
                        this.isBroken_4 = false
                        this.isBroken_5 = false
                        this.isBroken_6 = false
                        this.isBroken_7 = false
                    }, 1000)
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 3000,
                    onClose: () => {
                        this.isClick = true
                    }
                })
            })
        },
         // 弹窗
        leaderTc() {
            var that = this
            var leader_tc = $('.leader_tc')
            var LayIndex = layer.open({
                type: 1,
                content: leader_tc,
                anim: false,
                fixed: true,
                shade: 0.8,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                closeBtn: 0,
                isOutAnim: false,
                area: [auto, auto],
                success: function (layero) {
                    const lq = $(layero).find('.leader_btn');
                    that.Double12()
                    lq.click(function () {
                        layer.close(LayIndex)
                    })
                }
            });
        },
        //活动一信息接口
        Double12(){
            Double12({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                  this.isactive = res.data.isactive
                  this.onedata = res.data.take_num
                  this.userID = res.data.account_id
                  this.word_arr = res.data.word_arr
                  this.egg_arr = res.data.egg_arr
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
    },
    created(){
        this.Double12()
        
    }

}
</script>

<style scoped>
.num_bag{
    display: flex;
}
.bag_item{
    position: relative;
    margin-right: 12px;
    margin-top: 10px;
}
.bag_item:nth-of-type(7){
    margin-right: 0;
}
.bag_text{
    position: absolute;
    bottom: 6px;
    font-family: SimSun;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    line-height: 17px;
    background: linear-gradient(-19deg, #FFF1AE 0%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.info_box{
    position: relative;
    margin-top: 10px;
}
.info_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 19px;
    background: linear-gradient(0deg, #FFEC95 25.68359375%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    text-align: center;
}
body{
    display: flex;
    align-items: center;
    justify-content: center;
}
.bwite{
    filter: grayscale(100%);
}
.top_info_box{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url('/public/activity/doubleTwelveEgg/img/bg.png') no-repeat center;
    background-size: auto 140px ;
    padding-left: 80px;
    height: 120px;
}
.jzjl{
    display: flex;
    height: 100%;
}
.reach_list{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 8px;
}
.list_item{
    display: flex;
    width: 100%;
    margin-bottom: 4px;
}
.hr_xian{
    border: 1px dashed #480003;
    width: 100%;
    height: 1px;
    opacity: 0.05;
    margin: 1px 0;
}
.list_str{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #F8E4C4;
    min-width: 120px;
}
.jl_text{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #F8E4C4;
    margin-right: 20px;
    margin-left: 14px;
    min-width: 60px;
}
.num_color{
    color: #F8E4C4;
}
.rule_text{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #F8E4C4;
    margin-top: 8px;
    line-height: 22px;
}
.reach_btn{
    height: 16px;
    padding: 2px 4px;
    background: linear-gradient(0deg, #FFEC95, #FFFEE6);
    border-radius: 2px;
}
.reach_btn span{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #690E0E;
    background: linear-gradient(0deg, #752230 0%, #CB191E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.xian{
    margin: 0 20px;
    height: 97px;
}
.mr-1{
    margin-right: 10px;
    margin-top: 6px;
}
.take_box{
    display: flex;
    margin-top: 10px;
}
.take_item{
    position: relative;
}
.times_text{
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #7A0000;
    line-height: 17px;
    background: linear-gradient(-19deg, #84061C 0%, #CB191E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/* 活动一 */
    .doubleElevenEgg_top{
        background: url('/public/activity/doubleTwelveEgg/img/top.png') no-repeat;
        background-size: 1920px 1030px;
        min-width: 1903px;
        min-height: 1030px;
        padding: 294px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .active_one{
        position: absolute;
        display: flex;
        top: 51%;
        left: 17%;
        max-height: 500px
    }
    .active_one_content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .postion{
        z-index: 12;
        bottom: 20px;
    }
    .egg_box{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    .egg_lang2{
        position: relative;
        top:-40%
    }
    .egg_item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
    }
    .mr-3{
        /* margin-right: 130px; */
    }
    .egg_item_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .click_zz{
        position: absolute;
        z-index: 10;
        /* background: red; */
        width: 124px;
        height: 171px;
        top: 50%;
        transform: translateY(-50%);
    }
    .animation_egg{
         position: relative;
         z-index: 5;
        width: 300px;
        height: 300px;
        background-image: url("/public/activity/doubleTwelveEgg/img/0.png"); 
        background-repeat: no-repeat; 
        animation: play 1s steps(1) forwards; 
    }
    @keyframes play {
        0% { background-position: 0 0; }
        10% { background-position: 0 -300px; }       
        20% { background-position: 0 -600px; }   
        30% { background-position: 0 -900px; }  
        40% { background-position: 0 -1200px; } 
        50% { background-position: 0 -1500px; }   
        60% { background-position: 0 -1800px; } 
        70% { background-position: 0 -2100px; }   
        80% { background-position: 0 -2400px; } 
        90% { background-position: 0 -2700px; }  
        100% { background-position: 0 -2700px; }      
    }
    .egg{
        position: relative;
        z-index: 2;
        transition: all 0.5s ease-in-out;
        width: 100%;
    }
    .broken {
    animation: breakEgg 2s forwards;
    }

    @keyframes breakEgg {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    5%, 10% {
        transform: translate3d(-10px, 0, 0);
    }
    15%, 25%, 35%, 45% {
        transform: translate3d(10px, 0, 0);
    }
    20%, 30%, 40% {
        transform: translate3d(-10px, 0, 0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.5) rotate(45deg);
        opacity: 0;
    }
    }
    .dizuo{
        position: absolute;
        bottom: 10%;
        z-index: 1;
    }
    .dizuo2{
        position: absolute;
        bottom: 10%;
        z-index: 1;
    }
    .lqjl_btn{
        position: absolute;
        bottom: -27px;
        z-index: 5;
        width: 60%;
    }
    .reward{
        position: absolute;
        bottom: 14%;
        left: 50%;
        transform: translateX(-32%);
    }
    .ljcz_btn{
        position: absolute;
        bottom: 6.5%;
        left: 50%;
        transform: translateX(-44%);
    }
    .reward_title{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #FFEBB6;
        text-align: center;
    }
/* 弹窗 */
.leader_tc {
    display: none;
}

.leader_bg {
    position: relative;
    z-index: -10;
}

.tc_title {
    position: absolute;
    left: 66%;
    top: 50%;
    transform: translateX(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tc_title h5 {
    color: #FFD700;
    font-size: 20px;
}

.tc_title span {
    color: #FFD700;
    font-size: 35px;
}

.leader_btn {
    position: absolute;
    left: 57%;
    bottom: 18%;
    cursor: pointer;
    transition: all 0.5s;
}

.leader_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}
.layui-layer{
    background-color: transparent !important;
    box-shadow: none !important;
}
</style>