<template>
    <div>
        <div class="new_top">
            <div class="activity_box">
                <div class="ac_one">
                    <span class="ac_title">活动期间享受充值超高倍率</span>
                    <span class="ac_text">充值100 充值倍率32倍</span>
                    <span class="ac_text">充值200 充值倍率34倍</span>
                    <span class="ac_text">充值300 充值倍率36倍</span>
                    <span class="ac_text">充值500 充值倍率38倍</span>
                    <span class="ac_text">充值1000 充值倍率40倍</span>
                    <span class="ac_bz">备注:充值≥1000,充值倍率40倍</span>
                    <img class="djcz_btn btn_hover" src="/activity/2025newYear/img/djcz.png" @click="toReach">
                </div>
                <!-- 背景 -->
                <img src="/activity/2025newYear/img/activity1.png">
            </div>
            <div class="activity_box">
                <div class="ac_one">
                    <span class="ac_title2">活动期间充值1笔</span>
                    <span class="ac_title2">送1个充值点红包</span>
                    <img class="btn_hover bag_img" src="/activity/2025newYear/img/bag.png" @click="getBag">
                    <span class="ac_num">红包数量{{bag_num}}个</span>
                </div>
                <!-- 背景 -->
                <img src="/activity/2025newYear/img/activity2.png">
            </div>
            <!-- 背景 -->
            <video autoplay loop muted class="bg">
                <source src="/activity/2025newYear/img/bg.mp4" type="video/mp4">
            </video>
        </div>
        <!-- 弹窗 -->
        <div class="leader_tc">
            <div class="tc_title">
                <h5>恭喜您获得</h5>
                <span>{{money}}储值大礼</span>
            </div>
            <img class="leader_btn" src="/activity/2025newYear/img/btn.png">
            <!-- 背景 -->
            <img class="leader_bg" src="/activity/2025newYear/img/leader_bg.png">
        </div>
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {newYearData,newYearReceive} from '@/api/http.js'
export default {
    name: 'Home',
    data() {
      return {
        isClick: true, //防抖
        isactive:false, //活动是否开启
        money:'',
        bag_num:0,
      }
    },
    methods: {
        // 点击领取事件（和砸蛋事件一样）
        getBag(){
            if(this.isClick){
                this.isClick = false
                this.newYearReceive()  // 点击拆红包
            }else{
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.isClick = true
                    }
                })
            }
        },
        toReach(){
            this.$router.push({
                path:'/member/wallet'
            })
        },

        // 点击领取红包
        newYearReceive(){
            newYearReceive({
                token:this.$route.query.token
            }).then((res) => {
                if(res.code == 200){
                    this.money = res.data.money
                    this.leaderTc()
                    this.isClick = true
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 3000,
                    onClose: () => {
                        this.isClick = true
                    }
                })
            })
        },
         // 弹窗
        leaderTc() {
            var that = this
            var leader_tc = $('.leader_tc')
            var LayIndex = layer.open({
                type: 1,
                content: leader_tc,
                anim: false,
                fixed: true,
                shade: 0.8,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                closeBtn: 0,
                isOutAnim: false,
                area: [auto, auto],
                success: function (layero) {
                    const lq = $(layero).find('.leader_btn');
                    that.newYearData()
                    lq.click(function () {
                        layer.close(LayIndex)
                    })
                }
            });
        },
        //活动一信息接口
        newYearData(){
            newYearData({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                  this.isactive = res.data.isactive
                  this.bag_num = res.data.redNum
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
    },
    created(){
        this.newYearData()
    }
}
</script>

<style scoped>
     .new_top{
        padding-top: 44vh;
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        left: 0;
        top: 0;
    }
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -99;
        width: 100%;
    }
    .activity_box{
        position: relative;
    }
    .ac_one{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 66px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .ac_title{
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 28px;
        color: #A11717;
        line-height: 29px;
        background: linear-gradient(0deg, #86081D 0%, #CB191E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .ac_text{
        font-family: Source Han Sans SC;
        font-weight: 500;
        font-size: 22px;
        color: #751212;
        margin-top: 10px;
    }
    .ac_bz{
        font-family: Source Han Sans SC;
        font-weight: bold;
        font-size: 24px;
        color: #A11717;
        margin-top: 10px;
    }
    .djcz_btn{
        margin-top: 20px;
    }
    .ac_title2{
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 29px;
        background: linear-gradient(0deg, #86081D 0%, #CB191E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bag_img{
        margin-top: 15px;
    }
    .ac_num{
        font-family: Source Han Sans SC;
        font-weight: bold;
        font-size: 24px;
        color: #A81212;
    }
/* 弹窗 */
.leader_tc {
    display: none;
}

.leader_bg {
    position: relative;
    z-index: -10;
}

.tc_title {
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translateX(-50%);
    text-align: center;
}

.tc_title h5 {
    color: #FFEBB6;
    font-size: 16px;
    margin: 0;
}

.tc_title span {
    color: #FFD700;
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: 800;
    background: linear-gradient(0deg, #fffee6 25.68359375%, #ffec95 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.leader_btn {
    position: absolute;
    left: 50%;
    bottom: 23%;
    transform: translateX(-50%);
    cursor: pointer;
    transition: all 0.5s;
}

.leader_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}
.layui-layer{
    background-color: transparent !important;
    box-shadow: none !important;
}
</style>