<template>
    <div class="xtx-numbox">
    <div class="label">
      <slot />
    </div>
    <div class="numbox">
      <a @click='toggle(-1)'>-</a>
      <input  type="text" readonly :value="inventory > 0 ? num : 0">
      <a  @click='toggle(1)'>+</a>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'XtxNumbox',
        data() {
            return {
                // num:1
            }
        },
        props: {
            inventory: {
            type: Number,
            required: true
            },
        },
        computed:{
            num:{
                get(){
                    return this.$store.state.num
                },
                set(val){
                    return this.$store.state.num = val
                }
            }
        },
        methods:{
            toggle(n){
                
                if (n < 0) {
                    // 减一操作
                    if (this.num > 1) {
                        this.num -= 1
                    }
                } else {
                    if (this.num < this.inventory) {
                        this.num += 1
                    }
                }
                this.$emit('add',this.num)
            }
        }
    }
</script>
<style scoped lang="less">
.xtx-numbox {
  display: flex;
  align-items: center;
  .label {
    color: #999;
    margin-right: 10px;
  }
  .numbox {
    border: 1px solid #e4e4e4;
    display: flex;
    > a {
        user-select:none;
      width: 29px;
      line-height: 28px;
      text-align: center;
      background: #f8f8f8;
      font-size: 16px;
      color: #666;
      &:first-of-type {
        border-right: 1px solid #e4e4e4;
        color: #A1A1A1;
      }
      &:last-of-type {
        border-left: 1px solid #e4e4e4;
        color: #A1A1A1;
      }
    }
    > input {
      width: 80px;
      padding: 0 5px;
      text-align: center;
      color: #666;
      background: #F9F9F9;
      border: 1px solid #D2D2D2;
    }
  }
}
</style>
