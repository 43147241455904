var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('div',{staticClass:"new_box"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"new_content"},[_c('div',{staticClass:"content_top"},[_c('h5',{staticClass:"new_font new_font_color"},[_vm._v("新闻资讯")]),_c('div',{staticClass:"new_item"},[_c('a',{staticClass:"new_item_font",class:{'new_active':_vm.newId===0},on:{"click":function($event){_vm.newId=0}}},[_vm._v("全部")]),_vm._l((_vm.newClassify),function(n){return _c('div',{key:n.value,staticClass:"new_item"},[_c('div',{staticClass:"fgx"}),_c('a',{staticClass:"new_item_font",class:{'new_active':_vm.newId===n.value},on:{"click":function($event){_vm.newId= n.value}}},[_vm._v(_vm._s(n.label))])])})],2)]),_c('div',{staticClass:"content_bottom"},[_vm._l((_vm.getArticle),function(a,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newId === 0),expression:"newId === 0"}],key:index,staticClass:"all"},[_c('div',{staticClass:"new_title"},[_c('router-link',{attrs:{"to":{
                                path:'/newDetail',
                                query:{
                                        id:a.id
                                    }
                            }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.img_url + a.img),expression:"img_url + a.img"}],staticClass:"new_img"})]),_c('div',{staticClass:"title_box"},[_c('div',{staticClass:"title_top"},[_c('router-link',{attrs:{"to":{
                                        path:'/newDetail',
                                        query:{
                                                id:a.id
                                            }
                                    }}},[_c('h5',[_vm._v(_vm._s(a.title))])]),_c('span',{staticClass:"text_ellipsis"},[_vm._v(_vm._s(a.Introduction))])],1),_c('div',{staticClass:"time"},[_vm._v(_vm._s(a.created_at))])])],1),_c('router-link',{staticClass:"new_btn btn_color new_btn_font",attrs:{"to":{
                            path:'/newDetail',
                            query:{
                                    id:a.id
                                }
                        }}},[_vm._v("查看详情")])],1)}),_vm._l((_vm.getArticle),function(b,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newId === b.classify),expression:"newId === b.classify"}],key:index + '#',staticClass:"announcement"},[_c('div',{staticClass:"all"},[_c('div',{staticClass:"new_title"},[_c('router-link',{attrs:{"to":{
                                    path:'/newDetail',
                                    query:{
                                            id:b.id
                                        }
                                }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.img_url + b.img),expression:"img_url + b.img"}],staticClass:"new_img"})]),_c('div',{staticClass:"title_box"},[_c('div',{staticClass:"title_top"},[_c('router-link',{attrs:{"to":{
                                            path:'/newDetail',
                                            query:{
                                                    id:b.id
                                                }
                                        }}},[_c('h5',[_vm._v(_vm._s(b.title))])]),_c('span',{staticClass:"text_ellipsis"},[_vm._v(_vm._s(b.Introduction))])],1),_c('div',{staticClass:"time"},[_vm._v(_vm._s(b.created_at))])])],1),_c('router-link',{staticClass:"new_btn btn_color new_btn_font",attrs:{"to":{
                            path:'/newDetail',
                            query:{
                                    id:b.id
                                }
                            }}},[_vm._v("查看详情")])],1)])}),(_vm.pagination && _vm.total)?_c('Pagination',{staticClass:"possion_page",attrs:{"current":_vm.pagination.p,"pageSize":_vm.pagination.pageSize,"total":_vm.total,"hideOnSinglePage":_vm.hideOnSinglePage,"showQuickJumper":true,"showTotal":true,"placement":"right"},on:{"change":_vm.changePage}}):_vm._e()],2)])]),_c('img',{staticClass:"new_bg",attrs:{"src":_vm.img_url + _vm.material.pc_background,"width":"100%"}})]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }