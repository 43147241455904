<template>
  <div class="backtopBox"
    :style="{display:display}"
    @mouseenter="isArrter = true"
    @mouseleave="isArrter = false"
    @click="toTop()">
    <img class="top_img" :src="!isArrter ? '/banner/img/top.png' : '/banner/img/top_hover.png'" alt="返回顶部">
  </div>
</template>
<script>
export default {
  name: 'ReturnTop',
  data() {
    return {
      display: 'none',
      scrollTop: 0,
      isArrter:false    //  src控制
    }
  },
  // 滚动监听
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop > 300) {
        this.display = 'block'
      } else {
        this.display = 'none'
      }
    },
    // 滚动重置
    toTop() {
      let t = this.scrollTop
      let time = setInterval(() => {
        t -= 50
        document.documentElement.scrollTop = t
        if (t <= 0) {
          clearInterval(time)
        }
      }, 5)
      this.display = 'none'
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledScrollHandler);
  }
}
</script>
<style scoped>
.backtopBox {
    bottom: 90px;
    position: fixed;
    left: 20px;
    bottom: 45%;
    z-index: 99;
    border: solid 1px #bfbfbb;
    border-radius: 10px;
    padding: 20px 10px;
    cursor: pointer;
    display: block;
    background:#fff
}
.top_img{
  width: 60px;
}
@media (max-width:1800px) {
  .top_img{
    width: 40px;
  }
}
@media (max-width:1750px) {
  .top_img{
    width: 40px;
  }
  .backtopBox{
    left: 10px;
  }
}

</style>
