<template>
    <div>
        <div class="top flex_box">
                <div class="container top_box">
                    <div class="top_left">
                        <img src="/header/img/home.png">
                        <a @click="toHome" class="top_title" >返回首页</a>
                    </div>
                    <div class="top_right">
                        <div class="flex_box" v-show="unLogin">
                            <img src="/header/img/sm.png">
                            <a class="top_title" @click="LoginOne()">扫码登录</a>
                        </div>
                        <div class="flex_box" v-show="isLogin">
                            <router-link to="/member/personal" class="top_title">欢迎您：{{userInfo.id}}</router-link>
                            <div class="fgx"></div>
                            <a class="top_title" @click="login_back">退出</a>
                        </div>
                        <div class="fgx"></div>
                        <a class="top_title" v-if="material.shouquan" :href="material.shouquan !=='' ? img_url + material.shouquan :'javascript:0'" target="_blank">平台授权</a>
                        <div class="fgx"></div>
                        <a  class="top_title" @click="click_wallet">平台充值</a>
                        <div class="fgx"></div>
                        <a class="top_title"  @mouseenter="onMouseOver"  @mouseleave="onMouseOut">联系客服</a>
                    </div>
                    <div class="kf_box" v-show="isShow" @mouseenter="onMouseOver"  @mouseleave="onMouseOut">
                        <div class="kf_ewm">
                            <img :src="img_url + material.pc_customer" width="100px">
                            <h6>91CQ平台客服</h6>
                            <span>时间 9:00-22:00</span>
                        </div>
                    </div>
                </div>
        </div>
        <Login ref="login" @closeLogin="closeLogin"></Login>
    </div>
</template>

<script>
    import { auto } from "@popperjs/core";
    import layer from "layui-layer";
    import{ mapState } from 'vuex'
    import Login from '../components/Login.vue'
    export default {
        name:'HeaderTop',
        data() {
            return {
                isShow:false,
                
            }
        },
        components:{
            Login
        },
        computed:{
            ...mapState([
                'curId',
                'unLogin',
                'isLogin',
                'userInfo',
                'material'
            ]),
        },
        methods:{
            click_wallet(){
                this.$router.push({
                    path:'/member/wallet'
                })
                this.$czc( 'PC子页面顶部-平台充值', '点击', '访问平台充值')
            },
            LoginOne(){
                this.$refs.login.open()
                $("body").parent().css("overflow-y","hidden")
                $("body").parent().css("overflow-x","hidden")
            },
            closeLogin(){
                $("body").parent().css("overflow-y","auto")
                $("body").parent().css("overflow-x","auto")
            },
            toHome(){
                this.$router.push({
                    path:'/home'
                })
                location.reload()
            },
            // 登出
            login_back(){
                localStorage.setItem("token",'')
                localStorage.setItem('userInfo','')
                this.$router.push({path:'/home'})
                location.reload()
            },
            // 鼠标移入
            onMouseOver(){
                this.isShow = true
            },
            // 鼠标移出
            onMouseOut(){
                this.isShow = false
            },
        }
    }
</script>

<style scoped>
 .top{
        width: 100%;
        background: #2F3136;
        height: 30px;
    }
    .top_box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0; 
    }
    .top_left a{
        margin-left: 5px;
    }
    .top_title{
        text-decoration: none;
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #D2D4D8;
        transition: all 0.5s;
        padding: 10px 0;
    }
    .top_title:hover{
        color: #B4935A;
        transition: all 0.5s;
    }
    .top_right{
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
    }
    .fgx{
        width: 1px;
        height: 12px;
        background: #5B5E67;
        margin: 0 10px;
    }
    .top_right img{
        margin-right: 5px;
    }
    /* 客服二维码 */
    .kf_box{
        position: absolute;
        right: 8%;
        top: 30px;
        /* display: none; */
        transition: all 0.5s;
    }
    .kf_ewm{
        padding: 10px;
        background: #2F3136;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .kf_ewm h6{
        font-size: 12px;
        color: white;
        margin-bottom: 0;
        margin-top: 5px;
    }
    .kf_ewm span{
        font-size: 12px;
        color: gray;
    }
     
    @media (max-width:1750px) {
        .kf_box{
            right: 5%;
        }
    }
    @media (max-width:1300px) {
        .kf_box{
            right: 3%;
        }
    }
</style>