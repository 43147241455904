<template>
    <div>
        <Header></Header>
        <div class="member">
            <div class="container">
                <AtlasTop :num="map.num" :chenghao="map.vip_img"></AtlasTop>
                <!-- <div class="top_box">
                    <div class=" jr">
                        <div class="row">
                            <div class="col-12">
                                <div class="right_box">
                                    <div class="right_top">
                                        <div class="title_box">
                                            
                                            <h4>通关进度:</h4>
                                            <div class="jd">
                                                <div class="jd_x">
                                                    <div class="jd_n" :style="{ width: exp_jd + '%' }"></div>
                                                </div>
                                                <span>{{ map.num }}/{{ map.count_num }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right_bottom">
                                        
                                        <steps :active="map.num"  >
                                            <step v-for="(m,index) in map.count_num" :key="index">
                                                <template v-slot:icon>
                                                    <div :class="{'bigdian':(index+1)%5 == 0}">
                                                        <div class="baoxian" v-if="(index+1)%5 == 0">
                                                            <img src="/Atlas/img/bx.png">
                                                        </div>
                                                    </div>
                                                </template>
                                            </step>
                                        </steps>
                                    </div>
                                    <img class="hengfu" v-lazy="material.map_card ? (img_url + material.map_card.sm) :''" width="100%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="bottom_box">
                    <div class="container">
                        <!-- <div class="more"><a href="javascript:0">查看更多></a></div> -->
                        <div class="lang_box">
                            <img class="hengfu" v-lazy="material.map_card ? (img_url + material.map_card.sm) : ''"
                                width="100%">
                            <section class="card_box item" v-for="car in map_list" :key="car.id">
                                <div class="card_set" @click="cardSet($event)">
                                    <!-- 卡牌正面 -->
                                    <div class="box1 front">
                                        <img class="jt" :src="img_url + car.images">
                                        <img class="ywc" src="/Atlas/img/ywc.png">
                                        <div class="container msg_box">
                                            <div class="title_size hh1" title="{$vo.game_name}"> 通关版本：{{ car.game_name }}
                                            </div>
                                            <div class="title_size hh1" title="{$vo.nickname}"> 通关角色：{{ car.nickname }}
                                            </div>
                                            <div class="by_time hh1" title="{$vo.create_time}"> 达成成就时间:{{ car.create_time }}
                                            </div>
                                        </div>
                                        <!-- 背景 -->
                                        <!-- <img class="wkq" src="/Atlas/img/wkq.png" width="50%" v-if="car.is_lock == 0"> -->

                                        <img class="box1_bg"
                                            v-lazy="material.map_card ? (img_url + material.map_card.zm) : ''" width="95%">
                                    </div>
                                    <!-- 卡牌背面 -->
                                    <div class="box2 back">
                                        <div class="box2_tb">
                                            <!-- <div class="lq_after">
                                                <h4 class="hh1">奖励:<span class="money_num">{{ moneys }}</span>灵符币</h4>
                                                <img class="guanyun" src="/Atlas/img/guanyun.png" width="100%">
                                            </div> -->
                                            <!-- <div class="lq_last" v-if="car.is_lock == 0">
                                                <img class="share" @click.stop="hitHost($event, car.id)" v-lazy="img_url + material.map_card.btn"
                                                    width="100%">
                                                <img class="share2" @click.stop="hitHost($event, car.id)"
                                                    src="/Atlas/img/btn4.png" width="100%">
                                            </div> -->
                                            <div class="lq_last">
                                                <h4 class="hh1">奖励:<span>{{ car.money }}</span>灵符币</h4>
                                                <!-- 背景光晕 -->
                                                <img class="guanyun" src="/Atlas/img/guanyun.png" width="100%">
                                            </div>
                                        </div>
                                        <!-- 背景 -->
                                        <img v-lazy="material.map_card ? (img_url + material.map_card.fm) : ''" width="95%"
                                            height="100%">
                                    </div>
                                </div>
                            </section>

                            <div class="item">
                                <div class="box_3 ">
                                    <img class="jiahao" src="/Atlas/img/jia.png" width="35%">
                                    <img class="box3_bg" src="/Atlas/img/box3.png" width="95%" height="100%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="serch_box">
                    <div class="serch_top">
                        <div class="serch_title">
                            <div class="serch_hat"></div>
                            <h5>通关条件查询</h5>
                        </div>
                        <div class="serch">
                            <img class="fdj" src="/Atlas/img/fdj.png">
                            <input type="text" id="susuo" placeholder="游戏搜索" v-model="sosuo_val" @keyup.enter="susuo()">
                        </div>
                    </div>
                    <div class="serch_bottom">
                        <div class="row" id="hostGame">
                            <popover class="col-3" placement="top" title="通关条件" width="400" trigger="hover" 
                                @click.native="clickJion(c)"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal2"
                                v-for="c in customs" :key="c.id">
                                <div class="tips-content class_box" v-html="c.atlas_text">
                                </div>
                                <div class="serch_content" slot="reference">
                                    <img :src="img_url + c.game_icon" style="width:40px; height: 40px;">
                                    <span class="text_ellipsis">{{ c.game_name }}</span>
                                </div>
                            </popover>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 背景 -->
            <img class="member_bg" :src="img_url + material.pc_background" width="100%">
        </div>
        <!-- 兑换模态框 -->
        <div class="modal fade" id="myModal2" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <!-- 模态框头部 -->
                    <div class="modal-header head_bg">
                        <h4 class="modal-title2">参与通关</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="closeModel"></button>
                    </div>

                    <!-- 模态框内容 -->
                    <div class="modal-body content_dh">
                        <div class="jf_box">
                            <div class="left_jf">
                                <img class="money_img" v-lazy="img_url + now_game.game_web_image">
                                <div class="left_title">
                                    <div class="left_tip">
                                        <h4 class="text_ellipsis" :title="now_game.game_name">{{ now_game.game_name}}</h4>
                                        <span class="text_ellipsis" :title="now_game.game_introduce">{{ now_game.game_introduce }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="right_jf">
                                <a v-if="now_game.user_m_status" class="btn_color duihuan_btn" @click="jion(now_game.game_id)">立即参与</a>
                                <a v-else class="btn_color duihuan_btn isClick">已参与</a>
                            </div>
                        </div>
                        <div class="select_body class_box" >
                            <h5 class="modal-title2">通关条件</h5>
                            <div v-html="now_game.atlas_text"></div>
                        </div>
                
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import AtlasTop from '@/components/AtlasTop.vue'
import { Popover, Button, Steps, Step } from 'element-ui';
import { getMapList, openMapBox, atlasList,userAddMap } from '../api/http.js'
import { mapState } from 'vuex'
export default {
    components: { Footer, Header, Popover, Button, Steps, Step, AtlasTop },
    name: 'Atlas',
    computed: {
        ...mapState([
            'material',
        ]),
        exp_jd() {
            return this.map.num / this.map.count_num * 100
        }
    },
    data() {
        return {
            flage: true,
            map: '', //用户和图鉴信息
            map_list: '', //卡牌信息
            car_data: '', //卡牌宝箱信息
            revertime: '',
            mydsq: '',
            moneys: '',
            sosuo_val: '',
            active: 0,
            arr: [],
            time: '',    //抽奖倒计时
            begin: 1000, //随机数范围开始
            end: 10000,   //随机数范围结束
            customs: '', //通关条件列表
            now_game:[], //存储当前点击的条件
            bool:true, //立即参与按钮节流
        }
    },
    methods: {
        // 参与通关方法
        jion(id) {
            if (localStorage.getItem('token')) {
                this.$confirm('每个用户最多可同时参与三款游戏，是否选择参与该游戏？如需取消请联系客服。', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.userAddMap(id)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消参与'
                    });
                });
            } else {
                this.$message({
                    message: '请先登录后使用该功能！',
                    type: 'warning',
                    onClose: () => {
                        this.$refs.login.open()
                    },
                    duration: 1500,
                })
            }
        },
        // 参与通关接口
        userAddMap(id){
            if(this.bool){
                this.bool = false
                userAddMap({
                    game_id:id,
                }).then((res)=>{
                    if(res.code == 200){
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            onClose: () => {
                                this.bool = true
                                this.atlasList()
                            },
                            duration: 1500,
                        })
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err)
                    this.$message({
                        message: err.msg,
                        type: 'warning',
                        onClose: () => {
                            this.bool = true
                        },
                        duration: 1500,
                    })
                })
            }else{
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    onClose: () => {
                        this.bool = true
                    },
                    duration: 1500,
                })
            }
        },
        closeModel(){
            // 初始化参与通关信息
            this.now_game = ''
        },
        clickJion(data){
            this.now_game = data
        },
        // 宝箱信息查询接口
        getMapList() {
            getMapList().then((res) => {
                if (res.code == 200) {
                    this.map = res.data
                    this.map_list = res.data.map_list
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        winsize() {
            var tx = document.getElementsByClassName('tx_bg').offsetWidth
            var box1_bg = document.getElementsByClassName('box1_bg').offsetWidth
            // console.log(box1_bg)
            $('.jt').width(box1_bg)
            $('.tx').width(tx - 4)
        },
        cardSet: function (event) {
            const target = event.currentTarget
            if (target.classList.contains('flip')) {
                target.className = 'card_set';
            } else {
                target.className = 'card_set flip';
            }
        },
        // 开启宝箱接口
        // openMapBox(event, id) {
        //     openMapBox({
        //         id: id
        //     }).then((res) => {
        //         if (res.code == 200) {
        //             this.car_data = res.data
        //             //清除倒计时
        //             // clearInterval(this.revertime)
        //             // this.revertime = ''
        //             // 暂停数字滚动，并获取最终金额
        //             // var that = this
        //             // clearInterval(this.mydsq);
        //             // this.mydsq = ''
        //             // this.arr = [];
        //             // this.begin = 0;
        //             // b.style.display = 'none'
        //             this.getMapList()
        //             // betal = true //抽取结束标识
        //             this.moneys = res.data.map.money
        //             this.$message({
        //                 message: res.data.msg,
        //                 type: 'success',
        //                 onClose:()=>{
        //                     this.flage = true
        //                 },
        //                 duration: 1500,
        //             })
        //         }
        //     }).catch((err) => {
        //         console.log('捕获异常', err)
        //         this.$message({
        //             message: err.msg,
        //             type: 'warning',
        //             duration: 1500,
        //         })
        //     })
        // },
        // 通关条件列表接口
        atlasList(name) {
            atlasList({
                game_name: name
            }).then((res) => {
                if (res.code == 200) {
                    this.customs = res.data
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        // hitCard(event) {
        //     // 点击领取宝箱
        //     // bool = true//判断是否可点击,true为可点击
        //     var flag = true
        //     // var id =  $(this).attr("data-id")
        //     var b = event.currentTarget
        //     var b2 = b.nextSibling //获取立即领取按钮
        //     const parentElement = b2.parentElement;
        //     const siblings = parentElement.previousSibling
        //     // var lq_after = parentElement.previousSibling  //获取当前卡牌奖励池
        //     var lq_title = parentElement.parentElement.parentElement.previousSibling.querySelectorAll('.wkq') //卡牌正面的未领取奖励标识
        //     // lq_after.style.position = "relative";//初始化奖金池位置
        //     // var betal = false // 判断变量：奖池抽取状态，TRUE表示抽取完毕
        //     // var money = b.siblings('.lq_after').children('h4').children()
        //     if (flag) {
        //         flag = false
        //         // var money = document.getElementsByClassName('money_num')
        //         // 金额随机
        //         b.style.display = 'none' //开启吧隐藏
        //         // b2.style.display = 'inline' //立即领取显示
        //         lq_after.style.display = 'inline'
        //         if (!(lq_after.style.display == 'none')) {
        //             console.log('我进来循环')
        //             for (var i = this.begin; i <= this.end; i++) {
        //                 this.arr.push(i);
        //             }
        //             for (var i = this.begin; i <= this.end; i++) {
        //                 this.arr.push(i);
        //             }
        //             this.arr = this.arr.sort(function () {
        //                 return Math.random() - 0.5;
        //             })
        //             this.arr = this.arr.sort(function () {
        //                 return Math.random() - 0.1;
        //             })
        //             // console.log('length',this.arr.length)
        //             clearInterval(this.mydsq);
        //             var i = 0;
        //             var that = this
        //             this.mydsq = window.setInterval(function () {
        //                 i++;
        //                 if (i >= that.arr.length) {
        //                     i = 0;
        //                 }
        //                 // money.intHtml(arr[i]);
        //                 // money.write = arr[i]
        //                 that.moneys = that.arr[i]
        //             }, 50);
        //         }

        //         //60秒未领取自动刷新页面
        //         if (lq_title.length > 0) {
        //             this.$message({
        //                 message: "请在倒计时60秒内点击领取奖励！",
        //                 type: 'warning',
        //                 duration: 2000,
        //             })
        //             var time = 60
        //             this.revertime = setInterval(function () {
        //                 time--;
        //                 console.log(time)
        //                 $('#time').html(time);
        //                 if (time === 0) {
        //                     location.reload();
        //                 }
        //             }, 1000);
        //         }
        //     }
        //     // 一点要return,否则会冒泡
        //     return false
        //     // var jd = $('.jd span').text()
        //     // var number = eval(jd) * 100 + "%"
        //     // $('.jd_n').css("width", number)
        // },
        hitHost(event, id) {
            if (this.flage) {
                this.flage = false
                this.openMapBox(event, id)
            } else {
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    onClose: () => {
                        this.flage = true
                    },
                    duration: 1500,
                })
            }
        },
        // 搜索
        susuo() {
            this.atlasList(this.sosuo_val)
        }

    },
    mounted() {
        this.winsize()
        $(window).resize(function () {
            var tx = document.getElementsByClassName('tx_bg').offsetWidth
            var box1_bg = document.getElementsByClassName('box1_bg').offsetWidth
            // console.log(box1_bg)
            $('.jt').width(box1_bg)
            $('.tx').width(tx - 4)
        });
    },
    created() {
        this.getMapList()
        this.atlasList()
    }
}
</script>

<style scoped>
.isClick {
    filter: grayscale(100%);
}
/* 模态框 */
.btn-close {
    box-shadow: none;
}

.modal-title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.modal-header {
    background: #F1F1F1;
    border-radius: 4px 4px 0px 0px;
}

.modal-body {
    padding: 20px 0 50px;
}

.form_address {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 50px;
}

.form_box {
    margin-bottom: 10px;
    display: flex;
}

.arge_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
}

.user_name {
    width: 600px;
    height: 40px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 0 10px;
}

.address {
    width: 600px;
    height: 160px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 10px;
}

.rodia_box {
    width: 600px;
}

.form-check-input {
    box-shadow: none;
    margin-right: 5px;
}

.form-check-input:checked {
    background-color: #AC8747;
    border-color: #AC8747;
}

.modal_btns {
    width: 600px;
    margin-top: 10px;
}

.save_point {
    margin-right: 10px;
}

.btn_radius {
    border-radius: 4px;
}

.save_btn {
    cursor: pointer;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    width: 100%;
    padding: 18px 0;
}

.remove_btn {
    cursor: pointer;
    width: 100%;
    padding: 18px 0;
    background: #f2f2f2;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
    text-decoration: none;
}

/* 商品详情模态框 */
.pro_img {
    max-height: 76vh;
}

::v-deep(.pro_img > p) {
    margin: 0;
}

::v-deep(.pro_img > p > img) {
    width: 100%;
}

.btn_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.class_box {
    overflow: auto;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #d1b481;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: #e6e6e6;
    padding: 10px 0;
}

.head_bg {
    background: #FFFFFF !important;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.modal-title2 {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #141414;
}

.content_dh {
    padding: 30px 0;
}

.jf_box {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 30px;
}

.left_jf {
    display: flex;
    justify-content: start;
    align-items: center;
}

.money_img {
    margin-right: 14px;
    width: 192px;
    max-height: 200px;
}

.left_title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.left_tip h4 {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
    max-width: 250px;
}

.left_tip span {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
    max-width: 300px;
    display: block;
}

.right_jf {
    display: flex;
    align-items: center;
}

.duihuan_btn {
    padding: 15px 50px;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
}

.jt {
    cursor: pointer;
}
.class_box {
    overflow: auto;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #d1b481;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: #e6e6e6;
    padding: 10px 0;
}
.select_body {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-top: 20px;
    overflow: hidden;
    max-height: 160px;
    padding: 0 15px;
}
/* 进度条 */
.el-step__head.is-process {
    display: flex !important;
    align-items: center !important;
}

::v-deep(.el-step__head.is-finish) {
    display: flex;
    align-items: center;
}

::v-deep(.el-step__head.is-wait) {
    display: flex !important;
    align-items: center;
}

::v-deep(.el-step__head.is-process) {
    display: flex !important;
    align-items: center;
}

.baoxian {
    position: relative;
    bottom: 6vh;
    right: 14px;
    transform: none !important;
}

::v-deep(.el-step__icon.is-text) {
    position: relative;
    right: 4px;
    top: 6px;
}

/* 线完成 */
::v-deep(.el-step__line-inner) {
    border-color: #B5945B;
}

/* 点完成 */
::v-deep(.el-step__head.is-finish > .el-step__icon.is-text) {
    border-color: #B5945B;
    background-color: #B5945B;
    width: 10px;
    height: 10px;
    position: relative;
    z-index: 99;
    /* transform: rotate(45deg); */
    /* border-radius: 0; */
}

::v-deep(.el-step__head.is-finish > .el-step__icon.is-text > .bigdian) {
    border-color: #B5945B;
}

/* 点等待 */
::v-deep(.el-step__head.is-wait > .el-step__icon.is-text) {
    width: 10px;
    height: 10px;
    background: #C0C4CC;
    /* transform: rotate(45deg); */
    /* border-radius: 0; */
}

::v-deep(.el-step__head.is-wait > .el-step__icon.is-text > .bigdian > .baoxian) {
    filter: grayscale(100%);
}

/* 点准备 */
::v-deep(.el-step__head.is-process > .el-step__icon.is-text) {
    width: 10px;
    height: 10px;
    /* transform: rotate(45deg); */
    /* border-radius: 0; */
    background: #C0C4CC;
    border-color: #C0C4CC;
}

::v-deep(.el-step__head.is-process > .el-step__icon.is-text > .bigdian > .baoxian) {
    filter: grayscale(100%);
}

/*  */
iframe {
    border: none;
}

.click_token {
    cursor: pointer;
    z-index: 11;
}

.flex_pop {
    left: -12%;
    top: 10px;
}

img {
    user-select: none;
    -webkit-user-drag: none;
}

.hh1 {
    width: 100%;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.member {
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin-top: 110px;
    background: #18181a;
}

.member_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* height: 100%; */
}

/*  */

.top_box {
    margin-top: 10px;
    border: solid 1px rgb(233, 233, 232);
    padding: 30px 10px;
    background: white;
    z-index: 2;
    position: relative;
}

.title_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.jr {
    padding: 0 10px;
}

.title_box h4 {
    font-family: "SimSun";
    color: rgb(51, 51, 51);
    font-size: 24px;
    line-height: 1.5;
}

.title_box h4 span {
    color: rgb(154, 119, 57);
    line-height: 1.2;
}

.title_box h5 {
    font-family: "SimSun";
    font-size: 16px;
    line-height: 1.5;
    color: rgb(120, 120, 120);
}

.left_top h4 {
    font-size: 14px;
    font-family: "SimSun";
}

.right_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* 宝箱按钮 */
.right_top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.right_bottom {
    margin-top: 5%;
    width: 98%;
}

.left_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bx_box {
    display: flex;
    align-items: center;
}

.key_box {
    display: flex;
    align-items: center;
}

.btn_bx {
    border-radius: 4px;
    background-image: -moz-linear-gradient(90deg, rgb(225, 179, 117) 0%, rgb(255, 239, 183) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(225, 179, 117) 0%, rgb(255, 239, 183) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(225, 179, 117) 0%, rgb(255, 239, 183) 100%);
    font-size: 12px;
    font-family: "SimSun";
    color: rgb(117, 59, 49);
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
}

.fgx {
    opacity: 0.502;
    height: 40px;
    border: solid 1px rgb(198, 186, 158);
    margin: 20px;
    background-color: rgb(198, 186, 158);
}

.key_num {
    font-size: 14px;
    font-family: "Microsoft YaHei";
    color: rgb(149, 120, 81);
    text-transform: uppercase;
    line-height: 1.2;
    margin-left: 10px;
}

.hengfu {
    margin-bottom: 20px;
}

/* 头像 */
.touxian {
    position: relative;
    display: flex;
    justify-content: center;
}

.tx {
    position: absolute;
    top: 2%;
    height: 70%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 95%;
}

.tx_size {
    font-size: 12px;
    color: rgb(211, 207, 180);
    z-index: 2;
    text-align: left;
}

.tx_time {
    font-size: 12px;
    color: rgb(211, 207, 180, 0.6);
    margin-top: 10px;
}

.tx_msg_box {
    position: absolute;
    color: rgb(211, 207, 180);
    bottom: 3%;
    z-index: 2;
}


/* 进度 */
.jd_n {
    background-color: rgb(154, 119, 57);
    width: 0%;
    height: 6px;
    z-index: 2;
    border-radius: 10px;
}

.jd_x {
    width: 384px;
    height: 6px;
    background-color: rgb(224, 222, 219);
    opacity: 0.729;
    border-radius: 10px;
}

.jd span {
    color: rgb(154, 119, 57);
    font-size: 16px;
    margin-left: 10px;
}

.jd {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

/* 宝箱进度条 */
.time_box {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.xian_box {
    position: relative;
    height: 80%;
    width: 500%;
    left: 0;
    cursor: move;
}

.xian {
    /* background-color: rgb(181, 148, 91); */
    background-color: #8a8a8a;
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: 2px;
    left: 1px;
}

.xian_color {
    background-color: rgb(181, 148, 91);
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 10%;
}

.bx_img {
    position: relative;
    z-index: -99;
}

/* 消息提示框2-start */
.tip_box {
    position: relative;
    display: inline-block;
}

.tip_box .tip_text {
    position: absolute;
    right: -5px;
    top: calc(100% + 20px);
    width: max-content;
    font-size: 12px;
    font-weight: 400;
    word-break: break-word;
    padding: 6px 5px;
    border-radius: 4px;
    z-index: 99999;
    background: #fff;
    border: 1px solid #303133;
    color: #303133;
}

.tip_box .tip_text:before {
    content: "";
    position: absolute;
    top: -7px;
    right: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent #fff;
    z-index: 1;
}

.tip_box .tip_text:after {
    content: "";
    position: absolute;
    top: -8px;
    right: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent #303133;
}

.dian_color {
    background-color: rgb(181, 148, 91) !important;
}

.bigdian_color {
    border-color: rgb(181, 148, 91) !important;
}

.dd {
    background-color: #8a8a8a;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
}

.center_dd {
    background-color: #8a8a8a;
    width: 10px;
    height: 10px;
}

.dian {
    position: absolute;
    bottom: -4px;
    z-index: 1;
}

.bigdian {
    border-color: #8a8a8a;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    position: absolute;
    /* background: #fff; */
    width: 18px;
    height: 18px;
    z-index: 1;
}

.bx_1 {
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
}

.bx_1 h4 {
    filter: none;
    position: absolute;
    color: red;
    font-size: 20px;
    font-weight: bold;
    transform: rotate(-26deg);
    display: none;
    top: 20%;
    right: 15%;
    width: 70px;
}

.bx_1 img {
    position: relative;
}

.bx_up {
    top: 14px;
    z-index: 2;
    left: 1px;
}

.bx_2 {
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
}

.bx_2 img {
    position: relative;
}

.bx_3 {
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
}

.bx_3 img {
    position: relative;
}

.bx_4 {
    position: absolute;
    bottom: 50px;
    left: 96%;
    display: flex;
    flex-direction: column;
}

.bx_4 img {
    position: relative;
}

/*  */
.bottom_box {
    padding: 30px 0;
    border: solid 1px rgb(233, 233, 232);
    padding: 20px 0 30px;
    background: white;
    margin-top: 10px;
    /* background: url(../img/bg.png) no-repeat; */
    background-size: cover;
    height: 100%;
    position: relative;
    z-index: 2;
}

.more {
    text-align: right;
}

.more a {
    text-decoration: none;
    color: rgb(103, 103, 103);
    font-size: 16px;
    font-family: "Microsoft YaHei";
    line-height: 1.2;
}

.lang_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
}

.box1 {
    position: relative;
    display: flex;
    align-items: center;
}

.box1_bg {
    position: absolute;
    top: 0;
    height: 100%;
}

.jt {
    position: absolute;
    top: 0;
    height: 72%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 95%;
}

.ywc {
    position: absolute;
    bottom: 30%;
    right: 10%;
    z-index: 10;
    width: 25%;
}

.wkq {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.title_size {
    font-size: 16px;
    color: rgb(211, 207, 180);
    z-index: 2;
    text-align: left;
}

.by_time {
    font-size: 12px;
    margin-top: 10px;
    color: rgb(211, 207, 180, 0.6);
}

.msg_box {
    position: absolute;
    color: rgb(211, 207, 180);
    bottom: 5%;
    z-index: 2;
}

.box2 {
    position: relative;
    /* width: 20%; */
}

.box2 h4 {
    font-size: 16px;
    color: rgb(211, 207, 180);
    line-height: 1.5;
    z-index: 2;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box2 img {
    position: relative;
}

.box2_tb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
}

.lq_last {
    position: absolute;
    bottom: 0;
}

.lq_after {
    display: none;
    position: relative;
}

.guanyun {
    z-index: 1;
    position: absolute;
}

.box2_bf {
    position: relative;
    /* width: 20%; */
}

.box2_fb_tb {
    position: absolute;
    z-index: 2;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.box2_fb_tb h4 {
    font-size: 16px;
    color: rgb(211, 207, 180);
    line-height: 1.5;
    z-index: 2;
    text-align: center;
    margin-bottom: 20px;
}

.jiahao {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box_3 {
    position: relative;
    /* width: 20%; */
    height: 100%;
}

.item {
    width: 20%;
    margin-bottom: 10px;
    height: 384px;
}

/* 卡牌翻转 ↓*/
.card_box {
    position: relative;
    perspective: 800px;
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    -ms-transform: perspective(800px);
    -o-transform: perspective(800px);
    transform: perspective(800px);

}

.card_set {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
}

.card_set .front {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    /*让背面图案隐藏*/
    transform: rotateY(0deg);
    transition: transform .8s ease;
}

.card_set .back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: contain;
    backface-visibility: hidden;
    transform: rotateY(-180deg);
    transition: transform .8s ease;
}

.card_set.flip .front {
    z-index: 10;
    transform: rotateY(180deg);
}

.card_set.flip .back {
    z-index: 12;
    transform: rotateY(0deg);
}

.share {
    cursor: pointer;
}

.share2 {
    cursor: pointer;
    display: none;
}

body .lay-msg-bg {
    background-color: white !important;
}

/*  卡牌翻转↑*/
/* serch */
.serch_box {
    margin: 10px 0 0;
    padding: 20px;
    background: white;
    z-index: 9;
    position: relative;
}

.serch_title {
    display: flex;
    align-items: center;
}

.serch_title h5 {
    font-size: 20px;
    font-family: "SourceHanSansSC";
    color: rgb(51, 51, 51);
    line-height: 1.2;
    margin-left: 5px;
}

.serch_hat {
    background-color: rgb(128, 128, 128);
    width: 3px;
    height: 20px;
    border: solid 1px rgb(128, 128, 128);
}

.serch_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.serch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.serch input {
    border: solid 1px rgb(128, 128, 128);
    border-radius: 4px;
    background: #f6f7fb;
    padding-left: 25px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
}

.serch input::-webkit-input-placeholder {
    font-size: 14px;
    font-family: "Microsoft YaHei";
    color: rgb(153, 153, 153);
    line-height: 1.2;
}

.fdj {
    position: relative;
    left: 20px;
    z-index: 10;
}

.class_box {
    overflow: auto;
    max-height: 200px;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #d1b481;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: #e6e6e6;
    padding: 10px 0;
}

.serch_bottom .row {
    margin-top: 10px;
}

.serch_bottom>.row>.col-2 {
    padding: 0 5px;
}

::v-deep p {
    margin: 0 !important;
    padding: 0 !important;
}

.serch_content {
    display: flex;
    justify-content: left;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(233, 233, 232);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.serch_content img {
    border-radius: 5px;
}

.serch_content span {
    font-size: 20px;
    font-family: "Microsoft YaHei";
    color: rgb(51, 51, 51);
    line-height: 1.2;
    margin-left: 15px;
}

/* 弹窗 */

.bx_tc {
    display: none;
    position: relative;
    overflow: hidden;
}

.box_class {
    position: relative;
    top: 0;
    left: 0;
    z-index: -5;
}

.tc_title {
    position: absolute;
    left: 50%;
    bottom: 17%;
    transform: translateX(-50%);
    color: gold;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

@media (max-width:1700px) {
    .item {
        height: 323px;
    }

    .lq_last {
        bottom: -2%;
    }

    .msg_box {
        bottom: 3%;
    }
}
@media (max-width:1600px) {
    /* 立即兑换模态框 */
    .modal-title2 {
        font-size: 18px;
    }

    .left_tip h4 {
        font-size: 18px;
    }

    .left_tip span {
        font-size: 14px;
    }

    .left_tip span {
        font-size: 14px;
    }

    .duihuan_btn {
        font-size: 18px;
        padding: 10px 40px;
    }

    .select_title {
        font-size: 14px;
    }

    .user_title span {
        font-size: 18px;
    }

    .user_box {
        margin-top: 10px;
    }

    .address_xx {
        font-size: 18px;
    }

    .select_body {
        margin-top: 10px;
    }

    .content_dh {
        padding: 20px 0;
    }
}
@media (max-width:1550px) {

    .tx_time {
        margin-top: 5px;
    }

    .bigdian4 {
        bottom: -6px;
    }

    .bigdian7 {
        bottom: -6px;
    }

    .bigdian11 {
        bottom: -6px;
    }

    .bigdian17 {
        bottom: -6px;
    }

}

@media (max-width:1400px) {
    .item {
        height: 281px;
    }

    .title_size {
        font-size: 15px;
    }

    .by_time {
        margin-top: 5px;
    }

    .msg_box {
        bottom: 2%;
    }
    /* 立即兑换模态框 */
    .modal-title2 {
        font-size: 16px;
    }

    .left_tip h4 {
        font-size: 16px;
    }

    .left_tip span {
        font-size: 12px;
    }

    .left_tip span {
        font-size: 12px;
    }

    .duihuan_btn {
        font-size: 16px;
        padding: 10px 30px;
    }

    .select_title {
        font-size: 12px;
    }

    .user_title span {
        font-size: 16px;
    }

    .address_xx {
        font-size: 16px;
    }
}


@media (max-width:1300px) {
    .member {
        margin-top: 100px;
    }

    .item {
        height: 270px;
    }

    .left_top h4 {
        font-size: 12px;
    }

    .tx_time {
        margin-top: 0;
    }

    .jr {
        max-width: 1220px !important;
    }

    .title_box h {
        font-size: 20px;
    }

    .title_box h5 {
        font-size: 15px;
        margin: 0;
    }

    .jd span {
        font-size: 15px;
    }

    .title_size {
        font-size: 12px;
    }

    .box2_tb {
        bottom: 14%;
    }

    .serch_content span {
        font-size: 18px;
    }

    .lq_last {
        bottom: -10%;
    }

    .baoxian {
        bottom: 50px;
    }
}

@media (max-width:1200px) {
    .item {
        height: 238px;
    }
    /* 立即兑换模态框 */
    .jf_box {
        padding-bottom: 20px;
    }

    .select {
        padding: 20px 20px 0;
    }

    .user_info {
        padding: 10px 20px 10px;
    }

    .modal-title2 {
        font-size: 14px;
    }

    .left_tip h4 {
        font-size: 14px;
    }

    .duihuan_btn {
        font-size: 14px;
        padding: 10px 20px;
    }

    .user_title span {
        font-size: 14px;
    }

    .address_xx {
        font-size: 14px;
    }
}

@media (max-width:1100px) {

    .item {
        height: 226px;
    }

    .left_top {
        width: 40%;
    }

    .jr {
        max-width: 1036px !important
    }

    .title_box h4 {
        font-size: 17px;
    }

    .title_box h5 {
        font-size: 14px;
        margin: 0;
    }

    .title_size {
        font-size: 12px;
    }

    .by_time {
        margin-top: 5px;
    }

    .jd span {
        font-size: 15px;
    }

    .box2 h4 {
        font-size: 14px;
        /* margin-bottom: 15px; */
    }

    .box2_fb_tb h4 {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .box2_tb {
        bottom: 26%;
    }

    /* 头像 */
    .tx_size {
        line-height: 13px
    }

    .tx {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    /*  */
    .serch_content span {
        font-size: 15px;
    }

    .lq_last {
        bottom: -20%;
    }
}
</style>