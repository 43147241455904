<template>
  <div class="banner">
    <div class="container">
        <div class="row">
        <Suspendedwindow></Suspendedwindow>
        <ReportWindow></ReportWindow>
            <div class="col_gameclass vb">
                <div class="model_rouder">
                    <a @click="toWallet"><img class="model_img" :src="material.pc_index_card ? (img_url + material.pc_index_card.stored):''" width="100%"></a>
                    <a  @click="click_member"><img class="model_img" :src="material.pc_index_card ? (img_url + material.pc_index_card.sing):''" width="100%"></a>
                    <a @click="click_atlas"><img class="model_img" :src="material.pc_index_card ? (img_url + material.pc_index_card.hot):''" width="100%"></a>
                </div>
            </div>
            <div class="col_carousel vb" ref="lb" >
                <Carousel :lb_height="isHeihgt" ></Carousel>
            </div>
            <div class="col_vip vb" >
                <vip ref="vip_h"  v-resize="divResize"></vip>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Carousel from './Carousel.vue';
import GameClass from './GameClass.vue';
import Suspendedwindow from '../components/Suspendedwindow.vue';
import ReportWindow from '../components/ReportWindow.vue';
import { mapState } from 'vuex';
import Vip from './Vip.vue';
    export default {
        name:'Banner',
        components:{Carousel, Vip, GameClass,Suspendedwindow,ReportWindow},
        data() {
            return {
                isHeihgt:0,
            }
        },
        computed:{
            ...mapState([
                'material'
            ]),
            cz_id:{
                get() {
                    return this.$store.state.cz_id
                },
                set(val) {
                    return this.$store.state.cz_id = val
                }
            },
        },
        methods:{
            click_atlas(){
                this.$router.push({
                    path:'/atlas'
                })
                this.$czc( 'PC首页通关成就', '点击', '访问通关成就')
            },
            toWallet(){
                this.cz_id = 1
                this.$router.push({
                    path:'/member/wallet'
                })
                this.$czc( 'PC首页储值入口', '点击', '访问平台充值')
            },
            click_member(){
                // this.$router.push({
                //     path:'/member'
                // })
                // this.$czc('PC首页积分签到', '点击', '访问积分签到')
                // this.cz_id = 2
                this.$router.push({
                    path:'/member'
                })
                this.$czc('PC首页月卡购买', '点击', '访问月卡购买')
            },
            divResize(data){
                this.isHeihgt = data.height
                console.log("VIP高度改变",this.isHeihgt)
            }
            
        },
        directives: {
            // 自定义指令名称
            resize: {
                // 绑定时调用
                bind(el, binding){
                    let height = ""
                    function isResize(){
                        // 可根据需求，调整内部代码，利用binding.value返回即可
                        const style = document.defaultView.getComputedStyle(el)
                        if(height !== style.height){
                            // 此处关键代码，通过此处代码将数据进行返回，从而做到自适应
                            binding.value({height: style.height}) 
                        }
                        height = style.height
                    }
                    // 设置调用函数的延时，间隔过短会消耗过多资源
                    el.__vueSetInterval__ = setInterval(isResize, 100)
                },
                unbind(el){
                    clearInterval(el.__vueSetInterval__)
                }
            }
        },
        mounted(){
            this.$nextTick(function () {
                //  仅在整个视图都被重新渲染之后才会运行的代码
                window.onresize = () => {
                    return (() => {
                        setTimeout(()=>{
                            this.$nextTick(()=>{
                                const lb_h = this.$refs.vip_h.$el.offsetHeight
                                // console.log(lb_h)
                                this.isHeihgt = lb_h
                            })
                        },500) 
                    })();
                };
                setTimeout(()=>{
                    this.$nextTick(()=>{
                        const lb_h = this.$refs.vip_h.$el.offsetHeight
                        // console.log(lb_h)
                        this.isHeihgt = lb_h
                    })
                },800) 
                
            })
        }
    }
</script>

<style scoped>
.banner{
    overflow: hidden;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: #f5f5f5;
    padding-top: 30px;
}

.vb{
    padding: 0 6px;
}
.col_vip{
    width: 20%;
}
.col_carousel{
    width: 50%;
}
.col_gameclass{
    width: 30%;
}
.model_rouder{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}
.model_rouder a{
    width: 100%;
}

@media (max-width:1750px) {

}
@media (max-width:1450px) {
    .model_img{
        height: 100px;
    }
}
@media (max-width:1400px) {
    .model_img{
        height: 95px;
    }
}
@media (max-width:1200px) {
    .model_img{
        height: 85px;
    }
}
</style>