<template>
  <div class="sm_tc">
    <img :src="img_url +material.pc_sl_ts">
  </div>
</template>

<script>
import { mapState } from "vuex";
  export default {
      name:'Age',
      data() {
        return {
          img:'/supended/img/shuomin.jpg'
        }
      },
      computed:{
        ...mapState([
          'getClient',
          'material'
        ])
      },
  }
</script>

<style scoped>
  /* 弹窗 */
  .sm_tc{
    display: none;
  }
</style>