<template>
    <div>
        <Header></Header>
        <div class="member">
            <div class="container">
                <MemberTop></MemberTop>
                <div class="bottom_box">
                    <div class="row">
                        <div class="col-2">
                            <div class="left_nav">
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/personal">个人中心</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/wallet" @click.native="clicl_czc">会员充值</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/level">成长福利</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/played">近期玩过</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/echarge">充值记录</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/walletBalance">储值记录</router-link>
                                <!-- <router-link class="nav_font nav_pd" active-class="active" to="/member/monthlyCardRecord">月卡记录</router-link> -->
                                <!-- <router-link class="nav_font nav_pd" active-class="active" to="/member/giftBenefit">赠送福利记录</router-link> -->
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/memberMouthRecord">包服卡兑换记录</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/memberExchangeRecord">会员兑换记录</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/redeemGame">积分兑换记录</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/historyGame">历史版本</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/realName" v-if="userInfo.user_idcard == null">实名认证</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/phoneBinding" v-if="userInfo.user_mobile == null">完善信息</router-link>
                                <router-link class="nav_font nav_pd" active-class="active" to="/member/passwordModification">密码修改</router-link>
                                <router-link v-if="userInfo.user_account_0 == false" class="nav_font nav_pd" active-class="active" to="/member/createUer">创建账号</router-link>
                                <!-- <router-link class="nav_font nav_pd" active-class="active" to="/member/forgot">忘记密码</router-link> -->
                            </div>
                        </div>
                        <div class="col-10">
                            <keep-alive>
                                <router-view></router-view>
                            </keep-alive>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 背景 -->
            <img class="member_bg" :src="img_url + material.pc_background" width="100%">
        </div>
        <SigIn ref="sigin"></SigIn>
         <!-- 签到弹窗 -->
         <div class="singin_tc" ref="singin_tc">
            <img class="closed" src="/member/img/close.png">
            <img class="ljcz" src="/member/img/btn.png">
            <span id="countdown">5</span>
            <!-- 背景 -->
            <img class="tc_bg" src="/member/img/bgs.png">
        </div> 
        <!-- 询问37合并账号弹窗 -->
        <div class="hb_tc flex_box" id="hb_tc">
            <div class="form_posion">
                <div class="hb_title">37ww账号密码合并</div>
                <label class="hb_label text_ellipsis flex_box"><span>账号：</span><input type="text" class="hb_input" id="hb_account" v-model="account" placeholder="请输入账号"></label>
                <label class="hb_label text_ellipsis flex_box"><span>密码：</span><input type="password" class="hb_input" id="hb_password" v-model="password" placeholder="请输入密码" ></label>
                <div class="queren_btn btn_hover" @click="accountMerge">确认</div>
            </div>
        </div>
        <PayCode ref="pay_code"></PayCode>
        <Footer></Footer>
    </div>
  
</template>

<script>
    import Header from '../components/Header.vue'
    import MemberTop from '../components/MemberTop.vue'
    import Footer from '../components/Footer.vue'
    import SigIn from '../components/SignIn.vue'
    import PayCode from '../components/PayCode.vue'
    import { mapState } from 'vuex'
    import {accountMerge} from '@/api/http';
    export default {
        name:'Member',
        components:{MemberTop, Header,Footer,SigIn,PayCode},
         data() {
            return {
               account:'',
               password:'',
               hb_flag:true
            }
        },
        computed:{
            ...mapState([
                'material',
                'userInfo',
                'ReceiveBenefits'
            ]),
            cz_id:{
                get() {
                    return this.$store.state.cz_id
                },
                set(val) {
                    return this.$store.state.cz_id = val
                }
            },
        },
        methods:{
            clicl_czc(){
                this.cz_id = 1
                this.$czc( '会员中心侧边菜单-会员充值', '点击', '访问平台充值')
            },
             // 账号密码合并接口
             accountMerge(){
                if(this.hb_flag){
                    this.hb_flag = false
                    accountMerge({
                        new_id:this.userInfo.id,
                        account:this.account,
                        password:this.password
                    }).then((res)=>{
                        if(res.code == 200){
                            this.$message({
                                message:res.msg,
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.hb_flag = true
                                    this.account = ''
                                    this.password = ''
                                    $('.layui-layer-close').click()
                                }
                            })
                        }
                    }).catch((err)=>{
                        console.log("捕获异常",err)
                        this.$message({
                            message:err.msg,
                            type: 'warning',
                            duration: 1500,
                            onClose:() =>{
                                this.hb_flag = true
                                this.account = ''
                                this.password = ''
                            }
                        })
                    })
                }else{
                    this.$message({
                        message:'请勿重复提交',
                        type: 'warning',
                        duration: 1500,
                    })
                }
                
            },

        }
    }
</script>

<style scoped>
/* 询问账号合并弹窗 */
.hb_input{
    border: 1px solid #E6E6E6;
}
.hb_label:nth-of-type(1){
    margin-bottom: 10px;
}
.hb_tc{
    display: none;
    width: 400px;
    height: 200px;
    background: white;
    position: relative;
    border-radius: 4px;
}
.form_posion{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.hb_title{
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #E6E6E6;
    text-align: center;
}
.queren_btn{
    margin-top: 15px;
    padding: 5px 15px;
    background: #AC8747;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
/* 签到弹窗 */
.singin_tc{
    display: none;
}
.tc_bg{
    position: relative;
    left: 0;
    top: 0;
    z-index: -99;
}
.closed{
    position: absolute;
    right: 0;
    top: 20%;
    cursor: pointer;
}
.ljcz{
    position: absolute;
    left: 50%;
    bottom: 15%;
    transition: all 0.5s;
}
.ljcz:hover{
    transition: all 0.5s;
    filter: brightness(1.2);
}
#countdown{
    font-size: 20px;
    font-weight: bolder;
    color: rgb(255, 214, 150);
    position: absolute;
    left: 60.5%;
    top: 60.5%;
}

.member{
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin-top: 110px;
    background: #18181a;
}
.member_bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* height: 100%; */
}
.bottom_box{
    position: relative;
    z-index: 2;
}
.bottom_box > .row > div:nth-of-type(1){
    padding-right: 6px;
}
.bottom_box > .row > div:nth-of-type(2){
    padding-left: 6px;
}
/* 左侧导航*/
.active{
    border-left: 3px solid #93794C !important;
    background: #F7F5F2 ;
    color: #6F490C !important;
}
.nav_font:hover{
    border-left: 3px solid #93794C ;
    background: #F7F5F2;
    color: #6F490C ;
    transition: all 0.5s;
}
.bottom_box{
    margin-top: 10px;
}
.left_nav{
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 10px 0 85px;
    display: flex;
    flex-direction: column;
}
.nav_font{
    text-decoration: none;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #463F34;
    padding-left: 50px;
    border-left:3px solid white ;
    transition: all 0.5s;
}
.nav_pd{
    padding: 15px 50px;
}
@media (max-width:1600px) {
    /* 左侧导航 */
    .nav_pd {
        padding: 10px 35px;
    }
    .nav_font{
        font-size: 14px;
    }
    .head_png{
        width: 15%;
    }
    
}
@media (max-width: 1300px){
    .member{
        margin-top: 100px;
    }
    /* 左侧导航 */
    .nav_pd {
        padding: 10px 25px;
    }
    .nav_font{
        font-size: 12px;
    }
}

</style>