<template>
  <div id="app">
    <!-- 模板二 -->
    <router-view></router-view>
  </div>
</template>

<script>

import Header from './components/Header.vue';
import Banner from './components/Banner.vue';
import Ranking from './components/Ranking.vue';
import GameList from './components/GameList.vue';
import Footer from './components/Footer.vue';
import {Bus} from './router/bus.js'
import { getClientConfig, getMaterialData, getUserInformation, getUserGrowth, getUserStored, getUserPoint } from '@/api/http.js'
export default {
  name: 'app',
  components: {
    Header,
    Banner,
    Ranking,
    GameList,
    Footer,
  },
  data() {
    return {
      screenWidth: null,
      time:''
    }
  },
  watch:{
    screenWidth:{
      handler(nval,oval){
        console.log('窗口发送变化')
      }
    }
  },
  computed: {
    material: {
      get() {
        return this.$store.state.material
      },
      set(val) {
        return this.$store.state.material = val
      }
    },
    // 借助mapstate生成计算属性，从state中读取数据（数组写法）
    unLogin: {
      get() {
        return this.$store.state.unLogin
      },
      set(val) {
        return this.$store.state.unLogin = val
      }
    },
    isLogin: {
      get() {
        return this.$store.state.isLogin
      },
      set(val) {
        return this.$store.state.isLogin = val
      }
    },
    userInfo: {
      get() {
        return this.$store.state.userInfo
      },
      set(val) {
        return this.$store.state.userInfo = val
      }
    },
    userGrowth: {
      get() {
        return this.$store.state.userGrowth
      },
      set(val) {
        return this.$store.state.userGrowth = val
      }
    },
    userStore: {
      get() {
        return this.$store.state.userStore
      },
      set(val) {
        return this.$store.state.userStore = val
      }
    },
    userPoint: {
      get() {
        return this.$store.state.userPoint
      },
      set(val) {
        return this.$store.state.userPoint = val
      }
    },
    serve_quick:{
      get(){
        return this.$store.state.serve_quick
      },
      set(val){
        return this.$store.state.serve_quick = val
      }
    }
  },
  methods: {
    async getMaterialData() {
      try {
        const res = await getMaterialData()
        if (res.code === 200) {
          this.material = res.data
          localStorage.setItem('material',JSON.stringify(res.data))
          localStorage.setItem('material_time',res.ex_time)
        }
      } catch (err) {
        console.log("捕获异常", err)
      }
    },
    //获取设备唯一标识
    async getUniqueCode(){
      await this.$finger.load().then( fp => {
            fp.get().then(result => {
            　　const visitorId = result.visitorId;
                localStorage.setItem('finger-id',visitorId)
            　　console.log('获取设备唯一标识：',visitorId);
            }).then(()=>{
              location.reload()
            })
        })
    },
    // 判断本地是否存在Config，没有就发送请求（时间戳）
    async getClientConfig(){
      let config = JSON.parse(localStorage.getItem('config'));
      this.time = parseInt(new Date().getTime())
      localStorage.setItem('nowTime',this.time)   //存储当前时间戳
      if(config) {
        let configTime =new Date(config['exp_time']).getTime()
        console.log('我是configTime',configTime)
        console.log('我是当前时间戳',this.time)
        if(configTime >= this.time) {
						return config;
				}else{
          // if(localStorage.getItem('finger-id')){
          //   this.getConfig()
          // }else{
          //   this.getUniqueCode()
          // }
          this.getConfig()
        }
      } else {
        this.getConfig()
        // if(localStorage.getItem('finger-id')){
        //     this.getConfig()
        //   }else{
        //     this.getUniqueCode()
        //   }
      }
    },
    loadScript() {
        console.log('我插入了')
        // 删除旧脚本（如果存在）
          const existingScript = document.getElementById('kjk');
          if (existingScript) {
              existingScript.remove();
          }
          var timestamp = new Date().getTime();
          const script = document.createElement('script');
          script.id = 'kjk';
          script.src = 'https://betaoa.3pk.com/client/public/get_client_configs' + "?v=" + timestamp;
          script.onload = () => {
              localStorage.setItem('config',JSON.stringify(clientConfig))

          };
          document.head.appendChild(script);
      },
    // 渠道接口
    async getConfig(){
      try{
          const res = await getClientConfig()
          if(res.code === 200){
            localStorage.setItem('config',JSON.stringify(res.data))
            // localStorage.setItem('configTime',JSON.stringify(res.ex_time))
            console.log('我刷新了,w1caonima')
            // location.reload()
            this.$bus.$emit('allover')
          }
        }catch(err){
          console.log("捕获异常",err)
        }finally{
          this.serve_quick = true
          localStorage.setItem('serve_quick',this.serve_quick)
        }
    },
    // 获取当前时间
    getTime() {
      var date = new Date(),
        year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate(),
        hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
        second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      month >= 1 && month <= 9 ? (month = "0" + month) : "";
      day >= 0 && day <= 9 ? (day = "0" + day) : "";
      var timer = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      return timer;
    },

    // 确定登入状态，获取用户信息
    getUserInformation() {
      getUserInformation().then((res) => {
        if (res.code === 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.userInfo = res.data
          this.unLogin = false
          this.isLogin = true
          
        } else {
          console.log(res.msg)
        }
      }).catch((err) => {
        //token失效时清除本都数据
        localStorage.setItem("token", '')
        this.$router.push({
          path: '/home'
        })
        console.log('捕获异常', err)
      })
    },
    // 获取用户成长信息
    getUserGrowth() {
      getUserGrowth().then((res) => {
        if (res.code === 200) {
          this.userGrowth = res.data
        } else {
          console.log(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取用户储值信息
    getUserStored() {
      getUserStored().then((res) => {
        if (res.code === 200) {
          this.userStore = res.data
        } else {
          console.log(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取用户积分信息
    getUserPoint() {
      getUserPoint().then((res) => {
        if (res.code === 200) {
          this.userPoint = res.data
        } else {
          console.log(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    AdminInits() {
      //layui-fluid 为外层div
      console.log('layui-fluid 为外层div')
      $('.layui-fluid').height($(window).height());
      $('body').height($(window).height());
    }
    
  },
  mounted(){
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
    // function AdminInit() {
    //   //layui-fluid 为外层div
    //   $('.layui-fluid').height($(window).height());
    //   $('body').height($(window).height());
    // }

    // layer窗口变化位置居中
    // $(window).on('resize', function() {
    //     $('.layui-fluid').height($(window).height());
    //     $('body').height($(window).height());
    //     // iframe窗口自适应
    //     var $content = $('#nav_xbs_tab .layui-tab-content');
    //     $content.height($(this).height() - 125);
    //     $content.find('iframe').each(function() {
    //       $(this).height($content.height());
    //     });
    //   }).resize();
  },
   created() {
    this.getClientConfig()
    this.time = parseInt(new Date().getTime())
    const material_time = localStorage.getItem('material_time')
    const material = JSON.parse(localStorage.getItem('material'))
    if(this.time >= material_time || !material_time || !material){
      this.getMaterialData()
    }
    if (localStorage.getItem('token')) {
      this.getUserInformation()
      this.getUserGrowth()
      this.getUserStored()
      this.getUserPoint()
    }
  }
}
</script>

<style>
body{
  min-width: 1200px;
}
#cnzz_stat_icon_1281119979 {display: none;}
.el-message{
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}
.layui-layer {
  background-color: transparent !important;
  box-shadow: none !important;
}
/* .layui-layer-page .layui-layer-content{
    overflow: hidden !important;
} */
/* .layui-layer-content{
  height: 100%;
} */
/* v-lazy */
img[lazy=loading] {
		/*加载中*/
		border-radius: 19rpx;
		background: linear-gradient(90deg, #F1F2F4 25%, #e6e6e6 37%, #F1F2F4 50%);
		background-size: 400% 100%;
		margin-right: 15px;
		animation: skeleton 1.8s ease infinite;
	}
	
	img[lazy=error] {
		/*加载中*/
		border-radius: 19rpx;
		background: linear-gradient(90deg, #F1F2F4 25%, #e6e6e6 37%, #F1F2F4 50%);
		background-size: 400% 100%;
		margin-right: 15px;
		animation: skeleton 1.8s ease infinite;
	}

	@keyframes skeleton {
		0% {
			background-position: 100% 50%;
		}

		100% {
			background-position: 0 50%;
		}
	}
/*按钮主题色  */
.btn_hover{
  cursor: pointer;
  transition: all 0.5s;
}
.btn_hover:hover{
  transition: all 0.5s;
  filter: brightness(1.2);
}
.btn_color_down {
  background: #BF4949;
  transition: all 0.5s;
}

.btn_color_down:hover {
  transition: all 0.5s;
  filter: brightness(1.2);
}

.btn_color {
  transition: all 0.5s;
  background: #AC8747;
}

.btn_color:hover {
  transition: all 0.5s;
  filter: brightness(1.2);
}
.btn_color_light {
  transition: all 0.5s;
  background: #d3ad6c;
}

.btn_color_light:hover {
  transition: all 0.5s;
  filter: brightness(1.2);
}

/* 全局配置message */
.el-message {
  z-index: 9999999999 !important;
}

input {
  outline-style: none;
}

input:focus {
  border: 1px solid #AC8747 !important;
}

textarea {
  outline-style: none;
}

textarea:focus {
  border: 1px solid #AC8747 !important;
}

label {
  cursor: pointer;
}

.flex_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  cursor: pointer;
}

.lang_one {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lang_point {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.text_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.customMsgCls {
  z-index: 20010 !important;
}

@media (min-width: 1700px) {
  .container {
    max-width: 1500px !important;
  }
}
</style>
