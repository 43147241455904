m<template>
  <div class="content">
    <div class="rule_box">
      <span class="rule_text">
        活动规则:<br>
        1.单笔充值对应金额可以获得博饼机会次数;<br> 
        2.奖励储值点发放到会员账号。
      </span>
    </div>
    <div class="active_box">
      <div class="banner_box">
        <div class="left_center">
          <div class="jianli_box">
            <div class="jl_item">
              <div>
                <img class="jl_name" src="/activity/midAutumn/img/zy.png" >
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
              </div>
              <div class="jl_title">
                <span>奖励储值</span>
                <span class="jl_num">8888</span>
              </div>
            </div>
            <div class="jl_item">
              <div>
                <img class="jl_name" src="/activity/midAutumn/img/dt.png" >
                <img class="jl_dist" src="/activity/midAutumn/img/dt1.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt2.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt3.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt4.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt5.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt6.png">
              </div>
              <div class="jl_title">
                <span>奖励储值</span>
                <span class="jl_num">3888</span>
              </div>
            </div>
            <div class="jl_item">
              <div>
                <img class="jl_name" src="/activity/midAutumn/img/sh.png" >
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
              </div>
              <div class="jl_title">
                <span>奖励储值</span>
                <span class="jl_num">1888</span>
              </div>
            </div>
            <div class="jl_item">
              <div>
                <img class="jl_name" src="/activity/midAutumn/img/sj.png" >
                <img class="jl_dist" src="/activity/midAutumn/img/dt2.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt2.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt2.png">
                <img class="jl_dist" src="/activity/midAutumn/img/dt2.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
              </div>
              <div class="jl_title">
                <span>奖励储值</span>
                <span class="jl_num">888</span>
              </div>
            </div>
            <div class="jl_item">
              <div>
                <img class="jl_name" src="/activity/midAutumn/img/ej.png" >
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
              </div>
              <div class="jl_title">
                <span>奖励储值</span>
                <span class="jl_num">388</span>
              </div>
            </div>
            <div class="jl_item">
              <div>
                <img class="jl_name" src="/activity/midAutumn/img/yx.png" >
                <img class="jl_dist" src="/activity/midAutumn/img/four.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
                <img class="jl_dist" src="/activity/midAutumn/img/bank.png">
              </div>
              <div class="jl_title">
                <span>奖励储值</span>
                <span class="jl_num">288</span>
              </div>
            </div>
            <!-- 背景 -->
            <img class="img_width" src="/activity/midAutumn/img/jianli_bg.png" width="100%">
          </div>
          <div class="center_width">
            <div class="card_box flex_box">
              <div class="item_box flex_box">
                <span>单笔充值100<br>
                  博饼次数1</span>
              </div>
              <div class="item_box flex_box">
                <span>单笔充值200<br>
                  博饼次数2</span>
              </div>
              <div class="item_box flex_box">
                <span>单笔充值300<br>
                  博饼次数3</span>
              </div>
              <div class="item_box flex_box">
                <span>单笔充值500<br>
                  博饼次数6</span>
              </div>
              <div class="item_box flex_box">
                <span>单笔充值1000<br>
                  博饼次数13</span>
              </div>
              <img class="btn_hover ljcz_btn" @click="toReach()" src="/activity/midAutumn/img/ljcz.png" width="100%">
            </div>
            <div class="info_box flex_box" v-if="isactive">
              <span style="margin-right: 1rem;">用户ID:{{active_info.account_id}}</span>
              <span>当前拥有博饼次数：<b style="color:red">{{active_info.take_num}}</b>次</span>
            </div>
            <div class="info_box flex_box" v-else>
              <span>活动已结束</span>
            </div>
            <div class="center_box">
              <img v-show="this.isShow" class="tz_1  tz_img" :class="{'dice1':flag}" src="/activity/midAutumn/img/1.png">
              <img v-show="this.isShow" class="tz_2  tz_img" :class="{'dice2':flag}" src="/activity/midAutumn/img/2.png">
              <img v-show="this.isShow" class="tz_3  tz_img" :class="{'dice3':flag}" src="/activity/midAutumn/img/3.png">
              <img v-show="this.isShow" class="tz_4  tz_img" :class="{'dice4':flag}" src="/activity/midAutumn/img/4.png">
              <img v-show="this.isShow" class="tz_5  tz_img" :class="{'dice5':flag}" src="/activity/midAutumn/img/5.png">
              <img v-show="this.isShow" class="tz_6  tz_img" :class="{'dice6':flag}" src="/activity/midAutumn/img/6.png">
              <!-- 背景 -->
              <img  src="/activity/midAutumn/img/wan.png" width="100%">
            </div>
            <img class="btn_hover kjbb_btn" @click="boBingReceive" src="/activity/midAutumn/img/play.png">
          </div>
        </div>
        <div class="right_box">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;博饼文化是闽南地区特有的中秋节传统民俗活动，起源于福建泉州府同安县（今福建厦门地区），由郑成功发明。这一活动不仅是一种大众娱乐，而且富含深厚的文化意义，被认为是闽台地区民俗的一部分，并且已被列入泉州市市级非物质文化遗产名录。<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;博饼的规则基于掷六个骰子，通过组合这些骰子的点数来决定奖品，奖品包括状元饼、对堂饼、三红饼、四进饼、二举饼、一秀饼，分别代表不同的科举级别。博饼的规则包括多种组合，如出现特定数量的相同点数（如四个红四点为最高级别的状元，称为“状元插金花”），以及更复杂的组合如“五子”和“六朴红”，后者指的是六颗骰子均为同一数字的情况。<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;博饼文化的流传与郑成功的军事活动密切相关。相传，郑成功在厦门期间，为了缓解士兵的思乡之情，设计了这一游戏，使其成为一种激励士气的活动。随着时间的推移，这一活动逐渐演变成厦门及周边地区中秋节不可或缺的一部分，成为连接家庭和社区的重要纽带。博饼活动强调的是参与和乐趣，参与者通过掷骰子争夺奖品，同时寄托了对未来美好生活的期望。这种活动不仅丰富了节日的氛围，也加深了人们对传统文化的认同感。<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;此外，博饼文化还包含了丰富的象征意义，其中博得状元被认为能带来一年的好运。这种文化现象体现了人们对美好生活的向往和对传统文化的尊重。每年中秋节期间，厦门及周边地区的家庭和朋友都会聚集在一起，进行博饼游戏，享受这一传统习俗带来的快乐和团结的氛围。这一活动不仅是对历史的传承，也是对未来美好生活的祝愿。
          <!-- 背景 -->
          <img class="img_width" src="/activity/midAutumn/img/right_bg.png" width="100%">
        </div>
      </div>
      <img class="bg_img"  src="/activity/midAutumn/img/bg.png" width="100%">
    </div>
    <!-- 弹窗 -->
    <div class="bobing_tc">
      <img class="leader_btn" src="/activity/midAutumn/img/right.png">
      <!-- 背景 -->
      <img class="tc_bg" :src="win_img" width="100%">
    </div>
  </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {bobingInfo,boBingReceive} from '@/api/http'
export default {
  name:'midAutumn',
  data() {
    return {
      fontSizes:{
        1800:'15px',
        1700:'14px',
        1600:'13px',
        1400: '12px',
        1300: '11px',
        1250: '10px',
        1050: '9px',
        930: '8px',
      },
      active_info:{},
      isactive:false, //活动是否开启
      isClick:true,
      flag:false,//控制动画
      isShow:true, //控制骰子显示
      win_img:'',
    }
  },
  methods:{
       // 弹窗
       leaderTc() {
            var that = this
            var leader_tc = $('.bobing_tc')
            var LayIndex = layer.open({
                type: 1,
                content: leader_tc,
                anim: false,
                fixed: true,
                shade: 0.8,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                closeBtn: 0,
                isOutAnim: false,
                area: [auto, auto],
                success: function (layero) {
                    const lq = $(layero).find('.leader_btn');
                    lq.click(function () {
                        that.isShow = true
                        layer.close(LayIndex)
                    })
                }
            });
        },
    // 开始博饼接口
    boBingReceive(){
      if(this.isClick){
        this.isClick = false
        boBingReceive({
          token:this.$route.query.token
        }).then((res) =>{
            if(res.code == 200){
              this.flag = true //动画开启
              this.win_img = res.data.image
              this.bobingInfo()
              setTimeout(()=>{
                this.isClick = true
                this.flag = false
                this.isShow = false
                this.leaderTc()
              },1000)
            }
        }).catch((err) =>{
          console.log('捕获异常', err)
          this.$message({
                message: err.msg,
                type: 'warning',
                duration: 1500,
                onClose:()=>{
                  this.isClick = true
                }
            })
        })
      }else{
        this.$message({
            message: '操作过于频繁，请稍后再试~',
            type: 'warning',
            duration: 1500,
            onClose:()=>{
              this.isClick = true
            }
        })        
      }
      
    },
    bobingInfo(){
      bobingInfo({
        token:this.$route.query.token
      }).then((res) =>{
          if(res.code == 200){
            this.active_info =  res.data
            this.isactive = res.data.isactive
          }
      }).catch((err) =>{
        console.log('捕获异常', err)
        this.$message({
              message: err.msg,
              type: 'warning',
              duration: 1500,
          })
      })
    },
    toReach(){
      this.$router.push({
          path:'/member/wallet'
        })
    },
    // 根据屏幕宽度设置字体大小
    setFontSize() {
      const width = window.innerWidth;
      const html = document.querySelector('html');
      for (const breakpoint in this.fontSizes) {
        if (width <= breakpoint) {
          html.style.fontSize = this.fontSizes[breakpoint];
          break;
        }
      }
    }
  },
  mounted() {
    document.body.style.backgroundColor = '#144d58';
    // 初始化字体大小
    this.setFontSize();
    // 监听窗口大小变化，重新设置字体大小
    window.addEventListener('resize', this.setFontSize());
  },
  created(){
    this.bobingInfo()
  }
}
</script>

<style scoped>
.bobing_tc{
  display: none;
  position: relative;
  width: 41.6875rem;
}
.leader_btn{
  position: absolute;
    left: 58%;
    bottom: 10%;
    width: 6.25rem;
}
.tc_bg{
    position: relative;
    z-index: -10;
}
.jl_item{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.jl_name{
  width: 4.69rem;
}
.jl_dist{
  margin-left: .25rem;
  width: 2.5rem;
}
.jl_title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: SimSun;
  font-weight: 400;
  font-size: 0.75rem;
  color: #FFFEC4;
  line-height: 1.13rem;
}
.jl_num{
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1.25rem;
  color: #E8E8EA;
  line-height: 1.13rem;
  background: linear-gradient(0deg, #FFEC95 25.68359375%, #FFFEE6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img_width{
  width: 29.38rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -50;
}
.info_box{
  margin-top: .6rem;
  font-family: SimSun;
  font-weight: 400;
  font-size: 0.75rem;
  color: #013135;
  line-height: 1.13rem;
}
.item_box{
  background: url('/public/activity/midAutumn/img/card.png') no-repeat;
  background-size: 7.25rem 2.81rem;
  min-width: 7.25rem;
  min-height: 2.81rem;
  margin-right: .25rem;
}
.ljcz_btn{
  max-width: 7.25rem;
}
.item_box span{
  font-family: SimSun;
  font-weight: 400;
  font-size: 0.75rem;
  color: #013135;
  line-height: 0.88rem;
  text-stroke: 0.06px #2A716F;
  background: linear-gradient(0deg, #FFF6CE 0%, #FFFEF3 100%);
  -webkit-text-stroke: 0.06px #2A716F;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.bg_img{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
}
.banner_box{
  display: flex;
  width: 100%;
  justify-content: center;
}
.left_center{
    display: flex;
    justify-content: center;
}
.jianli_box{
  min-width: 29.38rem;
  padding: 3.19rem 1.81rem 0;
  display: flex;
  flex-direction: column;
  position: relative;
}
.center_box{
  width: 35rem;
  margin-top: .5rem;
  position: relative;
}
.tz_img{
  width: 4.5rem;
  position: absolute;
}
.tz_1{
    bottom: 30%;
    left: 30%;
}
.tz_2{
    bottom: 12%;
    left: 31%;
}
.tz_3{
    bottom: 36%;
    left: 46%;
}
.tz_4{
  left: 53%;
  bottom: 10%;
}
.tz_5{
    right: 28%;
    bottom: 26%;
}
.tz_6{
    left: 44%;
    top: 60%;
}
.kjbb_btn{
  width: 18.13rem;
}
.center_width{
    margin: 10.38rem 7.88rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card_box{
  margin-top:0.81rem;
}
.right_box{
  position: relative;
  max-width: 29.38rem;
  min-width: 29.38rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 0.75rem;
  color: #FFFEEB;
  line-height: 1.13rem;
  text-stroke: 0.06px #254D51;
  -webkit-text-stroke: 0.06px #254D51;
  padding: 3.69rem 1.69rem 0;
}
.active_box{
  padding: 14rem 3.13rem  3rem;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.rule_box{
  position: absolute;
  left: 50%;
  top: 19.44rem;
  transform: translateX(-36%);
}
.rule_text{
  font-family: SimSun;
  font-weight: 400;
  font-size: 0.75rem;
  color: #013135;
  line-height: 1.13rem;
}

.dice6 {
  animation: dice-throw6 1s ease-in-out ;
}
.dice5 {
  animation: dice-throw5 1s ease-in-out ;
}
.dice4 {
  animation: dice-throw4 1s ease-in-out ;
}
.dice3 {
  animation: dice-throw3 1s ease-in-out ;
}
.dice2 {
  animation: dice-throw2 1s ease-in-out ;
}
.dice1 {
  animation: dice-throw1 1s ease-in-out ;
}
@keyframes dice-throw1 {
  0% {
    transform: translate(0, 0) scale(1); /* 初始状态 */
  }
  5% {
    transform: translate(-.625rem, -1.25rem) scale(1) rotate(22.5deg); 
  }
  10% {
    transform: translate(-0.9375rem, -2.5rem) scale(1) rotate(45deg); 
  }
  15% {
    transform: translate(-1.25rem, -3.75rem) scale(0.95) rotate(67.5deg); 
  }
  20% {
    transform: translate(-1.5625rem, -5rem) scale(0.9) rotate(90deg); 
  }
  25% {
    transform: translate(-1.875rem, -6.25rem) scale(0.85) rotate(112.5deg); 
  }
  30% {
    transform: translate(-2.1875rem, -7.5rem) scale(0.8) rotate(135deg); 
  }
  35% {
    transform: translate(-2.5rem, -8.5rem) scale(0.75) rotate(157.5deg); 
  }
  40% {
    transform: translate(-2.8125rem, -10rem) scale(0.75) rotate(180deg); 
  }
  45% {
    transform: translate(-3.125rem, -8.5rem) scale(0.8) rotate(202.5deg); 
  }
  50% {
    transform: translate(-3.4375rem, -7.5rem) scale(0.85) rotate(225deg); 
  }
  55% {
    transform: translate(-3.75rem, -6.25rem) scale(0.85) rotate(247.5deg); 
  }
  60% {
    transform: translate(-4.0625rem, -5rem) scale(0.9) rotate(270deg); 
  }
  65% {
    transform: translate(-4.375rem, -3.75rem) scale(1) rotate(292.5deg); 
  }
  70% {
    transform: translate(-4.6875rem, -2.5rem) scale(1) rotate(315deg); 
  }
  75% {
    transform: translate(-3.125rem, -1.25rem) scale(1.05) rotate(337.5deg); 
  }
  80% {
    transform: translate(-1.5625rem, 0px) scale(1.05) rotate(360deg); 
  }
  85% {
    transform: translate(0px, 1.25rem) scale(1.1) rotate(382.5deg); 
  }
  90% {
    transform: translate(1.5625rem, 2.5rem) scale(1.1) rotate(405deg); 
  }
  95% {
    transform: translate(2.5rem, 3.75rem) scale(1.15) rotate(427.5deg); 
  }
  100% {
    transform: translate(4.0625rem, 0) scale(1.2) rotate(450deg); 
  }
}
@keyframes dice-throw2 {
  0% {
    transform: translate(0, 0) scale(1); /* 初始状态 */
  }
  5% {
    transform: translate(-1.25rem, -1.25rem) scale(1) rotate(22.5deg); 
  }
  10% {
    transform: translate(-2.5rem, -2.5rem) scale(0.95) rotate(45deg); 
  }
  15% {
    transform: translate(-3.75rem, -3.75rem) scale(0.9) rotate(67.5deg); 
  }
  20% {
    transform: translate(-5rem, -5rem) scale(0.85) rotate(90deg); 
  }
  25% {
    transform: translate(-6.25rem, -6.25rem) scale(0.8) rotate(112.5deg); 
  }
  30% {
    transform: translate(-5rem, -7.5rem) scale(0.95) rotate(135deg); 
  }
  35% {
    transform: translate(-3.75rem, -8.5rem) scale(0.9) rotate(157.5deg); 
  }
  40% {
    transform: translate(-2.5rem, -10rem) scale(0.85) rotate(180deg); 
  }
  45% {
    transform: translate(-1.25rem, -11.25rem) scale(0.8) rotate(202.5deg); 
  }
  50% {
    transform: translate(0px, -12.5rem) scale(0.85) rotate(225deg); 
  }
  55% {
    transform: translate(1.25rem, -13.75rem) scale(0.9) rotate(247.5deg); 
  }
  60% {
    transform: translate(2.5rem, -15rem) scale(0.95) rotate(270deg); 
  }
  65% {
    transform: translate(3.75rem, -16.25rem) scale(1) rotate(292.5deg); 
  }
  70% {
    transform: translate(5rem, -14.375rem) scale(1.05) rotate(315deg); 
  }
  75% {
    transform: translate(6.25rem, -12.5rem) scale(1.1) rotate(337.5deg); 
  }
  80% {
    transform: translate(7.5rem, -10.625rem) scale(1) rotate(360deg); 
  }
  85% {
    transform: translate(6.25rem, -9.375rem) scale(0.95) rotate(382.5deg); 
  }
  90% {
    transform: translate(5rem, -8.125rem) scale(0.9) rotate(405deg); 
  }
  95% {
    transform: translate(3.75rem, -6.25rem) scale(0.85) rotate(427.5deg); 
  }
  100% {
    transform: translate(2.5rem, -4.375rem) scale(1) rotate(450deg); /* 调整为最终位置 */
  }
}

@keyframes dice-throw3 {
  0% {
    transform: translate(0, 0) scale(1); /* 初始状态 */
  }
  5% {
    transform: translate(-1.25rem, -1.25rem) scale(1) rotate(22.5deg); 
  }
  10% {
    transform: translate(-2.5rem, -2.5rem) scale(1) rotate(45deg); 
  }
  15% {
    transform: translate(-3.75rem, -3.75rem) scale(1.05) rotate(67.5deg); 
  }
  20% {
    transform: translate(-5rem, -5rem) scale(1.1) rotate(90deg); 
  }
  25% {
    transform: translate(-6.25rem, -6.25rem) scale(1.1) rotate(112.5deg); 
  }
  30% {
    transform: translate(-7.5rem, -5rem) scale(1.15) rotate(135deg); 
  }
  35% {
    transform: translate(-8.5rem, -3.75rem) scale(1.18) rotate(157.5deg); 
  }
  40% {
    transform: translate(-10rem, -2.5rem) scale(1.2) rotate(180deg); 
  }
  45% {
    transform: translate(-11.25rem, -1.25rem) scale(1.18) rotate(202.5deg); 
  }
  50% {
    transform: translate(-11.5625rem, 0px) scale(1.15) rotate(225deg); 
  }
  55% {
    transform: translate(-11.25rem, 1.25rem) scale(1.12) rotate(247.5deg); 
  }
  60% {
    transform: translate(-11.5625rem, 2.5rem) scale(1.08) rotate(270deg); 
  }
  65% {
    transform: translate(-10rem, 3.75rem) scale(1.05) rotate(292.5deg); 
  }
  70% {
    transform: translate(-8.5rem, 5rem) scale(1.02) rotate(315deg); 
  }
  75% {
    transform: translate(-7.5rem, 3.75rem) scale(1) rotate(337.5deg); 
  }
  80% {
    transform: translate(-6.25rem, 2.5rem) scale(1) rotate(360deg); 
  }
  85% {
    transform: translate(-5rem, 1.25rem) scale(0.95) rotate(382.5deg); 
  }
  90% {
    transform: translate(-3.75rem, -.625rem) scale(0.9) rotate(405deg); 
  }
  95% {
    transform: translate(-2.5rem, -1.25rem) scale(0.85) rotate(427.5deg); 
  }
  100% {
    transform: translate(-1.25rem, 0) scale(1) rotate(450deg); /* 调整为最终位置 */
  }
}
@keyframes dice-throw4 {
  0% {
    transform: translate(0, 0) scale(1); /* 初始状态 */
  }
  5% {
    transform: translate(-.625rem, -1.25rem) scale(1) rotate(22.5deg); 
  }
  10% {
    transform: translate(-1.25rem, -2.5rem) scale(1) rotate(45deg); 
  }
  15% {
    transform: translate(-1.875rem, -3.75rem) scale(0.95) rotate(67.5deg); 
  }
  20% {
    transform: translate(-2.5rem, -5rem) scale(0.9) rotate(90deg); 
  }
  25% {
    transform: translate(-3.125rem, -6.25rem) scale(0.85) rotate(112.5deg); 
  }
  30% {
    transform: translate(-3.75rem, -7.5rem) scale(0.8) rotate(135deg); 
  }
  35% {
    transform: translate(-4.375rem, -8.5rem) scale(0.75) rotate(157.5deg); 
  }
  40% {
    transform: translate(-5rem, -10rem) scale(0.7) rotate(180deg); 
  }
  45% {
    transform: translate(-5.625rem, -9.375rem) scale(0.75) rotate(202.5deg); 
  }
  50% {
    transform: translate(-6.25rem, -8.5rem) scale(0.8) rotate(225deg); 
  }
  55% {
    transform: translate(-6.875rem, -8.125rem) scale(0.85) rotate(247.5deg); 
  }
  60% {
    transform: translate(-7.5rem, -7.5rem) scale(0.9) rotate(270deg); 
  }
  65% {
    transform: translate(-8.125rem, -6.875rem) scale(0.95) rotate(292.5deg); 
  }
  70% {
    transform: translate(-140x, -6.25rem) scale(1) rotate(315deg); 
  }
  75% {
    transform: translate(-8.125rem, -5.625rem) scale(1.05) rotate(337.5deg); 
  }
  80% {
    transform: translate(-7.5rem, -5rem) scale(1.1) rotate(360deg); 
  }
  85% {
    transform: translate(-7.5rem, -4.375rem) scale(1.1) rotate(382.5deg); 
  }
  90% {
    transform: translate(-6.875rem, -3.75rem) scale(1.05) rotate(405deg); 
  }
  95% {
    transform: translate(-6.25rem, -3.125rem) scale(1.05) rotate(427.5deg); 
  }
  100% {
    transform: translate(-6.5625rem, 0) scale(1) rotate(450deg); /* 调整为最终位置 */
  }
}
@keyframes dice-throw5 {
  0% {
    transform: translate(0, 0) scale(1); /* 初始状态 */
  }
  5% {
    transform: translate(-.625rem, -1.25rem) scale(1) rotate(22.5deg); 
  }
  10% {
    transform: translate(-0.9375rem, -2.5rem) scale(1) rotate(45deg); 
  }
  15% {
    transform: translate(-1.25rem, -3.75rem) scale(0.95) rotate(67.5deg); 
  }
  20% {
    transform: translate(-1.5625rem, -5rem) scale(0.9) rotate(90deg); 
  }
  25% {
    transform: translate(-1.875rem, -6.25rem) scale(0.85) rotate(112.5deg); 
  }
  30% {
    transform: translate(-2.1875rem, -7.5rem) scale(0.8) rotate(135deg); 
  }
  35% {
    transform: translate(-2.5rem, -8.5rem) scale(0.75) rotate(157.5deg); 
  }
  40% {
    transform: translate(-2.8125rem, -10rem) scale(0.75) rotate(180deg); 
  }
  45% {
    transform: translate(-3.75rem, -8.5rem) scale(0.8) rotate(202.5deg); 
  }
  50% {
    transform: translate(-5.625rem, -7.5rem) scale(0.85) rotate(225deg); 
  }
  55% {
    transform: translate(-6.875rem, -6.25rem) scale(0.85) rotate(247.5deg); 
  }
  60% {
    transform: translate(-8.5rem, -5rem) scale(0.9) rotate(270deg); 
  }
  65% {
    transform: translate(-10.625rem, -3.75rem) scale(1) rotate(292.5deg); 
  }
  70% {
    transform: translate(-12.5rem, -2.5rem) scale(1) rotate(315deg); 
  }
  75% {
    transform: translate(-10.625rem, -1.25rem) scale(1.05) rotate(337.5deg); 
  }
  80% {
    transform: translate(-8.5rem, 0px) scale(1.05) rotate(360deg); 
  }
  85% {
    transform: translate(-6.875rem, 1.25rem) scale(1.1) rotate(382.5deg); 
  }
  90% {
    transform: translate(-5.625rem, 2.5rem) scale(1.1) rotate(405deg); 
  }
  95% {
    transform: translate(-5.625rem, 3.75rem) scale(1.15) rotate(427.5deg); 
  }
  100% {
    transform: translate(-6.25rem, 0) scale(1.2) rotate(450deg); 
  }
}
@keyframes dice-throw6 {
  0% {
    transform: translate(0, 0) scale(1); /* 初始状态 */
  }
  5% {
    transform: translate(.625rem, -1.25rem) scale(1.05) rotate(22.5deg); 
  }
  10% {
    transform: translate(1.25rem, -2.5rem) scale(1.1) rotate(45deg); 
  }
  15% {
    transform: translate(1.875rem, -3.75rem) scale(1.15) rotate(67.5deg); 
  }
  20% {
    transform: translate(2.5rem, -5rem) scale(1.2) rotate(90deg); 
  }
  25% {
    transform: translate(3.125rem, -6.25rem) scale(1.25) rotate(112.5deg); 
  }
  30% {
    transform: translate(3.75rem, -7.5rem) scale(1.3) rotate(135deg); 
  }
  35% {
    transform: translate(4.375rem, -8.5rem) scale(1.35) rotate(157.5deg); 
  }
  40% {
    transform: translate(5rem, -10rem) scale(1.4) rotate(180deg); 
  }
  45% {
    transform: translate(5rem, -9.375rem) scale(1.35) rotate(202.5deg); 
  }
  50% {
    transform: translate(5rem, -8.5rem) scale(1.3) rotate(225deg); 
  }
  55% {
    transform: translate(5.625rem, -8.125rem) scale(1.25) rotate(247.5deg); 
  }
  60% {
    transform: translate(6.25rem, -7.5rem) scale(1.2) rotate(270deg); 
  }
  65% {
    transform: translate(6.5625rem, -6.875rem) scale(1.15) rotate(292.5deg); 
  }
  70% {
    transform: translate(6.875rem, -6.25rem) scale(1.1) rotate(315deg); 
  }
  75% {
    transform: translate(7.1875rem, -5.625rem) scale(1.05) rotate(337.5deg); 
  }
  80% {
    transform: translate(7.5rem, -5rem) scale(1) rotate(360deg); 
  }
  85% {
    transform: translate(7.8125rem, -4.375rem) scale(0.95) rotate(382.5deg); 
  }
  90% {
    transform: translate(7.8125rem, -3.75rem) scale(0.9) rotate(405deg); 
  }
  95% {
    transform: translate(8.125rem, -2.5rem) scale(0.85) rotate(427.5deg); 
  }
  100% {
    transform: translate(8.4375rem, 0) scale(1) rotate(450deg); /* 调整为最终位置 */
  }
}
@media (max-width:1200px) {
  .left_center{
    flex-direction: column-reverse;
    align-items: center;
  }
  .banner_box{
    flex-direction: column;
    align-items: center;
  }
  .jianli_box{
    margin-top: 2.5rem
  }
  .right_box{
    margin-top: 2.5rem
  }
}
</style>