<template>
  <div>
    <a @click="toActive">
      <img class="ac_float" :class="{'only_right': material.pc_index_left ? (material.pc_index_left.img ? false : true) : true}" v-lazy=" material.pc_index_alert && imgUrl ? img_url + material.pc_index_alert.img :'javascript:0'">
    </a>
    <Login ref="login"></Login>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name:'ActiveSuspension',
    data() {
      return {
        url:'/member/wallet',
        tab:'_self',
        imgUrl:false
      }
    },
    computed:{
      ...mapState([
        'getClient',
        'material'
      ])
    },
    methods:{
      toActive(){
        if(this.material.pc_index_alert.jump == 2){
            if(localStorage.getItem('token')){
              this.funRouter()
            }else{
              this.$refs.login.open()
            }
          }
          this.$czc('右下角活动悬浮窗', '点击', '访问活动页面')
          // this.$czc('测试埋点', '点击', '访问')
      },
      funRouter(){
        if(this.material.pc_index_alert.hd_id){
          var routeUrl = this.$router.resolve({
            path:this.url,
            query:{
              id:this.material.pc_index_alert.hd_id
            }
          })
        }else{
          var routeUrl = this.$router.resolve({
            path:this.url,
          })
        }
        window.open(this.url.includes('http') ? this.url : routeUrl.href, this.tab);
      },
      // 初始化显示悬浮窗
      startShow(){
        if(this.material.pc_index_alert.alert_type == 1){
          // 一直弹
          return  this.imgUrl = true
        }
        else if(this.material.pc_index_alert.alert_type == 2){
          if(localStorage.getItem('token')){
            return this.imgUrl = true
          }else{
            return this.imgUrl = false
          }
        }
        else if(this.material.pc_index_alert.alert_type == 3){
          if(!localStorage.getItem('token')){
            return this.imgUrl = true
          }else{
            return this.imgUrl = false
          }
        }
        else if(this.material.pc_index_alert.alert_type == 4){
          const dateStamp = new Date(new Date().toLocaleDateString()).getTime()+86400000;
          const time = localStorage.getItem('nowTime')*1
          console.log(time >= dateStamp)
          if(time >= dateStamp){
            return this.imgUrl = true
          }else{
            return this.imgUrl = false
          }
        }
        
      },
  },
  created() {
    this.url = this.material.pc_index_alert.jump_url
    this.startShow()
    // 判断shif
    if (this.material.pc_index_alert ? this.material.pc_index_alert.jump_url !== "" : 0) {
      this.url = this.material.pc_index_alert ? this.material.pc_index_alert.jump_url : ''
      this.tab = '_blank'
    }
  }
}
</script>

<style scoped>
    .ac_float{
        position: fixed;
        bottom: 5%;
        right: 1%;
        z-index: 999999;
        width: 6%;
    }
    .only_right{
      width:6% !important
    }
</style>