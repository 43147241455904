<template>
    <div>
        <div class="header">
            <HeaderTop @save="save"></HeaderTop>
            <Navigation></Navigation>
        </div>
        <Login ref="login"></Login>
        <Age></Age>
    </div>
</template>

<script>
    import Navigation from "./Navigation.vue"
    import HeaderTop from "./HeaderTop.vue"
    import Login from "./Login.vue"
    import Age from "./Age.vue"
    export default {
        name:'Header',
        components:{Navigation,HeaderTop,Login,Age},
        data() {
            return {
                
            }
        },
        methods:{
            save(){
                this.$refs.login.loginCode()
            }
        }
    }
</script>

<style>
    /*  */
    .header{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
    }
</style>