// 创建vuex中的store


import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

import createPersistedstate  from 'vuex-persistedstate'

Vue.use(Vuex);

// 准备actions,用于响应组件中的动作
const actions = {
    login(context,value){
        context.commit('LOGIN',value)
    },
    login2(context,value){
        context.commit('LOGIN2',value)
    }

}
// 准备mutations，用于操作数据（state）
const mutations = {
    LOGIN(state,value){
        state.curId = 0
    },
    LOGIN2(state,value){
        state.curId = 1
    },
    SERVICEID(state,value){
        state.service_id= value
    }
}
// 准备state用于存储数据
const state = {
    serve_quick:false, //渠道接口是否请求，是则可以拿service-id,否则不可拿
    curId:0, //判断登入弹窗使用的登入方式
    service_id:'',
    unLogin:true,
    isLogin:false,
    userInfo:[], //用户信息
    userGrowth:[], //获取用户成长信息
    userStore:[{available:"0"}], //获取用户储值信息
    userPoint:[], //获取用户积分信息
    getClient:[], //获取渠道信息
    idcard:'未绑定', //实名认证
    mobile:'未绑定',  //电话完善
    token:'',
    sigin_eg:'', //签到弹窗奖励msg
    pay_ewm:'',//钱包充值二维码
    pay_money:'',    //当前订单金额
    pay_type:'', //订单支付方式
    orderID:'', //充值订单ID
    lvfl:[], //会员等级福利配置信息
    tutorial_id:'',//新手教程文章获取ID
    num:1, //立即兑换商品数量
    material:[], //物料信息
    under_off:false, //控制下架倒计时清除开关
    product_data:[], //商品详情
    product_value:0, //商品总计
    isAddress: false, //是否有默认地址
    cz_id: 1, //切换充值模式
}
// geitters用于将state中的数据进行加工
const getters = {

}

const plugins = [
    createPersistedstate({
        key: 'rabbitstore-client',
        paths: ['num']
    }) 
  ]




// 创建并导出store
export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters,
    plugins
})