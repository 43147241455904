<template>
    <div>
        <Header></Header>
        <div class="member">
            <div class="container">
                <div class="paw_change">
                    <div class="bag_title">
                        <h4 class="font_color">忘记密码</h4>
                        <div class="tip">
                            <div class="tip_icon flex_box font_color">?</div>
                            <span class="font_color">如遇充值问题，请联系任意平台客服</span>
                        </div>
                    </div>
                    <div class="paw_box">
                        <div class="form_paw">
                            <div class="new_paw flex_box ">
                                <label class="lab_font">新密码：</label>
                                <input class=" input_dom" type="password" v-model="new_pass">
                            </div>
                            <div class="right_paw flex_box m_10">
                                <label class="lab_font">确认新密码：</label>
                                <input class=" input_dom" type="password" v-model="re_pass">
                            </div>
                            <div class="paw_input flex_box m_10">
                                <label class="lab_font">手机号：</label>
                                <input class=" input_dom" type="tel" v-model="user_mobile">
                            </div>
                            <div class="yzm flex_box">
                                <label class="lab_font">验证码：</label>
                                <div class="yzm_input flex_box">
                                    <input class="code_input" type="text" v-model="code">
                                    <a class="yzm_title flex_box lab_font" @click="getCode" v-if="isCode">获取验证码</a>
                                    <a class="yzm_title flex_box lab_font" v-else>{{count}}秒后重发</a>
                                </div>
                            </div>
                            <a class="flex_box tel_btn tel_btn_color tel_btn_font" @click="forget">立即提交</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 背景 -->
            <img class="member_bg" src="/member/img/bg.jpg" width="100%">
        </div>
        
        
        <Footer></Footer>
    </div>
  
</template>

<script>
    import {forgotPassword,messageVerification} from '@/api/http.js'
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    export default {
        name:'ForgotPassword',
        components:{Header,Footer},
        data() {
            return {
                new_pass:'',
                re_pass:'', 
                user_mobile:'',
                code:'',
                isCode:true, //判断验证码按钮
                count: '',
                timer: null,
                bool:true, //节流
            }
        },
        methods:{
            getCode() {
                this.messageVerification()
                console.log('我触发了')
                const num = 60;
                if (!this.timer) {
                    this.count = num;
                    this.isCode = false;
                    this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= num) {
                        this.count--;
                    } else {
                        this.isCode = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                    }, 1000);
                }
            },
            //短信验证
            messageVerification(){
                messageVerification({
                    mobile:this.user_mobile,
                    scene:'binding_mobile'
                }).then((res)=>{
                    if(res.code === 200){
                        this.isCode = false
                        this.sms_token = res.data.sms_token
                        this.$message({
                            message:'短信发送成功！',
                            type:'success',
                            duration:1500
                        })
                    }
                }).catch((err)=>{
                    this.isCode = true
                    clearInterval(this.timer);
                    this.timer = null;
                    console.log('捕获异常',err)
                    this.$message({
                        message:err.msg,
                        type: 'warning',
                        duration: 1500
                    })
                })
            },
            forgotPassword(){
                forgotPassword({
                    new_pass:this.new_pass,
                    re_pass:this.re_pass,
                    user_mobile:this.user_mobile,
                    code:this.code,
                    sms_token:this.sms_token
                }).then((res)=>{
                    if(res.code === 200){
                        this.new_pass = ''
                        this.re_pass = ''
                        this.user_mobile = ''
                        this.code = ''
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            onClose:()=>{
                                this.bool = true
                            },
                            duration: 1500
                        });
                    }
                }).catch((err) =>{
                    console.log("捕获异常",err)
                    this.$message({
                        message:err.msg,
                        type: 'warning',
                        onClose:()=>{
                            this.bool = true
                        },
                        duration: 1500
                    });
                })
            },
            forget(){
                if(this.sms_token){
                    if(this.bool){
                        this.bool = false
                        this.forgotPassword()
                    }else{
                        this.$message({
                            message:'操作过于频繁，请稍后再试！',
                            type: 'warning',
                            duration: 1500
                        })
                    }
                }else{
                    this.$message({
                        message:'请获取验证码！',
                        type: 'warning',
                        duration: 1500
                    })
                }
            }
        }
    }
</script>

<style scoped>
    a{
        text-decoration: none;
        cursor: pointer;
    }
    input{
        outline-style: none;
    }
    .member{
        position: relative;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 10px 0;
        margin-top: 110px;
        background: #18181a;
    }
    .member_bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        /* height: 100%; */
    }
    .paw_change{
        position: relative;
        z-index: 2;
        background: #fff;
        border-radius: 4px;
        padding: 30px 40px 231px;
        height: 100%;
    }
    .bag_title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(178, 147, 96,0.2);
    }
    .bag_title h4{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin: 0;
    }
    .tip{
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .tip_icon{
        width: 13px;
        height: 13px;
        border: 1px solid #806437;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-right: 5px;
    }
    .tip span{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    .font_color{ 
        color: #886A35;
    }
    /* 表单 */
    .paw_box{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
    }
    .form_paw{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
    .input_dom{
        width: 410px;
        height: 50px;
        border: 1px solid #B2B2B2;
        border-radius: 8px;
    }
    .lab_font{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4D4D4D;
    }
    .m_10{
        margin-top: 10px;
    }
    .yzm{
        margin-top: 10px;
    }
    .code_input{
        width: 307px;
        height: 50px;
        border: 1px solid #B2B2B2;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    /* .code_input:focus + .yzm_title{
        border-color: #AC8747;
    } */
    .yzm_title{
        width: 102px;
        height: 50px;
        border: 1px solid #B2B2B2;
        border-left: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .tel_btn{
        width: 410px;
        height: 50px;
        border-radius: 8px;
        margin-top: 20px;
    }
    .tel_btn_color{
        background: #B5945B;
        transition: all 0.5s;
    }
    .tel_btn_color:hover{   
        filter: brightness(1.2);
        transition: all 0.5s;
    }
    .tel_btn_font{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
    }
     /* 客服 */
     .content_right{
        display: flex;
        align-items: end;
        justify-content: end;
        margin-top: 40px;
    }
    .beauty_img{
        border: 1px solid #CEBFA5;
        border-radius: 8px;
        padding: 8px;
    }
    .beauty{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .beauty_title{
        font-size: 14px;
        font-family: SimSun;
        font-weight: 400;
        color: #4D4A45;
        margin-bottom: 10px;
    }
    .time{
        font-size: 14px;
        font-family: SimSun;
        font-weight: 400;
        color: #CF1F1F;
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
    @media (max-width:1600px) {
        .paw_change{
            padding: 20px 30px 200px;
        }
        .bag_title h4{
            font-size: 20px;
        }
    }
    @media (max-width:1300px) {
        .member{
            margin-top: 100px;
        }
        /* top */
        .bag_title h4{
            font-size: 18px;
        }
        .tip span{
            font-size: 12px;
        }

    }
    @media (max-width:1100px) {
        /* top */
        .bag_title h4{
            font-size: 16px;
        }
        .tip span{
            font-size: 12px;
        }
        .paw_change{
            padding: 20px 25px 150px;
        }
        .lab_font{
            font-size: 12px;
        }
        .input_dom{
            width: 310px;
            height: 35px;
        }
        .code_input{
            width: 208px;
            height: 35px;
        }
        .tel_btn{
            width: 310px;
            height: 35px;
        }
        .yzm_title{
            height: 35px;
        }
        .tel_btn_font{
            font-size: 12px;
        }
    }
</style>