<template>
  <!-- 轮播 -->
  <div id="demo" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="2000">

    <!-- 轮播图片 -->
    <div class="carousel-inner">
      <div class="carousel-item active" >
        <img src="/banner/img/lb01.png" class="d-block" width="100%" >
        <div class="carousel-caption">
          <div class="top_box">
            <h1 class="top_font"><strong>新人有好礼</strong></h1>
            <h4 class="top_font"><strong>微信扫码一键登入，新用户"50元专属红包"限时领！</strong></h4>
            <div class="btn_box">
              <button @click="toWallet(1)" class="btn btn-primary lb_btn">自助充值</button>
              <button v-if="unLogin" @click="LoginOne" class="btn btn-primary lb_btn">注册/登入</button>
              <button v-else class="btn btn-primary lb_btn">注册/登入</button>
              <button @click="toTutorial" class="btn btn-primary lb_btn">新手教程</button>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item " >
        <img src="/banner/img/lb02.png" class="d-block" width="100%" >
        <div class="carousel-caption">
          <div class="top_box">
            <h1 class="top_font"><strong>平台储值返还</strong></h1>
            <h4 class="top_font"><strong>每月首次登录，领取上月返利</strong></h4>
            <div class="btn_box">
              <button @click="toWallet(1)" class="btn btn-warning lb_btn">自助充值</button>
              <button v-if="unLogin"  @click="LoginOne" class="btn btn-warning lb_btn">注册/登入</button>
              <button v-else class="btn btn-warning lb_btn">注册/登入</button>
              <button @click="toTutorial" class="btn btn-warning lb_btn">新手教程</button>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item " >
        <img src="/banner/img/lb03.png" class="d-block" width="100%" >
        <div class="carousel-caption">
          <div class="top_box">
            <h1 class="top_font"><strong>老玩家回归福利</strong></h1>
            <h4 class="top_font"><strong>老玩家回归领取超值礼包</strong></h4>
            <div class="btn_box">
              <button @click="toWallet(1)"  class="btn btn-danger lb_btn">自助充值</button>
              <button v-if="unLogin" @click="LoginOne" class="btn btn-danger lb_btn">注册/登入</button>
              <button v-else class="btn btn-danger lb_btn">注册/登入</button>
              <button @click="toTutorial" class="btn btn-danger lb_btn">新手教程</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 指示符 -->
    <div class="carousel-indicators">
      <button type="button" class="active" data-bs-target="#demo" :data-bs-slide-to="0" ></button>
      <button type="button"  data-bs-target="#demo" :data-bs-slide-to="1" ></button>
      <button type="button"  data-bs-target="#demo" :data-bs-slide-to="2" ></button>
    </div>
    <Login ref="login" @closeLogin="closeLogin"></Login>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { auto } from "@popperjs/core";
import Login from "../components/Login.vue";
import layer from "layui-layer";
export default {
  name: 'Carousel2',
  components: {
    Login
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'material',
      'getClient',
      'isLogin',
      'unLogin',
    ]),
    cz_id:{
        get() {
            return this.$store.state.cz_id
        },
        set(val) {
            return this.$store.state.cz_id = val
        }
    },
  },
  methods: {
    toTutorial(){
      this.$router.push({
            path:'/beginnerTutorial'
        })
    },
    toRealName(){
      this.$router.push({
            path:'/member/realName'
        })
    },
    toWallet(type){
        this.cz_id = type
        this.$router.push({
            path:'/member/wallet'
        })
        this.$czc( 'PC首页储值入口', '点击', '访问平台充值')
    },
    LoginOne() {
      this.$refs.login.open()
      $("body").parent().css("overflow-y", "hidden")
      $("body").parent().css("overflow-x", "hidden")
    },
    closeLogin() {
      $("body").parent().css("overflow-y", "auto")
      $("body").parent().css("overflow-x", "auto")
    },
    toPath(item) {
       //  this.$router.resolve({ path: '/member/wallet' }).route.meta.isLogin 拿到指定路由的信息如meta
      if (item.jump_url) {
        if(item.jump_url == '/register' && this.getClient.channel_other.is_register == 1){
          this.$message({
            message: "注册通道暂未开启！",
            type: 'warning',
            duration: 1500
          })
        }
        else if (this.$router.resolve({ path: item.jump_url }).route.meta.isLogin && !localStorage.getItem('token')) {
            this.LoginOne()
        }else{
          if(item.jump_url == '/register' && localStorage.getItem('token')){
            this.$message({
              message: "您已经登录，请退出登录后再试！",
              type: 'warning',
              duration: 1500
            })
          }else{
            this.$router.push({ path:  item.jump_url})
            if(this.$route.path == '/member/wallet' && item.title == '充值15.5倍' ){
              this.cz_id = 1
              this.$czc('轮播图-充值15.5倍', '点击', '访问平台充值')
            }
            else if(this.$route.path == '/member/wallet' && item.title == '月卡购买' ){
              this.cz_id = 2
              this.$czc('轮播图-月卡购买', '点击', '访问月卡购买')
            }
          }
          
        }
      }
    },
  },
  mounted() {
   
  }
}
</script>
<style scoped>
.top_font{
  text-shadow:0 2px 8px rgb(0 0 0 / 65%);
  font-weight: 600;
}
.carousel-caption{
  bottom: 33%;
  display: flex;
}
.btn_box{
  display: flex;
  margin-top: 48px;
}
.top_box{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.lb_btn{
  font-size: 24px;
  padding: 17px;
  margin-right: 5px;
  color: #fff;
}
.carousel-indicators {
  margin: 0;
}

.ind {
  background: #000000 !important;
  opacity: 0.8 !important;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #E1E1E1 !important;
  text-decoration: none;
  padding: 12px 50px !important;
  text-align: center;
  transition: all 0.5s !important;
  display: flex;
  justify-content: center;
}

.ind:hover {
  background: #B5945B !important;
  opacity: 0.9 !important;
  transition: all 0.5s;
}

.carousel-indicators {
  bottom: 27% !important;
  left: 64% !important;
}

.active {
  /* background: #B5945B !important;
  opacity: 0.9 !important; */
}

@media (max-width:1300px) {
  .ind {
    font-size: 12px;
    padding: 12px 40px !important;
  }
}

@media (max-width:1100px) {
  .ind {
    padding: 12px 30px !important;
  }
}</style>