<template>
    <div>
      <img class="report_xfc" @click="sup_tc" v-lazy="img_url + material.pc_jb_img">
    </div>
  </template>
  
  <script>
      import { auto } from "@popperjs/core";
      import layer from "layui-layer";
      import { mapState } from "vuex";
      export default {
          name:'ReportWindow',
          data() {
            return {
              img:'/supended/img/xfc.png'
            }
          },
          computed:{
            ...mapState([
              'getClient',
              'material'
            ])
          },
          methods:{
            sup_tc(){
              var report_tc = $('.report_tc')
              var LayIndex = layer.open({
                  type: 1,
                  content: report_tc,
                  anim: false,
                  fixed: true,
                  shade: 0.6,
                  title: 0,
                  scrollbar: true,
                  shadeClose: true,
                  isOutAnim: false,
                  area: [auto, auto],
                  success:function(){
                      
                  }
              });
            }
          },
          
      }
  </script>
  
  <style scoped>
      .report_xfc{
          position: fixed;
          right: 0;
          z-index: 10;
          cursor: pointer;
          width: 10%;
          top: 35%;
      }
  </style>