<template>
    <div ref="vip">
        <div class="vip_box">
            <!-- 未登入 -->
            <div class="login_flag" v-show="unLogin">
                <div class="container vip_flex">
                    <h4>{{getClient.title}}</h4>
                    <img class="touxian"  :src="img_url +  material.pc_login_out_head_portrait" width="100%">
                    <div class="vip_btn">
                        <a class="v_btn v_pd" @click="LoginOne()" v-if="getClient.channel_other && (getClient.channel_other.is_login_mobile == 1 ? false : true)&&(getClient.channel_other.is_login_account == 1 ? true : false)">手机登录</a>
                        <a class="v_btn v_pd" @click="LoginOne()" v-if="getClient.channel_other && getClient.channel_other.is_login_account == 1 ? false : true">账号登录</a>
                        <a class="v_btn v_pd" @click="LoginOne()" v-if="getClient.channel_other && getClient.channel_other.is_login_weixin == 1 ? false : true">扫码登录</a>
                    </div>
                    <span class="head_title">会员享{{getClient.channel_times}}倍充值</span>
                </div>
            </div>
            <!-- 登录状态 -->
            <div class="login" v-show="isLogin">
                <div class="container vip_flex">
                    <div class="head_portrait vip_flex">
                        <div class="flex_box tx_box">
                            <!-- 头像框 -->
                            <div class="tx_content" v-if="userInfo.head_img">
                                <img class="head_img" :src="userInfo.head_img" width="100%">
                            </div>
                            <div class="tx_content" v-else>
                                <img class="touxian"  src="/banner/img/rw.png" width="100%">
                            </div>
                            <img class="touxian"  src="/banner/img/txk.png" width="100%">
                        </div>
                        <img class="vip_logo" v-lazy="userInfo.user_growth_icon" >
                    </div>
                    <h5 class="head_wl">欢迎您:{{userInfo.id}}</h5>
                    <div class="head_value">
                        <div class="item_box">
                            <h6>账户储值</h6>
                            <span>{{store}}</span>
                        </div>
                        <div class="item_box">
                            <h6>账户积分</h6>
                            <span>{{point}}</span>
                        </div>
                        <div class="item_box">
                            <h6>成长值</h6>
                            <span>{{growth}}</span>
                        </div>
                    </div>
                    <div class="vip_btn">
                        <router-link to="/member/wallet" class="v_btn login_pd">立即充值</router-link>
                        <a class="v_btn login_pd v_back" @click="login_back">退出登录</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="vip_gn flex_box">
            <div class="row gn_box">
                <div class="col-6 flex_box vb" >
                    <a class="gn_btn jfqd p_t" @click="click_member"><img class="vip_icon" src="/banner/img/jfqd.png">签到奖励</a>
                </div>
                <div class="col-6 flex_box vb ">
                    <router-link to="/member" class="gn_btn kfzx p_t"><img class="vip_icon" src="/banner/img/kfzx.png">客服咨询</router-link>
                </div>
                <div class="col-6 flex_box vb " >
                    <a @click="toPointsMall" class="gn_btn tsjy p_b"><img class="vip_icon" src="/banner/img/tsjy.png">灵符商城</a>
                </div>
                <div class="col-6 flex_box vb ">
                    <router-link to="/member" class="gn_btn ptxz p_b"><img class="vip_icon" src="/banner/img/ptxz.png">平台须知</router-link>
                </div>
            </div>
                <div class="v_fgx"></div>
                <!-- <div class="v_fgx2"></div>
                <div class="v_fgx3"></div> -->
        </div>
        <Login ref="login" @closeLogin="closeLogin"></Login>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Bus from '@/router/bus.js'
    import Login from "../components/Login.vue";
    export default {
        name:'Vip',
        data() {
            return {
                ewmUrl:'',
                isSend:false,  //判断二维码链接是否接收并存入ewmUrl
                loginToken:'',
                seconds: 600 // 倒计时秒数
            }
        },
        components:{
            Login
        },
        computed:{
            ...mapState([
                'curId',
                'unLogin',
                'isLogin',
                'userInfo',
                'userGrowth',
                'userStore',
                'userPoint',
                'getClient',
                'material'
            ]),
            store(){
                return  Math.round(this.userStore.available)
            },
            point(){
                return  Math.round(this.userPoint.available)
            },

            growth(){
                return  Math.round(this.userGrowth.available)
            },

        },
        methods:{
            toPointsMall(){
                this.$router.push({
                    path:'/pointsMall'
                })
                this.$czc( 'PC首页灵符商城', '点击', '访问灵符商城')
            },
            click_member(){
                this.$router.push({
                    path:'/member'
                })
                this.$czc( 'PC首页用户信息下方积分签到', '点击', '访问积分签到')
                // this.$czc( 'PC首页用户信息下方月卡奖励', '点击', '访问月卡奖励')
            },
            LoginOne(){
                this.$refs.login.open()
                $("body").parent().css("overflow-y","hidden")
                $("body").parent().css("overflow-x","hidden")
            },
            login_back(){
                localStorage.setItem("token",'')
                localStorage.setItem('userInfo','')
                location.reload()
            },
            closeLogin(){
                $("body").parent().css("overflow-y","auto")
                $("body").parent().css("overflow-x","auto")
            },
        },
    }
</script>

<style scoped>
*{
    margin: 0;
}
/* 登录状态 */
.login{
    /* display: none; */
}
.head_portrait{
    position: relative;
}
.vip_logo{
    position: absolute;
    bottom: 10px;
}
.tx_box{
    position: relative;
    overflow: hidden;
}
.head_img{
    position: absolute;
}
.head_wl{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B5945B;
}
.item_box h6{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B5945B;
}
.tx_content{
    position: absolute;
    top: 15%;
    width: 40%;
    overflow: hidden;
    background-color: #2F3136;
    border-radius: 50%;
    height: 70%;
}
.item_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.item_box span{
    font-size: 16px;
    font-family: Helvetica Neue LT Std;
    font-weight: 600;
    color: #BE5D50;
}
.head_value{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}
.login_pd{
    padding: 5px 30px;
}
/* 会员模块 */
.login_flag{
    /* display: none; */
}
.vip_box{
    padding: 20px 0;
    background: #FFFFFF;
}
.vip_flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.vip_flex h4{
    margin: 0;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B5945B;
}
.vip_btn{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.v_btn{
    background: #B5945B;
    border-radius: 2px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    transition: all 0.5s;
}
.v_back{
    background: #cdcdcd;
    transition: all 0.5s;
}
.v_back:hover{
    transition: all 0.5s;
    filter: brightness(1.2);
}
.v_pd{
    padding: 12px 30px;
}
.v_btn:hover{
    filter: brightness(1.2);
    transition: all 0.5s;
}
.v_btn:nth-of-type(1){
    margin-right: 3px;
}
.v_btn:nth-of-type(2){
    margin-left: 3px;
}
.head_title{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B5945B;
    margin-top: 15px;
}
.vip_gn{
    position: relative;
    width: 100%;
    background: #2F3136;
    padding: 10px;
    margin: 0;
}

.gn_btn{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E1E1E1;
    text-decoration: none;
    padding: 20px 0;
    transition:all 0.5s;
}
.gn_btn:hover{
    color: #B4935A;
    transition:all 0.5s;
}
.gn_btn img{
    margin-right: 5px;
}
.v_fgx{
    width: 10px;
    height: 10px;
    position: absolute;
    background: gray;
    transform: rotate(45deg);
}
.v_fgx2{
    width: 1px;
    height: 100%;
    background: gray;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.v_fgx3{
    width: 1px;
    height: 100%;
    background: gray;
    position: absolute;
    transform: rotate(90deg);
}
.vb{
    padding: 0;
}
.vb:nth-of-type(1){
    border-right: dotted 1px gray;
    border-bottom: solid 1px gray;
}
.vb:nth-of-type(2){
    border-bottom: solid 1px gray;
}
.vb:nth-of-type(3){
    border-right: dotted 1px gray;
}
.vb:nth-of-type(4){
}
.gn_box{
    /* padding: 0 10px; */
}
.p_t{
        padding-top: 10px !important;
    }
    .p_b{
        padding-bottom: 10px !important;
    }
@media (max-width:1700px) {
    .v_pd{
        padding: 12px 20px;
    }
    .login_pd{
        padding: 5px 20px;
    }
}
@media (max-width:1600px) {
    /* 会员登入模块 */
    .login_pd{
        padding: 5px 25px;
    }
    .vip_logo{
        width: 25%;
    }
    /*  */
    .banner{
        padding: 450px 0 30px;
    }
    .vip_box{
        padding: 15px 0;
    }
    .vip_flex h4{
        font-size: 14px;
    }
    .vip_btn{
        margin-top: 10px;
    }
    .v_pd{
        padding: 10px 20px;
    }
    .v_btn{
        font-size: 14px;
    }
    .head_title{
        font-size: 14px;
    }
    .gn_btn{
        font-size: 14px;
    }
}
@media (max-width:1450px) {
     /* 会员登入模块 */
     .login_pd{
        padding: 5px 20px;
    }
    .vip_logo{
        width: 20%;
        position: relative;
        top: -16px;
    }
    .head_wl{
        font-size: 12px;
    }
    .item_box h6{
        font-size: 12px;
    }
    .item_box span{
        font-size: 12px;
    }
    /*  */
    .banner{
        padding: 350px 0 30px;
        margin-top: 8%;
    }
    .p_t{
        padding-top: 0 !important;
    }
    .p_b{
        padding-bottom: 0 !important;
    }

    .v_btn{
        font-size: 12px;
    }
    .gn_btn{
        padding: 10px 0;
        font-size: 12px;
    }
    .vb{
        padding: 0 6px;
    }
   .v_pd{
        padding: 5px 20px;
    }
    .vip_btn{
        margin-top: 10px;
    }
    .head_title{
        font-size: 12px;
    }
    .vip_icon{
        width: 25%;
    }
}

@media (max-width:1200px) {
     /* 会员登入模块 */
     .login_pd{
        padding: 5px 12px;
    }
    .v_pd{
        padding: 5px 12px;
    }
    .head_title{
        font-size: 12px;
    }
    .vip_logo{
        width: 20%;
        top: -14px;
    }
    .vip_icon{
        width: 20%;
    }
}
</style>