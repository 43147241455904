<template>
    <div class="lucky_box  ">
        <img src="/activity/squared/img/logo.png">
        <div class="time_box">
            <span class="time_text">活动时间：2024.7.23-2024.8.31</span>
            <img src="/activity/squared/img/time_bg.png">
        </div>
        <div class="sigin_box">
            <div class="lang_one">
                <div class="card_box">
                    <span class="card_title">充值100，一次性领取待领取奖励</span>
                    <span class="card_text">获得1550平台充值点，再加30天签到领积分权限</span>
                    <div class="data_lang">
                        <div class="data_item ">
                            <span class="data_text">中奖金额:{{ winningMoney }}</span>
                        </div>
                        <div class="data_item ">
                            <span class="data_text">已领取:{{ received }}</span>
                        </div>
                        <div class="data_item ">
                            <span class="data_text">待领取:{{ unclaimed }}</span>
                        </div>
                    </div>
                    <div class="flex_box">
                        <div v-if="qualification" class="btn_hover yjlq_btn " @click="OneclickCollection">一键领取</div>
                        <div v-else class="btn_hover yjlq_btn bwite" >一键领取</div>
                    </div>
                </div>
                <div class="rule_boxs">
                    <div class="rule_title">
                        <span class="rule_top">活动规则</span>
                        <span class="rule_text">1.新用户注册账号，获得一次翻牌机会，通过翻牌获得随机金额。<br>
                        2.翻牌获得的金额，分7天领取，每天登入可领取部分金额。<span class="rule_color">例如：1000/7=每日获得142金额。</span><br>
                        3.翻牌机会每个用户一次，即同一个会员号、同IP、只可参与一次<span class="rule_color">（如提示未达到参与条件表示非唯一用户）。</span><br>
                        4.老用户需在会员有效期内，也可参与新人活动，<span class="rule_color">如未在会员有效期内，重新激活即可领取。</span><br>
                        5.活动结束后将不再抽取充值或是领取充值，请在活动时间内及时参与领取活动！</span>
                    </div>
                </div>
            </div>
            
            <div class="day_box">
                <div class="day_item" :class="{'bwite': getBtnFlag(0) == 3}">
                    <div class="content" >
                        <span class="day_text">第一天</span>
                        <span class="money_text">{{ getBtnMoney(0)}}<span class="money_num">储值</span></span>
                        <div v-if="getBtnFlag(0) == 1"  class="btn_hover lq_btn" @click="SquaredSigin">领取</div>
                        <div v-else-if="getBtnFlag(0) == 2"  class="btn_hover lq_btn bwite">待领取</div>
                        <div v-else-if="getBtnFlag(0) == 3"  class="btn_hover lq_btn bwite">已领取</div>
                        <div v-else  class="btn_hover lq_btn bwite">已过期</div>
                    </div>
                    <img src="/activity/squared/img/day_bg.png">
                </div>
                <div class="day_item" :class="{'bwite': getBtnFlag(1) == 3}">
                    <div class="content" >
                        <span class="day_text">第二天</span>
                        <span class="money_text">{{ getBtnMoney(1)}}<span class="money_num">储值</span></span>
                        <div v-if="getBtnFlag(1) == 1"  class="btn_hover lq_btn" @click="SquaredSigin">领取</div>
                        <div v-else-if="getBtnFlag(1) == 2"  class="btn_hover lq_btn bwite">待领取</div>
                        <div v-else-if="getBtnFlag(1) == 3"  class="btn_hover lq_btn bwite">已领取</div>
                        <div v-else  class="btn_hover lq_btn bwite">已过期</div>
                    </div>
                    <img src="/activity/squared/img/day_bg.png">
                </div>    
                <div class="day_item" :class="{'bwite': getBtnFlag(2) == 3}">
                    <div class="content" >
                        <span class="day_text">第三天</span>
                        <span class="money_text">{{ getBtnMoney(2)}}<span class="money_num">储值</span></span>
                        <div v-if="getBtnFlag(2) == 1"  class="btn_hover lq_btn" @click="SquaredSigin">领取</div>
                        <div v-else-if="getBtnFlag(2) == 2"  class="btn_hover lq_btn bwite">待领取</div>
                        <div v-else-if="getBtnFlag(2) == 3"  class="btn_hover lq_btn bwite">已领取</div>
                        <div v-else  class="btn_hover lq_btn bwite">已过期</div>
                    </div>
                    <img src="/activity/squared/img/day_bg.png">
                </div>    
                <div class="day_item" :class="{'bwite':getBtnFlag(3) == 3}">
                    <div class="content" >
                        <span class="day_text">第四天</span>
                        <span class="money_text">{{ getBtnMoney(3)}}<span class="money_num">储值</span></span>
                        <div v-if="getBtnFlag(3) == 1"  class="btn_hover lq_btn" @click="SquaredSigin">领取</div>
                        <div v-else-if="getBtnFlag(3) == 2"  class="btn_hover lq_btn bwite">待领取</div>
                        <div v-else-if="getBtnFlag(3) == 3"  class="btn_hover lq_btn bwite">已领取</div>
                        <div v-else  class="btn_hover lq_btn bwite">已过期</div>
                    </div>
                    <img src="/activity/squared/img/day_bg.png">
                </div>    
                <div class="day_item" :class="{'bwite':getBtnFlag(4) == 3}">
                    <div class="content" >
                        <span class="day_text">第五天</span>
                        <span class="money_text">{{ getBtnMoney(4)}}<span class="money_num">储值</span></span>
                        <div v-if="getBtnFlag(4) == 1"  class="btn_hover lq_btn" @click="SquaredSigin">领取</div>
                        <div v-else-if="getBtnFlag(4) == 2"  class="btn_hover lq_btn bwite">待领取</div>
                        <div v-else-if="getBtnFlag(4) == 3"  class="btn_hover lq_btn bwite">已领取</div>
                        <div v-else  class="btn_hover lq_btn bwite">已过期</div>
                    </div>
                    <img src="/activity/squared/img/day_bg.png">
                </div>    
                <div class="day_item" :class="{'bwite':getBtnFlag(5) == 3}">
                    <div class="content" >
                        <span class="day_text">第六天</span>
                        <span class="money_text">{{ getBtnMoney(5)}}<span class="money_num">储值</span></span>
                        <div v-if="getBtnFlag(5) == 1"  class="btn_hover lq_btn" @click="SquaredSigin">领取</div>
                        <div v-else-if="getBtnFlag(5) == 2"  class="btn_hover lq_btn bwite">待领取</div>
                        <div v-else-if="getBtnFlag(5) == 3"  class="btn_hover lq_btn bwite">已领取</div>
                        <div v-else  class="btn_hover lq_btn bwite">已过期</div>
                    </div>
                    <img src="/activity/squared/img/day_bg.png">
                </div>    
                <div class="day_item" :class="{'bwite':getBtnFlag(6) == 3}">
                    <div class="content" >
                        <span class="day_text">第七天</span>
                        <span class="money_text">{{ getBtnMoney(6)}}<span class="money_num">储值</span></span>
                        <div v-if="getBtnFlag(6) == 1"  class="btn_hover lq_btn" @click="SquaredSigin">领取</div>
                        <div v-else-if="getBtnFlag(6) == 2"  class="btn_hover lq_btn bwite">待领取</div>
                        <div v-else-if="getBtnFlag(6) == 3"  class="btn_hover lq_btn bwite">已领取</div>
                        <div v-else  class="btn_hover lq_btn bwite">已过期</div>
                    </div>
                    <img src="/activity/squared/img/day_bg.png">
                </div>           

            </div>
        </div>
         <!-- 领取奖励弹窗 -->
         <div class="receive_box">
            <div class="lq_content">
                <span class="receive_text">恭喜您获得了{{ all_money }}储值</span>
                <img class="lqjl_btn btn_hover" src="/activity/squared/img/lqjl.png">
            </div>
            <img src="/activity/squared/img/receive.png">
        </div>
        <!-- 立即充值弹窗 -->
        <div class="compensate_box">
            <div class="old_user">
                <span class="old_text">{{ cz_msg }}</span>
                <img class="lxkf_btn btn_hover" src="/activity/squared/img/ljcz.png">
            </div>
            <img src="/activity/squared/img/compensate.png">
        </div>
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {SquaredGetSigin,SquaredSigin,OneclickCollection} from '@/api/http.js'
export default {
    name: 'SquaredReceive',
    data() {
        return {
            btn_flag:{},
            isClick:true,
            money:0,
            winningMoney:0, //总金额
            received:0,//已领取
            unclaimed:0, //待领取
            flag:true, //已经领取节流
            cz_url:'',
            cz_msg:'',
            all_money:0, //一键领取金额
            qualification:true, //一键领取状态
            is_order:true, //是否有一键领取的资格
        }
    },
    computed:{
       
    },
    methods: {
        // 领取弹窗
        leaderTc() {
              var that = this
              var leader_tc = $('.receive_box')
              var LayIndex = layer.open({
                  type: 1,
                  content: leader_tc,
                  anim: false,
                  fixed: true,
                  shade: 0.6,
                  title: 0,
                  scrollbar: true,
                  shadeClose: false,
                  closeBtn: 0,
                  isOutAnim: false,
                  area: [auto, auto],
                  success: function (layero,index) {
                    that.flag = true
                    that.$message.closeAll();
                    layer.style(index, {
                        top: '15%',
                    });
                      const lq = $(layero).find('.lqjl_btn');
                      lq.click(function () {
                          layer.close(LayIndex)
                          that.SquaredGetSigin()
                      })
                  }
              });
          },
        // 立即充值弹窗
          compensate(){
            var that = this
              var compensate_box = $('.compensate_box')
              var LayIndex2 = layer.open({
                  type: 1,
                  content: compensate_box,
                  anim: false,
                  fixed: true,
                  shade: 0.6,
                  title: 0,
                  scrollbar: true,
                  shadeClose: false,
                  closeBtn: true,
                  isOutAnim: false,
                  area: [auto, '402px'],
                  success: function (layero,index) {
                        that.flag = true
                        layer.style(index, {
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'
                        });
                      const lq = $(layero).find('.lxkf_btn');
                      lq.click(function () {
                          layer.close(LayIndex2)
                          that.$router.push({
                            path:'/member/wallet',
                          })
                      })
                  }
              });
          },
        // 一键领取
        OneclickCollection(){
            if(this.flag){
                this.flag = false
                if(this.is_order){
                    this.$message({
                        message: '正在领取中，请稍后...',
                        type: 'warning',
                        duration: 100000,
                        onClose:() =>{
                        }
                    })
                }
                OneclickCollection().then((res) =>{
                    if(res.code == 200){
                        this.all_money = res.data.money
                        this.leaderTc()
                    }
                }).catch((err) =>{
                    if(err.code == 4000){
                        this.cz_msg = err.msg
                        this.compensate()
                    }else{
                        console.log('捕获异常', err)
                        this.$message.closeAll();
                        this.$message({
                            message: err.msg,
                            type: 'warning',
                            duration: 1500,
                            onClose:() =>{
                                this.flag = true
                            }
                        })
                    }
                })
            }else{
                this.$message({
                    message: "操作过于频繁，请稍后再试~",
                    type: 'warning',
                    duration: 1500
                });
            }
        },
        getBtnFlag(index){
            const firstKey = Object.keys(this.btn_flag)[index];
            return this.btn_flag[firstKey];
        },
        getBtnMoney(index){
            const firstKey = Object.keys(this.btn_flag)[index];
            return this.money[firstKey];
        },
        //获取签到信息接口
        SquaredGetSigin(){
            SquaredGetSigin().then((res) =>{
                if(res.code == 200){
                    this.is_order = res.data.is_order
                    this.qualification = res.data.qualification
                    this.btn_flag = res.data.sigin
                    this.money = res.data.dayMoney
                    this.winningMoney = res.data.winningMoney
                    this.received = res.data.received
                    this.unclaimed = res.data.unclaimed
                    this.cz_url = res.data.url
                }
            }).catch((err) =>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
        // 点击领取
        SquaredSigin() {
            if (this.isClick) {
                this.isClick = false
                SquaredSigin({
                    token: this.$route.query.token
                }).then((res) => {
                    if (res.code == 200) {
                        this.SquaredGetSigin()
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.isClick = true
                            }
                        })
                    }
                }).catch((err) => {
                    console.log('捕获异常', err)
                    this.$message({
                        message: err.msg,
                        type: 'warning',
                        duration: 1500,
                        onClose: () => {
                            this.isClick = true
                        }
                    })
                })
            }else{
                this.$message({
                    message: "操作过于频繁，请稍后再试~",
                    type: 'warning',
                    duration: 1500
                });
            }
           
        }
    },
    mounted(){
       
    },
    created() {
       this.SquaredGetSigin()
    },
}
</script>

<style scoped>
/* 弹窗 */
.receive_box{
    display: none;
}
.compensate_box{
    display: none;
}
.receive_text{
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 24px;
    color: #ffec95;
    background: linear-gradient(0deg, #ffec95 0%, #fffee6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
    text-align: center;
}
.lq_content{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 14%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.old_user{
     position: absolute;
    left: 70%;
    transform: translateX(-50%);
    bottom: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.old_text{
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 24px;
    color: #ffec95;
    background: linear-gradient(0deg, #ffec95 0%, #fffee6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 70px;
    text-align: center;
}

.card_box{
    background: url('/public/activity/squared/img/reacive_bg.png') no-repeat;
    background-size:386px 149px ;
    min-width: 386px;
    min-height: 149px;
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
}
.card_title{
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 18px;
    color: #FEFF90;
    background: linear-gradient(0deg, #FFEC95 25.68359375%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.card_text{
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 14px;
    color: #FEFF90;
    background: linear-gradient(0deg, #FFEC95 25.68359375%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto10px;
}
.data_lang{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}
.data_item{
    background: rgb(0, 0, 0,.6);
    width: 119px;
    border-radius: 4px;
    padding: 2px 5px;
}
.data_text{
     font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: 14px;
    color: #FEFF90;
    background: linear-gradient(0deg, #FFEC95 25.68359375%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.yjlq_btn{
    font-family: Source Han Sans SC;
    font-weight: bold;
    text-align: center;
    width: 114px;
    font-size: 16px;
    border-radius:20px ;
    margin-top: 10px;
    padding: 3px 20px;
    background: linear-gradient(to bottom, #fffee6, #ffec95);
    color: rgb(138, 85, 5);
}
.rule_boxs{
    position: relative;
    background: url('/public/activity/squared/img/rule_bg2.png') no-repeat;
    background-size:599px 149px ;
    min-width: 599px;
    min-height: 149px;
}

.rule_title{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.rule_text{
    color: #fffbce;
    font-size: 12px;
    font-family: Source Han Sans SC;
}
.rule_top{
    color: #fff373;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: bold;
}
.rule_color{
    color:#6cff44
}
.bwite{
    filter: grayscale(100%) !important
}
.lucky_box{
    background: url('/public/activity/squared/img/bg3.png') no-repeat;
   background-size: 1920px 1200px;
   min-width: 1903px;
   min-height: 1200px;
   padding-top: 90px;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.time_box{
    position: relative;
    margin-top: 15px;
    margin-bottom: 30px;
}
.time_text{
    font-family: Source Han Sans SC;
    font-weight: 400;
    font-size: 24px;
    color: #FEE1B5;
    line-height: 57px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.sigin_box{
    background: url('/public/activity/squared/img/box3.png') no-repeat;
   background-size: 1060px 373px;
   min-width: 1060px;
   min-height: 373px;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 27px 20px;
}
.lang_one{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.day_box{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
}
.day_item{
    position: relative;
}
.content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.day_text{
    font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #FEFF90;
    background: linear-gradient(0deg, #FFEC95 25.68359375%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    text-align: center;
}
.money_text{
    font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: 24px;
    color: #FEFF90;
    background: linear-gradient(0deg, #FFEC95 25.68359375%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.money_num{
    font-size: 16px;
}
.lq_btn{
    font-family: Source Han Sans SC;
    font-weight: 400;
    font-size: 14px;
    border-radius:20px ;
    margin-top: 5px;
    padding: 2px 20px;
    background: linear-gradient(to bottom, #fffee6, #ffec95);
    color: rgb(138, 85, 5);
}

</style>