<template>
      <div class="game_box">
        <div class="bag_title">
            <h4 class="font_color">赠送福利记录</h4>
            <div class="tip">
                <div class="tip_icon flex_box font_color">?</div>
                <span class="font_color">如遇充值问题，请联系任意平台客服</span>
            </div>
        </div>
        <table class="table">
            <thead class="table-light">
            <tr>
                <th>编号</th>
                <th>金额(元)</th>
                <th>用途/来源</th>
                <th>时间</th>
            </tr>
            </thead>
            <tbody>
            <tr  v-for="b in gifts" :key="b.id">
                <td>{{ b.id }}</td>
                <td>{{b.money}}</td>
                <td>{{b.from}}</td>
                <td>{{b.time}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name:'GiftBenefitRecord',
        data() {
            return {
                gifts:[
                    {id:1,money:'传奇',from:'3pk',time:'2023-2-12'},
                    {id:2,money:'传奇',from:'3pk',time:'2023-2-12'},
                    {id:3,money:'传奇',from:'3pk',time:'2023-2-12'},
                ]
            }
        },
    }
</script>

<style scoped>
    .game_box{
        background: #fff;
        border-radius: 4px;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        height: 100%;
    }
    .bag_title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }
    .bag_title h4{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin: 0;
    }
    .tip{
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .tip_icon{
        width: 13px;
        height: 13px;
        border: 1px solid #806437;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-right: 5px;
    }
    .tip span{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    .font_color{ 
        color: #806437;
    }
    /* table */
    .table th, .table td { 
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #463F34;
        padding: 14px 40px;
    }
    @media (max-width:1600px) {
        .bag_title h4{
            font-size: 20px;
        }
        .tip span {
            font-size: 12px;
        }
        .bag_title{
            padding-bottom: 15px;
        }
         /* table */
         .table th,.table td{
            font-size: 12px;
            padding: 14px 20px;
        }
    }
    @media (max-width:1300px) {
        .game_box{
            padding: 20px 30px;
        }
        .bag_title h4{
            font-size: 16px;
        }
    }
</style>