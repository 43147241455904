<template>
    <div class="labour">
        <div class="content_two">
            <div class="day_box flex_box">
                <span class="day_text">活动时间：7月5日0点-7月8日0点（活动时间三天）</span>
            </div>
            <div class="rule_box2">
                <div class="rule_title2">
                    <span class="rule_text2">活动说明：</span>
                    <span class="rule_content">
                        1、单笔充值达指定金额，获得对应冰淇淋；<br>
                        2、兑换奖励需联系客服获取，联系时间：每日9:00至22:30；<br>
                        3、兑换奖励成功后，扣除对应冰淇淋数量，奖励需直接兑换进指定游戏中；<br>
                        4、兑换奖励均为指定一款游戏的唯一游戏账号、唯一货币类型；<br>
                        5、活动结束后充值将不再获取冰淇淋，兑换时间截止至活动结束后60小时，请及时领取！
                    </span>
                </div>
                <img class="rule_bg" src="/activity/heatWave/img/rule_bg.png">
            </div>
            <div class="flex_box zz_box">
                <div class="item_box2 mr_50">
                    <div class="item_box flex_box ">
                        <div class="zz_title">单笔充值300元<br>3个冰淇淋</div>
                        <img src="/activity/heatWave/img/zz.png">
                    </div>
                </div>
                <div class="item_box2 mr_50">
                    <div class="item_box flex_box ">
                        <div class="zz_title">单笔充值金额500<br>获得7个冰淇淋</div>
                        <img src="/activity/heatWave/img/zz.png">
                    </div>
                </div>
                <div class="item_box2">
                    <div class="item_box flex_box ">
                        <div class="zz_title">单笔充值金额1000<br>获得15个冰淇淋</div>
                        <img src="/activity/heatWave/img/zz.png">
                    </div>
                </div>
            </div>
            <div class="show_zz_btn">
                <div class="btn_text">当前拥有{{num}}个冰淇淋</div>
                <img src="/activity/heatWave/img/now_zz.png">
            </div>
            <div class="btn_box flex_box">
                <img class="ljcz_btn btn_hover" @click="toReach" src="/activity/heatWave/img/ljcz.png">
            </div>
             <div class="suomin flex_box">
                <div class="sm_text">
                    <span class="sm_title">兑换说明</span>
                    <span class="sm_content">
                        冰淇淋*3 兑换 400游戏充值<br>
                        备注：冰淇淋*3的倍数进行兑换
                    </span>
                </div>
                <img src="/activity/heatWave/img/sm_bg.png">
            </div>
            <div class="kefu_box flex_box">
                <div class="kf_text">兑换游戏充值请联系平台客服</div>
                <img src="/activity/heatWave/img/kf.png">
            </div>
        </div>
        
    </div>
</template>

<script>
import {doubleEggInfo,userGoods} from "@/api/http";
export default {
    name: 'HeatWave',
    data() {
      return {
        isactive:false, //活动是否开启
        bagdata:[],//所有蛋的信息
        reset:false,//当日是否可以重置
        money:[],
        num:0
      }
    },
    methods: {
        toReach(){
            this.$router.push({
                path:'/member/wallet',
            })
        },
        // 活动信息接口
        doubleEggInfo(){
              doubleEggInfo({
                  token:this.$route.query.token
              }).then((res)=>{
                  if(res.code == 200){
                   this.bagdata = res.data.eggdata
                   this.reset = res.data.reset
                   this.money = res.data.money
                  }
              }).catch((err)=>{
                  console.log('捕获异常', err)
                  this.$message({
                        message: err.msg,
                        type: 'warning',
                        duration: 1500,
                    })
              })
          },
        // 获取粽子数量
        userGoods(){
            userGoods({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                    this.num = res.data.data
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        }
    },
    created() {
        // this.doubleEggInfo()
        this.userGoods()
    },
}
</script>

<style scoped>
/* 活动一 */
.bwite{
    filter: grayscale(100%);
}
.labour{
   background: url('/public/activity/heatWave/img/bg.png') no-repeat;
   background-size: 1920px 1080px;
   min-width: 1903px;
   min-height: 1080px;
   padding-top: 326px;
   position: relative;
}
.day_box{
    background: url('/public/activity/heatWave/img/time_bg.png') no-repeat;
    background-size: 675px 39px;
    min-width: 675px;
    min-height: 39px;
}
.day_text{
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 22px;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 9.5%;
}
.logo1{
    position: absolute;
    position: absolute;
    left: -20px;
    top: 30px;
}
.rule_box{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    right: 8%;
}
.rule_title{
    font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #2D5956;
}
.rule_text{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #2D5956;
}
.text_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    position: relative;
}
.text_content{
    position: absolute;
}
.text_box span{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #F6FBF4;
    line-height: 28px;
    margin-right: 10px;
}
.text_box span:nth-of-type(3){
    margin: 0;
}
.item_box{
    position: relative;
}
.money_text{
    position: absolute;
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #175738;
    line-height: 13px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 23%;
    z-index: 10;
}
.bag_box{
    margin-top: 20px;
}
.chuang_img{
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 35px;
}
.chuang_light{
    position: absolute;
    bottom: 35px;
}
.flex_column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.item_type{
    background: linear-gradient(162deg, #B3E2DF, #E3FEFC);
    border-radius: 9px;
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #175738;
    line-height: 13px;
    padding: 3px 18px;
    text-align: center;
    margin-top: 15px;
}
.jdt_box{
    position: relative;
    bottom: 10px;
}
.btn_box{
    margin-top: 13px;
}
.ljcz_btn{
    margin-right: 13px;
}
/* 活动二 */
.content_two{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 10.5%;
}
.logo2{
    position: absolute;
    z-index: 10;
    left: 0;
    left: -33px;
    top: 30px;
}
.rule_box2{
    position: relative;
    margin-top: 10px;
}
.rule_title2{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.rule_text2{
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 22px;
}
.rule_content{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 19px;
    width: 486px;
}
.zz_title{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #F4FFEF;
    line-height: 16px; 
    position: absolute;
    text-align: center;
    bottom: 2%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
}
.zz_box{
    margin-top: 30px;
}
.item_box2{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mr_50{
    margin-right: 50px;
}
.zz_tip{
    position: relative;
    margin-top: 20px;
}
.zz_text{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 13px;
    text-align: center;
    position: absolute;
}
.show_zz_btn{
    position: relative;
    margin-top: 20px
}
.btn_text{
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 16px;
    color: #FEFFBA;
    line-height: 13px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}
.suomin{
    margin-top: 10px;
    position: relative;
}
.kefu_box{
    margin-top: 10px;
    position: relative;
}
.sm_text{
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.sm_title{
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 22px;
}
.sm_content{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #FEFF9F;
    line-height: 19px;
    text-align: left;
    margin-top: 10px;
}
.kf_text{
    position: absolute;
    width: 100%;
    text-align: center;
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 24px;
    color: #FFFFFF;
    background: linear-gradient(0deg, #FFF1B1 0%, #FFFEE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>