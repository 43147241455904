<template>
    <div class="labour">
        <div class="content">
            <img class="logo1" src="/activity/dragonBoat/img/logo1.png">
            <div class="day_box flex_box">
                <span class="day_text">2024年6月7日0点-2024年6月11日0点（活动有效期4天）</span>
            </div>
            <div class="rule_box">
                <h5 class="rule_title">活动说明：</h5>
                <span class="rule_text">1.充值累积达到对应档位，自动领取对应档位粽子。<br>
                    2.累充到1000档位，所有粽子自动重置。<br>
                    3.每天玩家有一次机会手动刷新重置粽子，重置后累充金额重新计算。<br>
                    4.该活动与多充多送、满减活动叠加。
                </span>
                <!-- 活动logo -->
            </div>
            <div class="text_box">
                <div class="text_content">
                    <span>累计充值：{{ money.allmoney ? money.allmoney : 0 }}</span>
                    <span>当前充值：{{ money.reset_no_money ? money.reset_no_money : 0 }}</span>
                    <span>重置前充值：{{ money.reset_money ? money.reset_money : 0}}</span>
                </div>
                <img src="/activity/dragonBoat/img/text_bg.png">
            </div>
            <div class="bag_box flex_box">
                <div class="item_box">
                    <span class="money_text">累计充值100<br>赠送600储值</span>
                    <img class="zz_img " :class="{'bwite': !bagdata.oneEgg}" src="/activity/dragonBoat/img/light.png">
                </div>
                <div class="item_box">
                    <span class="money_text">累计充值200<br>赠送800储值</span>
                    <img class="zz_img" :class="{'bwite': !bagdata.twoEgg}" src="/activity/dragonBoat/img/light.png">
                </div>
                <div class="item_box">
                    <span class="money_text">累计充值300<br>赠送900储值</span>
                    <img class="zz_img" :class="{'bwite': !bagdata.threeEgg}" src="/activity/dragonBoat/img/light.png">
                </div>
                <div class="item_box">
                    <span class="money_text">累计充值500<br>赠送1300储值</span>
                    <img class="zz_img" :class="{'bwite': !bagdata.fourEgg}" src="/activity/dragonBoat/img/light.png">
                </div>
                <div class="item_box">
                    <span class="money_text">累计充值1000<br>赠送3100储值</span>
                    <img class="zz_img" :class="{'bwite': !bagdata.fiveEgg}" src="/activity/dragonBoat/img/light.png">
                </div>
            </div>
            <div class="jdt_box flex_box">
                <img class="chuang_img" v-show="!bagdata.oneEgg" src="/activity/dragonBoat/img/chuan.png">
                <div class="item_box flex_column">
                    <img class="zz_img " :class="{'bwite': !bagdata.oneEgg}" src="/activity/dragonBoat/img/1.png">
                    <div class="item_type">{{ !bagdata.oneEgg ? '未完成' : '完成' }}</div>
                    <img class="chuang_light" v-show="bagdata.oneEgg" src="/activity/dragonBoat/img/chuan.png">
                </div>
                <div class="item_box flex_column">
                    <img class="zz_img" :class="{'bwite': !bagdata.twoEgg}" src="/activity/dragonBoat/img/2.png">
                    <div class="item_type">{{ !bagdata.twoEgg ? '未完成' : '完成' }}</div>
                    <img class="chuang_light" v-show="bagdata.twoEgg" src="/activity/dragonBoat/img/chuan.png">
                </div>
                <div class="item_box flex_column">
                    <img class="zz_img" :class="{'bwite': !bagdata.threeEgg}" src="/activity/dragonBoat/img/3.png">
                    <div class="item_type">{{ !bagdata.threeEgg ? '未完成' : '完成' }}</div>
                    <img class="chuang_light" v-show="bagdata.threeEgg" src="/activity/dragonBoat/img/chuan.png">
                </div>
                <div class="item_box flex_column">
                    <img class="zz_img" :class="{'bwite': !bagdata.fourEgg}" src="/activity/dragonBoat/img/4.png">
                    <div class="item_type">{{ !bagdata.fourEgg ? '未完成' : '完成' }}</div>
                    <img class="chuang_light" v-show="bagdata.fourEgg" src="/activity/dragonBoat/img/chuan.png">
                </div>
                <div class="item_box flex_column">
                    <img class="zz_img" :class="{'bwite': !bagdata.fiveEgg}" src="/activity/dragonBoat/img/5.png">
                    <div class="item_type">{{ !bagdata.fiveEgg ? '未完成' : '完成' }}</div>
                    <img class="chuang_light" v-show="bagdata.fiveEgg" src="/activity/dragonBoat/img/chuan.png">
                </div>
            </div>
            <div class="btn_box flex_box">
                <img class="ljcz_btn btn_hover" @click="toReach" src="/activity/dragonBoat/img/ljcz.png">
                <img class="return_btn btn_hover" @click="resetting" src="/activity/dragonBoat/img/return.png">
            </div>
            
        </div>
        <div class="content_two">
            <img class="logo2" src="/activity/dragonBoat/img/logo2.png">
            <div class="rule_box2">
                <div class="rule_title2">
                    <span class="rule_text2">活动说明：</span>
                    <span class="rule_content">
                        1、单笔充值达指定金额，获得对应粽子数量；<br>
                        2、兑换奖励需联系客服获取，联系时间：每日9:00至22:30；<br>
                        3、兑换奖励成功后，扣除对应粽子数量，奖励需直接兑换进指定游戏中；<br>
                        4、兑换奖励均为指定一款游戏的唯一游戏账号、唯一货币类型；<br>
                        5、活动结束后充值将不再获取粽子数量，兑换时间截止至活动结束后60小时，请及时领取！
                    </span>
                </div>
                <img class="rule_bg" src="/activity/dragonBoat/img/rule_bg.png">
            </div>
            <div class="flex_box zz_box">
                <div class="item_box2">
                    <div class="item_box flex_box ">
                        <div class="zz_title">单笔充值金额300<br>获得3个粽子</div>
                        <img src="/activity/dragonBoat/img/zz.png">
                    </div>
                    <div class="zz_tip flex_box">
                        <span class="zz_text">粽子*3<br>兑换500游戏充值</span>
                        <img src="/activity/dragonBoat/img/zz_bg.png">
                    </div>
                </div>
                <div class="item_box2">
                    <div class="item_box flex_box ">
                        <div class="zz_title">单笔充值金额500<br>获得5个粽子</div>
                        <img src="/activity/dragonBoat/img/zz.png">
                    </div>
                    <div class="zz_tip flex_box">
                        <span class="zz_text">粽子*5<br>兑换800游戏充值</span>
                        <img src="/activity/dragonBoat/img/zz_bg.png">
                    </div>
                </div>
                <div class="item_box2">
                    <div class="item_box flex_box ">
                        <div class="zz_title">单笔充值金额1000<br>获得11个粽子</div>
                        <img src="/activity/dragonBoat/img/zz.png">
                    </div>
                    <div class="zz_tip flex_box">
                        <span class="zz_text">粽子*10<br>兑换1600游戏充值</span>
                        <img src="/activity/dragonBoat/img/zz_bg.png">
                    </div>
                </div>
                <div class="item_box2">
                    <div class="item_box flex_box ">
                        <div class="zz_title">单笔充值金额2000<br>获得22个粽子</div>
                        <img src="/activity/dragonBoat/img/zz.png">
                    </div>
                    <div class="zz_tip flex_box">
                        <span class="zz_text">粽子*20<br>兑换3200游戏充值</span>
                        <img src="/activity/dragonBoat/img/zz_bg.png">
                    </div>
                </div>
            </div>
            <div class="show_zz_btn">
                <div class="btn_text">当前拥有{{num}}个粽子</div>
                <img src="/activity/dragonBoat/img/now_zz.png">
            </div>
            <div class="kefu_box flex_box">
                <div class="kf_text">该活动需要联系客服兑换奖励</div>
                <img src="/activity/dragonBoat/img/kf.png">
            </div>
        </div>
        
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {doubleEggInfo,DoubleEggReset,userGoods} from "@/api/http";
export default {
    name: 'Home',
    data() {
      return {
        isactive:false, //活动是否开启
        bagdata:[],//所有蛋的信息
        reset:false,//当日是否可以重置
        money:[],
        num:0
      }
    },
    methods: {
        // 活动信息接口
        doubleEggInfo(){
              doubleEggInfo({
                  token:this.$route.query.token
              }).then((res)=>{
                  if(res.code == 200){
                   this.bagdata = res.data.eggdata
                   this.reset = res.data.reset
                   this.money = res.data.money
                  }
              }).catch((err)=>{
                  console.log('捕获异常', err)
                  this.$message({
                        message: err.msg,
                        type: 'warning',
                        duration: 1500,
                    })
              })
          },
        // 重置接口
        DoubleEggReset(){
            DoubleEggReset({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                    this.$message({
                        message: '重置成功',
                        type:'success',
                        duration: 1500,
                        onClose:()=>{
                            this.doubleEggInfo()
                        }
                    })
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
        // 重置按钮
        resetting(){
            if(this.reset){
                this.$confirm('重置后累充金额重新计算,是否继续重置？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.DoubleEggReset()
                }).catch(() => {
                });
            }else{
                this.$message({
                    message: '您今日已经重置过了~',
                    type: 'warning',
                    duration: 1500,
                })
            }
            
        },
        toReach(){
            this.$router.push({
                path:'/member/wallet',
            })
        },
        // 获取粽子数量
        userGoods(){
            userGoods({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                    this.num = res.data.data
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
            })
        }
    },
    created() {
        this.doubleEggInfo()
        this.userGoods()
    },
}
</script>

<style scoped>
/* 活动一 */
.bwite{
    filter: grayscale(100%);
}
.labour{
   background: url('/public/activity/dragonBoat/img/bg.png') no-repeat;
   background-size: 1920px 1080px;
   min-width: 1903px;
   min-height: 1080px;
   padding-top: 365px;
   position: relative;
}
.day_box{
    background: url('/public/activity/dragonBoat/img/time_bg.png') no-repeat;
    background-size: 675px 39px;
    min-width: 675px;
    min-height: 39px;
}
.day_text{
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 18px;
    color: #FCFFF8;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 9.5%;
}
.logo1{
    position: absolute;
    position: absolute;
    left: -20px;
    top: 30px;
}
.rule_box{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    right: 8%;
}
.rule_title{
    font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #2D5956;
}
.rule_text{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #2D5956;
}
.text_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    position: relative;
}
.text_content{
    position: absolute;
}
.text_box span{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #F6FBF4;
    line-height: 28px;
    margin-right: 10px;
}
.text_box span:nth-of-type(3){
    margin: 0;
}
.item_box{
    position: relative;
}
.money_text{
    position: absolute;
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #175738;
    line-height: 13px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 23%;
    z-index: 10;
}
.bag_box{
    margin-top: 20px;
}
.chuang_img{
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 35px;
}
.chuang_light{
    position: absolute;
    bottom: 35px;
}
.flex_column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.item_type{
    background: linear-gradient(162deg, #B3E2DF, #E3FEFC);
    border-radius: 9px;
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #175738;
    line-height: 13px;
    padding: 3px 18px;
    text-align: center;
    margin-top: 15px;
}
.jdt_box{
    position: relative;
    bottom: 10px;
}
.btn_box{
    margin-top: 30px;
}
.ljcz_btn{
    margin-right: 13px;
}
/* 活动二 */
.content_two{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 2%;
    padding-top: 45px;
}
.logo2{
    position: absolute;
    z-index: 10;
    left: 0;
    left: -33px;
    top: 30px;
}
.rule_box2{
    position: relative;
}
.rule_title2{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.rule_text2{
    font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}
.rule_content{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    width: 486px;
}
.zz_title{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #F4FFEF;
    line-height: 16px; 
    position: absolute;
    text-align: center;
    bottom: 6%;
    left: 28%;
}
.zz_box{
    margin-top: 10px;
}
.item_box2{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.zz_tip{
    position: relative;
    margin-top: 20px;
}
.zz_text{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #F7FFFA;
    line-height: 15px;
    text-align: center;
    position: absolute;
}
.show_zz_btn{
    position: relative;
    margin-top: 30px
}
.btn_text{
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    position: absolute;
    left: 62%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}
.kefu_box{
    margin-top: 20px;
    position: relative;
}
.kf_text{
    position: absolute;
    width: 100%;
    text-align: center;
    font-family: Source Han Serif CN;
    font-weight: 800;
    font-size: 20px;
    color: #F4FFEF;
    line-height: 16px;
}
</style>