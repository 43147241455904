<template>
  <div>
     <keep-alive>
        <component :is="SquardComponent" @selectCompontent="selectCompontent"></component>
     </keep-alive>
  </div>
</template>

<script>
import Squard from '@/components/squard/Squard.vue'
import SquaredReceive from '@/components/squard/SquaredReceive.vue'
import {SquaredInfo} from '@/api/http.js'
export default {
    name: 'SquaredIndex',
    components:{
        Squard,
        SquaredReceive
    },
    data() {
        return {
            SquardComponent:'Squard'
        }
    },
    methods:{
         // 活动信息接口
         SquaredInfo(){
            SquaredInfo({
                token:this.$route.query.token
            }).then((res) =>{
                if(res.code == 200){
                    if(!res.data.participation){
                       this.SquardComponent = 'SquaredReceive'
                    }else{
                        this.SquardComponent = 'Squard'
                    }
                }
            }).catch((err) =>{
                console.log('捕获异常', err)
            })
        },
        selectCompontent(data){
            this.SquardComponent = data
        }
    },
    created(){
        this.SquaredInfo()
    }
}
</script>

<style>

</style>