<template>
    <div class="lucky_box  ">
        <img src="/activity/squared/img/logo2.png">
        <div class="time_box">
            <span class="time_text">活动时间：2024.7.23-2024.8.31</span>
            <img src="/activity/squared/img/time_bg.png" width="650px">
        </div>
        <div class="rule_boxs">
            <div class="rule_title">
                <span class="rule_top">活动规则</span>
                <span class="rule_text">1.新用户注册账号，获得一次翻牌机会，通过翻牌获得随机金额。<br>
                2.翻牌获得的金额，分7天领取，每天登入可领取部分金额。<span class="rule_color">例如：1000/7=每日获得142金额。</span><br>
                3.翻牌机会每个用户一次，即同一个会员号、同IP、只可参与一次<span class="rule_color">（如提示未达到参与条件表示非唯一用户）。</span><br>
                4.老用户需在会员有效期内，也可参与新人活动，<span class="rule_color">如未在会员有效期内，重新激活即可领取。</span><br>
                5.活动结束后将不再抽取充值或是领取充值，请在活动时间内及时参与领取活动！</span>
            </div>
            <img src="/activity/squared/img/rule_bg.png">
        </div>
        <div class="squard_box">
            <section class="card_box item" v-for="(car,index) in prizes" :key="index">
                <div class="card_set" ref="card_all" @click="startLuck($event)">
                    <!-- 卡牌正面 -->
                    <div v-if="flag" class="box1 front">
                        <img class="jp_img" :src="car.src">
                        <span class="money_text">{{ car.value }}平台储值</span>
                        <img src="/activity/squared/img/bg.png" width="272px">
                    </div>
                    <div v-else class="box2 back">
                        <img class="jp_img" :src="image">
                        <span class="money_text">{{ money }}平台储值</span>
                        <img src="/activity/squared/img/active.png" width="272px">
                    </div>
                    <!-- 卡牌背面 -->
                    <div v-if="flag" class="box2 back">
                        <img class="jp_img" :src="image">
                        <span class="money_text">{{ money }}平台储值</span>
                        <img src="/activity/squared/img/active.png" width="272px">
                    </div>
                    <div v-else class="box1 front">
                        <img src="/activity/squared/img/back.png" width="272px">
                    </div>
                </div>
            </section>
        </div>
        <!-- 立即抽取 -->
        <img class="btn_hover mt-5" @click="backCard" src="/activity/squared/img/play2.png">
        
       
        <!-- 领取奖励弹窗 -->
        <div class="receive_box">
            <div class="content">
                <span class="receive_text">恭喜您获得了{{ money }}储值</span>
                <img class="lqjl_btn btn_hover" src="/activity/squared/img/lqjl.png">
            </div>
            <img src="/activity/squared/img/receive.png">
        </div>
        <!-- 领取补偿弹窗 -->
        <div class="compensate_box">
            <div class="old_user">
                <span class="old_text">未在会员有效期内不可参与</span>
                <img class="lxkf_btn btn_hover" src="/activity/squared/img/ljcz.png">
            </div>
            <img src="/activity/squared/img/compensate.png">
        </div>
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {SquaredInfo,SquaredPlay} from '@/api/http.js'
export default {
    name: 'Squard',
    data() {
        return {
            user_flag:false, //活动状态
            isClick:true,//抽奖按钮节流
            flag:true,//立即抽取按钮状态，true为未点击
            target:'',
            position:'',
            money:'',
            image:'', //奖品图片
            url:'',
            kefu_url:'',
            prizes: [
                
            ],
        }
    },
    methods: {
        // 立即抽取按钮
        backCard(){
            if(this.flag){
                this.flag = false
                this.$$refs.card_all.className = 'card_set flip';
            }
        },
        // 翻转卡牌基础功能
        cardSet: function () {
            // const target = event.currentTarget
            if (this.target.classList.contains('flip')) {
                target.className = 'card_set';
            } else {
                this.target.className = 'card_set flip';
                setTimeout(()=>{
                    this.leaderTc()
                },2000)
                
            }
        },
        // 不使用原生组件按钮，使用自定义透明遮罩按钮
        startLuck(dom){
            this.target = dom.currentTarget
            if(this.user_flag){
                if(!this.flag){
                    if(this.isClick){
                        this.isClick = false
                        this.SquaredPlay()
                    }else{
                        this.$message({
                            message: "操作过于频繁，请稍后再试~",
                            type: 'warning',
                            duration: 1500
                        });
                    }
                }
                
            }else{
                this.$message({
                    message: '活动已结束',
                    type: 'warning',
                    duration: 1500
                })
            }
        },
        // 抽奖接口
        SquaredPlay(){
            if(localStorage.getItem('token')){
                SquaredPlay().then((res) =>{
                    if(res.code == 200){
                        this.position = res.data.position
                        this.money = res.data.money
                        this.image = res.data.image
                        this.cardSet()
                    }
                }).catch((err) =>{
                    if(err.code == 4000){
                        console.log('我进入4000')
                        this.compensate()
                        this.isClick = true
                    }else{
                        console.log('捕获异常', err)
                        this.$message({
                            message: err.msg,
                            type: 'warning',
                            duration: 1500,
                            onClose:() =>{
                                this.isClick = true
                            }
                        })
                    }
                })
            }else{
                this.$message({
                    message: '登录已过期，请重新登录！',
                    type: 'warning',
                    duration: 3000,
                    onClose:() =>{
                        this.$router.push({
                            path:'/'
                        })
                    }
                })
            }
            
        },
        // 活动信息接口
        SquaredInfo(){
            SquaredInfo().then((res) =>{
                if(res.code == 200){
                    this.user_flag = res.data.isactive
                     //获取当前域名    
                    const currentURL = new URL(window.location.href);
                    this.url = currentURL.origin;
                    this.kefu_url = res.data.url
                    if(!res.data.participation){
                      this.$emit('selectCompontent','SquaredReceive')
                    }else{
                         this.prizes = res.data.prize_list
                    }
                }
            }).catch((err) =>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 5000,
                })
            })
        },
          // 弹窗
          leaderTc() {
              var that = this
              var leader_tc = $('.receive_box')
              var LayIndex = layer.open({
                  type: 1,
                  content: leader_tc,
                  anim: false,
                  fixed: true,
                  shade: 0.6,
                  title: 0,
                  scrollbar: true,
                  shadeClose: false,
                  closeBtn: 0,
                  isOutAnim: false,
                  area: [auto, auto],
                  success: function (layero,index) {
                    layer.style(index, {
                        top: '15%',
                    });
                      const lq = $(layero).find('.lqjl_btn');
                      lq.click(function () {
                          layer.close(LayIndex)
                          that.$emit('selectCompontent','SquaredReceive')
                      })
                  }
              });
          },
        // 联系客服弹窗
        compensate(){
        var that = this
            var compensate_box = $('.compensate_box')
            var LayIndex2 = layer.open({
                type: 1,
                content: compensate_box,
                anim: false,
                fixed: true,
                shade: 0.6,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                closeBtn: true,
                isOutAnim: false,
                area: [auto, '402px'],
                success: function (layero,index) {
                    layer.style(index, {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)'
                    });
                    const lq = $(layero).find('.lxkf_btn');
                    lq.click(function () {
                        layer.close(LayIndex2)
                        that.$router.push({
                        path:'/member/wallet'
                        })
                    })
                }
            });
        },
    },
    mounted(){
       
    },
    created() {
       this.SquaredInfo()
        
    },
}
</script>

<style scoped>
.rule_boxs{
    position: relative;
    margin-bottom: 20px;
}

.rule_title{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.rule_text{
    color: #fffbce;
    font-size: 12px;
    font-family: Source Han Sans SC;
    min-width: 120%;
}
.rule_top{
    color: #fff373;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: bold;
}
.rule_color{
    color:#6cff44
}
.squard_box{
    background: url('/public/activity/squared/img/box.png') no-repeat;
   background-size: 884px 527px;
   min-height: 527px;
   max-width: 884px;
   padding: 10px;
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}
.box2{
    position: relative;
}
.box1{
    position: relative;
}
.jp_img{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    width: 40%;
}
.money_text{
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2%;
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 18px;
    color: #FEFF90;
    background: linear-gradient(0deg, #FFFD36 0%, #FEFFB3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/* 卡牌翻转 ↓*/
.card_box {
    position: relative;
    perspective: 800px;
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    -ms-transform: perspective(800px);
    -o-transform: perspective(800px);
    transform: perspective(800px);
    width: 272px;
    height: 153px;
    margin: 8px;
    cursor: pointer;
    transition: all .5s;
}
.card_box:hover{
    transform: scale(1.05);
    transition: all .5s;
}
.card_set {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
}

.card_set .front {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    /*让背面图案隐藏*/
    transform: rotateY(0deg);
    transition: transform .8s ease;
}

.card_set .back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: contain;
    backface-visibility: hidden;
    transform: rotateY(-180deg);
    transition: transform .8s ease;
}

.card_set.flip .front {
    z-index: 10;
    transform: rotateY(180deg);
}

.card_set.flip .back {
    z-index: 12;
    transform: rotateY(0deg);
}

.share {
    cursor: pointer;
}

.share2 {
    cursor: pointer;
    display: none;
}

body .lay-msg-bg {
    background-color: white !important;
}

/*  卡牌翻转↑*/
/* 弹窗 */
.receive_box{
    display: none;
}
.compensate_box{
    display: none;
}
.receive_text{
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 24px;
    color: #ffec95;
    background: linear-gradient(0deg, #ffec95 0%, #fffee6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
    text-align: center;
}
.content{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 14%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.old_user{
     position: absolute;
    left: 70%;
    transform: translateX(-50%);
    bottom: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.old_text{
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 24px;
    color: #ffec95;
    background: linear-gradient(0deg, #ffec95 0%, #fffee6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 70px;
    text-align: center;
}
.luck{
    position: relative;
}
.top_btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-52%);
    width: 320px;
    height: 180px;
    z-index: 10;
    cursor: pointer;
}

.lucky_box{
    background: url('/public/activity/squared/img/bg3.png') no-repeat;
   background-size: 1920px 1200px;
   min-width: 1903px;
   min-height: 1200px;
   padding-top: 80px;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.time_box{
    position: relative;
    margin-top: 15px;
    margin-bottom: 20px;
}
.time_text{
    font-family: Source Han Sans SC;
    font-weight: 400;
    font-size: 18px;
    color: #FEE1B5;
    line-height: 57px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

</style>