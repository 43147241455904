<template>
  <div class="egg_page">
    <div class="smash_top">
        <div class="active_one ">
            <img class="logo" src="/activity/smash/img/logo1.png" height="100%">
            <div class="active_one_content">
                <div class="rule_box">
                    <div class="rule_title">
                        <h5 class="rule_title_text">活动说明：</h5>
                        <div class="rule_text">
                            <p>1.每天登入玩家，免费砸福蛋一个。</p>
                            <p>2.7天内有充值记录的玩家，额外再砸福蛋一个。</p>
                            <p>3.福蛋每日0点重置。</p>
                        </div>
                    </div>
                    <!-- 背景 -->
                    <img src="/activity/smash/img/bg1.png">
                </div>
                <div class="egg_box">
                    <div class="egg_item">
                        <div class="egg_item_box">
                            <img class="egg"
                                :class="{'broken':isBroken}" 
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(1)"
                                src="/activity/smash/img/egg1.png">
                            <img class="dizuo" src="/activity/smash/img/dizuo.png">
                            <img v-if="onedata" @click="getEgg(1)" class="lqjl_btn btn_hover" src="/activity/smash/img/lqjl.png">
                            <img v-else class="lqjl_btn " src="/activity/smash/img/ylq.png">
                        </div>
                        <div class="rule_box reward">
                            <div class="rule_title">
                                <div class="reward_title">
                                    <span>50%概率获得25储值</span>
                                    <span>50%概率获得50储值</span>
                                </div>
                            </div>
                            <!-- 背景 -->
                            <img src="/activity/smash/img/bg2.png">
                        </div>
                    </div>
                    <div class="egg_item">
                        <div class="egg_item_box">
                            <img class="egg"
                                :class="{'broken':isBroken_two}" 
                                @mouseover="changeCursor"
                                @mouseleave="resetCursor"
                                @mousedown="liftHammer"
                                @mouseup="dropAndHitHammer(2)"
                                src="/activity/smash/img/egg2.png">
                            <img class="dizuo" src="/activity/smash/img/dizuo.png">
                            <img v-if="twodata" @click="getEgg(2)" class="lqjl_btn btn_hover" src="/activity/smash/img/lqjl.png">
                            <img v-else class="lqjl_btn " src="/activity/smash/img/ylq.png">
                        </div>
                        <div class="rule_box reward">
                            <div class="rule_title">
                                <div class="reward_title">
                                    <span>50%概率获得100储值</span>
                                    <span>50%概率获得200储值</span>
                                </div>
                            </div>
                            <!-- 背景 -->
                            <img src="/activity/smash/img/bg2.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="smash_bottom">
        <div class=" active_two">
            <img class="logo2" src="/activity/smash/img/logo2.png" >
            <div class="rule_box">
                <div class="rule_title">
                    <h5 class="rule_title_text">活动说明：</h5>
                    <div class="rule_text">
                        <p>1.充值累积达到对应档位，自动领取对应档位福蛋。</p>
                        <p>2.累充到1000档位，所有福蛋自动重置。</p>
                        <p>3.每天玩家有一次机会手动刷新重置福蛋，重置后累充金额重新计算。</p>
                        <p>4.该活动与多充多送、满减活动叠加。</p>
                    </div>
                </div>
                <!-- 背景 -->
                <img src="/activity/smash/img/bg3.png">
            </div>
            <div class="text_box">
                <span>累计充值：{{ money.allmoney ? money.allmoney : 0 }}</span>
                <span>当前充值：{{ money.reset_no_money ? money.reset_no_money : 0 }}</span>
                <span>重置前充值：{{ money.reset_money ? money.reset_money : 0}}</span>
            </div>
            <div class="flex_box">
                <div class="egg_item2">
                    <div class="egg_item_box">
                        <img class="egg" 
                        :class="{'bwite': !eggdata.oneEgg}"
                        src="/activity/smash/img/egg1.png">
                        <img class="dizuo" src="/activity/smash/img/dizuo.png">
                    </div>
                    <div class="rule_box postion">
                        <div class="rule_title">
                            <div class="reward_title">
                                <span>累计充值100</span>
                                <span>赠送600储值</span>
                            </div>
                        </div>
                        <!-- 背景 -->
                        <img src="/activity/smash/img/bg2.png">
                    </div>
                </div>
                <div class="egg_item2">
                    <div class="egg_item_box">
                        <img class="egg" 
                        :class="{'bwite': !eggdata.twoEgg}"
                        src="/activity/smash/img/egg2.png">
                        <img class="dizuo" src="/activity/smash/img/dizuo.png">
                    </div>
                    <div class="rule_box postion">
                        <div class="rule_title">
                            <div class="reward_title">
                                <span>累计充值200</span>
                                <span>赠送800储值</span>
                            </div>
                        </div>
                        <!-- 背景 -->
                        <img src="/activity/smash/img/bg2.png">
                    </div>
                </div>
            </div>
            <div class="flex_box" style="position: relative;
    bottom: 80px;">
                    <div class="egg_item2">
                        <div class="egg_item_box">
                            <img class="egg" 
                            :class="{'bwite': !eggdata.threeEgg}"
                            src="/activity/smash/img/egg3.png">
                            <img class="dizuo" src="/activity/smash/img/dizuo.png">
                        </div>
                        <div class="rule_box postion">
                            <div class="rule_title">
                                <div class="reward_title">
                                    <span>累计充值300</span>
                                    <span>赠送900储值</span>
                                </div>
                            </div>
                            <!-- 背景 -->
                            <img src="/activity/smash/img/bg2.png">
                        </div>
                    </div>
                    <div class="egg_item2">
                        <div class="egg_item_box">
                            <img class="egg" 
                            :class="{'bwite': !eggdata.fourEgg}"
                            src="/activity/smash/img/egg4.png">
                            <img class="dizuo" src="/activity/smash/img/dizuo.png">
                        </div>
                        <div class="rule_box postion">
                            <div class="rule_title">
                                <div class="reward_title">
                                    <span>累计充值500</span>
                                    <span>赠送1300储值</span>
                                </div>
                            </div>
                            <!-- 背景 -->
                            <img src="/activity/smash/img/bg2.png">
                        </div>
                    </div>
                    <div class="egg_item2">
                        <div class="egg_item_box">
                            <img class="egg" 
                            :class="{'bwite': !eggdata.fiveEgg}"
                            src="/activity/smash/img/egg5.png">
                            <img class="dizuo" src="/activity/smash/img/dizuo.png">
                        </div>
                        <div class="rule_box postion">
                            <div class="rule_title">
                                <div class="reward_title">
                                    <span>累计充值1000</span>
                                    <span>赠送3100储值</span>
                                </div>
                            </div>
                            <!-- 背景 -->
                            <img src="/activity/smash/img/bg2.png">
                        </div>
                    </div>
            </div>
            <div class="cz_btn flex_box">
                <img class="btn_hover" @click="resetting" src="/activity/smash/img/sxcz.png">
            </div>
        </div>
    </div>
    <!-- 弹窗 -->
    <div class="leader_tc">
        <div class="tc_title">
            <h5>恭喜您获得</h5>
            <span>{{money}}储值大礼</span>
        </div>
        <img class="leader_btn" src="/activity/smash/img/btn.png">
        <!-- 背景 -->
        <img class="leader_bg" src="/activity/smash/img/leader_bg.png">
    </div>
  </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {doubleEgg,doubleEggindex,doubleEggInfo,DoubleEggReset } from '@/api/http.js'
export default {
    name: 'Egg',
    data() {
      return {
        isBroken: false,//是否可领取
        isBroken_two:false, 
        isClick: true, //防抖
        isactive:false, //活动是否开启
        money:'', //砸中的金额
        onedata:false, //第一颗蛋是否可领取
        twodata:false, //第二颗蛋是否可领取
        eggdata:[],//所有蛋的信息
        reset:false,//当日是否可以重置
        money:[]
      }
    },
    methods: {
        changeCursor() {
            // console.log('鼠标进入');
            document.body.style.cursor = "url('/activity/smash/img/before.cur'), auto";
        },
        resetCursor() {
            // console.log('鼠标移出');
            document.body.style.cursor = "default";
        },
        liftHammer() {
            // console.log('鼠标按下');
            document.body.style.cursor = "url('/activity/smash/img/after.cur'), auto";
        },
        dropAndHitHammer(type) {
            // console.log('鼠标松开');
            document.body.style.cursor = "url('/activity/smash/img/before.cur'), auto";
            if(this.isClick){
                this.isClick = false
                this.doubleEgg(type)  // 敲击后破裂金蛋
            }else{
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.isClick = true
                    }
                })
            }
        },
        // 点击领取事件（和砸蛋事件一样）
        getEgg(type){
            if(this.isClick){
                this.isClick = false
                this.doubleEgg(type)  // 敲击后破裂金蛋
            }else{
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.isClick = true
                    }
                })
            }
        },

        // 获得一点击砸金蛋接口
        doubleEgg(type){
            doubleEgg({
                type:type,
                token:this.$route.query.token
            }).then((res) => {
                if(res.code == 200){
                    if(type == 1){
                        this.isBroken = true; //开启砸蛋动画
                    }else{
                        this.isBroken_two = true; //开启砸蛋动画2
                    }
                    this.money = res.data.data
                    setTimeout(()=>{
                        this.leaderTc()
                        this.isClick = true
                        // 初始化动画
                        this.isBroken = false
                        this.isBroken_two = false
                    }, 2000)
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 3000,
                    onClose: () => {
                        this.isClick = true
                    }
                })
            })
        },
         // 弹窗
        leaderTc() {
            var that = this
            var leader_tc = $('.leader_tc')
            var LayIndex = layer.open({
                type: 1,
                content: leader_tc,
                anim: false,
                fixed: true,
                shade: 0.8,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                closeBtn: 0,
                isOutAnim: false,
                area: [auto, auto],
                success: function (layero) {
                    const lq = $(layero).find('.leader_btn');
                    that.doubleEggindex()
                    lq.click(function () {
                        layer.close(LayIndex)
                    })
                }
            });
        },
        //活动一信息接口
        doubleEggindex(){
            doubleEggindex({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                  this.isactive = res.data.isactive
                  this.onedata = res.data.onedata
                  this.twodata = res.data.twodata
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
        // 活动二信息接口
        doubleEggInfo(){
            doubleEggInfo({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                 this.eggdata = res.data.eggdata
                 this.reset = res.data.reset
                 this.money = res.data.money
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
            })
        },
        // 重置接口
        DoubleEggReset(){
            DoubleEggReset({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                    this.$message({
                        message: '重置成功',
                        type:'success',
                        duration: 1500,
                        onClose:()=>{
                            this.doubleEggInfo()
                        }
                    })
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
        // 重置按钮
        resetting(){
            if(this.reset){
                this.$confirm('重置后累充金额重新计算,是否继续重置？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.DoubleEggReset()
                }).catch(() => {
                });
            }else{
                this.$message({
                    message: '您今日已经重置过了~',
                    type: 'warning',
                    duration: 1500,
                })
            }
            
        },

    },
    created(){
        this.doubleEggindex()
        this.doubleEggInfo()
    }

}
</script>

<style scoped>
.egg_page{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.bwite{
    filter: grayscale(100%);
}
/* 活动一 */
    .smash_top{
        background: url('/public/activity/smash/img/top.png') no-repeat;
        background-size: 1920px 1085px;
        min-width: 1903px;
        min-height: 1085px;
        padding: 430px 280px 0;
    }
    .active_one{
        position: relative;
        display: flex;
    }
    .active_one_content{
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .rule_box{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .postion{
        z-index: 12;
        bottom: 20px;
    }
    .rule_title{
        display: flex;
        justify-content: center;
        align-items: start;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }
    .rule_title_text{
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 800;
        color: #FFEBB6;
    }
    .rule_text p{
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #FFEBB6;
        margin-bottom: 5px;
    }
    .egg_box{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .egg_item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
    }
    .egg_item2{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 40px;
    }
    .egg_item_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .egg{
        position: relative;
        z-index: 2;
        transition: all 0.5s ease-in-out;
    }
    .broken {
    animation: breakEgg 2s forwards;
    }

    @keyframes breakEgg {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    5%, 10% {
        transform: translate3d(-10px, 0, 0);
    }
    15%, 25%, 35%, 45% {
        transform: translate3d(10px, 0, 0);
    }
    20%, 30%, 40% {
        transform: translate3d(-10px, 0, 0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.5) rotate(45deg);
        opacity: 0;
    }
    }
    .dizuo{
        position: absolute;
        bottom: -25%;
        z-index: 1;
        left: -7px;
    }
    .lqjl_btn{
        position: absolute;
        bottom: -27px;
        z-index: 5;
    }
    .reward{
        margin-top: 60px;
    }
    .reward_title{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #FFEBB6;
    }
/* 活动二 */
    .smash_bottom{
        background: url('/public/activity/smash/img/bottom.png') no-repeat;
        background-size: 1920px 855px;
        min-width: 1903px;
        min-height: 855px;
        padding:77px 480px 0 ;
    }
    .active_two{
        position: relative;
        max-height: 778px;
    }
    .logo2{
        position: absolute;
        top: 0;
        left: 95px;
    }
    .cz_btn{
        position: relative;
        bottom: 75px;
    }
    .text_box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    .text_box span{
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #FFEBB6;
        margin-right: 20px;
    }
    .text_box span:nth-of-type(3){
        margin: 0;
    }
/* 弹窗 */
.leader_tc {
    display: none;
}

.leader_bg {
    position: relative;
    z-index: -10;
}

.tc_title {
    position: absolute;
    left: 66%;
    top: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.tc_title h5 {
    color: #FFD700;
    font-size: 20px;
}

.tc_title span {
    color: #FFD700;
    font-size: 35px;
}

.leader_btn {
    position: absolute;
    left: 57%;
    bottom: 20%;
    cursor: pointer;
    transition: all 0.5s;
}

.leader_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}
.layui-layer{
    background-color: transparent !important;
    box-shadow: none !important;
}
</style>