<template>
  <div class="banner">
        <Suspendedwindow></Suspendedwindow>
        <ReportWindow></ReportWindow>
        <Carousel2></Carousel2>
        <div class="bottom_box">
            <div class="container-xl" style="padding: 0;">
                <div class="row">
                    <div class="col-3">
                        <div class="card_box">
                            <div class="card_content">
                                <span class="card_text">立即充值</span>
                                <div @click="toWallet(1)" class="look_btn">查看详情</div>
                            </div>
                            <!-- 背景 -->
                            <img src="/banner/img/1.png" width="100%">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card_box">
                            <div class="card_content">
                                <span class="card_text">签到领充值</span>
                                <div @click="click_member" class="look_btn">查看详情</div>
                            </div>
                            <!-- 背景 -->
                            <img src="/banner/img/2.png" width="100%">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card_box">
                            <div class="card_content">
                                <span class="card_text">充值优惠</span>
                                <div @click="click_member" class="look_btn">查看详情</div>
                            </div>
                            <!-- 背景 -->
                            <img src="/banner/img/3.png" width="100%">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card_box">
                            <div class="card_content">
                                <span class="card_text">包服卡</span>
                                <div @click="toWallet(3)" class="look_btn">查看详情</div>
                            </div>
                            <!-- 背景 -->
                            <img src="/banner/img/4.png" width="100%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
  </div>
</template>

<script>
import Carousel2 from './Carousel2.vue';
import GameClass from './GameClass.vue';
import Suspendedwindow from '../components/Suspendedwindow.vue';
import ReportWindow from '../components/ReportWindow.vue';
import { mapState } from 'vuex';
    export default {
        name:'Banner2',
        components:{Carousel2, GameClass,Suspendedwindow,ReportWindow},
        data() {
            return {
                
            }
        },
        computed:{
            ...mapState([
                'material'
            ]),
            cz_id:{
                get() {
                    return this.$store.state.cz_id
                },
                set(val) {
                    return this.$store.state.cz_id = val
                }
            },
        },
        methods:{
            click_atlas(){
                this.$router.push({
                    path:'/atlas'
                })
                this.$czc( 'PC首页通关成就', '点击', '访问通关成就')
            },
            toWallet(type){
                this.cz_id = type
                this.$router.push({
                    path:'/member/wallet'
                })
                this.$czc( 'PC首页储值入口', '点击', '访问平台充值')
            },
            click_member(){
                // this.$router.push({
                //     path:'/member'
                // })
                // this.$czc('PC首页积分签到', '点击', '访问积分签到')
                // this.cz_id = 2
                this.$router.push({
                    path:'/member'
                })
                this.$czc('PC首页月卡购买', '点击', '访问月卡购买')
            },
            click_atlas(){
                this.$router.push({
                    path:'/atlas'
                })
                this.$czc( 'PC首页通关成就', '点击', '访问通关成就')
            },
            toWallet(){
                this.cz_id = 1
                this.$router.push({
                    path:'/member/wallet'
                })
                this.$czc( 'PC首页储值入口', '点击', '访问平台充值')
            },
            click_member(){
                // this.$router.push({
                //     path:'/member'
                // })
                // this.$czc('PC首页积分签到', '点击', '访问积分签到')
                // this.cz_id = 2
                this.$router.push({
                    path:'/member'
                })
                this.$czc('PC首页月卡购买', '点击', '访问月卡购买')
            },
        },
        directives: {
           
        },
        mounted(){
           
        }
    }
</script>

<style scoped>
.banner{
    overflow: hidden;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: #f5f5f5;
}
.card_text{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #fff;
}
.look_btn{
    border-radius: 25px;
    border: 1px solid #fff;
    padding: .25rem .5rem;
    color: #fff;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: all .5s;
    width: 90px;
}
.look_btn:hover{
    transition: all .5s;
    color: #000;
    background: #fff;
}
.card_content{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
    left: 35px;
}
.bottom_box{
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
}
.card_box{
    position: relative;
    z-index: 50;
    transition: all .5s;
    cursor: pointer;
}
.card_box:hover{
    transition: all .5s;
    transform: scale(1.05);
}
.vb{
    padding: 0 6px;
}
.col_vip{
    width: 20%;
}
.col_carousel{
    width: 50%;
}
.col_gameclass{
    width: 30%;
}
.model_rouder{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}
.model_rouder a{
    width: 100%;
}

@media (max-width:1750px) {

}
@media (max-width:1450px) {
    .model_img{
        height: 100px;
    }
}
@media (max-width:1400px) {
    .model_img{
        height: 95px;
    }
}
@media (max-width:1200px) {
    .model_img{
        height: 85px;
    }
}
</style>