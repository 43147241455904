<template>
    <div>
        <Header></Header>
        <div class="tutorial">
            <div class="container_tt">
                <div class="paw_change">
                    <div class="content ">
                        <div class="ly_box">
                            <h3>联运合作</h3>
                            <span class="fu_text">联运合作</span>
                            <hr width="100%">
                            <p class="ly_text mt-5">全网收购传奇游戏独家版本，独家版本也可以联运（种类不限）</p>
                            <p class="ly_text2 mt-5">欢迎版本作者和运营GM前来洽谈。</p>
                            <a href="http://wpa.qq.com/msgrd?v=3&uin=345565305&site=qq&menu=yes" target="_blank"
                                class="btn btn-danger btn-outline btn-rounded mt-5">商务QQ：345565305</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 背景 -->
            <img class="tutorial_bg" :src="img_url + material.pc_background" width="100%">
        </div>
        <Footer></Footer>
    </div>
</template>
  
  <script>
      import {getArticleData} from '@/api/http.js'
      import Header from '../components/Header.vue';
      import Footer from '../components/Footer.vue';
      import { mapState } from 'vuex';
      export default {
          name:'BeginnerTutorial',
          components:{
              Header,
              Footer
          },
          data() {
              return {
                  time:'',
                  newDate:{},
                  img:'/BeginnerTutorial/img/bg.png',
              }
          },
          computed:{
              ...mapState([
                  'getClient',
                  'tutorial_id',
                  'material'
              ]),
          },
          watch:{
             
          },
          methods:{
             
          },
          created(){
             
          }
      }
  </script>
  
  <style scoped>
   .ly_text{
        font-size: 1.5rem;
    }
    .ly_text2{
        font-size: 1.2rem;
        font-weight: bold;
    }
    .fu_text{
        font-size: 14px;
        color: #999;
        margin-top: 20px;
    }
    .ly_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    }
  .container_tt{
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-right: auto;
      margin-left: auto;
  }
  .tutorial{
      position: relative;
      overflow: hidden;
      top: 0;
      left: 0;
      padding: 10px 0;
      margin-top: 110px;
      background: #F4F4F4;
      min-height: 700px
  }
  .tutorial_bg{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      /* height: 100%; */
  }
  .paw_change{
      position: relative;
      z-index: 2;
      background: #fff;
      border-radius: 4px;
      height: 100%;
      margin-top: 50px;
      margin-bottom:50px ;
  }
      .bag_title{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 30px;
              border-bottom: 1px solid rgb(178, 147, 96,0.2);
       }
      .bag_title h4{
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #AC8747;
          margin: 0;
      }
      .tip{
          display: flex;
          align-items: center;
          justify-content: end;
      }
      .tip_icon{
          width: 13px;
          height: 13px;
          border: 1px solid #806437;
          border-radius: 50%;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-right: 5px;
      }
      .tip span{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
      }
      .font_color{ 
          color: #886A35;
      }
      /* content */
      .content{
          padding: 30px;
          min-height: 500px;
          
      }
      .item_box{
          display: flex;
         flex-direction: column;
          align-items: center;
          margin-bottom: 50px;
      }
      .tutorial_size{
          max-width: 70%;
      }
      .content span{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 20px;
      }
      @media (min-width: 992px){
          .container_tt{
              max-width: 750px;
          }
      }
      @media (min-width: 1200px){
          .container_tt{
              max-width: 875px;
          }
      }
      @media (min-width: 1400px){
          .container_tt{
              max-width: 1030px;
          }
      }
      
      @media (min-width: 1700px){
          .container_tt {
              max-width: 1240px!important;
          }
      }
      @media (max-width:1600px) {
          .bag_title{
              padding: 20px;
          }
          .content{
              padding: 20px;
          }
          .bag_title h4{
              font-size: 17px;
          }
          .tip span{
              font-size: 12px;
          }
          .content span{
              font-size: 12px;
          }
      }
      @media (max-width:1300px) {
          .member{
              margin-top: 100px;
          }
      }
      @media (max-width:1100px) {
          /* top */
          .bag_title h4{
              font-size: 16px;
          }
          .tip span{
              font-size: 12px;
          }
          
      }
  </style>