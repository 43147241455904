<template>
  <div>
    <Header></Header>
    <div class="content">
        <div class="container">
            <!-- <ProductDetalisTop ></ProductDetalisTop> -->
        </div>
    </div>
    <div class="pro_top"></div>
    <div class="container">
        <div class="pro_center">
            <div class="row">
                <div class="col-6">
                    <!-- <div class="content_bg">
                        <ImgShow :left_img="left_img" :right_img="right_img"></ImgShow>
                    </div> -->
                        <img class="content_bg" v-lazy="product_data ? (img_url + product_data.big_image_pc):''" width="100%" height="100%">
                </div>
                <div class="col-6">
                    <div class="center_right">
                        <div class="pro_name">
                            <h4 :title="product_data.name" class="text_ellipsis">{{ product_data.name }}</h4>
                            <span>{{ product_data.description }}</span>
                        </div>
                        <div class="lf_num">灵符:{{ product_value }}</div>
                        <div class="pro_top"></div>
                        <div class="redeemed text_ellipsis">
                            <span>已兑换</span>
                            <div class="red_num text_ellipsis">
                                {{ product_data.sum_num }}
                            </div>
                        </div>
                        <div class="redeemed">
                            <XtxNumbox @add="getNum" :inventory="Number(product_data.stock)"><span class="nums">数&nbsp;&nbsp;&nbsp;&nbsp;量</span></XtxNumbox>
                            <span class="stock">库存：{{product_data.stock}}</span>
                        </div>
                        <div class="pro_top"></div>
                        <div class="roll_btn roll_font" v-if="unLogin" title="立即兑换" @click="toLogin">立即兑换</div>
                        <div class="roll_btn roll_font" v-else-if="isAddress" data-bs-toggle="modal"
                                        data-bs-target="#myModal2"  title="立即兑换">立即兑换</div>
                        <div class="roll_btn roll_font" v-else @click="Redeem"  title="立即兑换">立即兑换</div>
                        <div class="redeemed">当前灵符余额：{{ Number(lingfu).toFixed(0) }}</div>
                    </div>
                </div>
            </div>
            <div class="pro_bottom">
                <div class="pro_class">
                    <h5 class="lang_class" :class="{ 'active': mall_id == 1 }" @click="mall_id = 1">商品详情</h5>
                    <h5 class="lang_class" :class="{ 'active': mall_id == 2 }" @click="mall_id = 2">兑换名单</h5>
                    <h5 class="lang_class" :class="{ 'active': mall_id == 3 }" @click="mall_id = 3">兑换须知</h5>
                </div>
                <div class="pro_content">
                    <div class="pro_item" v-if="mall_id == 1">
                        <div class="pro_img class_box" v-html="product_data.introduction"></div>
                    </div>
                    <div class="pro_item" v-if="mall_id == 2">
                        <table class="table   ">
                                <thead class="table_th_color">
                                    <tr>
                                        <th>用户名称</th>
                                        <th>商品名称</th>
                                        <th>兑换数量</th>
                                        <th>兑换所需灵符</th>
                                        <th>手机号码</th>
                                        <th>兑换时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="t in redem_order" :key="t.id">
                                        <td>{{ t.user_name }}</td>
                                        <td>{{ t.product_name }}</td>
                                        <td>{{ t.num }}</td>
                                        <td>{{ Number(t.total).toFixed(0) }}</td>
                                        <td>{{ t.contact_mobile }}</td>
                                        <td>{{ t.created_date }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Pagination class="page_possion" @change="changePage" :current="pagination.p"
                                :pageSize="pagination.pageSize" :total="total" :hideOnSinglePage="hideOnSinglePage"
                                :showQuickJumper="true" :showTotal="true" placement="right" v-if="pagination && total">
                            </Pagination>
                    </div>
                    <div class="pro_item" v-if="mall_id == 3">
                        <div class="redem_xz" v-html="redem.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <RedemptionPopup  :product_data="product_data"></RedemptionPopup>
    </div>
    <ReturnTop></ReturnTop>
    <Footer></Footer>
    <Login ref="login"></Login>
  </div>
</template>

<script>
    import Pagination from '../components/page.vue'
    import ImgShow from '../components/ImgShow.vue'
    import ProductDetalisTop from '../components/ProductDetalisTop.vue'
    import Header from '../components/Header.vue'
    import Footer from '../components/Footer.vue'
    import XtxNumbox from '../components/Numbox.vue'
    import ReturnTop from '../components/ReturnTop.vue'
    import RedemptionPopup from '../components/RedemptionPopup.vue'
    import {getProductData,getUserLingfuData,productArticle} from '@/api/http.js'
    import {mapState} from 'vuex'
    export default {
        name:'ProductDetalis',
        components:{ProductDetalisTop,Header,Footer,XtxNumbox,ReturnTop,RedemptionPopup,ImgShow,Pagination},
        data() {
            return {
                product_data:[],
                lingfu:'',
                mall_id:1,
                time:'',
                redem:'',
                left_img:'',  //图片放大组件原图
                right_img:'', //图片放大组件大图
                hideOnSinglePage: true,
                total: 1,
                pager: {},
                pagination: {
                    pageSize: 10,
                    p: 1
                },
                redem_order:[] //兑换名单
            }
        },
        computed:{
            ...mapState([
                'isAddress',
                'unLogin'
            ]),
            num: {
                get() {
                    return this.$store.state.num
                },
                set(val) {
                    return this.$store.state.num = val
                }
            },
            product_value: {
                get() {
                    return this.$store.state.product_value
                },
                set(val) {
                    return this.$store.state.product_value = val
                }
            }
            
        },
        watch:{
            pager: {
                deep: true,
                handler(nval, oval) {
                    this.getProductData()
                }
            },
        },
        methods:{
            changePage(pager) { // 分页器回调
                this.$emit('change', pager)
                this.pager = pager
            },
            //兑换须知
            productArticle(){
                const material = JSON.parse(localStorage.getItem('material'))
                productArticle({
                    id:material.product_xz
                }).then((res)=>{
                    if(res.code === 200){
                        localStorage.setItem('redem',JSON.stringify(res.data))
                        localStorage.setItem('redemTime',[res.ex_time])
                        this.redem = JSON.parse(localStorage.getItem('redem'))
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err)
                })
            },
            toLogin(){
                this.$message({
                    message: '请先登录后使用该功能！',
                    type: 'warning',
                    duration: 1500,
                    onClose:()=>{
                        this.$refs.login.open()
                    }
                })
            },
            Redeem() {
                if (this.isAddress) {
                    this.getProductData()
                } else {
                    this.$message({
                        message: '请先添加默认地址！',
                        type: 'warning',
                        duration: 1500,
                        onClose: () => {
                            this.$router.push({
                                path:'/pointsMall'
                            })
                            localStorage.setItem('mall_id', 2)
                            // 关闭模态框和遮罩
                            // const modal = document.querySelector('#myModal3');
                            // modal.classList.remove('show');
                            // const modalBackdrop = document.querySelector('.modal-backdrop');
                            // if (modalBackdrop) {
                            //     modalBackdrop.remove();
                            // }
                        }
                    })
                }
            },
            //获取商品详情
            getProductData() {
                getProductData({
                    id: this.$route.query.id,
                    page:this.pager.page
                }).then((res) => {
                    if (res.code == 200) {
                        this.product_data = res.data
                        this.product_value = Number(res.data.value).toFixed(0)
                        this.redem_order = res.data.user_order_list.data
                        this.total = res.data.user_order_list.total
                        this.pagination.pageSize = res.data.user_order_list.per_page
                        // 初始化配置图片放大组件
                        this.left_img = this.product_data ? (this.img_url + this.product_data.big_image_pc):''
                        this.right_img = this.product_data ? (this.img_url + this.product_data.big_image_pc):''
                    }
                }).catch((err) => {
                    console.log('捕获异常', err)
                })
            },
            //获取用户灵符信息
            getUserLingfuData() {
                getUserLingfuData().then((res) => {
                    if (res.code == 200) {
                        this.lingfu = res.data.available
                    }
                }).catch((err) => {
                    console.log('捕获异常', err)
                })
            },
            //获取数量
            getNum(val) {
                this.num = val
                this.product_value = val *  Number(this.product_data.value).toFixed(0)
            },
        },
        created(){
            this.time = parseInt(new Date().getTime() / 1000) + ''
            var redeTime = localStorage.getItem('redemTime')
            if(this.time > redeTime || !redeTime){
                console.log('我不存在')
                this.productArticle()
            }else{
                this.redem = JSON.parse(localStorage.getItem('redem'))
            }

            this.getProductData()
            if(localStorage.getItem('token')){
                this.getUserLingfuData()
            }
        }
    }
</script>

<style scoped>
.content{
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin-top: 110px;
}
.pro_top{
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
}
.pro_center{
    max-width: 1200px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 50px 0;
}
.content_bg{
    min-height: 500px;
    border: 1px solid #E8E8E8;
    position: relative;
    overflow: hidden;
}
.center_right{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 100%;
    padding-left: 50px;
}
.pro_name h4{
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #323232;
    margin-bottom: 15px;
    cursor: pointer;
}
.pro_name span{
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
}
.lf_num{
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #DF4040;
    line-height: 18px;
}
.redeemed{
    display: flex;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #A1A1A1;
    line-height: 18px;
}
.redeemed span{
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.red_num{
    max-width: 140px;
    background: #F9F9F9;
    padding: 6px 14px;
}
.nums{
    margin-right: 10px !important;
}
.stock{
    margin-left: 10px;
}
.roll_btn{
    background: #DF4040;
    padding: 13px 65px;
    transition: all 0.5s;
    cursor: pointer;
}
.roll_btn:hover{
    transition: all 0.5s;
    filter: brightness(1.2);
}
.roll_font{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
}
/* bottom */
.pro_bottom{
    margin-top: 40px;
}
.pro_class{
    background: #F9F9F9;
    padding: 18px 50px 0;
    display: flex;
    align-items: center;
    justify-content: start;
}
.pro_item{
    min-height: 100px;
}
.lang_class{
    margin: 0;
    margin-right: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    padding-bottom: 18px;
    cursor: pointer;
    transition: all 0.5s;
}
.lang_class:hover{
    color: #AC8747;
    border-bottom: 1px solid #AC8747;
}
.active{
    color: #AC8747 !important;
    border-bottom: 1px solid #AC8747 !important;
}
::v-deep(.pro_img > p) {
    margin: 0;
    text-align: center;
}

::v-deep(.pro_img > p > img) {
    /* max-width: 800px; */
}
/* 兑换须知 */
.redem_xz{
    padding: 20px;
}
/* 兑换名单 */
th,
td {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #463F34;
    padding-left: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
}
@media (max-width:1600px) {
    .pro_name h4{
        font-size: 25px;
    }
    .pro_name span{
        font-size: 14px;
    }
    .lf_num{
        font-size: 20px;
    }
    .redeemed span{
        font-size: 16px;
    }
}
@media (max-width:1400px) {
    /* 表格 */
    th,
    td {
        font-size: 12px;
    }
    
}
@media (max-width:1300px) {
    .content{
        margin-top: 100px;
    }
    .content_bg{
        min-height: 400px;
    }
    .lang_class{
        font-size: 14px;
    }
    .pro_class{
        padding: 12px 50px 0
    }
    .lang_class{
        padding-bottom:12px
    }
    .redeemed span{
        font-size: 14px;
    }
    .redeemed{
        font-size: 14px;
    }
}
@media (max-width:1200px) {
    

}
</style>