<template>
    <div class="zp_box">
        <img class="logo" src="/activity/turntable/img3/91.png">
        <div class="top_box">
            <div class="left_top ">
                <span class="tz_bg">1、领月饼活动，29日至6日，每天登入可
                    抽取月饼一枚，抽到后，系统自动将月饼
                    价值×15.5积分，存入您的积分余额。</span>
                <img src="/activity/turntable/img3/top_bg.png" width="100%">
            </div>
            <div class="left_top ">
                <span class="tz_bg">
                    2、双节同庆多充多送，29日至6日，玩家
                    进行充值时会根据VIP等级多充多送,赠送
                    充值直接到账。
                </span>
                <img src="/activity/turntable/img3/top_bg.png" width="100%">
            </div>
        </div>
        <div class="content">
            <div class="yy flex_box" :style="{ transform: rotate_deg, transition: rotate_transition } ">
                    <div class="item1" v-for="(item, index) in Arrays" :key="index"
                        :style="'transform: rotate(' + (360 / Arrays.length * index) + 'deg);'" >
                        <div class="yuebing_box">
                            <img :src="img_url + item.image" >
                            <!-- 转盘选中 -->
                            <img class="zp_active" v-if="hitDraw.position == index"  src="/activity/turntable/img3/zp_active.png" >  
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <div class="zp_map">
                        <!-- 转盘背景 -->
                        <img class="zp_bj" src="/activity/turntable/img3/zp_bg.png" >
                        <!-- 转盘线 -->
                        <img class="zp_bian" src="/activity/turntable/img3/zp_xian.png" >
                    </div>
            </div>
            <!-- 转盘指针 -->
            <img @click="hit" class="zp_zz" src="/activity/turntable/img3/zz.png">
            <!-- 转盘边框 -->
            <img class="zp_border" src="/activity/turntable/img3/zp1.png">
        </div>
        
        <!-- 六个月饼位置 -->
        <div class="yb_box">
            <div class="yb_item">
                <img src="/activity/turntable/img3/lrdh.png">
                <img class="left_item2" src="/activity/turntable/img3/hds.png">
                <img class="left_item3" src="/activity/turntable/img3/lxdh.png">
            </div>
            <div class="yb_item">
                <img src="/activity/turntable/img3/lrwr.png">
                <img class="right_item2" src="/activity/turntable/img3/shd.png">
                <img class="right_item3" src="/activity/turntable/img3/lxbp.png">
            </div>
        </div>
        <!-- 背景 -->
        <div class="zp_bg">
            <img class="" src="/activity/turntable/img3/bg1.jpg" width="100%">
            <img class="" src="/activity/turntable/img3/bg2.jpg" width="100%">
            <div class="footer flex_box">
                <span>{{ domain_name }}</span>
            </div>
        </div>
        <Winning2 :name="hitDraw"></Winning2>
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import { playTurntable, getPlaytimes } from '@/api/http.js'
import Winning2 from '@/components/Winning2.vue'
export default {
    name: 'Turntable3',
    components: {
        Winning2,
    },
    data() {
        return {
            scrollTop: 0, //列表滚动高度
            speed: 20, //滚动的速度
            // cat: 72, //总共5个扇形区域，每个区域约72度
            isAllowClick: true, //是否能够点击
            rotate_deg: 0, //指针旋转的角度
            rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
            copyHtml: '', //复制多一份防止滚动到后面出现空白
            timer: '',
            getUserInfo: [], //获取用户，活动信息
            hitDraw: [], //获取中奖结果
            userWin: [], //获取玩家中奖列表
            bool: true,
            flag: true,  //限制重置次数
            Arrays: [],
            domain_name:'' //当前页面的域名
        }
    },
    computed: {
        myList: {
            get() {
                return this.$store.state.myList
            },
            set(val) {
                return this.$store.state.myList = val
            }
        },
        cat() {
            return 360 / this.Arrays.length
        },
    },
    methods: {
        toPay() {
            // window.open(this.pay_url + '/member/wallet', '_blank');
            var domain = document.domain;
            window.open(window.location.protocol + '//' + domain + '/member/wallet', '_blank');
        },
        getQueryData() {
            let oSearchItem = {};
            let searchString = window.location.search;
            let reg = /[?&][^?&]+=[^?&]+/g;
            let arr = searchString.match(reg);
            if (arr) {
                arr.forEach((item) => {
                    let tempArr = item.substring(1).split("=");
                    let key = decodeURIComponent(tempArr[0]);
                    let value = decodeURIComponent(tempArr[1]);
                    oSearchItem[key] = value;
                });
            }
            return oSearchItem
        },
        // 获取抽奖次数接口
        getPlaytimes() {
            var token = this.getQueryData()
            getPlaytimes({
                token: token.token,
                id:this.$route.query.id
            }).then((res) => {
                if (res.code === 200) {
                    this.getUserInfo = res.data
                    this.Arrays = res.data.prize_list
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 5000,
                })
            })
        },
        // 转盘接口
        playTurntable() {
            console.log('我点击了')
            var token = this.getQueryData()
            playTurntable({
                token: token.token,
                id: this.$route.query.id
            }).then((res) => {
                if (res.code === 200) {
                    this.hitDraw = res.data
                    this.rotating();
                    console.log('我执行结束')
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
        winTc() {
            var win_tc = $('.win')
            var LayIndex = layer.open({
                type: 1,
                content: win_tc,
                anim: false,
                fixed: true,
                shade: 0.6,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                isOutAnim: false,
                area: [auto, auto],
                success: function (layero) {
                    const lq = $(layero).find('.btn');
                    lq.click(function(){
                        layer.close(LayIndex)
                    })
                }
            });
        },
        hit() {
            if (this.getUserInfo.isactive) {
                if (this.getUserInfo.take_num == 0) {
                    this.$message({
                        message: "机会已经用完了~",
                        type: 'warning',
                        duration: 1500
                    })
                    return;
                } else {
                    if (this.bool) {
                        this.bool = false
                        this.playTurntable()
                        // setTimeout(() => {
                        //     this.rotating();
                        // }, 500)
                    }
                }
            } else {
                this.$message({
                    message: '活动已结束',
                    type: 'warning',
                    duration: 1500
                })
            }
        },
        rotating() {
            if (!this.isAllowClick) return;
            this.isAllowClick = false;
            this.rotate_transition = "transform 3s ease-in-out";
            var rand_circle = 5; //默认多旋转5圈
            //   var winningIndex = Math.floor(Math.random() * 8); //获奖的下标   0-7   没有概率每个平均
            var winningIndex = this.hitDraw.position; //设置了概率的

            console.log('sdsdsd', this.hitDraw.position);
            var randomDeg = 360 - winningIndex * this.cat; //当前下标对应的角度    cat是总共7个扇形区域，每个区域约51度

            var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
            this.rotate_deg = "rotate(" + deg + "deg)";
            var that = this;
            setTimeout(function(){
               $('.zp_active').css('display','block') //旋转结束，开启选中样式
            },3000)
            setTimeout(function () {
                that.isAllowClick = true;
                $('.zp_active').css('display','none') //旋转结束，开启选中样式
                that.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
                that.rotate_transition = "";
                that.bool = true
                that.winTc()
                that.getPlaytimes()
            }, 3500);
        },

    },
    created() {
        var currentDomain = window.location.hostname;
        this.domain_name = currentDomain
        this.getPlaytimes()
    },
}
</script>

<style scoped>
.logo{
    position: absolute;
    left: 4%;
    top: 5%;
}
.footer{
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    background: #320202;
}
.footer span{
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #775546;
}
.show{
    display: block !important;
}
/* 活动通知 */
.top_box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 41%;
    right: 10%;
}

.left_top {
    position: relative;
    margin-right: 15px;
}

.tz_bg {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #FCF5BE;
    line-height: 16px;
    padding: 0 70px;
}


/* 模态框 */
.modal-title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #912C2C;
}

.modal-footer {
    justify-content: center;
}

/*  */
.zp_box {
    position: absolute;
    /* padding: 0 37% 0 19%; */
    width: 100%;
    height: 100%;
    min-width: 1900px;
}

.zp_bg {
    top: 0;
    left: 0;
    z-index: -99;
    position: absolute;
    width: 100%;
}

/* 转盘 */
.content {
    width: 100%;
    position: relative;
    top: 46.5%;
    left: -10.8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zp_border {
    position: absolute;
    left: 38.6%;
    top: -9%;
    z-index: 20;
}

.zp_bian {
    position: absolute;
    left: 0;
    z-index: 20;
}

.zp_zz {
    position: absolute;
    top: 22%;
    left: 47%;
    z-index: 21;
}

.zp_bj {
    
}

.zp_active {
    position: absolute;
    z-index: -1;
    top: -2%;
    left: -49%;
    display: none;
}
.zp_map{
    position: relative;
}
.yy{
    height: 100%;
    position: relative;
    left: 2px;
}
.item1{
    position: absolute;
    text-align: center;
    height: 100%;
    z-index: 20;
}
.yuebing_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.yuebing_box span{
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #FCF5BE;
}
/*  */
.yb_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 46%;
    position: absolute;
    left: 17%;
    top: 49%;
}
.yb_item{
    display: flex;
    flex-direction: column;
}
.left_item2{
    position: relative;
    top: -100px;
    right: 45px;
}
.left_item3{
    position: relative;
    top: -200px;
}
.right_item2{
    position: relative;
    top: -100px;
    left: 45px;
}
.right_item3{
    position: relative;
    top: -200px;
}
</style>