<template>
  <div class="ranking">
    <div class="container">
        <div class="tab_tit">
            <!--点击设置curId的值  如果curId等于0，第一个a添加cur类名，如果curId等于1，第二个a添加cur类名，以此类推。添加了cur类名，a就会改变样式 @click,:class ,v-show这三个是vue常用的指令或添加事件的方式-->
            <a href="javascript:;" @click="curId=0" :class="{'active':curId===0}" class="rank_item">昨日热游兑换排行榜<span>(每日24:00更新)</span></a>
            <a href="javascript:;" @click="curId=1" :class="{'active':curId===1}" class="rank_item">每周热游兑换排行榜<span>(每周一更新)</span></a>
            <a href="javascript:;" @click="curId=2" :class="{'active':curId===2}" class="rank_item">新游兑换排行榜<span>(每日24:00更新)</span></a>
        </div>
        <div class="tab_con">
            <!--根据curId的值显示div,如果curId等于0，第一个div显示，其它三个div不显示。如果curId等于1，第二个div显示，其它三个div不显示。以此类推-->
            <div class="rank_lang" v-show="curId===0">
                <div class="rank_box" v-for="game in games" :key="game.id">
                    <img class="king" v-if="game.id == 1" src="/ranking/img/first.png">
                    <img class="king" v-if="game.id == 2" src="/ranking/img/seccon.png">
                    <img class="king" v-if="game.id == 3" src="/ranking/img/third.png">
                    <router-link to="/gameNavigation"><img class="rank_png" :src="game.img" width="100%"></router-link>
                        <div class="container title_box">
                            <div class="text_box">
                                <div class="title_top">
                                    <router-link to="/gameNavigation"><h5>{{game.name}}</h5></router-link>
                                    <span>昨日兑换总额</span>
                                </div>
                                <div class="title_text">
                                    <span>{{game.title}}</span>
                                    <span>{{game.sum}}</span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="rank_lang" v-show="curId===1">
                <div class="rank_box" v-for="game in games" :key="game.id">
                    <img class="king" v-if="game.id == 1" src="/ranking/img/first.png">
                    <img class="king" v-if="game.id == 2" src="/ranking/img/seccon.png">
                    <img class="king" v-if="game.id == 3" src="/ranking/img/third.png">
                    <router-link to="/gameNavigation"><img class="rank_png" :src="game.img" width="100%"></router-link>
                        <div class="container title_box">
                            <div class="text_box">
                                <div class="title_top">
                                    <h5>{{game.name}}</h5>
                                    <span>昨日兑换总额</span>
                                </div>
                                <div class="title_text">
                                    <span>{{game.title}}</span>
                                    <span>{{game.sum}}</span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="rank_lang" v-show="curId===2">
                <div class="rank_box" v-for="game in games" :key="game.id">
                    <img class="king" v-if="game.id == 1" src="/ranking/img/first.png">
                    <img class="king" v-if="game.id == 2" src="/ranking/img/seccon.png">
                    <img class="king" v-if="game.id == 3" src="/ranking/img/third.png">
                    <img class="rank_png" :src="game.img" width="100%">
                        <div class="container title_box">
                            <div class="text_box">
                                <div class="title_top">
                                    <h5>{{game.name}}</h5>
                                    <span>昨日兑换总额</span>
                                </div>
                                <div class="title_text">
                                    <span>{{game.title}}</span>
                                    <span>{{game.sum}}</span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 背景 -->
    <img class="ranking_bg" src="/ranking/img/ranking.png" width="100%">
  </div>
</template>

<script>
    export default {
        name:'Ranking',
        data() {
            return {
                curId:0,
                games:[
                    {id:1,name:'光暗传说',title:'无限刀，专属',img:'/ranking/img/p1.png',sum:20124},
                    {id:2,name:'光暗传说',title:'无限刀，专属',img:'/ranking/img/p2.png',sum:20124},
                    {id:3,name:'光暗传说',title:'无限刀，专属',img:'/ranking/img/p3.png',sum:20124},
                    {id:4,name:'光暗传说',title:'无限刀，专属',img:'/ranking/img/p4.png',sum:20124},
                    {id:5,name:'光暗传说',title:'无限刀，专属',img:'/ranking/img/p5.png',sum:20124},
                ]
            }
        },
    }
</script>

<style scoped>
a{
    text-decoration: none;
}
.ranking{
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 40px 0;
}
.ranking_bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.rank_item{
    margin-right: 60px;
    text-decoration: none;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    transition: all 0.5s;
}
.rank_item span{
    font-size: 14px;
}
.rank_item:hover{
    color: #FBD697;
    transition: all 0.5s;
}
.active{
    color: #FBD697;
}
.tab_con{
    margin-top: 30px;
}
/* 盒模块 */
.rank_lang{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}
.rank_box{
    width: 18%;
    position: relative;
    margin-right: 32px;
}
.rank_box:nth-last-of-type(1){
    margin: 0;
}
.title_box{
    background: #FFFFFF;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.text_box{
    padding: 15px 0;
}
.title_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.title_top h5{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #343434;
}
.title_top span{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #828282;
}
.title_text{
    display: flex;
    justify-content: space-between;
}
.title_text span:nth-of-type(1){
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #828282;
}
.title_text span:nth-of-type(2){
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #DF0600;
}
.king{
    position: absolute;
    top: 0;
    left: 5%;
    width: 20%;
}
@media (max-width:1750px) {
    .ranking_bg{
        width: 105%;
    }
}
@media (max-width:1600px) {
    .ranking_bg{
        width: 120%;
    }
    .rank_item{
        font-size: 18px;
    }
    .rank_item span{
        font-size: 12px;
    }
}
@media (max-width:1300px) {
    .ranking_bg{
        width: 135%;
    }
    .rank_box{
        margin-right: 25px;
    }
    .title_top h5{
        font-size: 14px;
    }
    .title_top span{
        font-size: 12px;
    }
    .title_text span:nth-of-type(1){
        font-size: 12px;
    }
    .title_text span:nth-of-type(2){
        font-size: 12px;
    }
    .rank_item{
        font-size: 16px;
    }
}
@media (max-width:1100px) {
    .rank_box{
        margin-right: 20px;
    }
    .rank_item{
        margin-right: 30px;
        font-size: 15px;
    }
    .ranking_bg{
        width: 145%;
    }
}
</style>