<template>
    <div>
        <Header></Header>
        <div class="gift_box">
            <div class="container">
                <MemberTop></MemberTop>
                <div class="gift_bottom">
                    <div class="gift_top">
                        <h5 class="gift_title gift_font_color">会员礼包</h5>
                        <div class="integral">
                            <a class="jf_btn btn_color jf_btn_font">获取积分</a>
                            <div class="jf_title">
                                <a class="gift_font_color">积分明细</a>
                                <div class="fgx"></div>
                                <a class="gift_font_color">积分规则</a>
                            </div>
                        </div>
                    </div>
                    <div class="gift_content" >
                        <div class="content_box" v-for="w in welfare" :key="w.id">
                            <div class="content_top flex_box">
                                <div class="content_tip tip_font">会员福利</div>
                                <h2 class="money_color">{{ w.value }}<span>元</span></h2>
                                <!-- 背景 -->
                                <img class="content_bg" src="/WelfareGift/img/flbg.png">
                            </div>
                            <div class="msg_box">
                                <div class="msg_title">
                                    <h5>{{w.value}}充值</h5>
                                    <h6>领取条件:<span>{{ w.flag }}</span></h6>
                                </div>
                                <a class="btn_color liqv_btn liqv_btn_font text_ellipsis">领取福利</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 背景 -->
            <img class="gift_bg" src="/WelfareGift/img/bg.jpg" width="100%">
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '../components/Header.vue'
    import MemberTop from '../components/MemberTop.vue'
    import Footer from '../components/Footer.vue'
    export default {
        name:'WelfareGift',
        components:{MemberTop, Header, Footer},
        data() {
            return {
                welfare:[
                    {id:1,value:50,flag:'VIP6'},
                    {id:2,value:50,flag:'VIP6'},
                    {id:3,value:50,flag:'VIP6'},
                    {id:4,value:50,flag:'VIP6'},
                    {id:5,value:50,flag:'VIP6'},
                    {id:6,value:50,flag:'VIP6'},
                ]
            }
        },
    }
</script>

<style scoped>
    h1,h2,h3,h4,h5,h6{
        margin: 0;
    }
    .gift_box{
        position: relative;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 10px 0;
        margin-top: 110px;
        background: #18181a;
    }
    .gift_bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    /* gift_bottom */
    .gift_bottom{
        position: relative;
        z-index: 2;
    }
    .gift_bottom{
        margin-top: 10px;
        background: #FAFAFA;
        border-radius: 4px;
        
    }
    .gift_top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px ;
        border-bottom: 1px solid rgba(172,135,71,0.2);
    }
    
    .gift_title{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    .gift_font_color{
        color: #AC8747;
    }
    .jf_btn{
        padding: 10px 12px;
        border-radius: 4px;
    }
    .jf_btn_font{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FAFAFA;
        text-decoration: none;
    }
    .integral{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .jf_title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
    }
    .jf_title a{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-decoration: none;
    }
    .fgx{
        background: #DBD5CC;
    }
    /* 会员福利模块 */
    .gift_content{
        padding: 20px 30px 30px;
        display: flex;
        flex-wrap: wrap;
    }
    .content_box{
        width: 19.2%;
        background: rgba(221,154,154,0);
        border: 1px solid #EADDC7;
        border-radius: 4px;
        margin-top: 10px;
        margin-right: 10px;
    }
    .content_top{
        position: relative;
        overflow: hidden;
        padding: 10px 0;
    }
    .content_bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10;
        width: 100%;
    }
    .content_tip{
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, #E13A24, #FA634E);
        border: 1px solid #EADDC7;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 5px;
    }
    .tip_font{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFEC1;
    }
    .money_color{
        font-size: 110px;
        font-family: Helvetica Neue LT Std;
        font-weight: 900;
        background: linear-gradient(0deg, #AC823A 0%, #DCB97D 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .content_top h2{
        display: flex;
        align-items: flex-end;
    }
    .content_top h2 span{
        font-size: 20px;
        padding-bottom: 20px;
    }
    
    .msg_box{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 10px;
    }
    .liqv_btn{
        padding: 5px 15px;
        border-radius: 4px;
    }
    .liqv_btn_font{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFEFE;
        text-decoration: none;
    }
    .msg_title h5{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 5px;
    }
    .msg_title h6{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
    .msg_title h6 span{
       color: #BB2929;  
    }
    @media (max-width:1600px) {
        /* 会员福利 */
        .content_box{
            width: 19.1%;
        }
        .money_color{
            font-size: 80px;
        }
        .content_top h2 span{
            padding-bottom: 10px;
        }
        .jf_btn{
            padding: 5px 12px;
        }
    }
    @media (max-width: 1300px){
        .gift_box{
            margin-top: 100px;
        }
        .gift_title{
            font-size: 16px;
        }
        .jf_btn_font{
            font-size: 12px;
        }
        .jf_title a{
            font-size: 12px;
        }
        /* 会员福利 */
        .content_box{
            width: 19%;
        }
        .gift_content{
            padding: 10px 30px 30px;
        }
        .content_top{
            padding: 18px 0;
        }
        .money_color{
            font-size: 60px;
        }
        .content_top h2 span{
            font-size: 15px;
        }
        .msg_title h5{
            font-size: 16px;
        }
        .msg_title h6{
            font-size: 12px;
        }
        .liqv_btn{
            padding: 2px 10px;
        }
        .liqv_btn_font{
            font-size: 12px;
        }
        .content_tip{
            padding: 2px;
        }
    }
    @media (max-width:1100px) {
        .gift_top{
            padding: 20px;
        }
        .gift_content[data-v-6cf2a230] {
            padding: 10px 20px 30px;
        }
       /* 会员福利 */
       .content_box{
            margin-top: 8px;
            margin-right: 8px;
       }
       .money_color{
            font-size: 50px;
        }
        .content_top h2 span{
            font-size: 12px;
        }
        .content_top {
            padding: 15px 0;
        }
        .msg_title h5 {
            font-size: 14px;
        }
        .liqv_btn[data-v-6cf2a230] {
            padding: 2px 5px;
        }

    }
</style>