<template>
    <div class="sign_box">
        <div class="sign_tc">
            <div class="tc_title">
                <h5>签到成功</h5>
                <span>恭喜您获得{{sigin_eg}}积分</span>
            </div>
            <img class="sign_btn" :src="material.sign ? img_url + material.sign.btn : ''" >
            <!-- 背景 -->
            <img class="sign_bg" :src="material.sign ? img_url + material.sign.bj : ''">
        </div> 
    </div>
    
</template>

<script>
import { mapState } from 'vuex';
    export default {
        name:'SigIn',
        data() {
            return {
            }
        },
        computed:{
            ...mapState([
                'sigin_eg',
                'material'
            ])
        },
        methods:{
 
        },
        
    }
</script>

<style scoped>
    .sign_box{
        /* display: none; */
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .sign_tc{
        display: none;
    }
    .sign_bg{
        position: relative;
        top: 0;
        left: 0;
        z-index: -10;
    }
    .tc_title{
        position: absolute;
        top: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: '微软雅黑';
        color: #9c3423;
        left: 50%;
        transform: translateX(-50%);
    }
    .tc_title h5{
        font-size: 30px;
    }
    .tc_title{
        font-size: 25px;
    }
    .sign_btn{
        position: absolute;
        bottom: 0;
        cursor: pointer;
        transition: all 0.5s;
        left: 50%;
        transform: translateX(-50%);
    }
    .sign_btn:hover{
        transition: all 0.5s;
        filter: brightness(1.2);
    }
    @media (max-width:1600px) {
        /* 弹窗 */
        .tc_title{
            top:15vh;
            font-size: 20px;
        }
        .tc_title h5{
            font-size: 25px;
        }
    }
</style>