<template>
  <div>
    <a href="https://www.topm2.com/" target="_bank"><img class="engine_xfc" src="/banner/img/yq.png"></a>
  </div>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        name:'ReportWindow',
        data() {
          return {
          }
        },
        computed:{
          ...mapState([
            'getClient',
            'material'
          ])
        },
        methods:{
        
        },
        
    }
</script>

<style scoped>
    .engine_xfc{
      position: fixed;
      right: 1%;
      top: 55%;
      z-index: 10;
      cursor: pointer;
      width: 6%;
    }
</style>