<template>
  <div>
    <div class="open_active">
      <a @click="toActive">
        <img class="ac_float" v-lazy="material.pc_index_play && imgUrl ? img_url + material.pc_index_play.img : ''">
      </a>
    </div>
    <div class="home86" v-if="img_png">
        <img class="ac_float" :src="'/banner/img/'+ img_png +'.png'">
    </div>
  </div>

</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import { mapState } from 'vuex';
import {getMaterialData} from '@/api/http.js'
export default {
    name:'OpenActive',
    data() {
      return {
        url:'/member/wallet',
        tab:'_self',
        LayIndex:'',
        Layerwelcome:'',
        img_png:'',
        imgUrl:false,
        active:{}
      }
    },
    computed:{
      ...mapState([
        'getClient',
        'material'
      ])
    },
    watch:{

    },
    methods:{
      async getMaterialData() {
        try {
          const res = await getMaterialData()
          if (res.code === 200) {
            this.active = res.data
            if(res.data.pc_index_play.img){
              console.log('我要弹窗了')
              this.startShow(res.data)
              if(this.imgUrl){
                this.openActive() //活动弹窗
              }
            }else{
              if (localStorage.getItem('w86') == 1 && localStorage.getItem('img_png')) {
               this.welcome(0.6)
              }
            }
            if(res.data.pc_index_play ? res.data.pc_index_play.jump_url !== "" : 0){
              this.url = res.data.pc_index_play ? res.data.pc_index_play.jump_url :''
              this.tab = '_blank'
            }
          }
        } catch (err) {
          console.log("捕获异常", err)
        }
      },
      // 首页活动弹窗
      openActive(){
        var that = this
        var open_active = $('.open_active')
        this.LayIndex = layer.open({
          type: 1,
            content: open_active,
            anim: false,
            fixed: true,
            shade: 0.6,
            title: 0,
            scrollbar: false,
            shadeClose: false,
            isOutAnim: false,
            area: [auto, auto],
            success:function(layero,index){
              // 活动限定弹窗逻辑（抽过只弹一次）
              if(localStorage.getItem('token')){
                const activity = JSON.parse(localStorage.getItem('userInfo')).activity
                if(!activity){
                  localStorage.setItem('has_squard','1')
                }
              }
              // 
              layer.style(index, {
                  top: '50%',
                  left:'50% ',
                  transform:'translate(-50%,-50%)'
              });
              window.onresize = () => {
                  return (() => {
                    layer.style(index, {
                        transform:'none'
                    });
                    // layer.iframeAuto(index); // 让 iframe 高度自适应
                    // that.offset(); // 重新自适应弹层坐标
                  })();
              };
              $('.layui-layer-content').css('height','100%')
              $('.layui-layer-page .layui-layer-content').css("overflow", "visible")
              
              if (localStorage.getItem('w86') == 1 && localStorage.getItem('img_png') && localStorage.getItem('img_png') != 'hd') {
                that.welcome()
                // localStorage.setItem('w86','0')
              }
              
            },
            end: function(){ 
              
            }  
        });
      },
      welcome(shade){
        var that = this
        var home86 = $('.home86')
        this.Layerwelcome = layer.open({
          type: 1,
            content: home86,
            anim: false,
            fixed: true,
            shade: shade?shade:false,
            title: 0,
            scrollbar: false,
            shadeClose: false,
            isOutAnim: false,
            area: [auto, auto],
            success:function(layero,index){
              layer.style(index, {
                  top: '50%',
                  left:'50% ',
                  transform:'translate(-50%,-50%)'
              });
              window.onresize = () => {
                  return (() => {
                    layer.style(index, {
                        transform:'none'
                    });
                    // layer.iframeAuto(index); // 让 iframe 高度自适应
                    // that.offset(); // 重新自适应弹层坐标
                  })();
              };
              $('.layui-layer-content').css('height','100%')
              $('.layui-layer-page .layui-layer-content').css("overflow", "visible")
              // that.$router.push({
              //     path:'/'
              // })
            },
            end: function(){ 
              
            }  
        });
      },
      toActive(){
        if(this.material.pc_index_play.jump == 2){
            if(localStorage.getItem('token')){
              this.funRouter()
            }else{
              // alert('请先登入')
              layer.close(this.LayIndex)
              this.$emit('openLogin')
            }
        }
        this.$czc( 'PC首页悬浮窗', '点击', '访问活动页面')
      },
      funRouter(){
        if(this.material.pc_index_play.hd_id){
          var routeUrl = this.$router.resolve({
            path:this.url,
            query:{
              id:this.material.pc_index_play.hd_id
            }
          })
        }else{
          var routeUrl = this.$router.resolve({
            path:this.url,
          })
        }
        window.open(this.url.includes('http') ? this.url : routeUrl.href, this.tab);
      },
      // 初始化显示悬浮窗
      startShow(red){
        const type = red.pc_index_play ? red.pc_index_play.alert_type : 0
        if( type == 1 ){
          // 一直弹
          // console.log('一直弹')
          return  this.imgUrl = true

          
        }
        else if(type == 2 ){
          if(localStorage.getItem('token')){
            return this.imgUrl = true
          }else{
            return this.imgUrl = false
          }
        }
        else if(type == 3 ){
          if(!localStorage.getItem('token')){
            return this.imgUrl = true
          }else{
            return this.imgUrl = false
          }
        }
        else if(type == 4){
          const dateStamp = new Date(new Date().toLocaleDateString()).getTime()+86400000;
          const time = localStorage.getItem('nowTime')*1
          console.log(time >= dateStamp)
          if(time >= dateStamp){
            return this.imgUrl = true
          }else{
            return this.imgUrl = false
          }
        }
        else if(type == 5){
          // 临时活动弹窗逻辑修改
          if(localStorage.getItem('w86') == 1){ //如果是/wpath进入
            if(localStorage.getItem('img_png') == 'hd'){ //并且是特殊渠道进入
              if(localStorage.getItem('token')){
                const activity = JSON.parse(localStorage.getItem('userInfo')).activity
                if(!activity){ //抽过
                  if(localStorage.getItem('has_squard') == 1){ // 表示抽过且已经弹过一次了就不弹了
                    return this.imgUrl = false
                  }else{
                    return this.imgUrl = true
                  }
                }else{
                  return this.imgUrl = true
                }
              }else{
                return this.imgUrl = true
              }
            }else{ //地址上参数t为其他情况时默认走登录才弹
              if(localStorage.getItem('token')){
                return this.imgUrl = true
              }else{
                return this.imgUrl = false
              }
            }
          }else{ //非渠道进入，只有登录弹
            if(localStorage.getItem('token')){
              const activity = JSON.parse(localStorage.getItem('userInfo')).activity
                if(!activity){ //抽过
                  if(localStorage.getItem('has_squard') == 1){ // 表示抽过且已经弹过一次了就不弹了
                    return this.imgUrl = false
                  }else{
                    return this.imgUrl = true
                  }
                }else{
                  return this.imgUrl = true
                }
            }else{
              return this.imgUrl = false
            }
          }
        }
      }
    },
    created(){
        this.getMaterialData()
        if(localStorage.getItem('w86') == 1){
          if(localStorage.getItem('img_png')){
            this.img_png = localStorage.getItem('img_png')
          }
           // 获取当前时间的时间戳
          let currentDate = new Date();
          
          if(localStorage.getItem('threeDaysLaterTimeStamp') == '' || localStorage.getItem('threeDaysLaterTimeStamp') == undefined){
            // 计算3天后的时间戳
            let threeDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 3));
            let threeDaysLaterTimeStamp = threeDaysLater.getTime();
            localStorage.setItem('threeDaysLaterTimeStamp',threeDaysLaterTimeStamp)
          }else{
            if(currentDate > localStorage.getItem('threeDaysLaterTimeStamp')){
              localStorage.setItem('w86','0')
              localStorage.setItem('threeDaysLaterTimeStamp','')
            }
          }

        }
    }
   

}
</script>

<style scoped>
  .open_active{
    display:none;
  }
  .home86{
    display: none;
  }
  .ac_float{
    height: 50vh ;
    width: 100%;
  }

</style>