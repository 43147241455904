<template>
  <div>
    <img class="xfc" @click="sup_tc" v-lazy="img_url + material.pc_sl_img">
  </div>
</template>

<script>
    import { auto } from "@popperjs/core";
    import layer from "layui-layer";
    import { mapState } from "vuex";
    export default {
        name:'Suspendedwindow',
        data() {
          return {
            img:'/supended/img/xfc.png'
          }
        },
        computed:{
          ...mapState([
            'getClient',
            'material'
          ])
        },
        methods:{
          sup_tc(){
            var sm_tc = $('.sm_tc')
            var LayIndex = layer.open({
                type: 1,
                content: sm_tc,
                anim: false,
                fixed: true,
                shade: 0.6,
                title: 0,
                scrollbar: true,
                shadeClose: true,
                isOutAnim: false,
                area: [auto, auto],
                success:function(){
                    
                }
            });
          }
        },
        
    }
</script>

<style scoped>
    .xfc{
        position: absolute;
        left: 2%;
        z-index: 10;
        cursor: pointer;
        top: 35%;
    }

    @media (max-width:1900px) {
      .xfc{
        left: 1%;
        width: 5%;
      }
    }
    @media (max-width:1850px) {
      .xfc{
        left: 10px;
        width: 4%;
      }
    }
    @media (max-width:1780px) {
      .xfc{
        left: 10px;
        width: 3%;
      }
    }
    @media (max-width:1750px) {
      .xfc{
        left: 5px;
        width: 40px;
      }
    }
    @media (max-width:1740px) {
      .xfc{
        left: 5px;
        width: 40px;
      }
    }
    @media (max-width:1690px) {
      .xfc{
        left: 1%;
        width: 4%;
      }
    }
    @media (max-width:1480px) {
      .xfc{
        left: 0%;
        width: 3%;
      }
    }
    @media (max-width:1400px) {
      .xfc{
        left: 2%;
        width: 4%;
      }
    }
    @media (max-width:1300px) {
      .xfc{
        left: 0;
        width: 3%;
      }
    }
    @media (max-width:1230px) {
      .xfc{
        left: 0;
        width: 30px;
      }
    }
    @media (max-width:1200px) {
      .xfc{
        left: 0;
        width: 5%;
      }
    }
    @media (max-width:1100px) {
      .xfc{
        left: 1%;
        width: 4%;
      }
    }
</style>