<template>
  <div>
    <div class="pay_tc">
        <div class="pay_box">
            <div class="pay_top flex_box">
                <img class="pay_logo" src="/WalletRecharge/img/tc_logo.png">
            </div>
            <div class="pay_money">订单金额:{{ Number(pay_money).toFixed(0) }}元</div>
            <span class="pay_id">订单号:{{ orderID }}</span>
            <div class="ewm_box">
                <img alt="订单生成中，请稍后···" class="pay_ewm" v-lazy="pay_ewm" width="230px">
            </div>
            <div class="pay_type">请使用{{pay_type}}扫码支付</div>
        </div>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name:'PayCode',
        computed:{
            ...mapState([
                'pay_ewm',
                'pay_money',
                'pay_type',
                'orderID'
            ])
        },
        methods:{
            
        }
    }
</script>

<style scoped>
    /* 弹窗 */
    .pay_tc{
        display: none;
    }
    .pay_box{
        width: 450px;
        height: 520px;
        background: #FFFFFF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding-bottom: 50px;
    }
    .ewm_box{
        padding: 15px;
        background: #F5F5F5;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
    }
    .pay_top{
        padding: 25px 0;
        border-bottom: 1px solid #F2F2F2;
    }
    .pay_money{
        margin: 30px 0 10px;
        font-size: 25px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #343434;
    }
    .pay_id{
        font-size: 12px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #343434;
        margin-bottom: 10px;
    }
    .pay_type{
        margin-top: 15px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #343434;
    }
    @media (max-width:1600px) {
        .pay_box{
            width: 400px;
            height: 450px;
        }
        .pay_logo{
            width: 80%;
        }
        .pay_money{
            margin: 20px 0 10px;
            font-size: 20px;
        }
        .pay_ewm{
            width:180px
        }
        .pay_type{
            font-size: 14px;
        }
    }
    @media (max-width:1300px) {
        .pay_box{
            width: 350px;
            height: 400px;
        }
        .pay_money{
            font-size: 18px;
        }
        .ewm_box{
            padding: 10px;
        }
        .pay_ewm{
            width: 165px;
        }
    }
    @media (max-width:1100px) {
        .pay_box{
            width: 265px;
            height: 320px;
        }
        .pay_top{
            padding: 20px 0;
        }
        .pay_logo{
            width: 60%;
        }
        .pay_money{
            margin: 15px 0 10px;
            font-size: 15px;
        }
        .pay_ewm{
            width: 130px;
        }
        .pay_type[data-v-2e360e35] {
            font-size: 12px;
            margin-top: 10px;
        }
    }
</style>