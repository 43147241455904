<template>
    <div>
        <!-- 领取福利弹窗 -->
        <div ref="receive_tc" class="receive">
            <div class="tab_title">
                <!--点击设置curId的值  如果curId等于0，第一个a添加cur类名，如果curId等于1，第二个a添加cur类名，以此类推。添加了cur类名，a就会改变样式 @click,:class ,v-show这三个是vue常用的指令或添加事件的方式-->
                <a href="javascript:;" @click="reissueForm.reissue_type = 1"
                    :class="{ 'rec_active': reissueForm.reissue_type === 1 }" class="game_title">会员兑换</a>
                <a href="javascript:;" @click="reissueForm.reissue_type = 2"
                    :class="{ 'rec_active': reissueForm.reissue_type === 2 }" class="game_title">积分兑换</a>
                <a href="javascript:;" @click="reissueForm.reissue_type = 5"  v-if="userInfo.m_user_mouth"
                :class="{ 'rec_active': reissueForm.reissue_type === 5 }" class="game_title">包服卡兑换</a>
                <!-- <a href="javascript:;" @click="reissueForm.reissue_type = 4"
                    :class="{ 'rec_active': reissueForm.reissue_type === 4 }" class="game_title">月卡兑换</a> -->
                <img class="colsing close" src="/login/gb.png" @click="fun2" alt="">
            </div>
            <div>
                <div class="rec_content">
                    <div class="rec_top">
                        <p>您正在进行：{{ game_name }}的会员充值服务</p>
                            <countDown class="count" v-if="is_under == 1" :endTime="endTime" :endText="endText" ></countDown>
                    </div>
                    <div class="surplus">
                        <div class="container">
                            <div class="top_text">
                                <h5>您剩余可用{{reissueForm.reissue_type == 1 ? '储值点' :reissueForm.reissue_type == 2 ?  '积分':reissueForm.reissue_type == 5 ? '余额' : '月卡点' }}：
                                    <span>{{reissueForm.reissue_type == 1 ?  store :reissueForm.reissue_type == 2 ? point : reissueForm.reissue_type == 5 ? baofu : yueka}}</span></h5>
                                <span class="tishi" style="margin: 0;" v-if="reissueForm.reissue_type == 5">注：每日只能兑换一次，每次兑换货币总额必须等于100<br>需在会员有效期内可使用包服卡兑换</span>
                            </div>
                        </div>
                    </div>
                    <div class="rec_box ">
                        <div class="rec_left">
                            <img class="exchange_img" v-lazy="img_url + img">
                            <span class="tishi">请正确填写游戏账号，游戏账号不能小于4位或大于10位(请手动输入游戏账号，避免造成领取不到充值的情况)</span>
                            <span class="tishi mt_20">注：请再次确认您的订单信息，锁定信息后无法更改，望您游戏愉快!<br>
                                会员资格严禁倒卖/转让/借用，不可兑换现金，请妥善保管，遗失、泄露等做封禁处理且官方不做补偿!</span>
                        </div>
                        <div>
                            <div class="exchange_right  class_box">
                                <div class="rec_form ">
                                    <div class="user_box">
                                        <label class="rec_title lang_point" title="游戏账号：">游戏账号：</label>
                                        <div class="lb_1">
                                            <input class="zhanhao" type="text" maxlength="10" minlength="4"
                                                v-model="reissueForm.account">
                                        </div>
                                    </div>
                                    <div class="rec_form" v-for="item in fl_money" :key="item.id">
                                        <div class="exchange_dami">
                                            <span class="rec_title dami lang_one" :title="'兑换' + item.gameCurrencyName">兑换{{ item.gameCurrencyName }}：</span>
                                            <div>
                                                <div class="lb_2" v-show="reissueForm.reissue_type == 1">
                                                    <a ref="dami" class="dami_btn dami_font item_hover"
                                                        :class="{ 'hit_item': moneyCont[item.partition_id] ? moneyCont[item.partition_id].index == index : index == -5 }"
                                                        v-for="(item2, index) in money_btn" :key="item2.id" :value="item2.value"
                                                        @click="tabMoney(item, index, item2.value)">
                                                        {{ item2.value }}
                                                        <img class="hit" :class="{ 'btn_active': moneyCont[item.partition_id] ? moneyCont[item.partition_id].index == index : index == -5 }"
                                                            src="/gamelist/img/hit.png">
                                                    </a>
                                                </div>
                                                <div class="qita">
                                                    <div class="qita_box">
                                                        <div class="money_qita qita_title">请输入金额</div>
                                                        <input type="text" class="form_qita" @input="inputBlur(item)"
                                                            v-model="inputMoney[item.partition_id]">
                                                    </div>
                                                    <span class="tishi">正确填写货币数量(请勿填写小数点)，若无需此货币金额请填写0";</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    
                                </div>
                                
                            </div>
                            <div class="sub_box">
                                <div class="submit sub_font submit_color submit_font_color"
                                    @click="fl_submit()">提交</div>
                                <div class="close submit sub_font qvxiao submit_font_color" ref="closed" @click="fun2">取消</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import { supplyAgain, getGameCurrencyList,getUserYueka,getMouth } from '@/api/http.js'
import countDown from './countDown.vue';
export default {
    name: 'ReceiveBenefits',
    props: ['id', 'game_id','game_name', 'img','under_time','is_under'],
    data() {
        return {
            flag: true,
            account: '', //游戏账号
            money_btn: [
                { id: 1, value: 50 },
                { id: 2, value: 100 },
                { id: 3, value: 200 },
                { id: 4, value: 300 },
                { id: 5, value: 500 },
                { id: 6, value: 1000 },
            ],
            isShow: '',
            fl_money: [], //福利兑换货币种类
            /* 提交补发表单 */
            reissueForm: {
                /* 默认储值兑换 */
                reissue_type: 1
            },
            /* 选中状态存放 */
            moneyCont: {},
            /* input 输入框 */
            inputMoney: {},
            endTime:'',
			//  console.log(endTime)=>得到毫秒  1658030420 可在方法里面放入后台数据,new Date('放后台数据').getTime() / 1000 +  ' ',但要记得调用方法
			endText:'',
            yueka:'', //月卡点数
            baofu:0,//包服卡点数
        }
    },
    components:{
        countDown
    },
    watch: {
        under_time: {
            deep:true,
            immediate:true,
            handler(nval, oval) {
                this.endTime = new Date(nval).getTime() / 1000 +  ' '
            }
        }
    },
    computed: {
        ...mapState([
            'service_id',
            'userStore',
            'userPoint',
            'userInfo'
        ]),
        store() {
            return Math.round(this.userStore.available)
        },
        point() {
            return Math.round(this.userPoint.available)
        },
        under_off:{
            get(){
                return this.$store.state.under_off
            },
            set(val){
                return this.$store.state.under_off = val
            }
        }
    },
    methods: {
         // 获取用户包服卡点数接口
         getMouth(){
            getMouth().then((res)=>{
                if(res.code == 200){
                    if(res.data.uniform){
                        this.baofu = 100
                    }else{
                        this.baofu = 0
                    }
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        },
        // 获取用户月卡点数接口
        getUserYueka(){
            getUserYueka().then((res)=>{
                if(res.code == 200){
                    this.yueka = Number(res.data.available).toFixed(0)
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        },
        //获取游戏货币列表
        getGameCurrencyList(id) {
            getGameCurrencyList({
                id
                //   id:1
            }).then((res) => {
                if (res.code === 200) {
                    this.fl_money = res.data
                    this.endText = res.ex_time
                    /* 如果游戏有货币往表单添加渠道 */
                    if (res.data.length > 0) {
                        this.reissueForm.game_reissue = res.data[0].platform
                    }else{
                        this.$message({
                            message: '当前游戏还未开启货币兑换，敬请期待~',
                            type: 'warning',
                            duration: 1500
                        })
                    }
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: '当前游戏还未开启货币兑换，敬请期待~',
                    type: 'warning',
                    duration: 1500
                })
            })

        },
        open(game_id){
            if(localStorage.getItem('token') && this.userInfo.user_idcard){
                this.getGameCurrencyList(game_id)
            }
        },

        tabMoney(item, index, money) {
            /* 对索引进行赋值实现每个元宝列表独立 */
            this.moneyCont[item.partition_id] = { index }
            /* 给提交表单赋值 */
            this.reissueForm[item.partition_id] = money
            this.$forceUpdate()
            /* 给输入框赋值 */
            this.inputMoney[item.partition_id] = money
        },
        inputBlur(item) {
            /* 输入框输入值清除标签 */
            if (this.moneyCont[item.partition_id]) {
                this.moneyCont[item.partition_id] = null
                delete (this.reissueForm[item.partition_id])
            }
        },
        fl_submit() {
            /* 如果input 有值 加入到表单 */
            for (let key in this.inputMoney) {
                if (this.inputMoney[key]) {
                    this.reissueForm[key] = this.inputMoney[key]
                }
                if (this.inputMoney[key] == '' && this.reissueForm[key] && !this.moneyCont[key]) {
                    /* 如果输入框是空的 表单里有值 并且没有选中标签 删除对于的键名 */
                    delete (this.reissueForm[key])
                }
            }
            this.reissueForm.id = this.id
            if (!this.reissueForm.account) {
                this.Msg('请填写充值账号')
                return
            }

            if (Object.keys(this.reissueForm).length < 5) {
                this.Msg('至少选择一种充值货币')
                return
            }

            this.supplyAgain()
            
        },
        supplyAgain() {
            supplyAgain(this.reissueForm).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        onClose: () => {
                            this.flag = true
                            this.$refs.closed.click()
                            this.fun2()
                            if (res.data.tan) {
                                const config = JSON.parse(localStorage.getItem('config'))
                                this.$confirm(config.channel_other.fuli_note, '提示', {
                                    confirmButtonText: '是',
                                    cancelButtonText: '否',
                                    type: 'warning',
                                    center: true
                                }).then(() => {
                                    this.$router.push({
                                        path: '/gameDetails', 
                                        query: {
                                            id: res.data.id,
                                            game_id: res.data.game_id,
                                            uid:this.userInfo.id
                                        }
                                    })
                                }).catch(() => {
                                });
                            }
                            // location.reload()
                        },
                        duration: 1500
                    });
                     //判断是否是包服卡兑换
                     if(this.reissueForm.reissue_type === 5){
                        this.getMouth()
                    }
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    onClose: () => {
                        this.flag = true
                    },
                    duration: 1500
                });
            })
        },

        Msg(text) {
            return this.$message({
                message: text,
                type: 'warning',
                duration: 1500
            })
        },
         // 初始化金额选项
         fun2(){
            this.reissueForm={
                /* 默认储值兑换 */
                reissue_type: 1
            }
            /* 选中状态存放 */
            this.moneyCont= {},
            /* input 输入框 */
            this.inputMoney={}
            /* 货币列表  */
            this.fl_money = []
            if(this.is_under == 1){
                this.under_off = true
                // this.endTime = ''
                console.log('this.under_off',this.under_off)
            }
        }

    },
    created(){
        if(localStorage.getItem('token')){
            this.getUserYueka()
            this.getMouth() // 获取包服卡列表
        }
    }
}
</script>
  
<style scoped>
.count{
    max-width: 45%;
}
.colsing{
    position: absolute;
    right: 1%;
    cursor: pointer;
}
/* 领取福利弹窗 */
.layui-layer-page .layui-layer-content {
    overflow: visible !important;
}

/* 选项卡移入 */
.item_hover:hover {
    border: 1px solid #AC8747 !important;
    color: #805D25 !important;
    transition: all 0.5s;
}

.pay_hover:hover {
    border: 1px solid #AC8747 !important;
    transition: all 0.5s;
}

/* 选项卡点击 */
.hit_item {
    border: 1px solid #AC8747 !important;
    color: #805D25 !important;
}

/* .layui-layer-content {
      overflow: hidden !important;
  } */
.receive {
    background: white;
    display: none;
}

.tab_title {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 40px;
}

.rec_active {
    color: #B5945B !important;
    border-bottom-color: #B5945B !important;
}

.game_title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    text-decoration: none;
    transition: all 0.5s;
    padding: 20px 0 18px;
    border-bottom: 2px solid #fff;
    margin-right: 50px;
}

.game_title:hover {
    transition: all 0.5s;
    color: #B5945B;
    border-bottom-color: #B5945B;
}

.rec_content {
    border-top: 1px solid #E7E7E7;
    padding: 20px 40px 50px;
}

.rec_content p {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin: 0;
}
.rec_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.surplus {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    background: #F0F0F0;
    display: flex;
    justify-content: start;
    align-items: center;
}

.surplus h5 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin: 0;
}

.surplus h5 span {
    color: #CE3232;
}

.rec_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
    
}

.rec_left {
    width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-right: 40px;
}
.exchange_right{
    max-height: 380px;
    overflow: hidden;
}

.class_box{
    overflow: auto;
    padding-right: 12px;
}
.class_box::-webkit-scrollbar {
  /*滚动条整体样式*/
    width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
  }
  .class_box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 15px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #d1b481;
  }
  .class_box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background   : #e6e6e6;
  padding: 10px 0;
  }
.rec_form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.rec_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    min-width: 100px;
}
.top_text{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.tishi {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #CE3232;
    margin-top: 10px;
    text-align: right;
}

.mt_20 {
    margin-top: 20px;
}

.user_box {
    display: flex;
    align-items: center;
}

.lb_1 {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.zhanhao {
    width: 280px;
    height: 46px;
    background: rgba(181, 148, 91, 0);
    border: 1px solid #4D4D4D;
    border-radius: 4px;
    margin: 0;
    outline-style: none;
}

.exchange_dami {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-top: 20px;
}

.lb_2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 550px;
}

.dami {
    margin: 0;
    margin-top: 20px;
}

.dami_btn {
    text-decoration: none;
    border: 1px solid #4D4D4D;
    border-radius: 4px;
    padding: 10px 20px;
    margin-right: 10px;
    margin-top: 10px;
    text-align: center;
    position: relative;
    width: 80px;
}

.hit {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
}

.btn_active {
    display: block;
}

.dami_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4C4C4C;
}

.qita {
    margin-top: 10px;
}

.qita_box {
    display: flex;

}
.form_qita{
    border: 1px solid #4D4D4D;
}

.money_qita {
    height: 46px;
    border: 1px solid #4D4D4D;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    padding: 0 10px;
    align-items: center;
}

.qita_title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4D4D4D;
}

.sub_box {
    display: flex;
    /* flex-direction: column; */
    align-items: start;
    justify-content: start;
    margin-top: 30px;
    margin-left: 80px;
}

.submit {
    margin-right: 10px;
    border-radius: 4px;
    padding: 15px 45px;
    text-decoration: none;
    cursor: pointer;
}

.submit_color {
    background: #B49052;
    transition: all 0.5s;
}

.submit_color:hover {
    filter: brightness(1.2);
    transition: all 0.5s;
}

.qvxiao {
    background: #CDCDCD;
    transition: all 0.5s;
}

.qvxiao:hover {
    transition: all 0.5s;
    filter: brightness(1.1);
}

.sub_font {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;

}

.submit_font_color {
    color: #FEFEFE;
    transition: all 0.5s;
}

.submit_font_color:hover {
    transition: all 0.5s;
    color: #FFFFFF;
}

/*  */
@media (max-width:1600px) {

    /* 福利弹窗 */
    .exchange_right{
        max-height: 300px;
    }
    .rec_content {
        padding: 20px 40px 30px;
    }

    .game_title {
        font-size: 16px;
        padding: 15px 0 18px;
    }

    .rec_content p {
        font-size: 14px;
    }

    .surplus h5 {
        font-size: 16px;
    }

    .submit {
        padding: 10px 40px;
    }
}

@media (max-width:1300px) {

    /* 领取福利弹窗 */
    .rec_content {
        padding: 20px 40px 25px;
    }

    .game_title {
        font-size: 14px;
        padding: 10px 0 15px;
    }

    .rec_content p {
        font-size: 14px;
    }

    .surplus h5 {
        font-size: 15px;
    }

    .rec_title {
        font-size: 14px;
    }

    .zhanhao {
        height: 35px;
        width: 270px;
    }

    .dami_font {
        font-size: 12px;
    }

    .dami_btn {
        padding: 5px 10px;
    }

    .qita_title {
        font-size: 12px;
    }

    .money_qita {
        height: 35px;
    }

    .submit {
        margin-right: 10px;
        padding: 10px 30px;
    }

    .sub_box {
        margin-top: 15px;
        margin-left: 70px;
    }

    .exchange_dami {
        margin-top: 10px;
    }
}

@media (max-width:1100px) {
    /* 福利领取弹窗 */

    .rec_content {
        padding: 20px;
    }

    .game_title {
        font-size: 12px;
        padding: 5px 0;
    }

    .rec_content p {
        font-size: 12px;
    }

    .surplus {
        height: 35px;
    }

    .surplus h5 {
        font-size: 13px;
    }

    .rec_box {
        margin-top: 15px;
    }

    .rec_title {
        font-size: 12px;
    }

    .zhanhao {
        height: 25px;
    }

    .dami_btn {
        margin-top: 0;
    }

    .submit {
        padding: 5px 25px;
    }
}
</style>