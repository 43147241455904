<template>
    <div class="lve_box">
      <div class="bag_title">
          <h4 class="font_color">会员等级</h4>
          <div class="tip">
              <div class="tip_icon flex_box font_color">?</div>
              <span class="font_color">如遇充值问题，请联系任意平台客服</span>
          </div>
      </div>
      <table class="table table-bordered">
              <thead>
              <tr>
                  <th>等级</th>
                  <th>名称</th>
                  <th>等级标志</th>
                  <th>所需成长值</th>
                  <th>每笔额度</th>
                  <th>积分增加</th>
                  <th>专属客服</th>
              </tr>
              </thead>
              <tbody>
                  <tr v-for="(l, index) in lvfl" :key="index">
                      <td>{{ l.vip_name }}</td>
                      <td>{{ l.vip_instructions ? l.vip_instructions : "-" }}</td>
                      <td v-if="l.vip_img"><img class="military" v-lazy="l.vip_img ? img_url + l.vip_img : ''" ></td>
                      <td v-else>-</td>
                      <td>{{ l.vip_experience_x }}</td>
                      <td>{{ l.exchange_jiacheng ? l.exchange_jiacheng + '%' : '-' }}</td>
                      <td>{{ l.sigin_group_integral ? l.sigin_group_integral : '-' }}</td>
                      <td v-if="l.vip_kefu == 'true'"><img src="/MembershipLevel/img/gou.png"></td>
                      <td v-else>-</td>
                  </tr>
              </tbody>
      </table>
      <div class="rule">
          <h4 class="rule_tip">{{grow.title}}：</h4>
          <span v-html="grow.content">
          </span>
      </div>
      <div class="obtain">
          <h4 class="rule_tip">成长值获取说明：</h4>
          <table class="table table-bordered">
              <thead>
                  <tr>
                      <th>成长等级</th>
                      <th>会员充值</th>
                      <th>每日登录</th>
                      <th>签到打卡</th>
                      <th>会员领取</th>
                      <th>积分兑换</th>
                      <th>更多活动</th>
                  </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(l,index) in lvfl" :key="index">
                          <td>{{l.vip_name}}</td>
                          <td v-if="index == 0" :rowspan="lvfl.length" class="able_font_color">会员充值1:1获得成长值</td>
                          <td>{{ l.login }}</td>
                          <td>{{ l.sigin }}</td>
                          <td>{{ l.growth_pay }}</td>
                          <td>{{ l.integral_pay }}</td>
                          <td v-if="index == 0" :rowspan="lvfl.length">敬请期待</td>
                      </tr>
                  </tbody>
          </table>
      </div>
      <div class="warn2 able_font_color">
          注：除会员充值以外，其它成长值获取任务仅每日首次获得成长值。
      </div>
    </div>
  </template>
  
  <script>
      import { mapState } from 'vuex';
      import {getArticleData} from '@/api/http.js'
      export default {
          name:'MembershipLevel',
          data() {
              return {
                  grow:[],
                  time:''
              }
          },
          computed:{
              ...mapState([
                  'lvfl',
              ])
          },
          methods:{
              getArticleData(){
                  const g_id = JSON.parse(localStorage.getItem('config'));
                  getArticleData({
                      id:g_id.customer.growth_value_that
                  }).then((res)=>{
                      if(res.code == 200){
                          localStorage.setItem('lv_text',JSON.stringify(res.data))
                          localStorage.setItem('lvlTime',[res.ex_time])
                          this.grow = JSON.parse(localStorage.getItem('lv_text'))
                      }
                  }).catch((err)=>{
                      console.log('捕获异常',err)
                  })
  
              }
          },
          created(){
              setTimeout(()=>{
              this.time = parseInt(new Date().getTime() / 1000) + ''
                  var newTime = localStorage.getItem('lvlTime')
                  if(this.time > newTime || !newTime){
                      this.getArticleData()
                  }else{
                      this.grow = JSON.parse(localStorage.getItem('lv_text'))
                  }
              },500)
              
          }
      }
  </script>
  
  <style scoped>
  .lve_box{
      background: #fff;
      border-radius: 4px;
      padding: 30px 40px 50px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      height: 100%;
  }
   .bag_title{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 20px;
      }
      .bag_title h4{
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin: 0;
      }
      .tip{
          display: flex;
          align-items: center;
          justify-content: end;
      }
      .tip_icon{
          width: 13px;
          height: 13px;
          border: 1px solid #806437;
          border-radius: 50%;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-right: 5px;
      }
      .tip span{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
      }
      .font_color{ 
          color: #806437;
      }
      /* table */
      .table th, .table td { 
          text-align: center;
          vertical-align: middle!important;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #463F34;
      }
      .table td{
          padding: 24px 0;
      }
      .military{
          width: 65px;
      }
  /*  */
      .rule{
          width: 93%;
      }
      .rule_tip{
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4D4D4D;
      }
      .rule h5{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4D4D4D;
          margin: 5px 0;
      }
      .rule span{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #808080;
      }
      .warn{
          display: flex;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-top: 20px;
      }
      .able_font_color{
          color: #C53126 !important;
      }
      /* 成长获取说明 */
      .obtain{
          margin-top: 40px;
          width: 100%;
      }
      .warn2{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
      }
      @media (max-width:1600px) {
          .bag_title h4{
              font-size: 20px;
          }
          .tip span {
              font-size: 12px;
          }
          .bag_title{
              padding-bottom: 15px;
          }
          /* table */
          .table th,.table td{
              font-size: 12px;
          }
          .table td{
              padding: 20px 0;
          }
          
      }
      @media (max-width:1300px) {
          .lve_box{
              padding: 20px 30px 40px;
          }
          .bag_box{
              padding: 20px 40px 75px;
          }
          .bag_title h4{
              font-size: 16px;
          }
  
          /* table */
          .obtain{
              margin-top: 20px;
          }
          .table td{
              padding: 15px 0;
          }
          .military{
              width: 45px;
  
          }
          /* rule */
          .rule_tip{
              font-size: 16px;
          }
          .rule h5{
              font-size: 12px;
          }
          .rule span{
              font-size: 12px;
          }
          .warn{
              font-size: 12px;
          }
          .warn2{
              font-size: 12px;
          }
          
      }
  </style>