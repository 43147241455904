<template>
    <div>
        <Header></Header>
        <div class="regist">
            <div class="container">
                <div class="reg_box">
                    <img class="logo" :src="img_url + material.pc_register_logo">
                    <span class="logo_title">注册{{ getClient.title }}-享受{{ getClient.channel_times }}充值福利 </span>
                    <div class="reg_form">
                        <div class="input_box flex_box">
                            <label class="label_font flex_box">账号</label>
                            <input type="text" class="reg_input reg_input_wh" v-model="account">
                        </div>
                        <div class="input_box flex_box">
                            <label class="label_font flex_box">密码</label>
                            <input type="password" class="reg_input reg_input_wh" v-model="password">
                        </div>
                        <div class="input_box flex_box">
                            <label class="label_font flex_box">确认密码</label>
                            <input type="password" class="reg_input reg_input_wh" v-model="repassword">
                        </div>
                        <div class="input_box flex_box">
                            <label class="label_font flex_box">手机号</label>
                            <input type="tel" class="reg_input reg_input_wh" v-model="user_mobile">
                        </div>
                        <div class="input_box flex_box">
                            <label class="label_font flex_box">验证码</label>
                            <input type="tel" class="reg_input yzm_border" v-model="code">
                            <label class="yzm_font flex_box" @click="getCode" v-if="isCode">获取验证码</label>
                            <label class="yzm_font flex_box" v-else>{{ count }}秒后重发</label>
                        </div>
                    </div>
                    <img class="reg_btn" :src="img_url + material.pc_register_btn" @click="regSubmit">
                </div>
            </div>
            <div class="zz"></div>
            <!-- 背景 -->
            <img class="regist_bg" :src="img_url + material.pc_register_bj" width="100%">
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { userRegistration, messageVerification, getUserInformation } from '@/api/http.js'
import { mapState } from 'vuex'
export default {
    components: { Header, Footer },
    name: 'Registration',
    data() {
        return {
            account: '',
            password: '',
            repassword: '',
            user_mobile: '',
            code: '',
            isCode: true, //判断验证码按钮
            count: '',
            timer: null,
            sms_token: '',
            bool: true, //节流
        }
    },
    computed: {
        ...mapState([
            'material',
            'getClient'
        ]),
        token: {
            get() {
                return this.$store.state.token
            },
            set(val) {
                return this.$store.state.token = val
            }
        },
        unLogin: {
            get() {
                return this.$store.state.unLogin
            },
            set(val) {
                return this.$store.state.unLogin = val
            }
        },
        isLogin: {
            get() {
                return this.$store.state.isLogin
            },
            set(val) {
                return this.$store.state.isLogin = val
            }
        },
        userInfo: {
            get() {
                return this.$store.state.userInfo
            },
            set(val) {
                return this.$store.state.userInfo = val
            }
        },
    },
    methods: {
        getCode() {
            this.messageVerification()
            const num = 60;
            if (!this.timer) {
                this.count = num;
                this.isCode = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= num) {
                        this.count--;
                    } else {
                        this.isCode = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.bool = true
                    }
                }, 1000);
            }
        },
        //短信验证
        messageVerification() {
            messageVerification({
                mobile: this.user_mobile,
                scene: 'to_account_register'
            }).then((res) => {
                if (res.code === 200) {
                    this.isCode = false
                    this.sms_token = res.data.sms_token
                    this.$message({
                        message: '短信发送成功！',
                        type: 'success',
                        duration: 3000
                    })
                }
            }).catch((err) => {
                this.isCode = true
                clearInterval(this.timer);
                this.timer = null;
                console.log('捕获异常', err)
                this.isCode = true
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        },
        //注册接口
        userRegistration() {
            var token = localStorage.getItem('logingtoken')
            userRegistration({
                account: this.account,
                password: this.password,
                repassword: this.repassword,
                user_mobile: this.user_mobile,
                code: this.code,
                token: this.$route.query.tokens,
                sms_token: this.sms_token,
            }).then((res) => {
                if (res.code === 200) {
                    // this.token = res.data.token
                    localStorage.setItem('token', res.data.token)
                    const is_auth = res.data.is_auth
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        onClose: () => {
                            this.bool = true
                        },
                        duration: 1500
                    });
                     //一定要请求一次用户信息，否则以下路由会报错
                     getUserInformation().then((res) => {
                        if (res.code === 200) {
                            localStorage.setItem('userInfo', JSON.stringify(res.data))
                            this.userInfo = res.data
                            this.unLogin = false
                            this.isLogin = true
                            this.$router.push({ path: "/home" })
                            window.location.href=window.location.href
                            // 当本地存储有userinfo后再判断实名跳转
                            // if (is_auth) {
                            //     this.$router.push({ path: "/home" })
                            //     window.location.href=window.location.href
                            // } else {
                            //     console.log('我进入了else要去实名')
                            //     this.$router.push({
                            //         path: '/member/realName'
                            //     })
                            //     window.location.href=window.location.href
                            // }
                        } else {
                            console.log(res.msg)
                        }
                    }).catch((err) => {
                        //token失效时清除本都数据
                        localStorage.setItem("token", '')
                        this.setCookie('token', '')
                        this.$router.push({
                            path: '/home'
                        })
                        console.log('捕获异常', err)
                    })

                }
            }).catch((err) => {
                console.log("捕获异常：code非200", err)
                if (err.msg == 30001) {
                    this.open()
                    this.bool = true
                } else {
                    this.$message({
                        message: err.msg,
                        type: 'warning',
                        onClose: () => {
                            this.bool = true
                        },
                        duration: 1500
                    });
                }
            })
        },
        open() {
            this.$confirm('此账号或手机号已经注册，是否绑定当前微信', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                var token = localStorage.getItem('logingtoken')
                userRegistration({
                    account: this.account,
                    password: this.password,
                    repassword: this.repassword,
                    user_mobile: this.user_mobile,
                    code: this.code,
                    token: this.$route.query.tokens,
                    sms_token: this.sms_token,
                    is_bind: 1
                }).then((res) => {
                    if (res.code == 200) {
                        localStorage.setItem('token', res.data.token)
                        const is_auth = res.data.is_auth
                         //一定要请求一次用户信息，否则以下路由会报错
                        getUserInformation().then((res) => {
                            if (res.code === 200) {
                                localStorage.setItem('userInfo', JSON.stringify(res.data))
                                this.userInfo = res.data
                                this.unLogin = false
                                this.isLogin = true
                                this.$router.push({ path: "/home" })
                                window.location.href=window.location.href
                                // 当本地存储有userinfo后再判断实名跳转
                                // if (is_auth) {
                                //     this.$router.push({ path: "/home" })
                                //     window.location.href=window.location.href
                                // } 
                                // else {
                                //     console.log('我进入了else要去实名')
                                //     this.$router.push({
                                //         path: '/member/realName'
                                //     })
                                //     window.location.href=window.location.href
                                // }
                            } else {
                                console.log(res.msg)
                            }
                        }).catch((err) => {
                            //token失效时清除本都数据
                            localStorage.setItem("token", '')
                            this.setCookie('token', '')
                            this.$router.push({
                                path: '/home'
                            })
                            console.log('捕获异常', err)
                        })
                        
                    }
                }).catch((err) => {
                    this.$message({
                        message: err.msg,
                        type: 'warning',
                        duration: 1500
                    });
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消绑定'
                });
            });
        },
        regSubmit() {
            if (this.sms_token) {
                if (this.bool) {
                    this.bool = false
                    var reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){8,}$/
                    if (reg.test(this.password)) {
                        this.userRegistration()
                    } else {
                        this.bool = true
                        this.$message({
                            message: '密码长度为8-16位，必须由大写字母、小写字母、数字、特殊符号中的2种及以上类型组成',
                            type: 'warning',
                            duration: 5000
                        })
                    }
                } else {
                    this.$message({
                        message: '操作过于频繁，请稍后再试！',
                        type: 'warning',
                        duration: 1500
                    })
                }
            } else {
                this.$message({
                    message: '请获取验证码！',
                    type: 'warning',
                    duration: 1500
                })
            }

        },
        // 确定登入状态，获取用户信息
        getUserInformation() {
            getUserInformation().then((res) => {
                if (res.code === 200) {
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    this.userInfo = res.data
                    this.unLogin = false
                    this.isLogin = true
                } else {
                    console.log(res.msg)
                }
            }).catch((err) => {
                //token失效时清除本都数据
                localStorage.setItem("token", '')
                this.setCookie('token', '')
                this.$router.push({
                    path: '/home'
                })
                console.log('捕获异常', err)
            })
        },

    },
    created() {


    }
}
</script>

<style scoped>
.regist {
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 100px 0 88px;
    margin-top: 110px;
}

/* .zz{
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 200px;
    background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
} */
.regist_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.reg_box {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo_title {
    font-size: 23px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #A28A61;
    margin-top: 20px;
}

/* 表单 */
.reg_form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.input_box {
    margin-top: 20px;
}

.label_font {
    width: 90px;
    height: 49px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E7C790;
    border: 1px solid #6C5C3F;
    border-right: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background: #29221C;
}

.yzm_font {
    width: 100px;
    height: 49px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E7C790;
    border: 1px solid #6C5C3F;
    border-left: none;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background: #29221C;
}

.reg_input_wh {
    width: 420px;
    height: 49px;
}

.yzm_border {
    width: 319px;
    height: 49px;
}

.reg_input {

    background: #29221C;
    border: 1px solid #6C5C3F;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: #E7C790;
}

.reg_btn {
    margin-top: 40px;
    transition: all 0.5s;
    cursor: pointer;
}

.reg_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

@media (max-width:1600px) {
    .logo {
        width: 40%;
    }

    .logo_title {
        font-size: 18px;
    }

    .reg_form {
        margin-top: 10px;
    }

    .reg_btn {
        width: 20%;
    }
}

@media (max-width: 1300px) {
    .regist {
        margin-top: 100px;
    }

    .logo_title {
        font-size: 16px;
    }

    .label_font {
        width: 70px;
        height: 40px;
    }

    .reg_input_wh {
        width: 400px;
        height: 40px;
    }

    .yzm_border {
        width: 300px;
        height: 40px;
    }

    .reg_btn[data-v-c648d246] {
        margin-top: 30px;
    }

    .label_font {
        font-size: 12px;
    }

    .yzm_font {
        height: 40px;
        font-size: 12px;
    }
}

@media (max-width:1100px) {
    .logo_title {
        font-size: 14px;
    }

    .reg_btn {
        width: 18%;
    }
}
</style>