<template>
  <div class="chess_box">
    <div class="content_box">
      <div class="flex_box">
        <span class="user_msg">用户id:{{account_id}}</span>
        <span class="user_msg">当前拥有投掷骰子次数：<b>{{take_num}}</b></span>
        <span class="user_msg">累计投掷次数：<b>{{drawnNum}}</b></span>
      </div>
      <div class="tip_title">
        <span>每停留一个地区方格，您将有机会获得奖励188、288、388、888、1888、3888，8888</span>
      </div>
      <div class="desk">
        <div class="tab_item" v-for="province in map_info" :key="province.name" :style="getProvinceStyle(province)">
          <span class="foor_name" :class="{'bwite':!province.illume}">{{ province.name }}</span>
          <img v-if="province.place" class="flag" src="/activity/chess/img/qi.png">
          <!-- 背景 -->
          <img class="tab_bg" :class="{'bwite':!province.illume}" :src="province.image">
        </div>
        <div class="desk_title">
          <span class="tip_text">锦绣中华 游历大好江山</span>
          <div class="cz_box">
            <div class="cz_item">
              <span>单笔充值100<br>摇骰子1次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值200<br>摇骰子2次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值300<br>摇骰子3次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值500<br>摇骰子6次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值1000<br>摇骰子13次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值1500<br>摇骰子19次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值2000<br>摇骰子26次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值2500<br>摇骰子32次</span>
            </div>
            <div class="cz_item">
              <span>单笔充值3000<br>摇骰子39次</span>
            </div>
          </div>
          <div class="desk_content">
            <div class="js_box">
              <h5>游历大奖</h5>
              <div class="travel_item">
                <div class="travel_left">
                  <img src="/activity/chess/img/gps.png" width="10" height="16">
                  <span class="travel_span">点亮全部地图</span>
                </div>
                <div class="travel_right">
                  <span>奖励 28888</span>
                </div>
              </div>
              <div class="travel_item">
                <div class="travel_left">
                  <img src="/activity/chess/img/gps.png" width="10" height="16">
                  <span class="travel_span">累积点亮24个地图</span>
                </div>
                <div class="travel_right">
                  <span>奖励 5888</span>
                </div>
              </div>
              <div class="travel_item">
                <div class="travel_left">
                  <img src="/activity/chess/img/gps.png" width="10" height="16">
                  <span class="travel_span">累积点亮12个地图</span>
                </div>
                <div class="travel_right">
                  <span>奖励 1888</span>
                </div>
              </div>
              <div class="travel_item">
                <div class="travel_left">
                  <img src="/activity/chess/img/gps.png" width="10" height="16">
                  <span class="travel_span">累积点亮6个地图</span>
                </div>
                <div class="travel_right">
                  <span>奖励 588</span>
                </div>
              </div>
              <div class="travel_item" style="margin: 0;">
                <div class="travel_left">
                  <img src="/activity/chess/img/gps.png" width="10" height="16">
                  <span class="travel_span">每走完一轮</span>
                </div>
                <div class="travel_right">
                  <span>奖励 888</span>
                </div>
              </div>
              <div class="travel_must">注：重复的地图格子，只计算一个地图。</div>
            </div>
            <div class="play_box">
                <div class="flex_box btn_box" v-show="isClick">
                  <img class="btn_hover" @click="travelReceive" style="margin-right: 5px;" src="/activity/chess/img/play.png">
                  <img class="btn_hover" @click="toReach" src="/activity/chess/img/ljcz.png">
                </div>
                <div class="rule_box">
                  <span>
                    活动规则<br>
                    1.单笔充值对应金额，可以获得摇骰子次数；<br>
                    2.每次根据骰子点数，行进对应格子获得随机奖励；<br>
                    3.达到对应游历成就，可以获得额外奖励；<br>
                    4.奖励储值点发放到会员账号，储值点有效期90天。
                  </span>
                </div>
                <!-- 骰子动画 -->
                <div v-show="isShow" class="touzi"></div>
                <img v-if="over_show" class="over_img" :src="over_img">
            </div>
            <div class="js_box">
              <h5>介绍</h5>
              <div class="jieshao class_box">中华人民共和国省级行政区有省、自治区、直辖市、特别行政区四种类型，是中国现行的一级行政区，一共34个，包括<br>
              23个省（河北、山西、辽宁、吉林、黑龙江、江苏、浙江、安徽、福建、江西、山东、河南、湖北、湖南、广东、海南、四川、贵州、云南、陕西、甘肃、青海、台湾）<br>
              5个自治区（内蒙古、广西、西藏、宁夏、新疆）<br>
              4个直辖市（北京、天津、上海、重庆）<br>
              2个特别行政区（香港、澳门）
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
     <!-- 领取奖励弹窗 -->
     <div class="receive_box">
        <div class="lq_content">
            <span class="receive_text">恭喜您获得了<br>{{ money }}储值</span>
            <img class="lqjl_btn btn_hover" src="/activity/chess/img/right.png">
        </div>
        <img src="/activity/chess/img/tc_bg.png">
    </div>
  </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import {travelInfo,travelReceive} from '@/api/http'
export default {
    name: 'NationalDayChess',
    data() {
        return {
          map_info:[],
          account_id:'',
          isClick:true,
          isactive:true,
          take_num:0,
          now_url:'',
          subscript:0,
          isShow:false, //判断骰子图片是否显示
          over_img:'',
          over_show:false, //判断骰子结果图片显示
          count:0, //步数
          path:[],
          money:0,
          drawnNum:0,
        }
    },
    methods:{
      // 领取弹窗
      leaderTc() {
          var that = this
          var leader_tc = $('.receive_box')
          var LayIndex = layer.open({
              type: 1,
              content: leader_tc,
              anim: false,
              fixed: true,
              shade: 0.6,
              title: 0,
              scrollbar: true,
              shadeClose: false,
              closeBtn: 0,
              isOutAnim: false,
              area: [auto, auto],
              success: function (layero,index) {
                that.flag = true
                that.$message.closeAll();
                  const lq = $(layero).find('.lqjl_btn');
                  lq.click(function () {
                      layer.close(LayIndex)
                  })
              }
          });
      },
      calculatePath(start, end) {
        console.log(this.map_info.length)
        this.path = [];
        if (end >= start) {
          for (let i = start; i <= end; i++) {
            this.path.push(i % this.map_info.length); // 处理数组闭环
          }
        } else {
          for (let i = start; i < this.map_info.length; i++) {
            this.path.push(i);
          }
          for (let i = 0; i <= end; i++) {
            this.path.push(i);
          }
        }
        console.log(this.path)
        return this.path;
      },
      animatePath(path) {
        let i = 0;
        const intervalId = setInterval(() => {
          if (i < path.length) {
            const currentIndex = path[i];
            // 根据格子初始状态决定动画效果
            this.map_info[currentIndex].illume = !this.map_info[currentIndex].illume;
            this.map_info[currentIndex].place = !this.map_info[currentIndex].place
            if (i > 0) {
              const lastIndex = path[i - 1];
              // 恢复上一个格子的状态
              this.map_info[lastIndex].illume = !this.map_info[lastIndex].illume;
              this.map_info[lastIndex].place = false
            }
            i++;
          } else {
            clearInterval(intervalId);
            this.over_show = false
            this.travelInfo()
            this.leaderTc()
            // this.currentPos = this.targetPos; // 更新棋子当前位置
          }
        }, 500);
      },
      travelReceive(){
        if(this.isactive){
            if(this.isClick){
            this.isClick = false
            travelReceive({
              token:this.$route.query.token
            }).then((res) =>{
              if(res.code == 200){
                this.isShow = true
                this.over_img = res.data.image
                this.count = res.data.count
                this.money = res.data.money
                this.calculatePath(this.subscript,res.data.subscript)
                setTimeout(() =>{
                  this.isShow = false
                  this.over_show = true
                },2000)
                setTimeout(() =>{
                  this.animatePath(this.path)
                },2000)
                
              }
            }).catch((err) =>{
              console.log('捕获异常', err)
              this.$message({
                  message: err.msg,
                  type: 'warning',
                  duration: 1500,
                  onClose:() =>{
                      this.isClick = true
                  }
              })
            })
          }else{
            this.$message({
                message: "操作过于频繁，请稍后再试~",
                type: 'warning',
                duration: 1500
            });
          }
        }else{
          this.$message({
              message: '活动已结束',
              type: 'warning',
              duration: 1500
          })
        }
        
        
      },
      toReach(){
        this.$router.push({
          path:'/member/wallet'
        })
      },
      // 活动信息接口
      travelInfo(){
        travelInfo({
          token:this.$route.query.token
        }).then((res)=>{
          if(res.code == 200){
            this.map_info = res.data.map_info
            this.account_id = res.data.account_id
            this.take_num = res.data.take_num
            this.isactive = res.data.isactive
            this.subscript = res.data.subscript //当前所在的位置 数组下标.
            this.drawnNum = res.data.drawnNum
            this.isClick = true
          }
        }).catch((err) =>{
          console.log('捕获异常', err)
          this.$message({
              message: err.msg,
              type: 'warning',
              duration: 1500,
              onClose:()=>{
                this.isClick = true
              }
          })
        })
      },
      getProvinceStyle(province) {
        return {
          gridColumnStart: province.col,
          gridRowStart: province.row,
        };
      }
    },
    created(){
       // 获取当前域名
       const currentURL = new URL(window.location.href);
        // this.now_url = currentURL.origin;
        // this.now_url = 'http://192.168.110.41:8031'
      this.travelInfo()
    }
}
</script>

<style scoped>
/* 弹窗 */
.receive_box{
    display: none;
}
.compensate_box{
    display: none;
}
.receive_text{
    font-family: Source Han Sans SC;
    font-weight: 800;
    font-size: 30px;
    color: #ffec95;
    background: linear-gradient(0deg, #ffec95 0%, #fffee6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 60px;
    text-align: center;
}
.lq_content{
    position: absolute;
    left: 73%;
    transform: translateX(-50%);
    bottom: 14%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.over_img{
  position: absolute;
  z-index: 50;
}
.touzi{
  position: absolute;
  z-index: 50;
  width: 108px;
  height: 88px;
  background-image: url("/public/activity/chess/img/tz.png"); 
  background-repeat: no-repeat; /* 防止背景重复 */
  animation: play .5s steps(1) infinite; /* 动画设置 */
}
@keyframes play {
  0% { background-position: 0 0; }     
  20% { background-position: 0 -88px; }   
  40% { background-position: 0 -176px; }  
  60% { background-position: 0 -264px; }  
  80% { background-position: 0 -352px; } 
  100% { background-position: 0 0; }      
}
.class_box{
    overflow: auto;
}
.class_box::-webkit-scrollbar {
  /*滚动条整体样式*/
    width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
  }
  .class_box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 15px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #d1b481;
  }
  .class_box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background   : #e6e6e6;
  padding: 10px 0;
  }
.bwite{
    filter: grayscale(100%);
}
.chess_box{
  position: relative;
  background: url('/public/activity/chess/img/bg.png') no-repeat;
  background-size: 1920px 1080px;
  min-width: 1903px;
  min-height: 1080px;
  display: flex;
  justify-content: center;
  padding-top: 359px;
}
.content_box{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user_msg{
  font-family: SimSun;
  font-weight: 400;
  font-size: 12px;
  color: #FFF3D1;
  line-height: 23px;
  margin-right: 20px;
}
.user_msg b{
  color: rgb(187, 255, 0);
}
.desk{
  position: relative;
  background: url('/public/activity/chess/img/desk.png') no-repeat;
  background-size: 1343px 616px;
  min-width: 1343px;
  min-height: 616px;
  margin-top: 10px;
  padding: 45px 70px;
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 设置10列 */
  grid-template-rows: repeat(7, 1fr); /* 设置5行 */
  gap: 5px; /* 设置网格间隙 */
}
.tip_title{
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 16px;
  color: #FFE9AC;
  margin-top: 10px;
  background: url('/public/activity/chess/img/bg2.png') no-repeat;
  background-size: 846px 24px;
  min-width: 846px;
  min-height: 24px;
  text-align: center;
}
.tab_item{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 72px;
  position: relative;
}
.foor_name{
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 20px;
  color: #E28C28;
  line-height: 45px;
  background: linear-gradient(-10deg, #DC451F 0%, #CB191E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 10;
}
.tab_bg{
  position: absolute;
}
.flag{
  position: absolute;
  z-index: 10;
  top: -10%;
}
.desk_title{
  width: 1008px;
  height: 384px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
}
.tip_text{
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 30px;
  color: #E28C28;
  line-height: 45px;
  background: linear-gradient(-10deg, #DC451F 0%, #CB191E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15px;
}
.cz_box{
  background: url('/public/activity/chess/img/cz_bg.png') no-repeat;
  background-size: 949px 50px;
  min-width: 949px;
  min-height: 50px;
  margin-top: 14px;
  padding: 0 30px;
  display: flex;
  align-items: center;
}
.cz_item{
  text-align: center;
  font-family: SimSun;
  font-weight: 400;
  font-size: 12px;
  color: #E28C28;
  background: linear-gradient(-10deg, #DC451F 0%, #CB191E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
}
.cz_item:nth-of-type(2){
  right:77%;
}
.cz_item:nth-of-type(3){
  right:67%;
}
.cz_item:nth-of-type(4){
  right:57%;
}
.cz_item:nth-of-type(5){
  right:46%;
}
.cz_item:nth-of-type(6){
  right:36%;
}
.cz_item:nth-of-type(7){
  right:26%;
}
.cz_item:nth-of-type(8){
  right:16%;
}
.cz_item:nth-of-type(9){
  right: 57px;
}
.cz_item span{
  width: 71px;
}
.desk_content{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
}
.js_box{
  background: url('/public/activity/chess/img/js_bg.png') no-repeat;
  background-size: 256px 239px;
  max-width: 256px;
  max-height: 239px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 14px;
}
.jieshao{
  font-family: SimSun;
  font-weight: 400;
  font-size: 12px;
  color: #8F2205;
  line-height: 18px;
  width: 100%;
}
.js_box h5{
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 18px;
  color: #E28C28;
  background: linear-gradient(-10deg, #DC451F 0%, #CB191E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}
.travel_item{
  background: #FFF3D1;
  border: 1px solid rgba(201,50,29,0.09);
  width: 229px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 12px;
  margin-bottom: 5px;
}
.travel_left{
  display: flex;
  align-items: center;
}
.travel_span{
  font-family: SimSun;
  font-weight: 400;
  font-size: 12px;
  color: #86280B;
  line-height: 23px;
  margin-left: 5px;
}
.travel_right{
  font-family: SimSun;
  font-weight: 400;
  font-size: 12px;
  color: #86280B;
  line-height: 23px;
  display: flex;
  align-items: center;
  width: 60px;
}
.travel_must{
  font-family: SimSun;
  font-weight: 400;
  font-size: 12px;
  color: #BB0000;
  line-height: 23px;
}
.btn_box{
  margin-top: 50px;
}
.play_box{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 303px;
}
.rule_box{
  position: absolute;
  bottom: 0;
}
.rule_box span{
  width: 270px;
  font-family: SimSun;
  font-weight: 400;
  font-size: 12px;
  color: #8F2205;
}
</style>