<template>
    <div class="game_box" ref="g_box" :style="{height:lb_height}">
            <div class="tab_title" ref="tab_title">
                <!--点击设置curId的值  如果curId等于0，第一个a添加cur类名，如果curId等于1，第二个a添加cur类名，以此类推。添加了cur类名，a就会改变样式 @click,:class ,v-show这三个是vue常用的指令或添加事件的方式-->
                <a v-for="g in games" :key="g.value" @click="curId=g.value" :class="{'active':curId===g.value}" class="game_title">{{g.label}}</a>
            </div>
            <div class=" class_box" :style="{height:class_height + 'px'}">
                <!--根据curId的值显示div,如果curId等于0，第一个div显示，其它三个div不显示。如果curId等于1，第二个div显示，其它三个div不显示。以此类推-->
                <div v-for="g in games" :key="g.value" v-show="curId===g.value">
                    <div  v-for="game in g_list" :key="game.id">
                        <div class="tab_item"  v-if=" curId === game.column">
                            <div class="tab_link">
                                <div class="item_name">
                                    <!-- <div class="xing">新</div> -->
                                    <img class="xing" v-lazy="img_url + game.game_icon">
                                    <a @click="toGame(game.id)"
                                    class="tab_front  lang_point" :title="game.game_name">{{ game.game_name }}{{ g.column }}</a>
                                </div>
                                <span class="tab_front lang_point ml_1" :title="game.classify_name">{{game.classify_name}}</span>
                                <!-- <span class="tab_front lang_point ml_1" :title="game.create_time">{{game.create_time}}</span> -->
                            </div>
                            <router-link :to="{
                                path:'/gameDetails',
                                query:{id:game.id}
                            }"  
                            class="goto lang_point" title="进入官网" @click.native="toDetails">游戏详情</router-link>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </template>
    
    <script>
        import {getGameList,getGameClassList} from '@/api/http.js'
        import {mapState} from 'vuex'
        export default {
            name:'GameClass',
            props:['lb_height'],
            data() {
                return {
                    curId:4,
                    games:[],
                    g_list:[],
                    isHeihgt:0,
                    time:'' //当前时间戳
    
                }
            },
            computed:{
                ...mapState([
                    'serve_quick'
                ]),
                class_height(){
                    return parseFloat(this.lb_height) - this.isHeihgt - 20
                },
               
            },
            watch:{
                curId:{
                    immediate: false, // 立即执行
                    deep: true,
                    handler (nval, oval) {
                        this.getGameList()                    
                    }
                },
                serve_quick:{
                    immediate: false, // 立即执行
                    deep: false,
                    handler (nval, oval) {
                        console.log("我要发送请求了")
                        if(nval){
                            this.getGameClassList()
                            this.getGameList() 
                        }
                    }
                }
            },
            methods:{
                toGame(id){
                    this.$router.push({
                        path:'/gameDetails',
                        query:{
                            id:id
                        }
                    })
                    window.location.href = window.location.href
                },
                toDetails(){
                    location.reload()
                },
                async getGameList(){
                    try{
                        const res = await getGameList({
                            column:this.curId
                        })
                        if(res.code == 200){
                            this.g_list = res.data
                            localStorage.setItem('gameClass',JSON.stringify(res.data))
                            localStorage.setItem('gameClassTime',[res.ex_time])
                            this.g_list = JSON.parse(localStorage.getItem('gameClass'))
                        }else{
    
                        }
                    }catch(err){
                        console.log(err)
                    }
                },
                getGameClassList(){
                    getGameClassList().then((res)=>{
                        if(res.code === 200){
                            this.curId = res.data[0].value
                            localStorage.setItem('gameClassList',JSON.stringify(res.data))
                            this.games = JSON.parse(localStorage.getItem('gameClassList'))
                        }else{
                            console.log(res.msg)
                        }
                    }).catch((err)=>{
                        console.log(err)
                    })
                }
            },
            created(){
                this.time = parseInt(new Date().getTime() / 1000) + ''
                var gameClassTime = localStorage.getItem('gameClassTime')
                var gameClassList = JSON.parse(localStorage.getItem('gameClassList'))
                var isSend = localStorage.getItem('serve_quick')
                if((this.time > gameClassTime || !gameClassTime || !gameClassList) && isSend){
                    this.getGameList()
                    this.getGameClassList()
                }else{
                    this.g_list = JSON.parse(localStorage.getItem('gameClass'))
                    this.games = JSON.parse(localStorage.getItem('gameClassList'))
                    this.curId = this.games ? this.games[0].value : 4
                }
                
            },
            mounted(){
                this.$nextTick(function () {
                    //  仅在整个视图都被重新渲染之后才会运行的代码
                    window.onresize = () => {
                        return (() => {
                            setTimeout(()=>{
                                this.$nextTick(() => {
                                    const lb_h = this.$refs.tab_title.offsetHeight
                                    this.isHeihgt = lb_h
                                })
                            },500) 
                        })();
                    };
                    setTimeout(()=>{
                        this.$nextTick(() => {
                            const lb_h = this.$refs.tab_title.offsetHeight
                            this.isHeihgt = lb_h
                        })
                    },500) 
                })
            }
        }
    </script>
    
    <style scoped>
    .ml_1{
        /* margin-left: 10px; */
    }
    .game_box{
        background: #fff;
        height: 100%;
        padding: 10px 0 15px;
        padding-left: 12px;
        overflow: hidden;
    }
    .tab_title{
        display: flex;
        justify-content: start;
        align-items: center;
        /* border-bottom:1px solid #F3F3F3 ; */
    }
    .game_title{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        padding-bottom: 10px;
        color: #656565;
        text-decoration: none;
        transition: all 0.5s;
        border-bottom:1px solid #fff;
        margin-right: 20%;
    }
    .game_title:hover{
        transition: all 0.5s;
        color: #B5945B;
        border-bottom-color:#B5945B;
    }
    .tab_link{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 70%;
    }
    .active{
        color: #B5945B;
        border-bottom-color: #B5945B;
    }
    .tab_item{
        padding: 10px 0;
        border-top: 1px solid #F3F3F3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .item_name{
        display: flex;
        flex-direction: row;
    }
    .xing{
        width: 22px;
        height: 22px;
        /* padding: 1px 4px; */
        /* background: #BF4949; */
        border-radius: 2px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 10px;
    }
    .tab_front{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #737373;
        text-decoration: none;
        width: 90px;
        height: 100%;
    }
    .goto{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        background: #B5945B;
        border-radius: 2px;
        text-decoration: none;
        padding: 5px 10px;
        transition: all 0.5s;
    }
    
    .goto:hover{
        filter: brightness(1.2);
        transition: all 0.5s;
    }
    .class_box{
        overflow: auto;
        padding-right: 12px;
    }
    .class_box::-webkit-scrollbar {
      /*滚动条整体样式*/
        width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        padding: 10px 0;
      }
      .class_box::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 15px;
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #d1b481;
      }
      .class_box::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      background   : #e6e6e6;
      padding: 10px 0;
      }
    @media (max-width:1600px) {
        .tab_front{
            /* width: 60px; */
        }
    }
    @media (max-width:1300px) {
        .tab_front{
            font-size: 12px;
            /* width: 50px; */
        }
        .game_title{
            font-size: 13px;
        }
        .goto{
            font-size: 12px;
        }
        .xing{
            font-size: 12px;
            /* width: 17px;
            height: 17px; */
            margin-right: 5px;
        }
        .tab_item{
            padding: 5px 0;
        }
    }
    @media (max-width:1100px) {
        .tab_front{
            /* width: 40px; */
        }
        .goto{
            padding: 2px 5px;
        }
        .ml_1{
            margin-left: 5px;
        }
        .game_title{
            font-size: 12px;
            padding-bottom: 5px;
        }
    }
    </style>