<template>
    <div class="gamelist" id="gamelist">
        <div class="container">
            <div class="game_list">
                <div class="list_top">
                    <div class="game_top">
                        <div class="biaozhi"></div>
                        <h4>游戏列表</h4>
                    </div>
                    <div class="list_item">
                        <ul>
                            <li @click="curId = 0" :class="{ 'active': curId === 0 }">全部</li>
                            <li v-for="gc in gamesClass" :key="gc.value" @click="curId = gc.value; click_game_class(gc.label)"
                                :class="{ 'active': curId === gc.value }">
                                {{ gc.label }}
                            </li>
                            <router-link to="/removegame"><li>待下架专区</li></router-link>
                        </ul>
                        <div class="search_box">
                            <img class="fdj" src="/gamelist/img/fdj.png">
                            <input type="text" class="search" data-toggle="popover" :data-keys="{ keyWord, g_name }"
                                v-model="keyWord" @keydown.enter="search()">
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="box s_box" v-show="curId === 0">
                        <div class="model " v-for="game in  gamesfilter" :key="game.id">
                            <div class="model_img_box" @click="toGameDetails(game.id, game.game_id,userInfo.id)">
                                <!-- <img class="model_under" v-if="game.is_under == 1" :src="img_url + material.pc_under_icon"> -->
                                <!-- <img class="model_under 11" v-if="game.column_img_type !=1" :src="img_url + game.column_icon"> -->
                                <img class="model_under" v-if="game.column_img_type == 3" :src="img_url + game.column_img">
                                <img class="model_img" v-lazy="img_url + game.game_image" width="100%" height="100%">
                            </div>
                            <div class="model_title">
                                <div class="container">
                                    <div class="text_box">
                                        <div @click="toGameDetails(game.id, game.game_id,userInfo.id)">
                                            <h5 :title="game.game_name" class="g_name lang_point">{{ game.game_name }}</h5>
                                        </div>
                                        <div class="text_logo">{{ game.classify_name }}</div>
                                    </div>
                                    <div class="btn_box">
                                        <a class="btn_font down  flex_box" @click="downloadFile(game.client_url)"
                                            :class="{ 'lang_btn': delang === true }"><span
                                                :class="{ 'lang_point': lang === true }" title="下载游戏">下载游戏</span></a>
                                        <a @click="toGameDetails(game.id, game.game_id,userInfo.id)" class="btn_font look  flex_box"
                                            :class="{ 'lang_btn': delang === true }"><span
                                                :class="{ 'lang_point': lang === true }" title="查看详情">查看详情</span></a>
                                        <a class="btn_font linqv  flex_box"
                                            @click="flID = game.game_id; id = game.id; game_name = game.game_name; img = game.game_image;under_time = game.under_time;is_under = game.is_under; receiveBenefits(game.game_id)"
                                            :class="{ 'lang_btn': delang === true }"><span
                                                :class=" { 'lang_point': lang === true } " title="兑换充值">兑换充值</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box s_box" v-show=" curId === -1 ">
                        <div class="model " v-for=" s  in  searchGame " :key=" s.id ">
                            <div class="model_img_box" @click=" toGameDetails(s.id,s.game_id,userInfo.id) ">
                                <!-- <img class="model_under" v-if="s.is_under == 1" :src="img_url + material.pc_under_icon"> -->
                                <!-- <img class="model_under" v-else-if="s.column_img_type !=1" :src="img_url + s.column_icon"> -->
                                <img class="model_under" v-if="s.column_img_type == 3" :src="img_url + s.column_img">
                                <img class="model_img" v-lazy=" img_url + s.game_image " width="100%" height="100%"></div>
                            <div class="model_title">
                                <div class="container">
                                    <div class="text_box">
                                        <div @click=" toGameDetails(s.id, s.game_id,userInfo.id) ">
                                            <h5 :title="s.game_name" class="g_name lang_point">{{s.game_name}}</h5>
                                        </div>
                                        <div class="text_logo">{{s.classify_name}}</div>
                                    </div>
                                    <div class="btn_box">
                                        <a class="btn_font down  flex_box" @click="downloadFile(s.client_url)"
                                            :class=" { 'lang_btn': delang === true } "><span
                                                :class=" { 'lang_point': lang === true } " title="下载游戏">下载游戏</span></a>
                                        <a @click=" toGameDetails(s.id, s.game_id,userInfo.id) " class="btn_font look  flex_box"
                                            :class=" { 'lang_btn': delang === true } "><span
                                                :class=" { 'lang_point': lang === true } " title="查看详情">查看详情</span></a>
                                        <a class="btn_font linqv  flex_box"
                                            @click=" flID = s.game_id; id = s.id; game_name = s.game_name; img = s.game_image;under_time = s.under_time;is_under = s.is_under; receiveBenefits(s.game_id) "
                                            :class=" { 'lang_btn': delang === true } "><span
                                                :class=" { 'lang_point': lang === true } " title="兑换充值">兑换充值</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box s_box" v-for=" gc  in  gamesClass " :key=" gc.value " v-show=" curId === gc.value ">
                        <div class="model_w" v-for=" gb  in  games " :key=" gb.id ">
                            <div class=" model1" v-show=" curId === gb.classify ">
                                <div class="model_img_box" @click=" toGameDetails(gb.id, gb.game_id,userInfo.id) ">
                                    <img class="model_under" v-if="gb.is_under == 1" :src="img_url + material.pc_under_icon">
                                    <!-- <img class="model_under" v-else-if="gb.column_img_type !=1" :src="img_url + gb.column_icon"> -->
                                    <img class="model_under" v-else-if="gb.column_img_type == 3" :src="img_url + gb.column_img">
                                    <img class="model_img" v-lazy=" img_url + gb.game_image " width="100%" height="100%"></div>
                                <div class="model_title">
                                    <div class="container">
                                        <div class="text_box">
                                            <div @click=" toGameDetails(gb.id, gb.game_id,userInfo.id) ">
                                                <h5 :title="gb.game_name" class="g_name lang_point">{{gb.game_name}}</h5>
                                            </div>
                                            <div class="text_logo">{{gb.classify_name}}</div>
                                        </div>
                                        <div class="btn_box">
                                            <a class="btn_font down  flex_box" @click="downloadFile(gb.client_url)"  
                                                :class=" { 'lang_btn': delang === true } "><span
                                                    :class=" { 'lang_point': lang === true } " title="下载游戏">下载游戏</span></a>
                                            <a @click=" toGameDetails(gb.id, gb.game_id,userInfo.id) " class="btn_font look  flex_box"
                                                :class=" { 'lang_btn': delang === true } "><span
                                                    :class=" { 'lang_point': lang === true } " title="查看详情">查看详情</span></a>
                                            <a class="btn_font linqv  flex_box"
                                                @click=" flID = gb.game_id; id = gb.id; game_name = gb.game_name; img = gb.game_image;under_time = gb.under_time;is_under = gb.is_under;receiveBenefits(gb.game_id) "
                                                :class=" { 'lang_btn': delang === true } "><span
                                                    :class=" { 'lang_point': lang === true } " title="兑换充值">兑换充值</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ReceiveBenefits ref="rec" :id="id" :game_id="flID"  :game_name=" game_name " :img=" img "  :under_time="under_time" :is_under="is_under"></ReceiveBenefits>
    </div>
</template>
  
<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import { getGameList, getGameClass, searchGameList } from '@/api/http.js'
import { mapState } from "vuex";
import ReceiveBenefits from '@/components/ReceiveBenefits.vue'
import {Bus} from '@/router/bus.js'
export default {
    name: 'GameList',
    components: { ReceiveBenefits  },
    data() {
        return {
            id: 0,
            flID: 0,
            curId: 0,
            lang: false,
            delang: true,
            screenWidth: document.body.clientWidth,
            games: [],
            gamesfilter:[],
            gamesClass: [],
            keyWord: '', //关键词
            g_name: '',
            game_name: '', //游戏名称
            img: '', //传递图片给领取福利
            searchGame: [],
            under_time:'',//下架时间
            is_under:'', //是否下架
        }
    },
    computed: {
        ...mapState([
            'service_id',
            'material',
            'userInfo',
            'serve_quick'
        ]),
    },
    // 监听 localStorage 中的某个属性
    watch: {
        '$ls.serveId': function (newVal, oldVal) {
            // 处理逻辑
        },
        serve_quick:{
            immediate: false, // 立即执行
            deep: false,
            handler (nval, oval) {
                if(nval){
                    this.getGameClass()
                    this.getGameList()       
                }
            }
        }
    },
    methods: {
        click_game_class(val){
            this.$czc( '游戏分类', '点击', '选择分类',val)
        },
        //下载游戏链接转换
        downloadFile(url){
            console.log(url)
            location.href = url
        },
        // 跳转到游戏详情
        toGameDetails(id, game_id,uid) {
            this.$router.push({
                path: '/gameDetails', query: {
                    id: id,
                    game_id: game_id,
                    uid:uid
                }
            })
        },
        receiveBenefits(game_id) {
            this.$refs.rec.open(game_id)
            if (localStorage.getItem('token')) {
                if(!this.userInfo.user_idcard){
                    this.$message({
                        message: "请先实名认证后使用该功能！",
                        type: 'warning',
                        onClose:()=>{
                            this.$router.push({ path: "/member/realName" })
                            window.location.href=window.location.href
                        },
                        duration: 1500,
                    });
                }else{
                    const receive = $('.receive')
                    var that = this
                    var LayIndex = layer.open({
                        type: 1,
                        content: receive,
                        anim: false,
                        fixed: true,
                        shade: 0.6,
                        title: 0,
                        scrollbar: false,
                        shadeClose: false,
                        isOutAnim: false,
                        closeBtn: 0,
                        Boolean: true,
                        area: [auto, auto],
                        success: function (layero, index) {
                            layer.style(index, {
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)'
                            });
                            window.onresize = () => {
                                return (() => {
                                    layer.style(index, {
                                        transform: 'none'
                                    });
                                })();
                            };
                            $('.layui-layer-page .layui-layer-content').css("overflow", "visible")
                            const lq = $(layero).find('.close');
                            // console.log(lq)
                            lq.click(function () {
                                // 初始化选项
                                that.$refs.rec.fun2()
                                layer.close(LayIndex)
                            })

                        },
                        end:function(){
                            that.img = ''
                            that.id = ''
                            that.game_name = ''
                            that.is_under = ''
                            this.flID = ''
                        }
                    });
                }
            } else {
                this.$message({
                    message: "请登录后再试！",
                    type: 'warning',
                    duration: 1500
                })
            }

        },
        //获取游戏列表
        getGameList() {
            getGameList().then((res) => {
                if (res.code === 200) {
                    this.games = res.data
                    this.gamesfilter = res.data.filter(item => item.is_under !== 1 && item.is_show == 1)
                    // this.games.sort((p1,p2)=>{
                    //     return p1.game_id-p2.game_id
                    // })
                    localStorage.setItem('gameList',JSON.stringify(this.games))
                    localStorage.setItem('gameListTime',[res.ex_time])
                } else {
                    console.log(res.msg)
                }
            }).catch((err) => {
                console.log(err)
            })

        },
        //获取游戏分类
        getGameClass() {
            getGameClass().then((res) => {
                if (res.code === 200) {
                    localStorage.setItem('gameBoxClass', JSON.stringify(res.data))
                    this.gamesClass = JSON.parse(localStorage.getItem('gameBoxClass'))
                } else {
                    console.log(res.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        search() {
            if (this.keyWord == '') {
                this.curId = 0
            } else {
                this.sousuo()
                // this.keyWord = ''
            }
        },
        // 搜索接口
        sousuo() {
            searchGameList({ classify: this.games.classify, name: this.keyWord }).then((res) => {
                if (res.code === 200) {
                    this.searchGame = res.data
                    this.searchGame.sort(function (a, b) {
                        return a.id - b.id
                    })
                    if (this.searchGame.length > 0) {
                        this.curId = -1
                    } else {
                        this.curId = 0
                    }
                } else {
                    console.log(res.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        sendPost() {
            this.getGameClass()
            this.getGameList()
            console.log('我是全局总线，我被触发了')
        }

    },
     created() {
        this.$bus.$on('allover',this.sendPost)
        this.time = parseInt(new Date().getTime() / 1000) + ''
        var gameClassTime = localStorage.getItem('gameListTime')
        var isSend = localStorage.getItem('serve_quick')
        if ((this.time > gameClassTime || !gameClassTime) && isSend) {
            this.getGameClass()
            this.getGameList()
        } else {
            this.games = JSON.parse(localStorage.getItem('gameList'))
            if(this.games){
                this.gamesfilter = this.games.filter(item => item.is_under !== 1 && item.is_show == 1)
            }
            this.gamesClass = JSON.parse(localStorage.getItem('gameBoxClass'))
        }
    },
    mounted() {
        this.$nextTick(function () {
            // 立即监听一次
            setTimeout(() => {
                this.screenWidth = document.body.clientWidth
                if (this.screenWidth <= 1700) {
                    this.lang = true
                    this.delang = false
                } else {
                    this.lang = false
                    this.delang = true
                }
            })
            //  仅在整个视图都被重新渲染之后才会运行的代码
            window.addEventListener('resize', () => {
                // 监听窗口大小改变自适应样式
                setTimeout(() => {
                    this.screenWidth = document.body.clientWidth
                    if (this.screenWidth <= 1700) {
                        this.lang = true
                        this.delang = false
                    } else {
                        this.lang = false
                        this.delang = true
                    }
                })
            })
        })
    },
   
}
</script>
  
<style scoped>
a {
    text-decoration: none;
    cursor: pointer;
}

.gamelist {
    background: #f5f5f5;
}

.active {
    color: #B5945B !important;
}

.game_list {
    padding: 30px 0;
}

.list_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.biaozhi {
    width: 4px;
    height: 22px;
    background: #B5945B;
    margin-right: 10px;

}

.game_top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.game_top h4 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 0;
}

.list_item {
    display: flex;
    align-items: center;
    justify-content: end;
}

.list_item ul {
    list-style: none;
    display: flex;
    margin: 0;
}

.list_item ul li {
    margin-right: 40px;
    cursor: pointer;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4D4D4D;
    transition: all 0.5s;
}

.list_item ul li:hover {
    color: #B5945B;
    transition: all 0.5s;
}

.search_box {
    display: flex;
    align-items: center;
}

.fdj {
    position: absolute;
    margin-left: 8px;
}

.search {
    padding-left: 25px;
    border: 1px solid #808080;
    border-radius: 4px;
}

.search:focus {
    outline-style: none;
    border-color: #B5945B;
}

/* 模块 */
.box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: start;
    height: 100%;
}

.model {
    width: 19.45%;
    margin-top: 10px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.model1 {
    margin-right: 8px;
    margin-top: 10px;
    width: 287px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.model_w{
    /* height: 100%; */
    display: flex;
}
.model_img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    max-height: 158px;
}
.model_under{
    position: absolute;
    right: 0;
    top: 0;
    width: 25%;
}
.model_img_box {
    position: relative;
    height: 100%;
}

.model:nth-of-type(1) {
    margin-left: 0;
}

.model:nth-last-of-type(1) {
    margin-right: 0;
}

.model_title {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 15px 0;
}

.text_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.g_name{
    max-width: 150px;
}
.text_box h5 {
    margin: 0;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4D4D4D;
}

.text_logo {
    padding: 2px 10px;
    border: 1px solid #999999;
    border-radius: 4px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.btn_box {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.down {
    border-radius: 4px;
    padding: 5px 10px;
    background: #CA4242;
    margin-right: 5px;
}

.btn_box a {
    transition: all 0.5s;
}

.btn_box a:hover {
    filter: brightness(1.2);
    transition: all 0.5s;
}

.look {
    border-radius: 4px;
    padding: 5px 10px;
    background: #B5945B;
    margin-right: 5px;
}

.linqv {
    border-radius: 4px;
    padding: 5px 10px;
    background: #B5945B;
}

.lang_btn {
    -webkit-box-orient: vertical;
    white-space: nowrap;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.btn_font {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFEFE;
    text-decoration: none;
    width: 100%;
}
@media (max-width:1700px) {
    .model{
        width: 19.3%;
    }
    .model1{
        width: 251px;
    }
    .model_img{
        max-height: 150px;
    }
}

@media (max-width:1600px) {
    .game_top h4 {
        font-size: 20px;
    }

    .list_item ul li {
        font-size: 14px;
    }

    .model {
        margin-right: 7px;
    }

    .model1 {
        margin-right: 7px;
        width: 252px
    }
}

@media (max-width:1400px) {
    .g_name{
        max-width: 100px;
    }
    .model {
        margin-right: 5px;
        width: 19.5%;
    }
    .model1{
        width: 216px;
    }
    .list_item ul li {
        margin-right: 25px;
    }
}

@media (max-width:1300px) {

    .model {
        margin-right: 5px;
    }

    .model1 {
        margin-right: 5px;
        width: 217px
    }

    .text_logo {
        padding: 2px 5px;
    }

    .text_box h5 {
        font-size: 16px;
    }

    .btn_font {
        font-size: 12px;
    }

    .down {
        padding: 5px 10px;
    }

    .look {
        padding: 5px 10px;
    }

    .linqv {
        padding: 5px 10px;
    }
}

@media (max-width:1200px) {
    .list_item ul li {
        font-size: 12px;
        margin-right: 20px;
    }

    .v_pd {
        padding: 5px 12px;
    }

    .game_top h4 {
        font-size: 14px;
    }

    .model {
        margin-right: 5px;
        width: 19.45%;
    }

    .model1 {
        margin-right: 5px;
        width: 182px
    }

    .lang_btn {
        display: -webkit-box;
        text-overflow: ellipsis;
        word-break: break-all;
    }
    .model_img{
        max-height: 100px;
    }
}
</style>