<template>
  <div class="header">
    <HeaderTop></HeaderTop>
    <div class="menu">
      <div class="container menu_box">
        <div class="menu_left">
          <a class="menu_font" @click="toHome">首页</a>
          <span class="menu_font">&nbsp;>&nbsp;</span>
          <a class="menu_font" href="javascript:0">游戏详情</a>
        </div>
        <div class="menu_right">
          <div class="zz">
            <img class="logo" :src="img_url + getClient.channel_logo" width="100%">
          </div>
          <div class="fgx2"></div>
          <img class="logo2" :src="img_url + getClient.channel_log_bs" height="100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTop from './HeaderTop.vue'
import { mapState } from 'vuex'
export default {
  components: { HeaderTop },
  name: 'GameNavigation',
  data() {
    return {

    }
  },
  computed: {
    ...mapState([
      'getClient'
    ])
  },
  methods: {
    toHome() {
      this.$router.push({
        path: '/home'
      })
     location.reload()
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.menu {
  padding: 15px 0;
  background: #ffff;
}

.menu_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.menu_font {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4D4D4D;
  text-decoration: none;
  transition: all 0.5s;
}

.menu_font:hover {
  color: #AC8747;
  transition: all 0.5s;
}

.menu_right {
  display: flex;
  align-items: center;
  justify-content: end;
}

.zz {
  width: 145px;
  text-indent: 210px;
  overflow: hidden;
}

.logo {
  filter: drop-shadow(-210px 0 #B5945B);
}

.fgx2 {
  width: 1px;
  height: 50px;
  background: #B5945B;
  opacity: 0.3;
  margin: 0 20px;
}

@media (max-width:1600px) {
  .menu_font {
    font-size: 20px;
  }
}

@media (max-width:1300px) {
  .menu {
    padding: 10px 0;
  }

  .menu_font {
    font-size: 16px;
  }

  .logo {
    filter: drop-shadow(-185px 0 #B5945B);
    width: 80%;
  }

  .fgx2 {
    margin: 0 15px;
  }
}

@media (max-width:1200px) {

  .logo {
    filter: drop-shadow(-170px 0 #B5945B);
    width: 70%;
  }

  .logo2 {
    width: 40%;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1310px !important;
  }
}</style>