<template>
  <!-- 轮播 -->
  <div id="demo" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">

    <!-- 轮播图片 -->
    <div class="carousel-inner">
      <div class="carousel-item " :class="index == 0 ? 'active' : ''" v-for="(l, index) in material.pc_lunbo_img"
        :key="index">
        <a :href="material.pc_lunbo_img[index].type == 2 ? material.pc_lunbo_img[index].jump_url : 'javascript:0'"><img
            :src="img_url + l.img" class="d-block" width="100%" :height="lb_height"></a>
      </div>
    </div>

    <!-- 指示符 -->
    <div class="carousel-indicators">
      <a class="ind lang_one " :class="[index == 0 ? 'active' : '']" v-for="(item, index) in material.pc_lunbo_img" :key="index"
        data-bs-target="#demo" :data-bs-slide-to="index" ><span @click="toPath(item)">{{item.title}}</span></a>
    </div>
    <Login ref="login" @closeLogin="closeLogin"></Login>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { auto } from "@popperjs/core";
import Login from "../components/Login.vue";
import layer from "layui-layer";
export default {
  name: 'Carousel',
  components: {
    Login
  },
  props: ['lb_height'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'material',
      'getClient'
    ]),
    cz_id:{
        get() {
            return this.$store.state.cz_id
        },
        set(val) {
            return this.$store.state.cz_id = val
        }
    },
  },
  methods: {
    LoginOne() {
      this.$refs.login.open()
      $("body").parent().css("overflow-y", "hidden")
      $("body").parent().css("overflow-x", "hidden")
    },
    closeLogin() {
      $("body").parent().css("overflow-y", "auto")
      $("body").parent().css("overflow-x", "auto")
    },
    toPath(item) {
       //  this.$router.resolve({ path: '/member/wallet' }).route.meta.isLogin 拿到指定路由的信息如meta
      if (item.jump_url) {
        if(item.jump_url == '/register' && this.getClient.channel_other.is_register == 1){
          this.$message({
            message: "注册通道暂未开启！",
            type: 'warning',
            duration: 1500
          })
        }
        else if (this.$router.resolve({ path: item.jump_url }).route.meta.isLogin && !localStorage.getItem('token')) {
            this.LoginOne()
        }else{
          if(item.jump_url == '/register' && localStorage.getItem('token')){
            this.$message({
              message: "您已经登录，请退出登录后再试！",
              type: 'warning',
              duration: 1500
            })
          }else{
            this.$router.push({ path:  item.jump_url})
            if(this.$route.path == '/member/wallet' && item.title == '充值15.5倍' ){
              this.cz_id = 1
              this.$czc('轮播图-充值15.5倍', '点击', '访问平台充值')
            }
            else if(this.$route.path == '/member/wallet' && item.title == '月卡购买' ){
              this.cz_id = 2
              this.$czc('轮播图-月卡购买', '点击', '访问月卡购买')
            }
          }
          
        }
      }
    },
  },
  created() {
  }
}
</script>
<style scoped>
.carousel-indicators {
  margin: 0;
}

.ind {
  background: #000000 !important;
  opacity: 0.8 !important;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #E1E1E1 !important;
  text-decoration: none;
  padding: 12px 50px !important;
  text-align: center;
  transition: all 0.5s !important;
  display: flex;
  justify-content: center;
}

.ind:hover {
  background: #B5945B !important;
  opacity: 0.9 !important;
  transition: all 0.5s;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: auto;
  width: auto;
  height: auto;
  text-indent: inherit;
  border: none;
  margin: 0;
}

.active {
  background: #B5945B !important;
  opacity: 0.9 !important;
}

@media (max-width:1300px) {
  .ind {
    font-size: 12px;
    padding: 12px 40px !important;
  }
}

@media (max-width:1100px) {
  .ind {
    padding: 12px 30px !important;
  }
}</style>