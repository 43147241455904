<template>
    <div class="real_name">
        <div class="bag_title">
            <h4 class="font_color">创建账号</h4>
            <div class="tip">
                <div class="tip_icon flex_box font_color">?</div>
                <span class="font_color">如遇充值问题，请联系任意平台客服</span>
            </div>
        </div>
        <div class="content_box">
            <form class="real_form">
                <div class="form_lang">
                    <label>账号：</label>
                    <input type="text" class="name" v-model="account">
                </div>
                <div class="form_lang">
                    <label>密码：</label>
                    <input type="password" class="name" v-model="password">
                </div>
                <div class="form_lang">
                    <label>确认密码：</label>
                    <input type="password" class="name" v-model="repassword">
                </div>
                <a class="real_btn btn_color_real flex_box real_btn_font" @click="fun">立即提交</a>
            </form>
        </div>
    </div>
</template>

<script>
import { createUser} from '@/api/http.js'
import { mapState } from 'vuex'
export default {
    name: 'CreateUer',
    data() {
        return {
            account: '',
            password: '',
            repassword: '',
            bool: true
        }
    },
    computed:{
        ...mapState([
            'userInfo'
        ])
    },
    methods: {
        createUser() {
            var tok = localStorage.getItem('token')
            if (!tok) {
                this.$message({
                    message: "请登录后再试！",
                    type: 'warning',
                    onClose:()=>{
                        this.bool = true
                    },
                    duration: 1500
                });
            } else {
                var reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){8,}$/
                if (reg.test(this.password)) {
                    createUser({
                        account: this.account,
                        password: this.password,
                        repassword: this.repassword,
                    }).then((res) => {
                        if (res.code === 200) {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                                onClose:()=>{
                                    this.bool = true
                                    this.$router.push({
                                        path:'/member'
                                    })
                                    window.location.href=window.location.href
                                },
                                duration: 1500
                            });
                        } else {
                            console.log(res.msg)
                        }
                    }).catch((err) => {
                        console.log(err)
                        this.$message({
                            message: err.msg,
                            type: 'warning',
                            onCloseL:()=>{
                                this.bool = true
                            },
                            duration: 1500
                        });
                    })
                } else {
                    this.$message({
                        message: '密码长度为8-16位，必须由大写字母、小写字母、数字、特殊符号中的2种及以上类型组成',
                        type: 'warning',
                        onClose:()=>{
                            this.bool = true
                        },
                        duration: 3000
                    })
                }

            }

        },
        fun() {
            if(this.userInfo.user_mobile){  //判断是否绑定手机号
                if (this.bool) {
                    this.bool = false
                    this.createUser()
                }else{
                    this.$message({
                        message: '操作过于频繁，请稍后再试！',
                        type: 'warning',
                        duration: 1500
                    })
                }
            }else{
                this.$message({
                    message: '请先完善信息后使用该功能！',
                    type: 'warning',
                    duration: 1500
                })
            }
            

        }
    }
}
</script>

<style scoped>
.real_name {
    height: 100%;
    position: relative;
    z-index: 2;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 30px 40px;
}

/*  */
.bag_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(178, 147, 96, 0.2);
}

.bag_title h4 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin: 0;
}

.tip {
    display: flex;
    align-items: center;
    justify-content: end;
}

.tip_icon {
    width: 13px;
    height: 13px;
    border: 1px solid #806437;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-right: 5px;
}

.tip span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
}

.font_color {
    color: #886A35;
}

.real_btn_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
}

.btn_color_real {
    background: #B5945B;
    transition: all 0.5s;
}

.btn_color_real:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

/* 内容 */
.content_box {
    display: flex;
    justify-content: center;
}

.real_form {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.real_form label {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4D4D4D;
}

.real_form input {
    width: 410px;
    height: 50px;
    background: rgba(246, 242, 235, 0);
    border: 1px solid #B2B2B2;
    border-radius: 8px;
    outline-style: none;
}

.form_lang {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;
}

.real_btn {
    width: 410px;
    height: 50px;
    border-radius: 8px;
    margin-top: 10px;
}

@media (max-width:1600px) {
    .bag_title h4 {
        font-size: 20px;
    }

    .tip span {
        font-size: 12px;
    }

    .bag_title {
        padding-bottom: 15px;
    }
}

@media (max-width:1300px) {
    .real_name {
        padding: 20px 30px;
    }

    .bag_title h4 {
        font-size: 16px;
    }
}

@media (max-width:1100px) {
    .real_form label {
        font-size: 12px;
    }

    .real_btn_font {
        font-size: 12px;
    }

    .real_form input {
        width: 310px;
        height: 35px;
    }

    .real_btn {
        width: 310px;
        height: 35px;
    }
}
</style>