<template>
    <div class="content_box ">
        <video class="sp1" v-show="isVideo" autoplay loop muted preload style="width: 100%; height: 100%; object-fit: fill">
            <source src="/activity/continuousRecharge/img/sp2.mp4">
        </video>
        <img class="sp1" v-show="!isVideo" src="/activity/continuousRecharge/img/sp2.png" alt="">
        <!-- <img class="sp1" src="/activity/continuousRecharge/img/sp.png" alt=""> -->
        <!-- 背景 -->
        <div class="bcc">
            <img class="bc1" src="/activity/continuousRecharge/img/bc.png" alt="">
            <div class="contentaa">
                <div class="box_content">
                    <div class="contentaa_bc"></div>
                    <img class="title_img" src="/activity/continuousRecharge/img/title.png" alt="">
                    <!-- 用户名 -->
                    <div class="user_cz">
                        <span>用户：{{ userInfo.id }}</span>
                        <span>今日充值：{{ getMoney!=="0" ? getFiex(parseFloat(getMoney)) : 0 }}</span>
                    </div>
                    <!-- 三个宝箱 -->
                    <div class="day_box">

                        <div class="day_hz" v-for=" (item, index) in lianxuList.dataArr" :key="index">
                            <img src="/activity/continuousRecharge/img/day_bc.png" alt="">
                            <div class="day_content">
                                <div class="bx_content">
                                    <img class="day_title"
                                        :src="'/activity/continuousRecharge/img/day' + (index + 1) + '.png'" alt="">
                                    <img class="bx_img" :class="{ 'top_bx': item ? true : false }"
                                        :src="item ? '/activity/continuousRecharge/img/bx.png' : '/activity/continuousRecharge/img/bx1.png'"
                                        alt="">
                                    <img v-if="(index + 1) == lianxuList.num" class="bx_fl"
                                        src="/activity/continuousRecharge/img/fl.png" alt="">
                                    <div class="title_box">
                                        <span>额外{{ lianxuList.extra_arr[index] * 100 }}%收益</span>
                                        <span :style="item ? 'color: #4C9130; cursor:auto; ' : ''">{{ item ? '已到帐' : '未充值' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- 活动规则 -->
                    <div class="sm_box">
                        <img class="sm_img" src="/activity/continuousRecharge/img/shuoming.png" alt="">
                        <div class="sm_text">
                            <div class="sm_title">活动规则</div>
                            <div class="sm_content">
                                1.本次活动3天为一个周期。<br />
                                2.每天充值获得连充倍率加成奖励:首日10%,次日20%,三日30%;<br />
                                3.连续三天充值,第四天0点活动重置;<br />
                                4.必须连续三天充值才能享受对应天数加成，过程中断，重置回第一天倍率加成。<br />
                                5.该活动可与挡位加成活动叠加。<br />
                            </div>
                        </div>
                    </div>
                    <img class="title_img" src="/activity/continuousRecharge/img/cz_title.png" alt="">
                    <!-- 充值选择列表 -->
                    <div class="cz_box">
                        <img class="cz_money_img" src="/activity/continuousRecharge/img/cz_money.png" alt="">
                        <div class="czxz_box">

                            <div class="m-item" v-for="item in moneyList.feed_list" :key="item.value"
                                @click="getTabCont(item.value)">
                                <img class="cz_money"
                                    :src="item.value == tabCont ? '/activity/continuousRecharge/img/czxz1.png' : '/activity/continuousRecharge/img/czxz.png'"
                                    alt="">
                                <div class="money_xz"><span>{{ item.value }}</span>元</div>
                            </div>

                        </div>
                        <!-- 输入框 -->
                        <div class="czxz_input_box">
                            <div>
                                <img class="cz_input_img" src="/activity/continuousRecharge/img/cz_input.png" alt="">
                                <div class="cz_input"><input class="money_input" value="" type="number"
                                        placeholder="充值金额大于等于100" v-model="form.money" @input="getInput">
                                </div>
                            </div>
                        </div>

                        <!-- 联系qq -->
                        <div class="qq">
                            <img class="qq_img" src="/activity/continuousRecharge/img/qq.png" alt="">
                            <div class="main">
                                <div class="main_in">
                                    <input class="qq_input" type="number" v-model="form.contact"
                                        placeholder="请输入您的QQ号，以便后续处理充值异常等问题 ">
                                </div>
                            </div>
                        </div>
                        <img class="cz_money_img" src="/activity/continuousRecharge/img/cz_money2.png" alt="">
                        <div class="czxz_box">
                            <div class="zf_box" v-for="item in moneyList.pay_list" :key="item.key"
                                @click="getPayType(item.value)">
                                <img class="cz_money"
                                    :src="item.value == form.pay_type ? '/activity/continuousRecharge/img/czxz1.png' : '/activity/continuousRecharge/img/czxz.png'"
                                    alt="">
                                <div class="money_xz">{{ item.label }}</div>
                            </div>
                        </div>

                        <img class="cz_button_img" :style="!isHuoDon? 'filter: grayscale(100%);':''" @click="cz" src="/activity/continuousRecharge/img/cz_button.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <PayCode></PayCode>
    </div>
</template>
  
<script>
import { getLianxuRecharge, getUserInformation, getTodayRecharge, getPaymentAvailableList, initiatePayment, verifyOrderStatus } from '@/api/http.js'
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import PayCode from "@/components/PayCode.vue";
export default {
    name: 'ContinuousRecharge',
    components: {
        PayCode
    },
    data() {
        return {
            /* 充值表单 */
            form: {
                money: null,
                pay_type: null
            },
            /* 视频懒加载 */
            isVideo: false,
            /* 新活动列表 */
            lianxuList: {},
            /* 今日充值 */
            getMoney: null,
            /* 充值列表 */
            moneyList: {},
            /* 标签选择 */
            tabCont: 0,
            isHuoDon:false,
            errMsg:''
        }
    },
    computed: {
        userInfo: {
            get() {
                return this.$store.state.userInfo
            },
            set(val) {
                return this.$store.state.userInfo = val
            }
        },
        pay_ewm: {
            get() {
                return this.$store.state.pay_ewm
            },
            set(val) {
                return this.$store.state.pay_ewm = val
            }
        },
        pay_money: {
            get() {
                return this.$store.state.pay_money
            },
            set(val) {
                return this.$store.state.pay_money = val
            }
        },
        pay_type: {
            get() {
                return this.$store.state.pay_type
            },
            set(val) {
                return this.$store.state.pay_type = val
            }
        }
    },
    created() {
        /* 视频懒加载 */
        let a = setInterval(() => {
            if (document.querySelector('.sp1').readyState == 4) {
                this.isVideo = true
                clearInterval(a)
            }
        }, 10);
        if (localStorage.getItem('token')) {
            this.getUserInformation()
        }
        this.getLianxuRecharge(),
        this.getTodayRecharge(),
        this.getPaymentAvailableList()

    },
    methods: {
        /* 消息弹框 */
        msg(val) {
            return this.$message({
                message: val,
                type: 'warning',
                duration: 1500
            })
        },
        /* 充值按钮 */
        cz() {
            if (!this.isHuoDon) {
                this.msg(this.errMsg)
                return
            }
            if (!this.form.money) {
                this.msg('请先选择充值金额')
                return
            }
            if (this.form.money * 1 < this.moneyList.min_price * 1) {
                this.msg('充值金额小于最低限额' + this.moneyList.min_price)
                return
            }
            if (this.form.money * 1 > this.moneyList.max_price * 1) {
                this.msg('充值金额超限,最多充值' + this.moneyList.max_price)
                return
            }
            if (!this.form.contact) {
                this.msg('请输入联系方式')
                return
            }
            if (!this.form.pay_type) {
                this.msg('请选择充值渠道')
                return
            }
            if (!this.userInfo.user_idcard) {
                this.$message({
                    message: "您还未实名认证，请确认实名后再进行操作！",
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.$router.push({
                            path: '/member/realName'
                        })
                    }
                });
                return
            }
            this.$message({
                message: "订单生成中，请稍后···",
                type: 'success',
                duration: 1500,
            })
            this.initiatePayment()
        },
        //发起支付接口
        initiatePayment() {
            initiatePayment(this.form).then((res) => {
                if (res.code === 200) {
                    this.pay_ewm = res.data.pay_url
                    this.pay_money = res.data.money
                    this.pay_type = res.data.pay_type_msg
                    this.orderID = res.data.out_trade_no
                    this.payCode()
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                });
            })
        },

        // 支付二维码弹窗
        payCode() {
            var that = this
            var pay_tc = $('.pay_tc')
            var LayIndex = layer.open({
                type: 1,
                content: pay_tc,
                anim: false,
                fixed: true,
                shade: 0.6,
                title: 0,
                scrollbar: true,
                shadeClose: true,
                isOutAnim: false,
                area: [auto, auto],
                success: function () {
                    // console.log(that)
                    that.timer = setInterval(() => {
                        setTimeout(that.verifyOrderStatus(), 0)
                        if ($(".pay_tc").is(':hidden')) {
                            //处理业务  
                            clearInterval(that.timer)
                        }
                    }, 2000)
                },
            });
        },
        // 订单状态轮巡接口
        verifyOrderStatus() {
            verifyOrderStatus({
                out_trade_no: this.orderID
            }).then((res) => {
                if (res.code === 200) {
                    clearInterval(this.timer)
                    this.timer = ''
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            location.reload()
                        },
                    })
                }
            }).catch((err) => {
                console.log('捕获异常', err)

            })
        },
        /* 获取连续充值活动列表 */
        getLianxuRecharge() {
            getLianxuRecharge().then(res => {
                this.lianxuList = res.data
                this.isHuoDon = true
            }).catch(err=>{
                this.errMsg = err.msg
                this.msg(err.msg)
            })
        },
        // 确定登入状态，获取用户信息
        getUserInformation() {
            getUserInformation().then((res) => {
                if (res.code === 200) {
                    this.userInfo = res.data
                    this.unLogin = false
                    this.isLogin = true
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        // 获取今日充值
        getTodayRecharge() {
            getTodayRecharge().then(res => {
                this.getMoney = res.msg
            })
        },
        getFiex(val) {
            if (val) {
                return val.toFixed(2)
            }
        },
        /* 获取充值列表 */
        getPaymentAvailableList() {
            getPaymentAvailableList().then(res => {
                if (res.code == 200) {
                    this.moneyList = res.data
                }
            })
        },
        getTabCont(value) {
            this.form.money = value
            this.tabCont = value
        },
        getInput() {
            this.tabCont = 0
        },
        getPayType(val) {
            this.form.pay_type = val
        }
    },

}
</script>
  
<style scoped>
body {
    margin: 0;
    width: 100%;
    height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

.top_bx {
    margin: 4% 0 !important;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.sp1 {
    position: relative;
    width: 100%;
    z-index: -99;
}

.bcc {
    position: absolute;
    top: 60%;
    width: 100%;
    z-index: -1;
}

.bc1 {
    width: 100%;
}

.contentaa {
    position: absolute;
    top: 18%;
    width: 100%;
}

.contentaa_bc {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -9;
    background-color: rgb(193, 73, 49);
}

.content_box {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 1000px;
}

.box_content {
    position: relative;
    text-align: center;
    padding-bottom: 50px;
}

.title_img {
    width: 50%;
    margin-top: 25px;
}

.user_cz span {
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFF7DA;
    line-height: 25px;
    display: block;
    margin: 18px 0;
}

.day_box {
    display: flex;
    justify-content: center;
}

.day_bc {
    width: 20%;
}

.day_hz {
    position: relative;
}

.day_content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.day_hz>img {
    width: 100%;
}

.day_title {
    width: 20%;
    padding-top: 8.5%;
    padding-bottom: 1.5%;
    position: relative;
}

.bx_content {
    position: absolute;
    width: 100%;
    padding-right: 7.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bx_img {
    margin: 5% 0;
    width: 30%;
}

.bx_fl {
    position: absolute;
    top: 17%;
    right: 3%;
    width: 19.9%;
}

.title_box span {
    display: block;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E36D39;
    font-size: 1.3em;
    line-height: 25px;
}

.title_box span:last-child {
    color: #C54421;
    font-weight: bold;
}

.sm_box {
    position: relative;
    margin: 0 auto;
    width: 50%;
    margin-top: 25px;
}

.sm_img {
    width: 100%;
}

.sm_text {
    position: absolute;
    width: 100%;
    top: 5%;
}

.sm_title {
    width: 100%;
    text-align: center;
    font-size: 1.25em;
    font-weight: 800;
    color: #905A3C;
    line-height: 38px;
}

.sm_content {
    width: 95%;
    margin: 0 auto;
    font-size: 16px;
    font-family: Source Han Serif SC;
    font-weight: 800;
    color: #905A3C;
    line-height: 24px;
    text-align: left;
}

.cz_box {
    margin-top: 10px;

}

.cz_money {
    width: 100%;
}

.cz_money_img {
    margin-top: 30px;
    width: 8%;
}



.czxz_box {
    margin-top: 23px;
    display: flex;
    justify-content: center;
}


.czxz_box>div {
    cursor: pointer;
    position: relative;
}


.money_xz {
    position: absolute;
    font-size: 20px;
    top: 31%;
    width: 100%;
}



.czxz_input_box {
    display: flex;
    justify-content: center;
}

.czxz_input_box>div {
    position: relative;
}

.czxz_input_box {
    margin-top: 13px;

}

.cz_input_img {
    width: 100%;
}

.cz_input {
    position: absolute;
    top: 22%;
    width: 100%;
    height: 100%;
    text-align: left;
}

::v-deep .cz_input>input {
    font-size: 1.25em !important;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: black;
    line-height: 24px;
    margin-left: 10%;
    height: 56%;
    width: 75%;
    background: transparent;
    border: none !important;
}


.main {
    margin-left: 10px;
    width: 30%;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 8px;
    background-image: -webkit-linear-gradient(0deg, #FBB788, #F8CF6A);
}

.main_in {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: inset 0px 0px 3px black, inset 0px 0px 0px black;
    ;
    background: #B5381F;
}


.qq {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.qq_img {
    width: 13%;
    height: 13%;
}

::v-deep .qq_input:focus {
    border: none !important;
}

.qq_input {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #F2E9D0;
    width: 100%;
    height: 99.5%;
    background: transparent;
    border-radius: 20px;
    border: none;
    padding: 0 26px;
}

.qq_input::-webkit-input-placeholder {
    font-size: 20px;
    color: #F2E9D0;
}

.cz_button_img {
    cursor: pointer;
    width: 12%;
}

@media (max-width:1800px) {
    .sm_content {
        font-size: 14.5px;
    }

}

@media (max-width:1600px) {
    .sm_content {
        font-size: 12.5px;
    }


    .qq_input {
        font-size: 18px;
    }

    .qq_input::-webkit-input-placeholder {
        font-size: 18px;
    }

    .sm_title {
        font-size: 1.1em;
        line-height: 30px;
    }
}

@media (max-width:1300px) {
    ::v-deep .cz_input>input {
        font-size: 1.1em !important;
        margin-left: 22%;
    }

    ::v-deep .cz_input_img {
        width: 75% !important;
    }

    .zf_box {
        width: 12%;
    }

    .qq_input {
        font-size: 15px;
    }

    .qq_input::-webkit-input-placeholder {
        font-size: 15px;
    }

    .title_box span {
        font-size: 1em;
    }

    .m-item {
        width: 12%;
    }

    .money_xz {

        font-size: 13px;
    }


    .sm_title {
        font-size: 0.9em;
        line-height: 25px;
    }

    .sm_content {
        font-size: 12px;
    }
}

@media (max-width:1200px) {
    .sm_img {
        height: 210px;
    }
}

@media (max-width:1100px) {
    .title_box span {
        font-size: 0.8em;
        line-height: 20px;
    }

    ::v-deep .cz_input>input {
        font-size: 0.9em !important;
        margin-left: 25%;
    }

    ::v-deep .cz_input_img {
        width: 65% !important;
    }

    .zf_box {
        width: 12%;
    }

    .zf_box>div {}



    .m-item {
        width: 12%;
    }

    .money_xz {

        font-size: 13px;
    }


    .qq_input {
        font-size: 12px;
    }

    .qq_input::-webkit-input-placeholder {
        font-size: 12px;
    }

    .sm_img {
        height: 210px;
    }

    .czxz_box>div {
        margin: 0 15px;
    }
}

@media (max-width:1000px) {}
</style>