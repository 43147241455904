<template>
    <div>
        <Header></Header>
        <div class="new_detail">
            <div class="container">
                <div class="content_box">
                    <div class="content_bottom" >
                        <div class="new_content" v-html="newDate.content">
                        </div>
                    </div>
                </div> 
            </div>
            
            <!-- 背景 -->
            <img class="detail_bg" src="/NewDetail/img/bg.jpg" width="100%">
        </div>
        <Footer></Footer>
    </div>
  
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import {getArticleData} from '@/api/http.js'
    export default {
  components: { Header, Footer },
        name:'Bank',
        data() {
            return {
                newDate:{}, //新闻信息
                url:'/bank'
            }
        },
        methods:{
            getArticleData(){
                getArticleData({
                    id:this.$route.query.id
                }).then((res)=>{
                    if(res.code === 200){
                        this.newDate = res.data
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err)
                    this.$message({
                        message:'暂无信息',
                        type: 'warning',
                        duration: 3000
                    })
                })
            }
        },
        created(){
            this.getArticleData()
        }
    }
</script>

<style scoped>
.new_detail{
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin-top: 110px;
    background: #18181a;
}
.detail_bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.content_box{
    position: relative;
    z-index: 2;
    background: #FAFAFA;
    border-radius: 4px;
}

.content_bottom{
    padding: 30px;
    height: 100%;
}

.new_content{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4D4D4D;
    margin-top: 15px;
}
@media (max-width:1600px) {

    .content_bottom{
        padding: 20px;
    }
    .new_content{
        font-size: 12px;
    }
}
@media (max-width: 1300px){
    .new_detail{
        margin-top: 100px;
    }
    .content_bottom{
        padding:10px 20px;
    }
}

</style>