<template>
    <!-- 兑换模态框 -->
    <div class="modal fade" id="myModal2" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">

                <!-- 模态框头部 -->
                <div class="modal-header head_bg">
                    <h4 class="modal-title2">兑换商品</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="closeModel"></button>
                </div>

                <!-- 模态框内容 -->
                <div class="modal-body content_dh">
                    <div class="jf_box">
                        <div class="left_jf">
                            <img class="money_img" v-lazy="img_url + product_data.image_pc">
                            <div class="left_title">
                                <div class="left_tip">
                                    <h4 class="text_ellipsis">{{ product_data ? product_data.name : '' }}</h4>
                                    <span>需要灵符：{{ product_value }}</span>
                                </div>
                                <XtxNumbox @add="getNum" :inventory="Number(product_data.stock)">数量</XtxNumbox>
                                <span class="remain">剩余数量：{{ product_data.stock }}</span>
                            </div>
                        </div>
                        <div class="right_jf">
                            <a class="btn_color duihuan_btn" @click="CreateOrder">立即兑换</a>
                        </div>
                    </div>
                    <div class="select">
                        <span class="select_title">选择地址</span>
                        <div class="user_box">
                            <div class="">
                                <div class="user_title">
                                    <span>{{ model_address ? model_address.name : '' }}</span>
                                    <span>{{ model_address ? model_address.mobile : '' }}</span>
                                    <span>邮编：{{ model_address ? model_address.zipcode : '' }}</span>
                                </div>
                                <span class="address_xx">{{ model_address ? model_address.address : '' }}</span>
                            </div>
                            <img class="jt" :class="{ 'arrowRight': !flag, 'arrowDown': flag }"
                                @click="flag = !flag; showAddress()" src="/WelfareGift/img/jt.png" width="12px"
                                height="20px">
                        </div>
                    </div>
                    <div class="select_body display class_box">
                        <div class="user_info" v-for="ad in address" :key="ad.id" @click="changAddress(ad)">
                            <div class="user_title">
                                <span>{{ ad.name }}</span>
                                <span>{{ ad.mobile }}</span>
                                <span>邮编：{{ ad.zipcode }}</span>
                            </div>
                            <span class="address_xx">{{ ad.address }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {createProductOrder,getAddressList} from '@/api/http.js'
import XtxNumbox from '../components/Numbox.vue'
export default {
    name: 'RedemptionPopup',
    props:['product_data'],
    components:{
        XtxNumbox
    },
    data() {
        return {
            duihuan_flag: true, //立即兑换节流判断
            // product_data:[],
            model_address: {}, //地址选择
            address: [],
            flag: false, //控制箭头
        }
    },
    watch:{
        // num:{
        //     immediate: true, // 立即执行
        //     handler(nval, oval) {
        //         console.log('num变化了，输出总价',this.product_value)
        //     }
        // }
    },
    computed:{
        
        num: {
            get() {
                return this.$store.state.num
            },
            set(val) {
                return this.$store.state.num = val
            }
        },
        product_value:{
            get() {
                return this.$store.state.product_value
            },
            set(val) {
                return this.$store.state.product_value = val
            }
        },
        isAddress:{
            get() {
                return this.$store.state.isAddress
            },
            set(val) {
                return this.$store.state.isAddress = val
            }
        }
    },
    methods: {
        // 兑换灵符地址切换
        changAddress(ad) {
            this.model_address = ad
            //初始化下来菜单
            $(".select_body").slideToggle()
            this.flag = false
        },
        showAddress() {
            $(".select_body").slideToggle();
        },
        //获取地址列表
        getAddressList() {
            getAddressList().then((res) => {
                if (res.code == 200) {
                    this.address = res.data
                    this.model_address = res.data[0]
                    if (res.data.length > 0) {  //判断是否有默认地址
                        this.isAddress = true
                    } else {
                        this.isAddress = false
                    }
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        //获取数量
        getNum(val) {
            this.num = val
            this.product_value = val * Number(this.product_data.value).toFixed(0)
        },
        closeModel() { //初始化下拉菜单
            $(".select_body").hide()
            this.flag = false
            this.model_address = this.address[0] ? this.address[0] : {}
            this.num = 1
            this.product_value = Number(this.product_data.value).toFixed(0)
            // this.product_data = ''
        },
        //立即兑换按钮
        CreateOrder() {
            if (this.duihuan_flag) {
                this.duihuan_flag = false
                this.$confirm(`本次兑换需消耗${this.product_value}灵符,是否立即兑换!`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.createProductOrder()
                }).catch(() => {
                    this.duihuan_flag = true
                })
            } else {
                this.$message({
                    message: "操作过于频繁，请稍后再试~",
                    type: 'warning',
                    duration: 1500
                });
            }
        },
        //商品兑换接口
        createProductOrder() {
            createProductOrder({
                num: this.num,
                product_id: this.product_data.id,
                contact_name: this.model_address.name,
                contact_mobile: this.model_address.mobile,
                contact_address: this.model_address.address
            }).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.getAddressList()
                            this.duihuan_flag = true
                            location.reload()
                        }
                    })
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.duihuan_flag = true
                    }
                });
            })
        },
        //获取商品详情
        // getProductData() {
        //     getProductData({
        //         id: this.$route.query.id
        //     }).then((res) => {
        //         if (res.code == 200) {
        //             this.product_data = res.data
        //         }
        //     }).catch((err) => {
        //         console.log('捕获异常', err)
        //     })
        // },
    },
    created(){
        // this.getProductData()
        if(localStorage.getItem('token')){
            this.getAddressList()
        }
        
    }
}
</script>

<style scoped>
/* 模态框 */
.btn-close {
    box-shadow: none;
}

.modal-title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.modal-header {
    background: #F1F1F1;
    border-radius: 4px 4px 0px 0px;
}

.modal-body {
    padding: 20px 0 50px;
}

.form_address {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 50px;
}

.form_box {
    margin-bottom: 10px;
    display: flex;
}

.arge_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
}

.user_name {
    width: 600px;
    height: 40px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 0 10px;
}

.address {
    width: 600px;
    height: 160px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 10px;
}

.rodia_box {
    width: 600px;
}

.form-check-input {
    box-shadow: none;
    margin-right: 5px;
}

.form-check-input:checked {
    background-color: #AC8747;
    border-color: #AC8747;
}

.modal_btns {
    width: 600px;
    margin-top: 10px;
}

.save_point {
    margin-right: 10px;
}

.btn_radius {
    border-radius: 4px;
}

.save_btn {
    cursor: pointer;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    width: 100%;
    padding: 18px 0;
}

.remove_btn {
    cursor: pointer;
    width: 100%;
    padding: 18px 0;
    background: #f2f2f2;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
    text-decoration: none;
}

/* 商品详情模态框 */
.pro_img {
    max-height: 76vh;
}

::v-deep(.pro_img > p) {
    margin: 0;
}

::v-deep(.pro_img > p > img) {
    width: 100%;
}

.btn_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.class_box {
    overflow: auto;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #d1b481;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: #e6e6e6;
    padding: 10px 0;
}

.head_bg {
    background: #FFFFFF !important;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.modal-title2 {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #141414;
}

.content_dh {
    padding: 30px 0;
}

.jf_box {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 30px;
}

.left_jf {
    display: flex;
    justify-content: start;
    align-items: center;
}

.money_img {
    margin-right: 14px;
}

.left_title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.left_tip h4 {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
    max-width: 250px;
}

.left_tip span {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
}

.remain {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #BD5F58;
}

.right_jf {
    display: flex;
    align-items: center;
}

.duihuan_btn {
    padding: 15px 50px;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
}

.select {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px 30px 0;

}

.user_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.user_title span {
    margin-right: 30px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
}

.select_title {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #989898;
}

.address_xx {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
}

.jt {
    cursor: pointer;
}

.arrowRight {
    cursor: pointer;
    transition: 0.2s;
    transform-origin: center;
    transform: rotateZ(0deg);
    transition: all 0.5s;
}

.arrowDown {
    cursor: pointer;
    transition: 0.2s;
    transform-origin: center;
    transform: rotateZ(90deg);
    transition: all 0.5s;
}

.select_body {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-top: 20px;
    overflow: hidden;
    max-height: 160px;
}

.display {
    display: none;
}

.user_info {
    padding: 10px 30px 10px;
    border-top: 1px solid #EFEFEF;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s;
}

.user_info:hover {
    transition: all 0.5s;
    background: rgb(243, 244, 246);
}

.class_box {
    overflow: auto;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #d1b481;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: #e6e6e6;
    padding: 10px 0;
}

@media (max-width:1600px) {
    /* 立即兑换模态框 */
    .modal-title2 {
        font-size: 18px;
    }

    .left_tip h4 {
        font-size: 18px;
    }

    .left_tip span {
        font-size: 14px;
    }

    .left_tip span {
        font-size: 14px;
    }

    .duihuan_btn {
        font-size: 18px;
        padding: 10px 40px;
    }

    .select_title {
        font-size: 14px;
    }

    .user_title span {
        font-size: 18px;
    }

    .user_box {
        margin-top: 10px;
    }

    .address_xx {
        font-size: 18px;
    }

    .select_body {
        margin-top: 10px;
    }

    .content_dh {
        padding: 20px 0;
    }
}
@media (max-width:1400px) {
    /* 立即兑换模态框 */
    .modal-title2 {
        font-size: 16px;
    }

    .left_tip h4 {
        font-size: 16px;
    }

    .left_tip span {
        font-size: 12px;
    }

    .left_tip span {
        font-size: 12px;
    }

    .duihuan_btn {
        font-size: 16px;
        padding: 10px 30px;
    }

    .select_title {
        font-size: 12px;
    }

    .user_title span {
        font-size: 16px;
    }

    .address_xx {
        font-size: 16px;
    }
}
@media (max-width:1200px) {
    /* 立即兑换模态框 */
    .jf_box {
        padding-bottom: 20px;
    }

    .select {
        padding: 20px 20px 0;
    }

    .user_info {
        padding: 10px 20px 10px;
    }

    .modal-title2 {
        font-size: 14px;
    }

    .left_tip h4 {
        font-size: 14px;
    }

    .duihuan_btn {
        font-size: 14px;
        padding: 10px 20px;
    }

    .user_title span {
        font-size: 14px;
    }

    .address_xx {
        font-size: 14px;
    }
}

</style>