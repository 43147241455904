<template>
    <div class="report_tc">
      <img :src="img_url +material.pc_jb_ts">
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
    export default {
        name:'Age',
        data() {
          return {
          }
        },
        computed:{
          ...mapState([
            'getClient',
            'material'
          ])
        },
    }
  </script>
  
  <style scoped>
    /* 弹窗 */
    .report_tc{
      display: none;
    }
  </style>