import { render, staticRenderFns } from "./SquaredReceive.vue?vue&type=template&id=64458ac4&scoped=true&"
import script from "./SquaredReceive.vue?vue&type=script&lang=js&"
export * from "./SquaredReceive.vue?vue&type=script&lang=js&"
import style0 from "./SquaredReceive.vue?vue&type=style&index=0&id=64458ac4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64458ac4",
  null
  
)

export default component.exports