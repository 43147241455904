<template>
    <div>
        <Header></Header>
        <div class="new_detail">
            <div class="container">
                <div class="content_box">
                    <div class="content_top">
                        <router-link class="blod_font blod_font_color" to="/information">新闻咨询</router-link>
                        <span class="blod_font blod_font_color">&nbsp;>&nbsp;</span>
                        <a class="blod_font blod_font_color" href="javascript:0">新闻详情</a>
                    </div>
                    <div class="content_bottom" >
                        <div class="new_title">
                            <h5>{{newDate.title}}</h5>
                            <span>官方公告&nbsp;{{ newDate.created_at }}</span>
                        </div>
                        <div class="new_content" v-html="newDate.content">
                        </div>
                    </div>
                </div> 
            </div>
            
            <!-- 背景 -->
            <img class="detail_bg" :src="img_url + material.pc_background" width="100%">
        </div>
        <Footer></Footer>
    </div>
  
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import {getArticleData} from '@/api/http.js'
import { mapState } from 'vuex'
    export default {
  components: { Header, Footer },
        name:'NewDetail',
        data() {
            return {
                time:'',
                newDate:{} //新闻信息
            }
        },
        computed:{
            ...mapState([
                'material'
            ])
        },
        methods:{
            getArticleData(){
                getArticleData({
                    id:this.$route.query.id
                }).then((res)=>{
                    if(res.code === 200){
                        localStorage.setItem('newDate',JSON.stringify(res.data))
                        localStorage.setItem('newDataTime',[res.ex_time])
                        this.newDate = JSON.parse(localStorage.getItem('newDate'))
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err)
                })
            }
        },
        created(){
            this.time = parseInt(new Date().getTime() / 1000) + ''
            var newTime = localStorage.getItem('newDataTime')
            if(this.time > newTime || !newTime){
                this.getArticleData()
            }else{
                this.newDate = JSON.parse(localStorage.getItem('newDate'))
            }
        }
    }
</script>

<style scoped>
.new_detail{
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin-top: 110px;
    background: #18181a;
}
.detail_bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.content_box{
    position: relative;
    z-index: 2;
    background: #FAFAFA;
    border-radius: 4px;
}
.blod_font{
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-decoration: none;
}
.blod_font_color{
    color: #AC8747;
}
.content_top{
    padding: 30px;
    border-bottom:1px solid rgba(102,102,102,0.2);
}
.content_bottom{
    padding: 30px;
    height: 100%;
}
.new_title{
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(102,102,102,0.2);
}
.new_title h5{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
.new_title span{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}
.new_content{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4D4D4D;
    margin-top: 15px;
}
@media (max-width:1600px) {
    
    .blod_font{
        font-size: 16px;
    }
    .content_top{
        padding: 20px;
    }
    .content_bottom{
        padding: 20px;
    }
    .new_title h5{
        font-size: 14px;
    }
    .new_title span{
        font-size: 12px;
    }
    .new_content{
        font-size: 12px;
    }
}
@media (max-width: 1300px){
    .new_detail{
        margin-top: 100px;
    }
    .blod_font{
        font-size: 14px;
    }
    .content_top{
        padding:10px 20px;
    }
    .content_bottom{
        padding:10px 20px;
    }
}

</style>