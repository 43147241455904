<template>
    <div>
        <Header></Header>
        <div class="gift_box">
            <div class="container">
                <MemberTop></MemberTop>
                <div class="gift_bottom">
                    <div class="gift_top">
                        <div class="item_class">
                            <h5 class="gift_title gift_font_color" @click="getTabCont(1)"
                                :class="{ 'active': mall_id == 1 }">
                                实物展示</h5>
                            <div class="fgx_2"></div>
                            <h5 class="gift_title gift_font_color" @click="getTabCont(2)"
                                :class="{ 'active': mall_id == 2 }">
                                收货地址</h5>
                            <div class="fgx_2"></div>
                            <h5 class="gift_title gift_font_color" @click="getTabCont(3)"
                                :class="{ 'active': mall_id == 3 }">
                                兑换记录</h5>
                            <div class="fgx_2"></div>
                            <h5 class="gift_title gift_font_color" @click="getTabCont(4)"
                                :class="{ 'active': mall_id == 4 }">
                                灵符记录</h5>
                            <div class="fgx_2"></div>
                            <h5 class="gift_title gift_font_color" @click="getTabCont(5)"
                            :class="{ 'active': mall_id == 5 }">
                            兑换须知</h5>
                        </div>
                        <div class="integral">
                            <div class="lingfu">
                                <span>当前灵符：{{ Number(lingfu).toFixed(0) }}</span>
                            </div>
                            <!-- <a class="jf_btn btn_color jf_btn_font">获取灵符</a>
                            <div class="jf_title">
                                <a class="gift_font_color">灵符明细</a>
                                <div class="fgx"></div>
                                <a class="gift_font_color">灵符规则</a>
                            </div> -->
                        </div>
                    </div>
                    <div class="gift_content" :class="{ 'pd_cord': (mall_id == 3 || mall_id == 4) ? true : false }">
                        <!-- 获取灵符 -->
                        <div class="tab_item " v-if="mall_id == 1">
                            <div class="content_box" v-for="w in product_info" :key="w.id">
                                <div class="content_top flex_box">
                                    <!-- <h2 class="money_color">{{ w.value }}<span>元</span></h2> -->
                                    <!-- 背景 -->
                                    <router-link class="pro_box" :to="{
                                        path: '/productDetalis',
                                        query: {
                                            id: w.id
                                        }
                                    }"><img class="content_bg" v-lazy="img_url + w.image_pc"></router-link>
                                </div>
                                <div class="msg_box">
                                    <div class="msg_title">
                                        <router-link  :to="{
                                            path: '/productDetalis',
                                            query: {
                                                id: w.id
                                            }
                                        }">
                                            <h5 class="lang_point product_name" :title="w.name">{{ w.name }}</h5>
                                        </router-link>
                                        <h6>灵符:<span>{{ Number(w.value).toFixed(0) }}</span></h6>
                                    </div>
                                    <a class=" liqv_btn liqv_btn_font text_ellipsis" v-if="unLogin" title="立即兑换" @click="toLogin">立即兑换</a>
                                    <a class=" liqv_btn liqv_btn_font text_ellipsis" v-else-if="isAddress" data-bs-toggle="modal"
                                        data-bs-target="#myModal2" @click="Redeem(w.id)" title="立即兑换">立即兑换</a>
                                    <a class=" liqv_btn liqv_btn_font text_ellipsis" v-else @click="Redeem(w.id)"
                                        title="立即兑换">立即兑换</a>
                                </div>
                            </div>
                            <Pagination class="page_possion" @change="changePage2" :current="pagination2.p"
                                :pageSize="pagination2.pageSize" :total="total2" :hideOnSinglePage="hideOnSinglePage"
                                :showQuickJumper="true" :showTotal="true" placement="right" v-if="pagination2 && total2">
                            </Pagination>
                        </div>
                        <!-- 收货地址 -->
                        <div class="tab_item2 " v-if="mall_id == 2">
                            <div class="address_box">
                                <div class="address_lang">
                                    <div class="row">
                                        <div class="col-4" v-for="a in address" :key="a.id">
                                            <div class="address_item">
                                                <div class="base_info">
                                                    <span class="base_name lang_point" :title="a.name">姓名：{{ a.name }}</span>
                                                    <span>手机号：{{ a.mobile }}</span>
                                                    <span>邮政编码：{{ a.zipcode }}</span>
                                                </div>
                                                <div class="souhuo lang_point">
                                                    <span :title="a.address">收货地址：{{ a.address }}</span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="set_btn lang_point" v-if="a.status == 0"
                                                            @click="setDefaultAddress(a.id)">设置为默认地址</div>
                                                        <div class="set_btn  defaul_color lang_point" v-else>默认地址</div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="set_btn lang_point" data-bs-toggle="modal"
                                                            data-bs-target="#myModal"
                                                            @click="add_or_updata = 2; upDataPush(a)">修改</div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="set_btn lang_point" @click="remove_add(a.id)">删除</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ad_box">
                                    <a class="address_btn  btn_color" v-if="unLogin" @click="toLogin">新增收货地址</a>
                                    <a class="address_btn  btn_color" v-else data-bs-toggle="modal" data-bs-target="#myModal"
                                        @click="add_or_updata = 1">新增收货地址</a>
                                    <span class="ad_title">您已创建{{ address.length }}个收货地址</span>
                                </div>
                            </div>
                        </div>
                        <!-- 兑换记录 -->
                        <div class="tab_item2 " v-if="mall_id == 3">
                            <table class="table   ">
                                <thead class="table_th_color">
                                    <tr>
                                        <th>兑换商品名称</th>
                                        <th>兑换所需灵符</th>
                                        <th>数量</th>
                                        <th>状态</th>
                                        <th>快递单号</th>
                                        <th>兑换时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="t in duihuan_cord" :key="t.id">
                                        <td>{{ t.product_id.name }}</td>
                                        <td>{{ Number(t.value).toFixed(0) }}</td>
                                        <td>{{ t.num }}</td>
                                        <td>{{ t.status }}</td>
                                        <td>{{ t.delivery_no }}</td>
                                        <td>{{ t.created_date }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Pagination class="page_possion" @change="changePage" :current="pagination.p"
                                :pageSize="pagination.pageSize" :total="total" :hideOnSinglePage="hideOnSinglePage"
                                :showQuickJumper="true" :showTotal="true" placement="right" v-if="pagination && total">
                            </Pagination>
                        </div>
                        <!-- 灵符记录 -->
                        <div class="tab_item2 " v-if="mall_id == 4">
                            <table class="table ">
                                <thead class="table_th_color">
                                    <tr>
                                        <th>编号</th>
                                        <th>交易金额</th>
                                        <th>用途/来源</th>
                                        <th>备注</th>
                                        <th>时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="t in lingfu_cord" :key="t.id">
                                        <td>{{ t.id }}</td>
                                        <td>{{ t.wallet_money - t.wallet_before }}</td>
                                        <td>{{ getClient.title }}</td>
                                        <td>{{ t.remarks }}</td>
                                        <td>{{ t.create_time }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Pagination class="page_possion" @change="changePage3" :current="pagination3.p"
                                :pageSize="pagination3.pageSize" :total="total3" :hideOnSinglePage="hideOnSinglePage"
                                :showQuickJumper="true" :showTotal="true" placement="right" v-if="pagination3 && total3">
                            </Pagination>
                        </div>
                        <div class="tab_item2 " v-if="mall_id == 5">
                            <div v-html="redem.content"></div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- 背景 -->
            <img class="gift_bg" :src="img_url + material.pc_background" width="100%">
        </div>
        <!-- 地址模态框 -->
        <div class="modal fade" id="myModal">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <!-- 模态框头部 -->
                    <div class="modal-header">
                        <h4 class="modal-title">{{ add_or_updata == 1 ? '添加收货地址' : '修改收货地址' }}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="clearAddressInfo"></button>
                    </div>

                    <!-- 模态框内容 -->
                    <div class="modal-body">
                        <div class="form_address">
                            <div class="form_box">
                                <label class="arge_font">收货人：</label>
                                <input type="text" class="user_name" placeholder="收货人姓名" v-model="add_address.name">
                            </div>
                            <div class="form_box">
                                <label class="arge_font">手机号：</label>
                                <input type="text" class="user_name" placeholder="手机号码"
                                    onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="11"
                                    v-model="add_address.mobile">
                            </div>
                            <div class="form_box">
                                <label class="arge_font">详细地址：</label>
                                <textarea type="text" class="address" placeholder="收货详细地址"
                                    v-model="add_address.address"></textarea>
                            </div>
                            <div class="form_box">
                                <label class="arge_font">邮政编码：</label>
                                <input type="text" class="user_name" placeholder="当地邮政编码"
                                    onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="6" v-model="add_address.zipcode">
                            </div>
                            <div class="form_box">
                                <div class="rodia_box">
                                    <input id="default" class="form-check-input" type="checkbox" name="remember" value="1"
                                        v-model="add_address.status">
                                    <label for="default" class="arge_font">设置成默认地址</label>
                                </div>
                            </div>
                            <div class="modal_btns flex_box">
                                <div class="btn_color btn_radius save_point save_btn flex_box" v-if="add_or_updata == 1"
                                    @click="saveAddress">
                                    保存新地址</div>
                                <div class="btn_color btn_radius save_point save_btn flex_box" v-else
                                    @click="updataAddress()">保存修改</div>
                                <div class="btn_color btn_radius remove_btn flex_box" data-bs-dismiss="modal"
                                    @click="clearAddressInfo">取消</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RedemptionPopup :product_data="product_data"></RedemptionPopup>
        <Footer></Footer>
        <Login ref="login"></Login>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
import MemberTop from '../components/MemberTop.vue'
import Footer from '../components/Footer.vue'
import Pagination from '../components/page.vue'
import RedemptionPopup from '../components/RedemptionPopup.vue'
import { mapState } from 'vuex'
import { getProductList, getProductData, getAddressList, getUserLingfuData, createUserAddress, editAddrssData, editUserAddress, delAddress, exchangeRecords, lingfuRecords,productArticle } from '../api/http.js'
export default {
    name: 'PointsMall',
    components: { MemberTop, Header, Footer, Pagination,RedemptionPopup },
    data() {
        return {
            flag: false, //控制箭头
            product_info: [], //商品列表
            product_data: {}, //商品详情
            address: [],
            lingfu: 0, //灵符数量
            mall_id: 1,
            flag: true, //设置默认地址节流判断
            bool: true, //添加地址节流判断
            add_or_updata: '', //判断调用模态框的状态 1：为添加，2：为修改
            updata_flag: true,//修改地址节流判断
            del_flag: true,//删除地址节流判断
            // num:1, //兑换数量
            login_flag:true, //登录弹窗提示节流
            duihuan_flag: true, //立即兑换节流判断
            duihuan_cord: [], //兑换记录
            lingfu_cord: [], //灵符记录
            add_address: {
                id: '',
                name: '',        //收货人
                mobile: '',      //电话
                address: '',     //详细地址
                zipcode: '',     //邮编
                status: ''   //是否设为默认地址
            }, //添加地址表单
            hideOnSinglePage: true,
            total: 1,
            total2: 1,
            total3: 1,
            pager: {},
            pager2: {},
            pager3: {},
            pagination: {
                pageSize: 10,
                p: 1
            },
            pagination2: {
                pageSize: 10,
                p: 1
            },
            pagination3: {
                pageSize: 10,
                p: 1
            },
            redem:'',//兑换须知
            time:''
        }
    },
    watch: {
        pager: {
            deep: true,
            immediate: true,
            handler(nval, oval) {
                if(this.isLogin){
                    this.exchangeRecords()
                }
            }
        },
        pager2: {
            deep: true,
            immediate: true,
            handler(nval, oval) {
                this.getProductList()
            }
        },
        pager3: {
            deep: true,
            immediate: true,
            handler(nval, oval) {
                if(this.isLogin){
                    this.lingfuRecords()
                }
            }
        }
    },
    computed: {
        ...mapState([
            'material',
            'getClient',
            'unLogin',
            'isLogin'
        ]),
        num: {
            get() {
                return this.$store.state.num
            },
            set(val) {
                return this.$store.state.num = val
            }
        },
        isAddress:{
            get() {
                return this.$store.state.isAddress
            },
            set(val) {
                return this.$store.state.isAddress = val
            }
        },
        product_value: {
            get() {
                return this.$store.state.product_value
            },
            set(val) {
                return this.$store.state.product_value = val
            }
        }
    },
    methods: {
        //兑换须知
        productArticle(){
            const material = JSON.parse(localStorage.getItem('material'))
            productArticle({
                id:material.product_xz
            }).then((res)=>{
                if(res.code === 200){
                    localStorage.setItem('redem',JSON.stringify(res.data))
                    localStorage.setItem('redemTime',[res.ex_time])
                    this.redem = JSON.parse(localStorage.getItem('redem'))
                }
            }).catch((err)=>{
                console.log('捕获异常',err)
            })
        },
        toLogin(){
            if(this.login_flag){
                this.login_flag = false
                this.$message({
                    message: '请先登录后使用该功能！',
                    type: 'warning',
                    duration: 1500,
                    onClose:()=>{
                        this.$refs.login.open()
                        this.login_flag = true
                    }
                })
            }
        },
        //修改地址按钮传入初始数据进入模态框
        upDataPush(a) {
            this.add_address.id = a.id,
                this.add_address.name = a.name,
                this.add_address.mobile = a.mobile,
                this.add_address.address = a.address,
                this.add_address.zipcode = a.zipcode,
                this.add_address.status = a.status
        },
        //清除地址表单信息
        clearAddressInfo() {
            this.add_address = {
                id: '',
                name: '',        //收货人
                mobile: '',      //电话
                address: '',     //详细地址
                zipcode: '',     //邮编
                status: ''   //是否设为默认地址
            }
        },
        changePage(pager) { // 分页器回调
            this.$emit('change', pager)
            this.pager = pager
        },
        changePage2(pager) { // 分页器回调
            this.$emit('change', pager)
            this.pager2 = pager
        },
        changePage3(pager) { // 分页器回调
            this.$emit('change', pager)
            this.pager3 = pager
        },
        //获取商品列表
        getProductList() {
            getProductList({
                page: this.pager2.page
            }).then((res) => {
                if (res.code == 200) {
                    this.product_info = res.data.data
                    this.total2 = res.data.total
                    this.pagination2.pageSize = res.data.per_page
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        //获取商品详情
        getProductData(id) {
            getProductData({
                id: id
            }).then((res) => {
                if (res.code == 200) {
                    this.product_data = res.data
                    this.product_value = Number(res.data.value).toFixed(0)
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        // 立即兑换按钮
        Redeem(id) {
            if (this.isAddress) {
                this.getProductData(id)
            } else {
                this.$message({
                    message: '请先添加默认地址！',
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.getTabCont(2)
                        // 关闭模态框和遮罩
                        const modal = document.querySelector('#myModal3');
                        if(modal){
                            modal.classList.remove('show');
                        }
                        const modalBackdrop = document.querySelector('.modal-backdrop');
                        if (modalBackdrop) {
                            modalBackdrop.remove();
                        }
                    }
                })
            }

        },
        // 商品详情事件
        productDetails(id) {
            this.getProductData(id)
        },
        //获取地址列表
        getAddressList() {
            getAddressList().then((res) => {
                if (res.code == 200) {
                    this.address = res.data
                    if (res.data.length > 0) {  //判断是否有默认地址
                        this.isAddress = true
                    } else {
                        this.isAddress = false
                    }
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        //获取用户灵符信息
        getUserLingfuData() {
            getUserLingfuData().then((res) => {
                if (res.code == 200) {
                    this.lingfu = res.data.available

                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        //添加收货地址
        createUserAddress() {
            createUserAddress({
                name: this.add_address.name,
                mobile: this.add_address.mobile,
                address: this.add_address.address,
                status: this.add_address.status ? 1 : '',
                zipcode: this.add_address.zipcode
            }).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: '添加成功！',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.bool = true
                            location.reload()
                        }
                    })
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.bool = true
                    }
                })
            })
        },
        //保存新地址
        saveAddress() {
            if (this.bool) {
                this.bool = false
                this.createUserAddress()
            } else {
                this.$message({
                    message: "操作过于频繁，请稍后再试~",
                    type: 'warning',
                    duration: 1500
                });
            }

        },
        // 切换视图
        getTabCont(index) {
            this.mall_id = index
            localStorage.setItem('mall_id', index)
        },

        //设为默认地址接口
        editAddrssData(id) {
            editAddrssData({
                id: id
            }).then((res) => {
                if (res.code == 200) {
                    this.getAddressList()
                    this.$message({
                        message: '设置成功！',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.flag = true
                        }
                    })
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.flag = true
                    }
                });
            })
        },
        //设为默认地址按钮
        setDefaultAddress(id) {
            if (this.flag) {
                this.flag = false
                this.editAddrssData(id)
            } else {
                this.$message({
                    message: "操作过于频繁，请稍后再试~",
                    type: 'warning',
                    duration: 1500
                });
            }

        },
        //修改地址接口
        editUserAddress() {
            editUserAddress({
                id: this.add_address.id,
                name: this.add_address.name,
                mobile: this.add_address.mobile,
                address: this.add_address.address,
                status: this.add_address.status ? 1 : '',
                zipcode: this.add_address.zipcode
            }).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: '设置成功！',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.updata_flag = true
                            location.reload()
                        }
                    })
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.updata_flag = true
                    }
                });
            })
        },
        // 修改地址按钮
        updataAddress() {
            if (this.updata_flag) {
                this.updata_flag = false
                this.editUserAddress()
            } else {
                this.$message({
                    message: "操作过于频繁，请稍后再试~",
                    type: 'warning',
                    duration: 1500
                });
            }
        },
        //删除接口
        delAddress(id) {
            delAddress({
                id: id
            }).then((res) => {
                if (res.code == 200) {
                    this.getAddressList()
                    this.$message({
                        message: '设置成功！',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.del_flag = true
                        }
                    })
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.del_flag = true
                    }
                });
            })
        },
        //删除按钮
        remove_add(id) {
            if (this.del_flag) {
                this.del_flag = false
                this.delAddress(id)
            } else {
                this.$message({
                    message: "操作过于频繁，请稍后再试~",
                    type: 'warning',
                    duration: 1500
                });
            }

        },
        //兑换记录接口
        exchangeRecords() {
            exchangeRecords({
                page: this.pager.page
            }).then((res) => {
                if (res.code == 200) {
                    this.duihuan_cord = res.data.data
                    this.total = res.data.total
                    this.pagination.pageSize = res.data.per_page
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                });
            })
        },
        // 灵符记录接口
        lingfuRecords() {
            lingfuRecords({
                wallet_3_log_page: this.pager3.page
            }).then((res) => {
                if (res.code == 200) {
                    this.lingfu_cord = res.data.data
                    this.total3 = res.data.total
                    this.pagination3.pageSize = res.data.per_page
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                });
            })
        }
    },
    created() {
        this.getProductList()

        this.time = parseInt(new Date().getTime() / 1000) + ''
        var redeTime = localStorage.getItem('redemTime')
        if(this.time > redeTime || !redeTime){
            console.log('我不存在')
            this.productArticle()
        }else{
            this.redem = JSON.parse(localStorage.getItem('redem'))
        }

        if(localStorage.getItem('token')){
            this.getAddressList()
            this.getUserLingfuData()
            this.exchangeRecords()
            this.lingfuRecords()
        }
        
        if (localStorage.getItem('mall_id')) {
            this.mall_id = localStorage.getItem('mall_id')
        }
    },
}
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;
}

.gift_box {
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin-top: 110px;
    background: #18181a;
}

.gift_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

/* gift_bottom */
.gift_bottom {
    position: relative;
    z-index: 2;
}

.gift_bottom {
    margin-top: 10px;
    background: #FAFAFA;
    border-radius: 4px;

}

.gift_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 30px;
    border-bottom: 1px solid rgba(172, 135, 71, 0.2);
}

.item_class {
    display: flex;
    align-items: center;
    justify-content: start;
}

.gift_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
}

.gift_font_color {
    color: #9A9A9A;
}

.fgx_2 {
    width: 1px;
    height: 16px;
    background: #DCD2C1;
    margin: 0 10px;
}

.active {
    color: #AC8747;
}

.jf_btn {
    padding: 5px 12px;
    border-radius: 4px;
}

.jf_btn_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FAFAFA;
    text-decoration: none;
}

.integral {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lingfu {
    padding: 5px 20px;
    background: #f0f0f0;
    border-radius: 4px;

}

.lingfu span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a92828;
}

.jf_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
}

.jf_title a {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-decoration: none;
}

.fgx {
    background: #DBD5CC;
    width: 1px;
    height: 13px;
    margin: 0 10px;
}

/* 会员福利模块 */
.gift_content {
    padding: 20px 30px 60px;
    min-height: 400px;
}

.pd_cord {
    padding: 0 0 60px !important;
}

.tab_item {
    display: flex;
    flex-wrap: wrap;
}

.content_box {
    width: 19.2%;
    background: rgba(221, 154, 154, 0);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-top: 10px;
    margin-right: 10px;
}

.content_top {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.5s;
    max-height: 185px;
    background: #FFFFFF;
}
.content_top:hover .content_bg {
    transform: scale(1.1);
    transition: all 0.5s;
}
.pro_box{
    position: relative;
    z-index: 10;
}
.content_bg {
    position: relative;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
}

.content_tip {
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, #E13A24, #FA634E);
    border: 1px solid #EADDC7;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px;
}

.tip_font {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFEC1;
}

.money_color {
    font-size: 110px;
    font-family: Helvetica Neue LT Std;
    font-weight: 900;
    background: linear-gradient(0deg, #AC823A 0%, #DCB97D 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content_top h2 {
    display: flex;
    align-items: flex-end;
}

.content_top h2 span {
    font-size: 20px;
    padding-bottom: 20px;
}

.msg_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
}

.liqv_btn {
    padding: 3px 15px;
    border-radius: 4px;
    border: 1px solid #AC8747;
}

.liqv_btn_font {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 100;
    color: #AC8747;
    text-decoration: none;
    transition: all 0.5s;
}

.liqv_btn_font:hover {
    transition: all 0.5s;
    color: #FFFFFF;
    background: #ceae78;
    border: 1px solid #ceae78;
}

.msg_title h5 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 7px;
    text-align: center;
}

.msg_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product_name {
    cursor: pointer;
    transition: all 0.5s;
}

.product_name:hover {
    color: #B5945B;
    transition: all 0.5s;
}

.msg_title h6 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    text-align: center;
    margin-bottom: 10px;
}

.msg_title h6 span {
    color: #BB2929;
}

/* 收货地址 */
.address_btn {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 4px;
    margin-right: 8px;
}

.ad_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #979797;
}

.ad_box {
    margin-top: 20px;
}

.address_lang {
    width: 100%;
}

.address_item {
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 2px;
    margin-bottom: 10px;
}

.row>div {
    padding: 0 6px;
}

.address_item span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #656565;
}

.base_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.base_name{
    max-width: 20%;
}

.souhuo {
    margin: 18px 0;
}

.set_btn {
    background: #cdcdcd;
    border-radius: 4px;
    padding: 6px 0;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
    transition: all 0.5s;
    cursor: pointer;
    text-align: center;
}

.defaul_color {
    background: #AC8747;
    color: #FFFFFF !important;
}

.set_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

.tab_item2 {
    width: 100%;
}

.table_th_color {
    background: #F5F5F5;
}

/* 模态框 */
.btn-close {
    box-shadow: none;
}

.modal-title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.modal-header {
    background: #F1F1F1;
    border-radius: 4px 4px 0px 0px;
}

.modal-body {
    padding: 20px 0 50px;
}

.form_address {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 50px;
}

.form_box {
    margin-bottom: 10px;
    display: flex;
}

.arge_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
}

.user_name {
    width: 600px;
    height: 40px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 0 10px;
}

.address {
    width: 600px;
    height: 160px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 10px;
}

.rodia_box {
    width: 600px;
}

.form-check-input {
    box-shadow: none;
    margin-right: 5px;
}

.form-check-input:checked {
    background-color: #AC8747;
    border-color: #AC8747;
}

.modal_btns {
    width: 600px;
    margin-top: 10px;
}

.save_point {
    margin-right: 10px;
}

.btn_radius {
    border-radius: 4px;
}

.save_btn {
    cursor: pointer;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    width: 100%;
    padding: 18px 0;
}

.remove_btn {
    cursor: pointer;
    width: 100%;
    padding: 18px 0;
    background: #f2f2f2;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808080;
    text-decoration: none;
}

/* 商品详情模态框 */
.pro_img {
    max-height: 76vh;
}

::v-deep(.pro_img > p) {
    margin: 0;
}

::v-deep(.pro_img > p > img) {
    width: 100%;
}

.btn_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.class_box {
    overflow: auto;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #d1b481;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: #e6e6e6;
    padding: 10px 0;
}

/* 兑换记录 */
th,
td {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #463F34;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.page_possion {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

@media (max-width:1700px) {
    .content_box {
        margin-right: 9px;
    }
}

@media (max-width:1600px) {

    /* 会员福利 */
    .content_box {
        width: 19.1%;
    }

    .money_color {
        font-size: 80px;
    }

    .content_top h2 span {
        padding-bottom: 10px;
    }

    .jf_btn {
        padding: 5px 12px;
    }

    .gift_title {
        font-size: 16px;
    }

    .lingfu span {
        font-size: 14px;
    }

    /* 收货地址 */
    .address_btn {
        font-size: 16px;
    }

    .ad_title {
        font-size: 16px;
    }

    .souhuo {
        margin: 12px 0;
    }

    .set_btn {
        font-size: 14px;
    }
}

@media (max-width: 1400px) {
    .gift_box {
        margin-top: 100px;
    }

    .gift_title {
        font-size: 14px;
    }

    .gift_top {
        padding: 15px 30px;
    }

    .jf_btn_font {
        font-size: 12px;
    }

    .jf_title a {
        font-size: 12px;
    }

    /* 会员福利 */
    .content_box {
        width: 19%;
    }

    .gift_content {
        padding: 10px 30px 60px;
    }

    .money_color {
        font-size: 60px;
    }

    .content_top h2 span {
        font-size: 15px;
    }

    .msg_title h5 {
        font-size: 16px;
    }

    .msg_title h6 {
        font-size: 12px;
    }

    .liqv_btn {
        padding: 2px 10px;
    }

    .liqv_btn_font {
        font-size: 12px;
    }

    .content_tip {
        padding: 2px;
    }

    /* 收货地址 */
    .address_btn {
        padding: 5px 30px;
        font-size: 12px;
    }

    .ad_title {
        font-size: 12px;
    }

    /* 地址管理模态框 */
    .modal-title {
        font-size: 15px;
    }

    .arge_font {
        font-size: 14px;
    }

    input::-webkit-input-placeholder {
        /*WebKit browsers*/
        font-size: 12px;
    }

    input::-moz-input-placeholder {
        /*Mozilla Firefox*/
        font-size: 12px;
    }

    input::-ms-input-placeholder {
        /*Internet Explorer*/
        font-size: 12px;
    }

    textarea::-webkit-input-placeholder {
        /*WebKit browsers*/
        font-size: 12px;
    }

    textarea::-moz-input-placeholder {
        /*Mozilla Firefox*/
        font-size: 12px;
    }

    textarea::-ms-input-placeholder {
        /*Internet Explorer*/
        font-size: 12px;
    }

    .modal-lg,
    .modal-xl {
        --bs-modal-width: 700px;
    }

    .user_name {
        width: 500px;
    }

    .address {
        width: 500px;
    }

    .rodia_box {
        width: 500px;
    }

    .modal_btns {
        width: 500px;
    }

    .save_btn {
        padding: 12px 0;
        font-size: 14px;
    }

    .remove_btn {
        padding: 12px 0;
        font-size: 14px;
    }

    .set_btn[data-v-122f608a] {
        font-size: 12px;
        padding: 3px 0;
    }

    .ad_box[data-v-122f608a] {
        margin-top: 10px;
    }

    .address_item {
        padding: 15px;
    }

    .address_item span {
        font-size: 12px;
    }

    /* 表格 */
    th,
    td {
        font-size: 12px;
    }

    
}

@media (max-width:1200px) {

    /* 表格 */
    th,
    td {
        padding-left: 20px;
    }

    /*  */
    .lingfu span {
        font-size: 12px;
    }

    .gift_top {
        padding: 10px 20px;
    }

    .gift_content {
        padding: 10px 20px 60px;
    }

    .gift_title{
        font-size: 12px;
    }
    /* 会员福利 */
    .content_box {
        margin-top: 8px;
        margin-right: 8px;
    }

    .money_color {
        font-size: 50px;
    }

    .content_top h2 span {
        font-size: 12px;
    }

    .msg_title h5 {
        font-size: 14px;
    }

    .liqv_btn {
        padding: 2px 5px;
    }

    /* 地址管理模态框 */
    .modal-title {
        font-size: 12px;
    }

    .arge_font {
        font-size: 12px;
    }

    .user_name {
        height: 30px;
    }

    .address {
        height: 120px;
    }
    .save_btn {
        padding: 10px 0;
        font-size: 12px;
    }

    .remove_btn {
        padding: 10px 0;
        font-size: 12px;
    }

    .modal-body {
        padding: 20px 0 35px;
    }

    
}
</style>