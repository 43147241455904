<template>
    <div class="mb_box">
        <div class="row">
            <div class="col-8">
                <div class="member_box">
                    <div class="member_msg">
                        <!-- <img class="head_png" src="/member/img/head.png"> -->
                        <div class="flex_box tx_box">
                            <!-- 头像框 -->
                            <div class="tx_content" v-if="userInfo.head_img">
                                <img class="head_img" :src="userInfo.head_img" width="100%">
                            </div>
                            <div class="tx_content" v-else>
                                <img class="touxian" src="/banner/img/rw.png" width="100%">
                            </div>
                            <img class="touxian" src="/banner/img/txk2.png" width="100%">
                        </div>
                        <div class="msg_content">
                            <h5>会员：{{ userInfo.id }}</h5>
                            <div class="icon_msg">
                                <div class="phone">
                                    <img src="/member/img/phone.png" height="16px">
                                    <span class="icon_font">{{ userInfo.user_mobile ? '已绑定' : '未绑定' }}</span>
                                </div>
                                <div class="zj">
                                    <img src="/member/img/zj.png" height="16px">
                                    <span class="icon_font">{{ userInfo.user_idcard ? '已绑定' : '未绑定' }}</span>
                                </div>
                            </div>
                            <!-- 经验条 -->
                            <div class="exp">
                                <div class="exp_body">
                                    <div class="zs_box">
                                        <img src="/member/img/zs.png">
                                        <h6>VIP</h6>
                                        <span>{{ userInfo.user_growth ? userInfo.user_growth : 0 }}</span>
                                    </div>
                                    <div class="exp_jd"
                                        :style="{ width: setExp(lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth] : {}) + '%' }">
                                    </div>
                                </div>
                                <!-- <span>{{ setExp(lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth] : {}) }}/100</span> -->
                                <span class="lang_point" :title="userInfo.growth_expires_time">{{ becomeTime }}</span>
                            </div>
                            <div class="denji">
                                <span class="dj_font lang_point"
                                    v-if="(lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].vip_pay_yuer != '0' : false) || (lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].vip_pay_jifen != '0' : false) || (lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].exchange_jiacheng != '0' : false)"
                                    title="会员等级福利">会员等级福利:</span>
                                <div class="fuli_box">
                                        <div class="fuli_lang mb_5"
                                            v-if="lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].vip_pay_yuer != '0' : false">
                                            <img class="lp" src="/member/img/lp.png" height="18px">
                                            <span class="dj_font send_money lang_point" title="充值赠送余额">充值赠送余额</span>
                                            <h6>{{ lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].vip_pay_yuer : 0
                                            }}%
                                            </h6>
                                        </div>
                                        <div class="fgx1 mb_5"
                                            v-if="lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].vip_pay_jifen != '0' : false">
                                        </div>
                                        <div class="fuli_lang mb_5"
                                            v-if="lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].vip_pay_jifen != '0' : false">
                                            <img class="lp" src="/member/img/lp.png" height="18px">
                                            <span class="dj_font send_money lang_point" title="充值赠送积分">充值赠送积分</span>
                                            <h6>{{ lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].vip_pay_jifen : 0
                                            }}
                                            </h6>
                                        </div>
                                        <div class="fuli_lang mb_5"
                                            v-if="lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].exchange_jiacheng != '0' : false">
                                            <img class="lp" src="/member/img/lp.png" height="18px">
                                            <span class="dj_font send_money lang_point" title="兑换加成">兑换加成</span>
                                            <h6>{{ lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].exchange_jiacheng : 0
                                            }}%
                                            </h6>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="fgx2"></div>
                        <div class="bag">
                            <span class="bag_num">{{ setStores(userGrowth.available ? userGrowth.available : 0) }}</span>
                            <span class="bag_title lang_point" title="会员成长值">会员成长值</span>
                        </div>
                        <div class="bag">
                            <span class="bag_num">{{ setStores(userStore.available ? userStore.available : 0) }}</span>
                            <span class="bag_title lang_point" title="账户余额">账户余额</span>
                        </div>
                        <div class="bag">
                            <span class="bag_num">{{ setPoints(userPoint.available ? userPoint.available : 0) }}</span>
                            <span class="bag_title lang_point" title="账户积分">账户积分</span>
                        </div>
                        <!-- <router-link to="/member/wallet" class="member_btn member_font text_ellipsis btn_color"
                            title="会员充值">会员充值</router-link> -->
                    </div>
                    <!-- 背景 -->
                    <img class="mb_bg" :src="img_url + material.pc_user_data_bj">
                </div>
            </div>
            <div class="col-4">
                <div class="ewm_box flex_box">
                    <!-- <div class="beauty">
                        <h6 class="beauty_title lang_point" title="平台客服">平台客服</h6>
                        <img class="" v-lazy="img_url + material.pc_customer">
                        <div class="time text_ellipsis" title="服务时间：9:00-00:00">
                            服务时间：9:00-00:00
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-6">
                            <div class="beauty">
                                <h6 class="beauty_title lang_point" title="91CQ平台客服">91CQ客服</h6>
                                <img class="beauty_ewm" v-lazy="img_url + material.pc_customer">
                                <div class="time text_ellipsis" title="服务时间：9:00-00:00">
                                    服务时间：9:00-19:00
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="beauty">
                                <h6 class="beauty_title lang_point" title="86CQ平台客服">91CQ客服</h6>
                                <img class="gzh_ewm" v-lazy="img_url + material.pc_mp">
                                <div class="time text_ellipsis" title="服务时间:9:00-00:00">
                                    服务时间:14:00-22:00
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 背景 -->
                    <img class="ewm_bg" :src="img_url + material.pc_user_ewm_bj">
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import { memberLvBenefits } from '@/api/http.js'
export default {
    name: 'MemberTop',
    data() {
        return {
            name: '',
            lv: 0,
        }
    },
    computed: {
        ...mapState([
            'userInfo',
            'userGrowth',
            'userStore',
            'userPoint',
            'isLogin',
            'getClient',
            'material'
        ]),
        lvfl: {
            get() {
                return this.$store.state.lvfl
            },
            set(val) {
                return this.$store.state.lvfl = val
            }
        },
        idcard: {
            get() {
                return this.$store.state.idcard
            },
            set(val) {
                return this.$store.state.idcard = val
            }
        },
        mobile: {
            get() {
                return this.$store.state.mobile
            },
            set(val) {
                return this.$store.state.mobile = val
            }
        },
        becomeTime() {
            let nowTime = new Date();
            const become = Date.parse(this.userInfo.growth_expires_time)
            const n = become - nowTime
            if (n > 0) {
                return this.returnBeginTime(become)
            } else {
                return null
            }

        }

    },
    methods: {
        returnBeginTime(timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + "-";
            var M =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            var D = this.change(date.getDate()) + " ";
            return Y + M + D;
        },
        change(t) {
            if (t < 10) {
                return "0" + t;
            } else {
                return t;
            }
        },
        memberLvBenefits() {
            memberLvBenefits().then((res) => {
                if (res.code === 200) {
                    this.lvfl = res.data
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        setPoints(val) {
            return Math.round(val)
        },
        setStores(val) {
            return Math.round(val)
        },
        setExp(val) {
            var ep = val.sy_czz_bfb ? val.sy_czz_bfb.toFixed(0) : 0
            if (Object.keys(val).length > 0 && val.sy_czz_bfb > 100) {
                ep = 100
            }
            return ep
        }

    },
    created() {
        if(localStorage.getItem('token')){
            this.memberLvBenefits()
        }
    }
}
</script>
  
<style scoped>
.mb_box {
    position: relative;
    z-index: 2;
}

.mb_box>.row>div:nth-of-type(1) {
    padding-right: 6px;
}

.mb_box>.row>div:nth-of-type(2) {
    padding-left: 6px;
}

/* 会员信息模块 */
.tx_box {
    position: relative;
    overflow: hidden;
}

.tx_content {
    position: absolute;
    top: 13%;
    width: 73%;
    overflow: hidden;
    background-color: #2F3136;
    border-radius: 50%;
    height: 73%;
}

.head_img {
    position: absolute;
}

.member_box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.mb_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.member_msg {
    padding: 25px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

}

.msg_content h5 {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #6F5427;
}

.icon_msg {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.msg_content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-left: 15px;
}

.phone {
    display: flex;
    align-items: center;
    justify-content: start;
}

.zj {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 30px;
}

.zj img {
    margin-right: 5px;
}

.phone img {
    margin-right: 5px;
}

.icon_font {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #808080;
}

.exp {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.exp_body {
    position: relative;
    width: 257px;
    height: 16px;
    background: #d7d5d1;
    border-radius: 8px;
}

.exp span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #886B3A;
    margin-left: 10px;
}

.zs_box {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4px;
    z-index: 2;
}

.exp_jd {
    width: 0%;
    height: 16px;
    ;
    background: #AC8747;
    border-radius: 8px;
    position: absolute;
    top: 0;
    z-index: 1;
}

.zs_box h6 {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-style: italic;
    color: #FFFCF6;
    line-height: 9px;
    margin: 0;
}

.zs_box span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-style: italic;
    color: #FFFCF6;
    line-height: 12px;
    margin: 0;
}

.denji {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-top: 25px;
}

.dj_font {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #81673B;
}
.fuli_box{
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.fuli_lang{
    display: flex;
    justify-content: start;
    align-items: center;
    /* width: 40%; */
}
.mb_5{
    margin-bottom: 5px;
}
.lp {
    margin-left: 10px;
}

.send_money {
    margin: 0 5px;
}

.denji h6 {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #A92828;
    margin: 0;
}

.fgx1 {
    width: 1px;
    height: 12px;
    background: #B5945B;
    margin-left: 10px;
}

.fgx2 {
    width: 1px;
    height: 134px;
    background: #E9E4DB;
    margin: 0 30px;
}

.bag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F6F2EB;
    border-radius: 4px;
    padding: 15px 35px;
    margin-right: 10px;
}

.bag_num {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #886B3A;
}

.bag_title {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #8C7B5E;
}

.member_btn {
    border-radius: 4px;
    padding: 10px 12px;
    margin-left: 20px;
}

.member_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-decoration: none;
    color: #FFFFFF;
}

/* 二维码模块 */
.ewm_box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 20px 60px;
}

.ewm_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -50;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.beauty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.beauty_title {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #4D4A45;
}

.time {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #807B74;
    margin-top: 10px;
    text-align: center;
    width: 100%;
}

.beauty_ewm {
    width: 75%;
}

.gzh_ewm {
    width: 75%;
}

@media (max-width:1600px) {
    .head_png {
        width: 15%;
    }

    .bag_num {
        font-size: 15px;
    }

    .bag_num {
        font-size: 15px;
    }

    .bag {
        padding: 12px 20px;
    }

    .member_btn {
        margin-left: 15px;
    }

    .member_font {
        font-size: 14px;
    }

    .exp_body {
        width: 220px;
    }
}

@media (max-width: 1300px) {

    /* 会员信息模块 */
    .member_msg {
        padding: 10px 15px;
    }

    .msg_content h5 {
        font-size: 15px;
    }

    .phone img {
        height: 14px;
    }

    .exp_body {
        width: 157px;
    }

    .exp span {
        font-size: 12px;
    }

    .denji h6 {
        font-size: 13px;
    }

    .fgx2 {
        margin: 0 15px;
    }

    .bag {
        padding: 10px;
    }

    .bag_num {
        font-size: 13px;
    }

    .bag_title {
        font-size: 12px;
    }

    .member_font {
        font-size: 12px;
    }

    .member_btn {
        margin-left: 10px;
        padding: 4px 10px;
    }

    /* 二维码模块 */
    .ewm_box {
        padding: 10px 35px;
    }

    .ewm_box {
        padding: 15px 35px;
    }

    .beauty_title {
        font-size: 12px;
    }

    .time {
        font-size: 12px;
    }
}</style>