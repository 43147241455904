import { render, staticRenderFns } from "./AtlasTop.vue?vue&type=template&id=013908af&scoped=true&"
import script from "./AtlasTop.vue?vue&type=script&lang=js&"
export * from "./AtlasTop.vue?vue&type=script&lang=js&"
import style0 from "./AtlasTop.vue?vue&type=style&index=0&id=013908af&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013908af",
  null
  
)

export default component.exports