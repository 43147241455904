<template>
  <div class="headers">
    <div class="menu">
        <div class="menu_box container">
            <div class="menu_left">
                <a @click="toHome" class="top_title" >返回首页</a>
                <a class="top_title" v-if="material.shouquan" :href="material.shouquan !=='' ? img_url + material.shouquan :'javascript:0'" target="_blank">平台授权</a>
                <router-link to="/member/wallet" class="top_title">平台充值</router-link>
            </div>
            <div class="menu_left">
                <ul>
                    <li class="item_li">
                        <a @click="toHome" class="top_title">网站首页</a>
                    </li>
                    <li>
                        <router-link to="/member" class="top_title">会员中心</router-link>
                    </li>
                    <li>
                        <a class="top_title" @click="click_czc">平台充值</a>
                    </li>
                    <li>
                        <a href="https://npc.91cq.com" target="_blank" class="top_title">游戏查询</a>
                    </li>
                    <!-- <li>
                        <a  class="top_title" @click="click_atlas">通关成就</a>
                    </li> -->
                    <!-- <li>
                        <router-link to="/welfareGift" class="top_title">福利礼包</router-link>
                    </li>-->
                    <!-- <li>
                        <a @click="toPointsMall" class="top_title">灵符商城</a>
                    </li> -->
                    <li>
                        <router-link to="/information" class="top_title">新闻资讯</router-link>
                    </li>
                    <li>
                        <a @click="toCharts" class="top_title">排行榜</a>
                    </li>
                    <li v-if="show_tutoral">
                        <router-link to="/beginnerTutorial" class="top_title">新手教程</router-link>
                    </li> 
                    <li>
                        <router-link to="/combined" class="top_title">联运中心</router-link>
                    </li> 
                    <!-- <li>
                        <router-link to="/member/level" class="top_title">等级福利</router-link>
                    </li> -->
                    <!-- <li>
                        <a href="javascript:0" class="top_title">关于我们</a>
                    </li> -->
                    <li>
                        <router-link to="/helps" class="top_title">帮助</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 背景 -->
        <img class="menu_bg" src="/header/img/hd_bg.png" width="100%">
    </div>
    <video class="banner_bg" autoplay loop muted preload  v-if="(material.pc_index_background ? material.pc_index_background.type :'' )== 1"
    style="width: 100%; height: 100%; object-fit: fill">
      <source :src="img_url + material.pc_index_background.img">
    </video>
    <img class="banner_bg" v-else-if="(material.pc_index_background ? material.pc_index_background.type :'') == 2" :src="img_url + material.pc_index_background.img">
  </div>
</template>

<script>
import { mapState } from 'vuex';
    export default {
        name:'Header2',
        data() {
            return {
                show_tutoral:false, //是否显示新手教程
                materials: JSON.parse(localStorage.getItem('material')) || null,
            }
        },
        computed:{
            ...mapState([
                'material'
            ])
        },
        watch: {
            materials: {
                handler(newVal, oldVal) {
                    if(this.materials){
                        console.log('我是物料',this.materials.pc_tutorial_banner)
                        if(this.materials.pc_tutorial_banner.img || this.materials.pc_tutorial_banners.img || this.materials.pc_tutorial_bannerss.img){
                            this.show_tutoral = true
                        }else{
                            this.show_tutoral = false
                        }
                    }
                },
                immediate: true // 立即执行一次，用于初始化
            }
        },
        methods:{
            click_atlas(){
                this.$router.push({
                    path:'/atlas'
                })
                this.$czc('PC首页顶部导航通关成就', '点击', '访问通关成就')
            },
            click_czc(){
                this.$router.push({
                    path:'/member/wallet'
                })
                this.$czc( 'PC首页顶部导航平台充值', '点击', '访问平台充值')
            },
            toHome(){
                this.$router.push({
                    path:'/home'
                })
                location.reload()
            },
            toPointsMall(){
                this.$router.push({
                    path:'/pointsMall'
                })
                localStorage.setItem('mall_id','1')
                this.$czc('PC首页顶部导航灵符商城', '点击', '访问灵符商城')
            },
            toCharts(){
                this.$router.push({
                    path:'/chartsing'
                })
                this.$czc( 'PC子页面排行榜', '点击', '访问排行榜')
            }
        },
        mounted(){
            // this.$nextTick(() =>{
            //     const material = JSON.parse(localStorage.getItem('material'))
            //     console.log('我是物料',material.pc_tutorial_banner)
            //     if(material.pc_tutorial_banner.img || material.pc_tutorial_banners.img || material.pc_tutorial_bannerss.img){
            //         this.show_tutoral = true
            //     }else{
            //         this.show_tutoral = false
            //     }
            // })
        }
    }
</script>

<style scoped>
.menu{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.menu_bg{
    position:absolute;
    top: 0;
    left: 0;
    z-index: -10;
}
.top_title{
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #cccccc;
    margin-right: 12px;
    text-decoration: none;
    transition: all 0.5s;
}
.top_title:hover{
    color: #B4935A ;
    transition: all 0.5s;
}
.menu_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 25px 0 60px;
}
.banner_bg{
    width: 100%;
    position: relative;
    z-index: -99;
}
.menu_left{
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
}
.menu_left ul{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 0;
    padding: 0;
}
.menu_left ul li{
    list-style: none;
}
.menu_left ul li:nt{
    margin: 0;
}

</style>