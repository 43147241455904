<template>
	<p>
		<slot>温馨提示：版本待下架期间的新区不享受平台沙巴克奖励<br>请您思考后慎重进行充值兑换本游戏将在{{content}}下架</slot>
	</p>
</template>

<script>
	export default {
		data(){
			return {
				content: '',
                timer:''
			}
		},
		props:['endTime','endText'],
        computed:{
            under_off:{
                get(){
                    return this.$store.state.under_off
                },
                set(val){
                    return this.$store.state.under_off = val
                }
            }
        },
		watch: {//监听时间的变化
			endTime:{
                handler(nval,oval){
                    this.countdowm(this.endTime)
                }
			},
            endText:{
                handler(nval,oval){
                    clearInterval(this.timer)
                    this.countdowm(this.endTime)
                }
            },
            under_off:{
                immediate:true,
                handler(nval,oval){
                    console.log('我能行')
                    if(nval){
                        console.log('我变化',nval)
                        clearInterval(this.timer)
                        this.under_off = false
                        console.log('清除后',this.under_off)
                    }
                }
            }
		},
		mounted () {
			// this.countdowm(this.endTime)
            // console.log('我进来121313')
		},
		methods: {
			countdowm(timestamp){
				let self = this;
				this.timer = setInterval(function(){
					let nowTime = new Date();
					let endTime = new Date(timestamp * 1000);
                    console.log(endTime)
					let t = endTime.getTime() - nowTime.getTime();
					if(t>0){
						let day = Math.floor(t/86400000);
						let hour=Math.floor((t/3600000)%24);
						let min=Math.floor((t/60000)%60);
						let sec=Math.floor((t/1000)%60);
						hour = hour < 10 ? "0" + hour : hour;
						min = min < 10 ? "0" + min : min;
						sec = sec < 10 ? "0" + sec : sec;
						let format = '';
						if(day > 0){
							format = `${day}天${hour}小时${min}分${sec}秒`;
						}
						if(day <= 0 && hour > 0 ){
							format = `${hour}小时${min}分${sec}秒`;
						}
						if(day <= 0 && hour <= 0){
							format =`${min}分${sec}秒`;
						}
						self.content = format;
					}else{
						clearInterval(self.timer);
						self.content = self.endText;
					}
                    // if(localStorage.getItem('under_off')==1){
                    //     clearInterval(self.timer);
                    // }
				},1000);
			}
		},
        deactivated(){
            console.log('倒计时失活')
        }
	}
</script>
<style scoped>
 p{
    color: red;
 }
</style>
