<template>
    <div class="zp_box">
        <img class="logo" src="/activity/turntable/img/logo.png">
        <div class="conetent_box">
            <div class="zp_yuan" >
                <img class="zz" @click="hit" src="/activity/turntable/img2/zz.png">
                <div class="yy" :style="{ transform: rotate_deg, transition: rotate_transition } ">
                    <div class="item1" v-for="(item,index) in Arrays" :key="index" :style="'transform: rotate('+(360/Arrays.length*index )+'deg);'">
                        <img  :src="img_url + item.image" :class="{'gay':item.is_show == 0}">
                    </div>
                    <div class="item2" v-for="(a,index) in Arrays" :key="a.id + '_'" :style="'transform: rotate('+(360/Arrays.length*index+ 360/Arrays.length/2)+'deg);'">
                        <div  class="xian"></div>
                    </div>
                    
                    <!-- 转盘背景 -->
                    <img class="yuan" src="/activity/turntable/img2/yuan3.png">
                </div>
                
            </div>
            <div class="my_time" v-if="getUserInfo.isactive">我(id:{{ getUserInfo.account_id }})的抽奖次数：<span>{{ getUserInfo.take_num }}</span></div>
            <div class="my_time2" v-else>活动已结束</div>
            <div class="content">
                <div class="links ">
                    <img class="zj_logo" src="/activity/turntable/img2/logo2.png">
                    <div class="rule class_box" v-if="getUserInfo.rule !== ''" v-html="getUserInfo.rule">
                    </div>
                </div>
                <div class="btn_box">
                    <div class="recharge recharge_font flex_box" @click="toPay">立即充值</div>
                    <!-- <div class="reward reward_font flex_box" v-if="getUserInfo.reset" data-bs-toggle="modal" data-bs-target="#myModal" >立即重置</div>
                    <div class=" reward_font flex_box return" v-else>已重置</div> -->
                </div>
            </div>
            <!-- 背景 -->
            <img class="body_bg" src="/activity/turntable/img2/body1.png" width="100%">
        </div>

        <!-- 背景 -->
        <img class="zp_bg" src="/activity/turntable/img2/bg1.png" width="100%">
        <Winning :name="hitDraw.value"></Winning>
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import { playTurntable, getPlaytimes } from '@/api/http.js'
import Winning from '@/components/Winning.vue'
export default {
    name: 'Turntable',
    components: {
        Winning,
    },
    data() {
        return {
            scrollTop: 0, //列表滚动高度
            speed: 20, //滚动的速度
            // cat: 72, //总共5个扇形区域，每个区域约72度
            isAllowClick: true, //是否能够点击
            rotate_deg: 0, //指针旋转的角度
            rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
            copyHtml: '', //复制多一份防止滚动到后面出现空白
            timer: '',
            getUserInfo: [], //获取用户，活动信息
            hitDraw: [], //获取中奖结果
            userWin: [], //获取玩家中奖列表
            bool: true,
            flag:true ,  //限制重置次数
            Arrays:[],
            errMsg:''
        }
    },
    computed: {
        myList: {
            get() {
                return this.$store.state.myList
            },
            set(val) {
                return this.$store.state.myList = val
            }
        },
        cat(){
            return 360/this.Arrays.length
        }

    },
    methods: {
        toPay() {
            // window.open(this.pay_url + '/member/wallet', '_blank');
            var domain = document.domain;
            window.open(window.location.protocol+ '//'+ domain + '/member/wallet', '_blank');
        },
        getQueryData() {
            let oSearchItem = {};
            let searchString = window.location.search;
            let reg = /[?&][^?&]+=[^?&]+/g;
            let arr = searchString.match(reg);
            if (arr) {
                arr.forEach((item) => {
                    let tempArr = item.substring(1).split("=");
                    let key = decodeURIComponent(tempArr[0]);
                    let value = decodeURIComponent(tempArr[1]);
                    oSearchItem[key] = value;
                });
            }
            return oSearchItem
        },
        // 获取抽奖次数接口
        getPlaytimes() {
            var token = this.getQueryData()
            getPlaytimes({
                token: token.token,
                id:this.$route.query.id
            }).then((res) => {
                if (res.code === 200) {
                    this.getUserInfo = res.data
                    this.Arrays = res.data.prize_list
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.errMsg = err.msg
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 5000,
                })
            })
        },
        // 转盘接口
        playTurntable() {
            var token = this.getQueryData()
            playTurntable({
                token: token.token,
                id:this.$route.query.id
            }).then((res) => {
                if (res.code === 200) {
                    this.hitDraw = res.data
                    this.rotating();
                    console.log('我执行结束')
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
        winTc() {
            var win_tc = $('.win')
            var LayIndex = layer.open({
                type: 1,
                content: win_tc,
                anim: false,
                fixed: true,
                shade: 0.6,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                isOutAnim: false,
                area: [auto, auto],
                success: function () {

                }
            });
        },
        hit() {
            if (this.getUserInfo.isactive) {
                if (this.getUserInfo.take_num == 0) {
                    this.$message({
                        message: "机会已经用完了~",
                        type: 'warning',
                        duration: 1500
                    })
                    return;
                } else {
                    if (this.bool) {
                        this.bool = false
                        this.playTurntable()
                        // setTimeout(() => {
                        //     this.rotating();
                        // }, 500)
                    }
                }
            } else {
                this.$message({
                    message: this.errMsg,
                    type: 'warning',
                    duration: 1500
                })
            }


        },
        rotating() {
            if (!this.isAllowClick) return;
            this.isAllowClick = false;
            this.rotate_transition = "transform 3s ease-in-out";
            var rand_circle = 5; //默认多旋转5圈
            //   var winningIndex = Math.floor(Math.random() * 8); //获奖的下标   0-7   没有概率每个平均
            var winningIndex = this.hitDraw.position; //设置了概率的

            console.log('sdsdsd', this.hitDraw.position);
            var randomDeg = 360 - winningIndex * this.cat; //当前下标对应的角度    cat是总共7个扇形区域，每个区域约51度

            var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
            this.rotate_deg = "rotate(" + deg + "deg)";

            var that = this;
            setTimeout(function () {
                that.isAllowClick = true;
                that.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
                that.rotate_transition = "";
                that.bool = true
                that.winTc()
                that.getPlaytimes()
            }, 3500);
        },
    },
    created() {
        this.getPlaytimes()
    },
}
</script>

<style scoped>
/* 模态框 */
.modal-title{
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #912C2C;
}
.modal-footer{
    justify-content: center;
}
/*  */
.return{
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(100%);
    background: linear-gradient(0deg, #D69A5A, #FFDCA5);
    border-radius: 4px;
    transition: all 0.5s;
    padding: 12px;
    width: 100%;
    cursor: pointer;
}
.zp_box {
    position: relative;
    width: 100%;
    height: 100%;
}

.conetent_box {
    position: absolute;
    left: 0;
    top: 116%;
    height: 100%;
    width: 100%;
}

.body_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -90;
}

.zp_bg {
    top: 0;
    left: 0;
    z-index: -99;
    position: fixed;
    width: 100%;
    height: 104vh;
}

.zp_yuan {
    position: absolute;
    z-index: 1;
    left: 4.6%;
    top: 137%;
}

.yuan {
    width: 32vw;
    position: relative;
    top: 0;
    left: 0;
    z-index: -1;
}

.zz {
    position: absolute;
    left: 39.5%;
    top: 32%;
    cursor: pointer;
    z-index: 10;
    width: 7vw;
}
.yy{
    width: 100%;
}
.gay{
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(100%);
}
.xian{
    width: 1px;
    background: #e9bba2;
    height: 50%;
    transform: rotate(180deg);
}
.item1{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 8%;
    height: 85%;
}
.item2{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.item1 img{
    width: 20%;
}
.item1 img{
    transform: rotate(90deg);
    width: 20%;
}
/* .yy .item1:nth-child(2){
    transform: rotate(72deg);
}
.yy .item1:nth-child(3){
    transform: rotate(144deg);
}
.yy .item1:nth-child(4){
    transform: rotate(216deg);
}
.yy .item1:nth-child(5){
    transform: rotate(288deg);
} */

.content {
    position: absolute;
    left: 44%;
    top: 180%;
    height: 100%;
}

.links {
    background: #FFFBD9;
    border: 1px solid #E8E4B8;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 10px 15px 25px;
    width: 20vw;
    max-height: 300%;
}

.zj_logo {
    width: 17vw;
}

.links ul {
    padding: 0;
    margin: 0;
}

.btn_box {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.logo {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 6vh;
    width: 65vw;
}

.recharge {
    background: linear-gradient(0deg, #BF3722, #FC5339);
    border-radius: 4px;
    padding: 12px;
    /* margin-right: 10px; */
    width: 10vw;
    transition: all 0.5s;
    cursor: pointer;
}

.recharge:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

.recharge_font {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #F6EEA5;
    text-decoration: none;
}

.reward {
    background: linear-gradient(0deg, #D69A5A, #FFDCA5);
    border-radius: 4px;
    transition: all 0.5s;
    padding: 12px;
    width: 100%;
    cursor: pointer;
}

.reward:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

.reward_font {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #912C2C;
    text-decoration: none;
}

.my_time {
    position: absolute;
    left: 16%;
    top: 580%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFBD9;
    z-index: 10;
}
.my_time2 {
    position: absolute;
    left: 18%;
    top: 580%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFBD9;
    z-index: 10;
}

.my_time span {
    color: #FFFA69;
}

.rule {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #912C2C;
    margin-top: 10px;
}

::v-deep.rule td {
    border: 1px solid #912C2C;
}

.class_box {
    overflow: auto;
    padding-right: 12px;
}

.class_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    padding: 10px 0;
}

.class_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #d1b481;
}

.class_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background: #e6e6e6;
    padding: 10px 0;
}

@media (max-width:1750px) {
    .zp_bg {
        top: -5px;
    }

    .my_time {
        font-size: 16px;
    }

    .recharge {
        padding: 6px;
    }

    .reward {
        padding: 6px;
    }
}

@media (max-width:1600px) {
    .reward_font {
        font-size: 16px;
    }

    .recharge_font {
        font-size: 16px;
    }

    .my_time {
        font-size: 15px;
    }

    .rule {
        font-size: 12px;
    }
}

@media (max-width:1300px) {
    .btn_box {
        margin-top: 20px;
    }

    .reward_font {
        font-size: 12px;
        height: 30px;
    }

    .recharge_font {
        font-size: 12px;
        height: 30px;
    }

    .my_time {
        font-size: 12px;
    }
}

@media (max-width:1100px) {
    .btn_box {
        margin-top: 20px;
    }

    .reward_font {
        font-size: 12px;
    }

    .recharge_font {
        font-size: 12px;
    }
}</style>