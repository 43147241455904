import { render, staticRenderFns } from "./LabourDay.vue?vue&type=template&id=288ea7fd&scoped=true&"
import script from "./LabourDay.vue?vue&type=script&lang=js&"
export * from "./LabourDay.vue?vue&type=script&lang=js&"
import style0 from "./LabourDay.vue?vue&type=style&index=0&id=288ea7fd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288ea7fd",
  null
  
)

export default component.exports