<template>
    <div>
        <Header></Header>
        <div class="new_box">
            <div class="container">
                <div class="new_content">
                    <div class="content_top">
                        <h5 class="new_font new_font_color">新闻资讯</h5>
                        <div class="new_item" >
                            <a class="new_item_font" @click="newId=0" :class="{'new_active':newId===0}">全部</a>
                            <div class="new_item" v-for="n in newClassify" :key="n.value" >
                                <div class="fgx"></div>
                                <a class="new_item_font" @click="newId= n.value" :class="{'new_active':newId===n.value}">{{n.label}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="content_bottom">
                        <div class="all" 
                        v-show="newId === 0"
                        v-for="(a,index) in getArticle" :key="index">
                            <div class="new_title">
                                <router-link :to="{
                                    path:'/newDetail',
                                    query:{
                                            id:a.id
                                        }
                                }" 
                            ><img class="new_img" v-lazy="img_url + a.img"></router-link>
                                <div class="title_box">
                                    <div class="title_top">
                                        <router-link :to="{
                                            path:'/newDetail',
                                            query:{
                                                    id:a.id
                                                }
                                        }" 
                            ><h5>{{ a.title }}</h5></router-link>
                                        <span class="text_ellipsis">{{ a.Introduction }}</span>
                                    </div>
                                    <div class="time">{{ a.created_at }}</div>
                                </div>
                            </div>
                            <router-link :to="{
                                path:'/newDetail',
                                query:{
                                        id:a.id
                                    }
                            }" 
                            class="new_btn btn_color new_btn_font">查看详情</router-link>
                        </div>
                        <div class="announcement" v-show="newId === b.classify" v-for="(b,index) in getArticle" :key="index + '#'">
                            <div class="all" >
                                <div class="new_title">
                                    <router-link :to="{
                                        path:'/newDetail',
                                        query:{
                                                id:b.id
                                            }
                                    }" 
                            ><img class="new_img" v-lazy="img_url + b.img"></router-link>
                                    <div class="title_box">
                                        <div class="title_top">
                                            <router-link :to="{
                                                path:'/newDetail',
                                                query:{
                                                        id:b.id
                                                    }
                                            }" 
                            ><h5>{{ b.title }}</h5></router-link>
                                            <span class="text_ellipsis">{{ b.Introduction }}</span>
                                        </div>
                                        <div class="time">{{ b.created_at }}</div>
                                    </div>
                                </div>
                                <router-link :to="{
                                path:'/newDetail',
                                query:{
                                        id:b.id
                                    }
                                }" 
                             class="new_btn btn_color new_btn_font">查看详情</router-link>
                            </div>
                        </div>
                        <Pagination
                        class="possion_page"
                        @change="changePage"
                        :current="pagination.p"
                        :pageSize="pagination.pageSize"
                        :total="total"
                        :hideOnSinglePage="hideOnSinglePage"
                        :showQuickJumper="true"
                        :showTotal="true"
                        placement="right"
                        v-if="pagination && total"
                        ></Pagination>
                    </div>
                </div>
            </div>
            
            <!-- 背景 -->
            <img class="new_bg" :src="img_url + material.pc_background" width="100%">
        </div>
        <Footer></Footer>
    </div>
  
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Pagination from '../components/page.vue'
import { mapState } from 'vuex'
import {articleClassificationList,getArticleList} from '@/api/http.js'
    export default {
        components: { Footer,Header,Pagination },
        name:'News',
        data() {
            return {
                newId:0,
                newClassify:[], //新闻分类
                getArticle:[], //新闻列表
                time:'', //当前时间戳
                hideOnSinglePage: false,
                total: 0,
                pagination: {
                    pageSize: 15,
                    p: 1
                },
                pager:{}
            }
        },
        watch:{
            pager:{
                deep:true,
                immediate:true,
                handler(nval,oval){
                    this.getArticleList()
                }
            },
            newId:{
                handler(nval,oval){
                    this.getArticleList()
                }
            }
        },
        computed:{
            ...mapState([
                'material'
            ])
        },
        methods:{
            changePage (pager) { // 分页器回调
                this.$emit('change', pager)
                this.pager = pager
            },
            articleClassificationList(){
                articleClassificationList().then((res)=>{
                    if(res.code === 200){
                        localStorage.setItem('newClassify',JSON.stringify(res.data))
                        localStorage.setItem('newTime',[res.ex_time])
                        this.newClassify = JSON.parse(localStorage.getItem('newClassify'))
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err)
                })
            },
            getArticleList(){
                getArticleList({
                    classify:this.newId,
                    page:this.pager.page
                }).then((res)=>{
                    if(res.code ===200){
                        localStorage.setItem('getArticle',JSON.stringify(res.data.data))
                        this.getArticle = JSON.parse(localStorage.getItem('getArticle'))
                        this.total = res.data.total
                        this.pagination.pageSize = res.data.per_page
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err)
                })
            }
        },
        created(){
            this.time = parseInt(new Date().getTime() / 1000) + ''
            var newTime = localStorage.getItem('newTime')
            if(this.time > newTime || !newTime){
                this.articleClassificationList()
                this.getArticleList()
            }else{
                this.newClassify = JSON.parse(localStorage.getItem('newClassify'))
                this.getArticle = JSON.parse(localStorage.getItem('getArticle'))
            }
            
        }
    }
</script>

<style scoped>
    a{
        text-decoration: none;
    }
    .possion_page{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .new_box{
        position: relative;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 10px 0;
        margin-top: 110px;
        background: #18181a;
    }
    .new_bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    /* 内容 */
    .new_active{
        color: #AC8747 !important;
    }
    .fgx{
        width: 1px;
        height: 13px;
        background: #CCCCCC;
        margin: 0 10px;
    }
    .new_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .new_font{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin: 0;
        text-decoration: none;
    }
    .new_font_color{
        color: #AC8747;
    }
    .new_content{
        position: relative;
        z-index: 2;
        background: #FAFAFA;
        border-radius: 4px;
    }
    .content_top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        border-bottom: 1px solid rgba(102,102,102,0.2);
    }
    .content_bottom{
        padding: 0 30px 70px;
        min-height: 620px;
    }
    .new_item_font{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        text-decoration: none;
        transition: all 0.5s;
    }
    .new_item_font:hover{
        color: #AC8747;
        transition: all 0.5s;
    }
    .title_top h5{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
    .title_top span{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        display: inherit;
        width: 400px;
    }
    .time{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
    /* 新闻咨询 */
    .all{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0 ;
        border-bottom: 1px solid rgba(102,102,102,0.2);
    }
    .new_title{
        display: flex;
        justify-content: space-between;
    }
    .title_box{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-left: 20px;
    }
    .new_btn{
        padding: 15px 38px;
        border-radius: 4px;
    }
    .new_btn_font{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        text-decoration: none;
    }
    @media (max-width:1600px) {
       
        .content_top{
            padding: 20px
        }
        .content_bottom{
            padding: 0 20px 70px;
        }
        .new_item_font{
            font-size: 14px;
        }
        /* 新闻资讯 */
        .new_btn{
            padding: 10px 30px;
        }
    }
    @media (max-width: 1300px){
        .new_box{
            margin-top: 100px;
        }
        .new_font{
            font-size: 16px;
        }
        .new_item_font{
            font-size: 12px;
        }
        /* 新闻资讯 */
        .title_top h5{
            font-size: 14px;
            margin-bottom: 5px;
        }
        .title_top span{
            font-size: 14px;
        }
        .time{
            font-size: 12px;
        }
        .new_btn_font{
            font-size: 12px;
        }
        .new_btn{
            padding: 10px 20px;
        }
    }
    @media (max-width:1100px) {
        /* 新闻资讯 */
        .title_box{
            margin-left: 15px;
        }
        .title_top h5{
            font-size: 12px;
        }
        .title_top span{
            font-size: 12px;
        }
        .new_btn{
            padding: 5px 20px;
        }
    }
</style>