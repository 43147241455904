<template>
   <div class="game_box">
        <div class="content">
            <div class="bag_title">
                <h4 class="font_color">会员兑换记录</h4>
                <div class="tip">
                    <div class="tip_icon flex_box font_color">?</div>
                    <span class="font_color">如遇充值问题，请联系任意平台客服</span>
                </div>
            </div>
            <table class="table">
                <thead class="table-light">
                <tr>
                    <th>游戏名称</th>
                    <th>兑换账号</th>
                    <th>金额</th>
                    <th>实际到账</th>
                    <th>状态</th>
                    <th>时间</th>
                </tr>
                </thead>
                <tbody>
                <tr  v-for="d in dedeem" :key="d.id">
                    <td>{{ d.game_name }}</td>
                    <td>{{d.account}}</td>
                    <td>{{d.money}}</td>
                    <td>{{ d.real_money }}</td>
                    <td>{{ d.status_msg }}</td>
                    <td>{{d.created_at}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination
        @change="changePage"
        :current="pagination.p"
        :pageSize="pagination.pageSize"
        :total="total"
        :hideOnSinglePage="hideOnSinglePage"
        :showQuickJumper="true"
        :showTotal="true"
        placement="right"
        v-if="pagination && total"
        ></Pagination>
    </div>
</template>

<script>
    import Pagination from '../components/page.vue'
    import {getReissueStoredList} from '@/api/http.js'
    export default {
        name:'MemberExchangeRecord',
        data() {
            return {
                dedeem:[],
                hideOnSinglePage: false,
                total: 0,
                pagination: {
                    pageSize: 15,
                    p: 1
                },
                pager:{}
            }
        },
        components:{
            Pagination
        },
        watch:{
            pager:{
                deep:true,
                immediate:true,
                handler(nval,oval){
                    this.getReissueStoredList()
                }
            }
        },
        methods:{
            changePage (pager) { // 分页器回调
                this.$emit('change', pager)
                this.pager = pager
            },
            // 获取用户积分兑换列表接口
            getReissueStoredList(){
                getReissueStoredList({
                    wallet_reissue_page:this.pager.page
                }).then((res)=>{
                    if(res.code === 200){
                        this.dedeem = res.data.data
                        this.total = res.data.total
                        this.pagination.pageSize = res.data.per_page
                    }
                }).catch((err)=>{
                    console.log("捕获异常",err)
                    this.$message({
                        message:err.msg,
                        type: 'warning',
                        duration: 1500
                    });
                })
            }
        }
    }
</script>

<style scoped>
    .content{
        width: 100%;
    }
    .game_box{
        background: #fff;
        border-radius: 4px;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    .bag_title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }
    .bag_title h4{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin: 0;
    }
    .tip{
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .tip_icon{
        width: 13px;
        height: 13px;
        border: 1px solid #806437;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-right: 5px;
    }
    .tip span{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    .font_color{ 
        color: #806437;
    }
    /* table */
    .table th, .table td { 
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #463F34;
        padding: 14px 40px;
    }
    @media (max-width:1600px) {
        .bag_title h4{
            font-size: 20px;
        }
        .tip span {
            font-size: 12px;
        }
        .bag_title{
            padding-bottom: 15px;
        }
         /* table */
         .table th,.table td{
            font-size: 12px;
            padding: 14px 20px;
        }
    }
    @media (max-width:1300px) {
        .game_box{
            padding: 20px 30px;
        }
        .bag_title h4{
            font-size: 16px;
        }
    }
</style>