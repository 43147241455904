<template>
    <div class="rebate">
        <div class="content_box">
            <img class="red_bag" @mouseenter="madeEnter" @mouseleave="madeOut"
                :class="{ 'red-envelope': isdo == 1}" :src="hdInfo.residue_rebate == 0 ? '/activity/rebate/img/close.png': '/activity/rebate/img/bag.png'"
                @click="leader">
            <div class="flex_box center_box">
                <div class="item ">
                    <span>用户ID:{{ hdInfo.user_id }}</span>
                    <img class="item_bg" src="/activity/rebate/img/box.png">
                </div>
                <div class="item ">
                    <span>今日已领取:{{ hdInfo.rebate - hdInfo.residue_rebate }}</span>
                    <img class="item_bg" src="/activity/rebate/img/box.png">
                </div>
                <div class="item ">
                    <span>待领取:{{ hdInfo.residue_rebate }}</span>
                    <img class="item_bg" src="/activity/rebate/img/box.png">
                </div>
            </div>
            <div class="rule">
                <span>
                    活动规则:<br>
                    1.每日充值可额外领取充值积分返利，不限制次数.<br>
                    2.每日充值后点击领取返利按钮领取充值额度.<br>
                    3.当日充值金额返利额度会累计，可当日一次性领取完毕。<br>
                    4.每日返利活动次日0点刷新，请当日24点以前及时领取返利额度。<br>
                    5.本次活动可与平台本身多充多送活动累加，即最高可获得70%额外返利额度。
                </span>
                <img class="rule_bg" src="/activity/rebate/img/rule.png">
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="leader_tc">
            <div class="tc_title">
                <h5>恭喜您获得</h5>
                <span>{{ leader_data.money }}储值大礼</span>
            </div>
            <img class="leader_btn" src="/activity/rebate/img/btn.png">
            <!-- 背景 -->
            <img class="leader_bg" src="/activity/rebate/img/leader_bg.png">
        </div>
        <!-- 背景 -->
        <img class="rebate_bg" src="/activity/rebate/img/1.jpg">
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import { hdDanoInfo, hdDanoLeader } from '@/api/http.js'
export default {
    name: 'Rebate',
    data() {
        return {
            isdo: '',
            hdInfo: {},
            leader_data:{},
            bool:true,
            leader_msg:''
        }
    },
    methods: {
        // 信息接口
        hdDanoInfo() {
            hdDanoInfo().then((res) => {
                if (res.code == 200) {
                    this.hdInfo = res.data
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        },
        // 领取接口
        hdDanoLeader() {
            hdDanoLeader().then((res) => {
                if (res.code == 200) {
                    this.leader_data = res.data
                    this.leader_msg = res.msg
                    this.leaderTc()
                    this.hdDanoInfo()
                    this.bool = true
                }
            }).catch((err) => {
                this.bool = true
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        },
        // 领取事件
        leader() {
            if(this.bool){
                this.bool = false
                if (this.hdInfo.residue_rebate != 0) {
                    this.hdDanoLeader()
                } else {
                    this.$message({
                        message: '当前暂无可领取返利！',
                        type: 'warning',
                        duration: 1500,
                        onClose:()=>{
                            this.bool = true
                        }
                    })
                }
            }else{
                this.$message({
                    message: '操作过于频繁，请稍后再试！',
                    type: 'warning',
                    duration: 1500
                })
            }
        },
        // 弹窗
        leaderTc() {
            var that = this
            var leader_tc = $('.leader_tc')
            var LayIndex = layer.open({
                type: 1,
                content: leader_tc,
                anim: false,
                fixed: true,
                shade: 0.9,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                closeBtn: 0,
                isOutAnim: false,
                area: [auto, auto],
                success: function (layero,that) {
                    const lq = $(layero).find('.leader_btn');
                    lq.click(function () {
                        layer.close(LayIndex)
                    })
                }
            });
        },
        // 鼠标移入
        madeEnter() {
            if (this.hdInfo.residue_rebate == 0) {
                this.isdo = 0
            } else {
                this.isdo = 1
            }
        },
        // 鼠标移出
        madeOut() {
            this.isdo = 0
        }
    },
    mounted() {
        document.body.style.backgroundColor = 'rgb(5, 3, 0)';
    },
    created() {
        this.hdDanoInfo()
    }
}
</script>

<style scoped>
body {
    overflow-x: hidden;
    background: rgb(5, 3, 0);

}

.isClick {
    filter: grayscale(100%);
}

.rebate {
    width: 100%;
    height: 100%;
}

.rebate_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -99;
    width: 100%;
}

.content_box {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 45%;
}

.red_bag {
    width: 10vw;
    cursor: pointer;
}

.red-envelope {
    animation: shake 0.8s ease-in-out infinite;
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(5deg);
    }

    40% {
        transform: rotate(-5deg);
    }

    60% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

/* center */
.item {
    position: relative;
}

.item span {
    margin-left: 45px;
    color: #fff9eb;
    font-size: 12px;
    position: absolute;
    bottom: 7%;
    left: 5%;
}

.center_box {
    margin-top: 40px;
}

/* 规则 */
.rule {
    margin-top: 40px;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 25px 200px;
    display: flex;
    justify-content: center;
}

.rule span {
    font-size: 16px;
    font-family: FZShengShiKaiShuS-DB-GB;
    font-weight: 400;
    color: #9E0E00;
    position: relative;

}

.rule_bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -90;
}

/* 弹窗 */
.leader_tc {
    display: none;
}

.leader_bg {
    position: relative;
    z-index: -10;
}

.tc_title {
    position: absolute;
    left: 47%;
    top: 44%;
    transform: translateX(-50%);
    text-align: center;
}

.tc_title h5 {
    color: #FFD700;
    font-size: 16px;
}

.tc_title span {
    color: #FFD700;
    font-size: 25px;
}

.leader_btn {
    position: absolute;
    left: 47%;
    top: 61%;
    transform: translateX(-50%);
    cursor: pointer;
    transition: all 0.5s;
}

.leader_btn:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

@media (max-width:1600px) {
    .content_box {
        top: 50%;
    }
}

@media (max-width:1400px) {}

@media (max-width:1300px) {
    .center_box {
        margin-top: 20px;
    }

    .rule {
        margin-top: 20px;
        padding: 15px 200px;
    }

    .rule span {
        font-size: 12px;
    }
}

@media (max-width:1200px) {}

@media (max-width:1100px) {}</style>