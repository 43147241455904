<template>
    <div class="labour">
        <div class="day_box flex_box">
            <span class="day_text">2024年4月30日0点-2024年5月6日0点（活动有效期6天）</span>
        </div>
        <div class="text_box">
            <div class="text_content">
                <span>累计充值：{{ money.allmoney ? money.allmoney : 0 }}</span>
                <span>当前充值：{{ money.reset_no_money ? money.reset_no_money : 0 }}</span>
                <span>重置前充值：{{ money.reset_money ? money.reset_money : 0}}</span>
            </div>
            
            <img src="/activity/labourDay/img/text_bg.png">
        </div>
        <div class="content_box">
            <div class="bag_box flex_box">
                <div class="bag_item">
                    <img class="bag"  :class="{'bwite': !bagdata.oneEgg}" src="/activity/labourDay/img/bag.png">
                    <div class="money_box">
                        <div class="money_item">
                            <span class="money_text">累计充值100<br>赠送600储值</span>
                            <!-- 背景 -->
                            <img class="money_bg" src="/activity/labourDay/img/money_bg.png">
                        </div>
                    </div>
                </div>
                <div class="bag_item">
                    <img class="bag" :class="{'bwite': !bagdata.twoEgg}" src="/activity/labourDay/img/bag.png">
                    <div class="money_box">
                        <div class="money_item">
                            <span class="money_text">累计充值200<br>赠送800储值</span>
                            <!-- 背景 -->
                            <img class="money_bg" src="/activity/labourDay/img/money_bg.png">
                        </div>
                    </div>
                </div>
                <div class="bag_item">
                    <img class="bag" :class="{'bwite': !bagdata.threeEgg}" src="/activity/labourDay/img/bag.png">
                    <div class="money_box">
                        <div class="money_item">
                            <span class="money_text">累计充值300<br>赠送900储值</span>
                            <!-- 背景 -->
                            <img class="money_bg" src="/activity/labourDay/img/money_bg.png">
                        </div>
                    </div>
                </div>
                <div class="bag_item">
                    <img class="bag" :class="{'bwite': !bagdata.fourEgg}" src="/activity/labourDay/img/bag.png">
                    <div class="money_box">
                        <div class="money_item">
                            <span class="money_text">累计充值500<br>赠送1300储值</span>
                            <!-- 背景 -->
                            <img class="money_bg" src="/activity/labourDay/img/money_bg.png">
                        </div>
                    </div>
                </div>
                <div class="bag_item">
                    <img class="bag" :class="{'bwite': !bagdata.fiveEgg}" src="/activity/labourDay/img/bag.png">
                    <div class="money_box">
                        <div class="money_item">
                            <span class="money_text">累计充值1000<br>赠送3200储值</span>
                            <!-- 背景 -->
                            <img class="money_bg" src="/activity/labourDay/img/money_bg.png">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 背景 -->
            <img class="content_bg" src="/activity/labourDay/img/content_bg.png">
        </div>
        <div class="rule">
            <div class="left_box">
                <h5 class="rule_title">活动说明：</h5>
                <span class="rule_text">1.充值累积达到对应档位，自动领取对应档位福袋。<br>
                    2.累充到1000档位，所有福袋自动重置。<br>
                    3.每天玩家有一次机会手动刷新重置福袋，重置后累充金额重新计算。<br>
                    4.该活动与多充多送、满减活动叠加。
                </span>
            </div>
            <img class="reset_btn btn_hover" src="/activity/labourDay/img/btn.png" @click="resetting">
        </div>
    </div>
</template>

<script>
import {doubleEggInfo,DoubleEggReset} from "@/api/http";
export default {
    name: 'LabourDay',
    data() {
      return {
        isactive:false, //活动是否开启
        bagdata:[],//所有蛋的信息
        reset:false,//当日是否可以重置
        money:[]
      }
    },
    methods: {
        // 活动信息接口
        doubleEggInfo(){
              doubleEggInfo({
                  token:this.$route.query.token
              }).then((res)=>{
                  if(res.code == 200){
                   this.bagdata = res.data.eggdata
                   this.reset = res.data.reset
                   this.money = res.data.money
                  }
              }).catch((err)=>{
                  console.log('捕获异常', err)
                  this.$message({
                      message: err.msg,
                      type: 'warning',
                      duration: 1500,
                  })
              })
          },
        // 重置接口
        DoubleEggReset(){
            DoubleEggReset({
                token:this.$route.query.token
            }).then((res)=>{
                if(res.code == 200){
                    this.$message({
                        message: '重置成功',
                        type:'success',
                        duration: 1500,
                        onClose:()=>{
                            this.doubleEggInfo()
                        }
                    })
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                })
            })
        },
        // 重置按钮
        resetting(){
            if(this.reset){
                this.$confirm('重置后累充金额重新计算,是否继续重置？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.DoubleEggReset()
                }).catch(() => {
                });
            }else{
                this.$message({
                    message: '您今日已经重置过了~',
                    type: 'warning',
                    duration: 1500,
                })
            }
            
        },

    },
    created() {
        this.doubleEggInfo()
    },
}
</script>

<style scoped>
.bwite{
    filter: grayscale(100%);
}
.labour{
   background: url('/public/activity/labourDay/img/bg.png') no-repeat;
   background-size: 1920px 1080px;
   min-width: 1903px;
   min-height: 1080px;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 528px;
}
.day_box{
    background: url('/public/activity/labourDay/img/time_bg.png') no-repeat;
    background-size: 660px 40px;
    min-width: 660px;
    min-height: 40px;
}
.day_text{
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 18px;
    color: #FFEFD6;
}
.text_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
}
.text_content{
    position: absolute;
}
.text_box span{
    font-size: 16px;
    font-family: SimSun;
    color: #ce0f0e;
    margin-right: 20px;
}
.text_box span:nth-of-type(3){
    margin: 0;
}
.content_box{
    margin-top: 15px;
    position: relative;
}
.bag_box{
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}
.bag_item{
    position: relative;
}
.money_box{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top:71%
}
.money_item{
    position: relative;
}
.money_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #FFEBB6;
    line-height: 16px;
    text-align: center;
    width: 100%;
}
.rule{
    background: url('/public/activity/labourDay/img/sm_bg.png') no-repeat;
    background-size: 610px 140px;
    min-width: 610px;
    min-height: 140px;
    padding: 17px 28px;
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.left_box{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.reset_btn{
    margin-right: 13px;
    cursor: pointer;
}
.rule_title{
    font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFEFD6;
}
.rule_text{
    font-family: SimSun;
    font-weight: 400;
    font-size: 12px;
    color: #FFEFD6;
}
</style>