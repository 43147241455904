<template>
  <div class="win">
    <div class="win_title">
        <h3>恭喜您获得</h3>
        <span>{{name.name}}</span>
        <br>
        赠送<span>{{ name.integral }}</span>积分
    </div>
    <img class="btn" src="/activity/turntable/img3/btn.png">
    <img class="win_img" src="/activity/turntable/img3/win.png">
  </div>
</template>

<script>
    export default {
        name:'Winning2',
        props:['name']
    }
</script>

<style scoped>
    .btn{
        position: absolute;
        bottom: 3%;
        left: 42%;
        cursor: pointer;
    }
    .win{
        display: none;
        position: relative;
        width: 100%;
    }
    .win_img{
        position: relative;
        top: 0;
        left: 0;
        z-index: -10;
    }
    .win_title{
        font-size: 24px;
        color: #FCF5BE;
        position: absolute;
        left: 60%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .win_title h3{
        font-size: 24px;
        margin: 0;
    }
    .win_title span{
        font-weight: bold;
        font-size: 30px;
    }
    .layui-layer{
        background-color: transparent !important;
        box-shadow: none !important;
    }
</style>